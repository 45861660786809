<template>
  <div
    class="my-personal-detail"
  >
    <div class="my-personal-detail__label ubuntuRegular ariane-txt-color-secondary ariane-text-emphatize">
      {{ label }}
    </div>
    <div class="my-personal-detail__info ubuntuMedium">
      {{ info }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyPersonalDetail',

  props: {
    label: String,
    info: String
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';
  .my-personal-detail{
    padding: 0 1rem 0 0;
    .my-personal-detail__label{
    }
    .my-personal-detail__info{
      min-height: 1rem;
      color: @main-color;
      font-weight: @heavy-font-weight;
    }
  }
</style>
