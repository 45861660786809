import {
  memberAPI,
  publicAPI
} from '../request';
import { findDomain, adaptUrl } from '@/utils/url';
export default {
  getSessionsFromMembersApiById (idSession, code = '') {
    return memberAPI.get(`sessions/${idSession}/${code}`);
  },
  getSessionsFromPublicApiById (idSession, code = '') {
    const url = adaptUrl(':domain/sessions/:sessionId/:code', {
      params: {
        sessionId: idSession,
        code,
        domain: findDomain()
      }
    });
    return publicAPI.get(url);
  },
  getSessionsFromMembersApi: ({ activityIds, resources, coachIds, zoneId, from, to } = {}) => {
    const url = adaptUrl('sessions', {
      queries: {
        zoneId,
        from,
        to,
        activityIds,
        resourceIds: resources,
        coachIds
      }
    });
    return memberAPI.get(url);
  },
  getShareLink: (sessionId, code = null) => {
    return memberAPI.get(`sessions/${sessionId}/${code ? `${code}/` : ''}share`);
  },
  getShareLinkPublic: (sessionId, code = null) => {
    const params = { domain: findDomain(), sessionId };
    let url = null;
    if (code) {
      params.code = code;
      url = adaptUrl(':domain/sessions/:sessionId/:code/share', { params });
    } else {
      url = adaptUrl(':domain/sessions/:sessionId/share', { params });
    }
    return publicAPI.get(url);
  },
  getSessionsFromPublicApi: ({ activityIds, resources, coachIds, zoneId, from, to } = {}) => {
    const url = adaptUrl(':domain/sessions', {
      params: {
        domain: findDomain()
      },
      queries: {
        zoneId,
        from,
        to,
        activityIds,
        resourceIds: resources,
        coachIds
      }
    });
    return publicAPI.get(url);
  }
};
