import HTTP from '../../services/HTTP';
import CONSTANTS from '@/utils/constants';
import i18n from '@/services/translation/i18n';
const { t } = i18n.global;

export default {
  namespaced: true,
  state: {
    memberAppConfigs: {},
    tunnelConfigurations: {},
    calendarConfigs: {
      zone: null,
      activities: [],
      resources: [],
      coaches: [],
      date: null,
      mode: CONSTANTS.CALENDAR_MODES.SESSION
    },
    localStorageUnavailable: false
  },
  mutations: {
    SET_MEMBER_APP_CONFIGS (state, configs) {
      state.memberAppConfigs = configs;
    },
    SET_SELECTED_ACTIVITIES (state, selectedActivities) {
      state.calendarConfigs.activities = selectedActivities;
    },
    SET_SELECTED_RESOURCES (state, selectedResources) {
      state.calendarConfigs.resources = selectedResources;
    },
    SET_SELECTED_COACHES (state, coachIds) {
      state.calendarConfigs.coaches = coachIds;
    },
    SET_SELECTED_ZONE (state, selectedZone) {
      if (selectedZone) state.calendarConfigs.zone = selectedZone;
    },
    SET_SELECTED_DATE (state, date) {
      state.calendarConfigs.date = date;
    },
    SET_SELECTED_CALENDAR_MODE (state, calendarMode) {
      state.calendarConfigs.mode = calendarMode;
    },
    SET_TUNNEL_CONFIGURATIONS (state, configurations) {
      state.tunnelConfigurations = configurations;
    },
    SET_LOCALSTORAGE_UNAVAILABLE (state) {
      state.localStorageUnavailable = true;
    }
  },
  actions: {
    async loadMemberAppConfigurations ({ dispatch, commit }) {
      const res = await HTTP.zone.getMemberAppConfigs();
      commit('SET_MEMBER_APP_CONFIGS', res.data);
      dispatch('_detectCalendarModeConfiguration', res.data);
    },
    _detectCalendarModeConfiguration ({ dispatch, commit }, data) {
      if (data.webConfig_calendarMode === CONSTANTS.BOOKING_MODES.ONLY_SESSION) {
        commit('SET_SELECTED_CALENDAR_MODE', CONSTANTS.CALENDAR_MODES.SESSION);
      } else if (data.webConfig_calendarMode === CONSTANTS.BOOKING_MODES.ONLY_RESOURCE_AVAILABILITY) {
        commit('SET_SELECTED_CALENDAR_MODE', CONSTANTS.CALENDAR_MODES.AVAILABILITY);
      } else if (data.LDC_web_showResourcesFirst) {
        commit('SET_SELECTED_CALENDAR_MODE', CONSTANTS.CALENDAR_MODES.AVAILABILITY);
      }
    },
    updateTunnelConfigurationShopAndCalendarByZone ({ commit, rootState }, value) {
      const tunnelConfigurations = rootState.config.tunnelConfigurations;
      commit('SET_TUNNEL_CONFIGURATIONS', {
        ...tunnelConfigurations,
        [CONSTANTS.TUNNEL_LABELS.SHOP_BY_ZONE]: value,
        [CONSTANTS.TUNNEL_LABELS.CALENDAR_BY_ZONE]: value
      });
    }
  },
  getters: {
    // APP CONFIG GETTERS
    currency: state => {
      return state.memberAppConfigs?.currency?.symbol || '€';
    },
    facebookTrackingId: state => {
      return state.memberAppConfigs?.pixelFacebook;
    },
    googleTrackingId: state => {
      return state.memberAppConfigs?.wgoogleanalytics;
    },
    publicRoutes: state => {
      return state.memberAppConfigs?.publicRoutesScopes
        ? JSON.parse(state.memberAppConfigs.publicRoutesScopes)
        : null;
    },
    reservationLink: state => {
      return state.memberAppConfigs?.wresa_web;
    },
    domainName: state => {
      return state.memberAppConfigs?.wnom_club_web;
    },
    webUrl: state => {
      return state.memberAppConfigs?.webConfig_url;
    },
    resourceLabel: state => {
      return state.memberAppConfigs?.webConfig_resource_label || t('configurations.defaultResourceLabel');
    },
    customMenus: (state, getters, rootState, rootGetters) => {
      const loggedInUser = rootGetters['user/loggedInUser'];
      if (!loggedInUser && state.memberAppConfigs?.webConfig_customMenus) {
        return state.memberAppConfigs.webConfig_customMenus.filter(customMenu => !!customMenu.filters.zoneId || customMenu.filters.zoneId === null);
      } else {
        return state.memberAppConfigs?.webConfig_customMenus;
      }
    },
    appStoreUrls: state => {
      return state.memberAppConfigs?.appStoreUrls;
    },
    showCoaches: state => {
      return state.memberAppConfigs?.LDC_afficherCoach;
    },
    showLogo: state => {
      return state.memberAppConfigs?.webConfig_showLogo;
    },
    showNbPlaces: state => {
      return state.memberAppConfigs?.LDC_afficherNbPlace;
    },
    prepaidCardSaleMode: state => {
      return state.memberAppConfigs?.webConfig_prepaidCardSaleMode;
    },
    subscriptionSaleMode: state => {
      return state.memberAppConfigs?.webConfig_subscriptionSaleMode;
    },
    // CALENDAR CONFIG GETTERS
    hasBothCalendarModes: state => {
      return state.memberAppConfigs?.webConfig_calendarMode === CONSTANTS.BOOKING_MODES.BOTH_SESSION_AND_AVAILABILITY;
    },
    selectedZone: state => {
      return state.calendarConfigs.zone;
    },
    selectedZoneId: state => {
      return state.calendarConfigs?.zone?.id;
    },
    selectedCalendarMode: state => {
      return state.calendarConfigs.mode;
    },
    selectedActivities: state => {
      return state.calendarConfigs.activities;
    },
    selectedResources: state => {
      return state.calendarConfigs.resources;
    },
    selectedCoaches: state => {
      return state.calendarConfigs.coaches;
    },
    selectedDate: state => {
      return state.calendarConfigs.date;
    },
    isCustomMenuInHeader: (state, getters) => {
      return !getters.customMenus || getters.customMenus?.length <= 1;
    },
    // TUNNEL CONFIG GETTERS
    zoneFilterByTunnel: state => {
      return state.tunnelConfigurations[CONSTANTS.TUNNEL_LABELS.CALENDAR_BY_ZONE]
        ? parseInt(state.tunnelConfigurations[CONSTANTS.TUNNEL_LABELS.CALENDAR_BY_ZONE])
        : false;
    },
    coachFilterByTunnel: state => {
      const queryCoaches = state.tunnelConfigurations[CONSTANTS.TUNNEL_LABELS.CALENDAR_BY_COACH];
      if (queryCoaches) {
        if (Array.isArray(queryCoaches)) {
          const returnedCoach = [];
          queryCoaches.forEach(qc => {
            returnedCoach.push(parseInt(qc));
          });
          return returnedCoach;
        }
        const targettedCoaches = state.tunnelConfigurations[CONSTANTS.TUNNEL_LABELS.CALENDAR_BY_COACH].split('-').map(id => parseInt(id));
        return targettedCoaches;
      } else {
        return false;
      }
    },
    forcedActivityByTunnel: (state, _, rootState) => {
      const queryActivity = state.tunnelConfigurations[CONSTANTS.TUNNEL_LABELS.CALENDAR_BY_ACTIVITY];
      if (queryActivity) {
        const allActivities = rootState.zone.activities;
        if (Array.isArray(queryActivity)) {
          const returnedActivity = [];
          queryActivity.forEach(qa => {
            returnedActivity.push(getIdFromTunnelQuery(qa, allActivities));
          });
          return returnedActivity;
        } else return getIdFromTunnelQuery(queryActivity, allActivities);
      }
    },
    forcedResourceByTunnel: (state, _, rootState) => {
      const queryResource = state.tunnelConfigurations[CONSTANTS.TUNNEL_LABELS.CALENDAR_BY_RESOURCE];

      if (queryResource) {
        const allResources = rootState.zone.resources;
        if (Array.isArray(queryResource)) {
          const returnedResource = [];
          queryResource.forEach(qr => {
            returnedResource.push(getIdFromTunnelQuery(qr, allResources));
          });
          return returnedResource;
        } else return getIdFromTunnelQuery(queryResource, allResources);
      }
    },
    forcedZoneByTunnel: state => {
      return getIdFromTunnelConfiguration(state.tunnelConfigurations, CONSTANTS.TUNNEL_LABELS.SINGLE_ZONE);
    },
    zoneShopFilterByTunnel: state => {
      return getIdFromTunnelConfiguration(state.tunnelConfigurations, CONSTANTS.TUNNEL_LABELS.SHOP_BY_ZONE);
    },
    unconnectedWelcomeModalByTunnel: state => {
      return state.tunnelConfigurations[CONSTANTS.TUNNEL_LABELS.UNCONNECTED_WELCOME_MODAL];
    },
    cornerLogoRemovedByTunnel: state => {
      return state.tunnelConfigurations[CONSTANTS.TUNNEL_LABELS.NO_CORNER_LOGO];
    },
    showLandingPage: (state, _, rootState, rootGetters) => {
      // if is not multi-site and has only one activity, do not show landing page
      if (!rootGetters['domain/isMultiSite'] && rootState.zone.activities.length === 1) {
        return false;
      }
      return state.memberAppConfigs?.LDC_afficherPageDecouverte;
    },
    slotPricingLabel: state => value => {
      let princingLabel = '';
      if (state.memberAppConfigs?.slotPricing && state.memberAppConfigs.slotPricing.length > 0) {
        const pricing = state.memberAppConfigs.slotPricing.find(p => p.value === value);
        if (pricing?.text) princingLabel = pricing.text;
      }
      return princingLabel;
    }
  }
};

function getIdFromTunnelConfiguration (tunnelConfiguration, key) {
  return tunnelConfiguration[key]
    ? parseInt(tunnelConfiguration[key])
    : false;
}

function getIdFromTunnelQuery (query, datasQuery) {
  if (parseInt(query)) return parseInt(query);
  else {
    const objResource = datasQuery.find(r => r.name.toLowerCase() === query.toLowerCase());
    return objResource?.id;
  }
}
