<template>
  <a :href="video.url" target="_blank">
    <div class="video-player-external">
      <VideoThumbnail
        :video="video"
        :showTitle="false"
        :showCoach="false"
        :showDuration="false"
        :showPrice="false"
      />
      <div class="video-player-external__overlay">
        <i class="far fa-play-circle"></i>
      </div>
    </div>
  </a>
</template>

<script>
import VideoThumbnail from './VideoThumbnail.vue';

export default {
  name: 'VideoPlayerExternal',

  components: {
    VideoThumbnail
  },

  props: {
    video: Object
  }
};
</script>

<style lang="less">
.video-player-external{
  position: relative;
  .video-player-external__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 2;
    .fa-play-circle{
      color: white;
      position: absolute;
      font-size: 4rem;
      left: calc(50% - 2rem);
      top: calc(50% - 2rem);
    }
  }
}
</style>
