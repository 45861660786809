<template>
  <div class="flex flex-col md:gap-default lg:p-xs" :class="{ 'h-full': !isIframe()}">
    <div class="flex flex-col md:gap-default overflow-auto p-xs" :class="{ 'flex-grow': !isIframe()}">
      <div class="flex flex-col gap-xxs">
        <div class="flex items-center gap-sm justify-between">
          <span class="tag-live" v-if="timeslot.isLivestream">VIDEO</span>
          <div class="font-medium text-base text-primary flex gap-xxs">
            <span v-if="timeslot.description">{{ timeslot.description }} - </span>
            <div class="flex gap-xxs">
              <span>{{ getCurrentActivity.name }}</span>
              <i v-if="handleDescriptions.titleDescription" :title="handleDescriptions.titleDescription"
                 class="far fa-info-circle text-secondary text-xs"></i>
            </div>
          </div>
          <i v-if="isReservationSlot" @click="onShare()"
             class="fa fa-arrow-up-from-bracket cursor-pointer px-xs hover:opacity-30"/>
        </div>
        <span
          class="text-sm text-primary">{{ $datetime.getLocalMediumDate(timeslot.date) }} - {{ timeslot.startTime }}</span>
        <div class="text-sm text-secondary gap-xs">
          <span v-if="timeslot.zoneId">
            {{ getActiveZone(timeslot.zoneId) && getActiveZone(timeslot.zoneId).clubName }}
          </span>
          <span :class="{ 'cursor-pointer': getRessource.description }" :title="getRessource.description"
                v-if="isReservationSlot">- {{ getRessource.name }} <i v-if="getRessource.description"
                                                                      class="fal fa-info-circle"/></span>
          <span v-if="isReservationSlot">- {{ handleDuration }}</span>
        </div>
        <div v-if="isReservationSlot && getRessource.location" class="text-sm text-secondary gap-xs">
          <span>{{ $t('timeslot.location', { location: getRessource.location }) }}</span>
        </div>
        <tagManager displayMode :modelValue="timeslot.tags"/>
      </div>
      <el-divider class="divider-small"/>
      <div v-if="(handleDescriptions && handleDescriptions.gridDescription) || coach" class="gap-default flex flex-col">
        <div class="grid-coach-activity"
             :class="handleDescriptions && handleDescriptions.gridDescription && coach ? 'grid-2-col' : 'grid-1-col'">
          <span class="title-grid-coach-activity"
                v-if="handleDescriptions.gridDescription">{{ $t('products.description') }}</span>
          <span class="title-grid-coach-activity" v-if="coach">{{ $t('coach') }}</span>
          <div class="text-primary text-sm" v-if="handleDescriptions.gridDescription">
            <pre class="descr-grid-content "
                  :class="{ 'line-clamp-4': !showAllDescr }">{{ handleDescriptions.gridDescription.replaceAll('\n', '\n') }}</pre>
            <AriButton v-if="lineClampEnabled && handleDescriptions.gridDescription" @click="showAllDescr = !showAllDescr" text
                       :content="showAllDescr ? $t('btn.hide'): $t('btn.seeAll')" class="flex"/>
          </div>
          <div class="whitespace-nowrap flex items-center gap-xs" v-if="coach">
            <AriAvatar :user="coach.name" :url="coach.profilePictureUrl" size="44"/>
            <span>{{ coach.name }}</span>
          </div>
        </div>
        <el-divider class="divider-small"/>

        <div v-if="showNumberOfReservedPlaces" class="gap-default flex flex-col">
          <div class="title-grid-coach-activity flex flex-row">
            {{$t('timeslot.numberOfReservedPlaces')}}
            <i :title="$t('timeslot.youCanHandleInvitedAtHome')" class="far fa-info-circle text-secondary text-xs"></i>
          </div>
          <p class="text-primary text-sm">{{timeslot.memberStatus.numberOfReservedPlaces}}</p>
        </div>

      </div>
      <timeslotBookSeance :alreadyBooked="['BOOKED', 'QUEUED'].includes(timeslot.status)"
                          v-model:lessonGuests="lessonGuests" :getRessource="getRessource" :timeslot="timeslot"
                          :getCurrentActivity="getCurrentActivity" v-if="isReservationSlot"
                          :handleDescriptions="handleDescriptions" :isFull="isFull"/>
      <timeslotCreateResa :getCurrentActivity="getCurrentActivity" v-else :getRessource="getRessource"
                          :timeslot="timeslot" :schedule="schedule" ref="createResa"/>
      <ModalPlaceMap
        v-if="showPlaceSelectionModal"
        :placeMap="currentSlotPlaceMap"
        @place-selection="onPlaceSelection"
        @on-cancel="showPlaceSelectionModal = false"
      />
      <div v-if="sanctionText" class="font-bold">{{sanctionText}}</div>
    </div>
    <div class="footer-buttons">
      <AriButton text :content="$t('app.return')" @click="$emit('close')" removePaddingLeft v-if="!isOnMobile"/>
      <AriButton v-if="!['BOOKED', 'QUEUED'].includes(timeslot.status)" filled :content="computedLabelResa"
                 :disabled="isMainButtonDisabled" @click="clickMainButton()" :class="{ 'w-full': isOnMobile }"/>
      <AriButton v-else class="w-full" filled error :content="$t('timeslot.cancelTimeslot')"
                 @click="prepCancelBooking()"/>
      <!-- <AriButton filled :disabled="buttonDisabled" class="flex justify-center"
        :content="computedLabelResa" @click="clickMainButton()"/> -->
    </div>
    <AriModal v-if="showRedirectionShop" @close="cancelRedirectionShop()" :showClose="false"
              modalTitle="Aucune prestation active">
      <template #header>
        <span>{{ $t('shop.redirectionTitle') }}</span>
      </template>
      <template #body>
        <div class="flex flex-col gap-sm">
          <div class="flex flex-col">
            <span class="text-error-accent font-medium">{{ $t('shop.redirection1') }}</span>
            <span class="text-secondary font-light">{{ $t('shop.redirection2') }}</span>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-between w-full items-center flex-col-reverse sm:flex-row">
          <AriButton link :content="$t('app.close')" @click="cancelRedirectionShop()"/>
          <AriButton :content="$t('timeslot.toShop')" @click="redirectToShop()"/>
        </div>
      </template>
    </AriModal>
  </div>
</template>

<script>
import { displayBookingErrors } from '@/utils/errors';
import CalendarControl from '../../../mixins/calendarControl.js';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import timeslotCreateResa from './components/timeslotCreateResa.vue';
import timeslotBookSeance from './components/timeslotBookSeance.vue';
import AuthControl from '@/modules/auth/mixins/authControl.js';
import ModalPlaceMap from '@/modules/calendar/components/ModalPlaceMap.vue';
import EventBus from '@/services/eventBus/eventBus';
import tagManager from './components/components/tag-manager.vue';

export default {
  name: 'DetailedTimeSlot',

  components: {
    timeslotCreateResa,
    timeslotBookSeance,
    ModalPlaceMap,
    tagManager
  },

  mixins: [CalendarControl, AuthControl],
  data () {
    return {
      currentSlotPlaceMap: null,
      showPlaceSelectionModal: false,
      lessonGuests: [],
      addDescription: false,
      isOpen: false,
      schedule: {},
      showAllDescr: false,
      lineClampEnabled: false,
      showRedirectionShop: false
    };
  },
  props: {
    isLoading: Boolean,
    timeslot: Object,
    autoHideHack: Boolean,
    disableReload: Boolean,
    forceIsReservationSlot: Boolean,
    timeslotOpen: Boolean
  },
  mounted () {
    this.schedule = this.generateDefaultSchedule();
    this.isLineClampEnabled();
  },
  computed: {
    ...mapState('config', ['calendarConfigs']),
    ...mapState('zone', ['coaches']),
    ...mapGetters('config', ['showCoaches']),
    ...mapState('user', ['bookings']),
    sanctionText () {
      let sanction = '';
      const booking = this.bookings.find(memberBooking => memberBooking.booking.id === this.timeslot.id)?.booking;
      if (booking && booking.sanctionText && booking.cancelDateSanction && this.$datetime.isOlderThanCurrentTime(this.$datetime.fromISODate(booking.cancelDateSanction))) {
        sanction = booking.sanctionText;
      }

      return sanction;
    },
    coach () {
      return this.showCoaches && this.coaches.find(coach => coach.id === this.timeslot.coachId);
    },
    isValidOpenMatch () {
      if (this.isReservationSlot) return true;
      else {
        return this.schedule.resourceId;
      }
    },
    isMainButtonDisabled () {
      return (!this.waitingListActivated && this.isFull) || !this.isValidOpenMatch || this.timeslot.status === this.$CONSTANTS.STATUSES.NOT_AVAILABLE_YET;
    },
    handleDescriptions () {
      if (this.timeslot.details) {
        return {
          titleDescription: this.getCurrentActivity?.description,
          gridDescription: this.timeslot.details
        };
      } else return { gridDescription: this.getCurrentActivity?.description };
    },
    handleDuration () {
      const hours = this.timeslot.duration / 3600;
      const seconds = this.timeslot.duration % 3600 / 60;
      return `${parseInt(hours)}H${seconds ? seconds.toFixed(0) : ''}`;
    },
    computedLabelResa () {
      if (this.isFull && this.waitingListActivated) {
        return this.$t('calendar.timeslot.bookInWaitingList');
      } else if (this.isFull && !this.waitingListActivated) {
        return this.$t('calendar.timeslot.full');
      } else if (!this.isReservationPossible && this.timeslot.status === this.$CONSTANTS.STATUSES.NOT_AVAILABLE_YET) {
        return this.$t('calendar.timeslot.notAvailable');
      } else {
        return this.$t('calendar.timeslot.create');
      }
    },
    isReservationPossible () {
      return this.timeslot.status === this.$CONSTANTS.STATUSES.AVAILABLE;
    },
    isFull () {
      return this.timeslot.status === this.$CONSTANTS.STATUSES.FULL;
    },
    ...mapGetters('user', ['usersZoneDetails', 'loggedInUser']),
    ...mapState('user', ['userDomainInfo']),
    ...mapState('config', ['memberAppConfigs']),
    ...mapGetters('domain', ['getActiveZone']),
    waitingListActivated () {
      return this.memberAppConfigs && this.memberAppConfigs.waitingListStatus !== 'disabled' && this.timeslot.visibility !== 'public';
    },
    isReservationSlot () {
      return this.forceIsReservationSlot || !this.timeslot.isResource;
    },
    rightContentHeader () {
      return this.isReservationSlot ? `${this.timeslot.startTime} - ${this.timeslot.endTime}` : `${this.getCurrentActivity.name} - ${this.timeslot.startTime}`;
    },
    titlePopover () {
      if (this.isReservationSlot) return this.timeslot.description;
      else if (this.timeslot.allowPublicSessions) return this.$t('timeslot.createOpenMatch');
      else return this.$t('timeslot.bookResource');
    },
    minPlacesToBook () {
      if (this.timeslot?.resources?.length > 0 && this.schedule.resourceId) {
        const myResource = this.timeslot.resources.find(r => r.id === this.schedule.resourceId);
        return this.schedule.visibility === 'O' ? myResource.minPlacesToBookPublic : myResource.minPlacesToBookPrivate;
      } else return 1;
    },
    generateSanitizedtags () {
      const sanitizedTags = {};
      if (this.getCurrentActivity?.reservationTags) {
        this.getCurrentActivity.reservationTags.forEach(tagGroup => {
          if (this.schedule.tags) {
            this.schedule.tags.forEach(tag => {
              if (tagGroup.values.includes(tag)) sanitizedTags[tagGroup.code] = tag;
            });
          }
        });
      }
      return sanitizedTags;
    },
    buttonDisabled () {
      const resourceIdMissing = !this.schedule.resourceId;
      const durationMissing = !this.schedule.duration;
      // const isPublicWithoutTags = this.schedule.visibility === 'O' ? this.schedule.tags.length !== this.getCurrentActivity.reservationTags.length : false;
      const invitedWithoutError = (participant) => {
        return this.schedule.invitedMembers.length > 0 ? (participant.username && participant.email) || (!participant.email && !participant.username) : false;
      };
      const checkInvitedWithWrongParameters = this.schedule.invitedMembers.length > 0 ? !this.schedule.invitedMembers.every(invitedWithoutError) : false;
      return (resourceIdMissing || checkInvitedWithWrongParameters || durationMissing) && !!this.loggedInUser;
    },
    getRessource () {
      const resource = this.resources.find(resource => resource.id === (this.schedule?.resourceId || this.timeslot?.resourceId)) || {};
      if (resource?.metadata?.address) {
        resource.location = `${resource.metadata.address} ${(resource.metadata?.postalCode || resource.metadata?.city) ? '-' : '' } ${resource.metadata?.postalCode || ''} ${resource.metadata?.city || ''}`;
      }
      return resource;
    },
    getCurrentActivity () {
      return this.activities.find(activity => activity.id === this.timeslot?.activityId) || {};
    },
    ...mapState('zone', ['resources', 'activities']),
    ...mapState('user', ['deviceFormat']),
    isOnMobile () {
      return this.deviceFormat === this.$CONSTANTS.DEVICE_FORMATS.MOBILE;
    },
    showNumberOfReservedPlaces () {
      return this.timeslot.memberStatus?.numberOfReservedPlaces > 1;
    },
    hasWebPayment () {
      return this.loggedInUser ? this.usersZoneDetails.hasWebPayment : this.getActiveZone(this.zoneFilterByTunnel)?.hasWebPayment;
    }
  },
  methods: {
    ...mapActions('user', ['bookSession', 'loadUpcomingUserBookings', 'registerToWaitingList', 'saveBookingDuringPurchase', 'saveTimeslotForDetailsPage']),
    ...mapMutations('cart', ['SET_PENDING_RESA']),
    deleteParticipant (index) {
      if (this.getCurrentActivity?.minPlacesToBook || (this.schedule.invitedMembers.length >= this?.getCurrentActivity?.minPlacesToBook)) {
        this.schedule.invitedMembers.splice(index, 1);
      }
    },
    isIframe () {
      try {
        return window.self !== window.top;
      } catch (err) {
        return false;
      }
    },
    isLineClampEnabled () {
      this.$nextTick(() => {
        const activityDescriptionElement = document.querySelector('.descr-grid-content');
        if (activityDescriptionElement) {
          this.lineClampEnabled = activityDescriptionElement.scrollHeight > activityDescriptionElement.offsetHeight + 1;
        }
      });
    },
    changeArraySchedule (newSchedule) {
      this.$set('schedule', newSchedule);
    },
    onPlaceSelection (placeId) {
      this.book(placeId);
    },
    async prepCancelBooking () {
      const cancellationDetails = {
        cancelId: this.timeslot.id,
        bookingDateAndTime: this.$datetime.getLocalMediumDateAndTime(this.timeslot.date),
        calendarMode: this.$CONSTANTS.CALENDAR_MODES.SESSION
      };
      let cancelOk = false;
      if (this.timeslot.status === this.$CONSTANTS.STATUSES.BOOKED) {
        cancellationDetails.bookingType = this.$CONSTANTS.BOOKING_TYPES.BOOKING;
        cancelOk = await this.cancelBooking(cancellationDetails);
        !this.disableReload && await this.getAllTimeslotsFromStore();
      } else {
        cancellationDetails.bookingType = this.$CONSTANTS.BOOKING_TYPES.WAITING_LIST;
        cancelOk = await this.cancelBooking(cancellationDetails);
        !this.disableReload && await this.getAllTimeslotsFromStore();
      }
      !this.disableReload && this.reloadBookingsAfterChange();
      if (cancelOk) {
        this.$emit('booking-cancel');
      }
    },
    async reloadBookingsAfterChange () {
      await this.loadUpcomingUserBookings();
      await this.getAllTimeslotsFromStore();
      if (this.isOnMobile) this.$router.go(-1);
    },
    onProductRequired (placeId) {
      this.saveBookingDuringPurchase({ ...this.timeslot, placeId });
      this.$information.alert({
        message: this.$t('products.productRequiredSubtitle'),
        duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.LONG,
        type: this.$CONSTANTS.USER_MESSAGE_TYPES.GRAY_MESSAGE
      });
      this.$router.push({ name: 'shop', query: { activityId: this.timeslot.activityId } });
    },
    async onRegisterToWaitingList () {
      if (!this.loggedInUser) {
        this.alertSignInMandatory();
        this.redirectToSignIn();
      } else {
        const { waitingListOrder } = await this.registerToWaitingList(this.timeslot.id);
        this.waitingListOrder = waitingListOrder;
        // const bookingResult = this.handleBookingResult(registrationResponse, true);
        if (this.waitingListOrder > 0) {
          !this.disableReload && this.reloadBookingsAfterChange();
          this.onWaitingListRegistered();
        }
        // if (bookingResult == iserror)
      }
    },
    onWaitingListRegistered (waitingListOrder) {
      this.$emit('on-waiting-list-registered', this.waitingListOrder);
      this.$emit('booking-success');
      this.$information.alert({
        message: this.$t('booking.success'),
        type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
      });
    },
    async onBook () {
      if (!this.loggedInUser) {
        this.alertSignInMandatory();
        EventBus.$emit('saveCalendarStateForLoggedIn', this.timeslot.id);
        this.redirectToSignIn();
      } else {
        if (typeof this.timeslot.visibility === 'string') this.book();
        else {
          const resSessionDetails = await this.getSessionDetails(this.timeslot.id);
          this.currentSlotPlaceMap = resSessionDetails?.map;
          if (this.currentSlotPlaceMap) {
            this.showPlaceSelectionModal = true;
          } else {
            this.book();
          }
        }
      }
    },
    async book (placeId) {
      this.$emit('update:isLoading', true);
      const body = {
        bookingId: this.timeslot.id,
        placeId,
        fullError: true,
        invitedMembers: this.isReservationSlot ? this.lessonGuests || undefined : this.schedule?.invitedMembers || undefined
      };
      if (this.$route.params.code) {
        body.uniqueId = this.$route.params.code;
      }
      const bookingResponse = await this.bookSession(body);
      const bookingResult = this.handleBookingResult(bookingResponse, true);
      this.isBookingSuccessful = bookingResult === this.$CONSTANTS.CALENDAR.SUCCESSFUL_BOOKING;
      if (this.isBookingSuccessful) {
        this.trackBooking();
        !this.disableReload && this.reloadBookingsAfterChange();
        this.$information.alert({
          message: this.$t('booking.success'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
        this.$emit('booking-success');
      } else {
        const recommendedSubscriptions = bookingResult?.response?.data?.data?.recommendedSubscriptions;
        if (recommendedSubscriptions?.length) {
          body.zoneId = this.calendarConfigs.zone;
          this.SET_PENDING_RESA({ recommendedSubscriptions, resaDatas: body });
          if(this.hasWebPayment) {
            this.showRedirectionShop = true;
          } else {
            displayBookingErrors(bookingResult, body);
          }
        } else displayBookingErrors(bookingResult, body);
      }
      this.$emit('update:isLoading', false);
    },
    cancelRedirectionShop () {
      this.showRedirectionShop = false;
      this.SET_PENDING_RESA(null);
    },
    redirectToShop () {
      this.$router.push({ name: 'shop' });
    },
    nextBookingAction () {
      if (this.isReservationPossible) {
        return this.onBook();
      } else if (this.isFull && this.waitingListActivated) {
        return this.onRegisterToWaitingList();
      }
      return () => null;
    },
    generateDefaultSchedule () {
      const resourceId = this.timeslot?.resources && this.timeslot?.resources.length === 1 ? this.timeslot.resources[0].id : null;
      const myRessource = resourceId && this.resources ? this.resources.find(resource => resource.id === resourceId) : null;
      const minGuest = this.activities.find(act => act.id === this.timeslot.activityId)?.minPlacesToBook || 1;
      const invitedMemberArr = [];
      invitedMemberArr.length = minGuest - 1;
      invitedMemberArr.fill({});
      const ressourceDefaultDuration = myRessource?.defaultDuration;
      const ressourceFirstAvailableDuration = myRessource?.availableDurations?.length > 0 ? myRessource?.availableDurations[0] : null;
      const timeSlotFirstAvailableDuration = this.timeslot?.availableDurations?.length > 0 ? this.timeslot?.availableDurations[0] : null;
      return {
        visibility: 'P',
        resourceId: resourceId || null,
        invitedMembers: invitedMemberArr,
        capacity: myRessource ? myRessource?.maxPlayerCount : 1,
        duration: ressourceDefaultDuration || ressourceFirstAvailableDuration || timeSlotFirstAvailableDuration || null,
        tags: [],
        details: ''
      };
    },
    clickMainButton () {
      if (this.isReservationSlot) {
        this.nextBookingAction();
      } else {
        if (this.loggedInUser) this.$refs.createResa.checkStates();
        this.createReservation();
      }
    },
    createReservation () {
      if (!this.loggedInUser) {
        this.alertSignInMandatory();
        this.redirectToSignIn();
      } else {
        const body = {
          schedule: { ...this.schedule }
        };
        this.$emit('update:isLoading', true);
        body.invitedMembers = this.schedule.invitedMembers;
        body.schedule.activityId = this.getCurrentActivity.id;
        body.schedule.date = this.timeslot.isoDate;
        body.schedule.tags = this.generateSanitizedtags;
        delete body.schedule.invitedMembers;
        this.$services.HTTP.booking.bookResourceMO(body).then(res => {
          this.reloadBookingsAfterChange();
          this.$information.alert({
            message: this.$t('booking.success'),
            type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
          });
          // eslint-disable-next-line no-return-assign
        }).catch((err) => {
          const recommendedSubscriptions = err.response.data.data.recommendedSubscriptions;
          if (recommendedSubscriptions?.length) {
            this.SET_PENDING_RESA({ recommendedSubscriptions, resaDatas: body });
            this.showRedirectionShop = true;
          } else displayBookingErrors(err, body);
        }).finally(() => {
          this.$emit('update:isLoading', false);
          this.$emit('update:autoHideHack', true);
        });
      }
    },
    async onShare () {
      let getShareLink = null;
      if (this.loggedInUser) {
        getShareLink = this.$services.HTTP.session.getShareLink;
      } else {
        getShareLink = this.$services.HTTP.session.getShareLinkPublic;
      }
      const code = this.$route.params.code;
      try {
        const { data } = await getShareLink(this.timeslot.id, code);
        if (data.link) {
          EventBus.$emit('share', { url: data.link });
        }
      } catch (error) {
        this.$information.capture({ error });
        this.$information.alert({
          showClose: true,
          message: this.$t('booking.share.error'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
        });
      }
    }
  }
};
</script>

<style>

.tag-live {
@apply bg-error font-medium py-xxs rounded-sm;
  color: #fff;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 10px;
}
pre {
  font-family: 'Ubuntu', 'Poppins', 'Arial', sans-serif !important;
}
.divider-small {
  margin: 0px !important;
}
.descr-grid-content {
  @apply leading-4 whitespace-break-spaces;
  text-justify: auto;
}
.title-grid-coach-activity {
@apply text-sm font-normal text-secondary;
}

.grid-coach-activity {
@apply grid gap-x-md gap-y-sm;
  grid-template-rows: repeat(2, auto);
}

.grid-2-col {
  grid-template-columns: 1fr auto;
}

.grid-1-col {
  grid-template-columns: 1fr;
}

.footer-buttons {
  @apply w-full flex items-center justify-between gap-md px-xs py-sm bg-content;
}

@media (max-width: 1024px) {
  .footer-buttons {
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  }
}
</style>
