<template>
  <div v-if="!displayMode" class="flex flex-col gap-default overflow-auto">
    <div class="flex gap-sm" v-for="(groupTag, groupIndex) in availableTags" :key="groupIndex" :class="{ 'group-tag-error': groupInErrorIndexes.includes(groupIndex) }">
      <span class="text-error" v-if="mandatory && groupTag.required">*</span>
      <span class="white-space" v-else></span>
      <span class="whitespace-nowrap flex text-secondary">{{groupTag.title}} :</span>
      <div class="flex gap-sm overflow-y-auto">
        <span class="tag-timeslot text-main" :class="{ selected: modelValue[groupIndex] === tag }" @click="addTag(tag, groupIndex)"
              v-for="(tag, tagIndex) in groupTag.values" :key="tagIndex">{{tag}}</span>
      </div>
    </div>
  </div>
  <div v-else class="flex gap-xxs flex-wrap hover:opacity-70 cursor-pointer">
    <span class="tag-timeslot selected" :class="{ hidden: !tag }" v-for="(tag, tagIndex) in modelValue" :key="tagIndex">
      <span class="text-main">{{tag}}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'TagManager',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  data () {
    return {
      groupInErrorIndexes: []
    };
  },
  methods: {
    checkState () {
      if (this.mandatory) {
        for (let i = 1; i <= this.availableTags.length; i++) {
          if (!this.modelValue[i - 1]) this.groupInErrorIndexes.push(i - 1);
        }
      }
    },
    addTag (newTag, groupIndex) {
      const newArr = [...this.modelValue];
      if (!newArr[groupIndex] || !newArr[groupIndex].includes(newTag)) newArr[groupIndex] = newTag;
      else {
        newArr[groupIndex] = null;
      };
      this.$emit('update:modelValue', newArr);
    }
  },
  props: {
    modelValue: [],
    availableTags: [],
    displayMode: Boolean,
    mandatory: Boolean
  }
};
</script>

<style scoped>
.tag-timeslot {
  border-radius: 100px;
  @apply  py-xxs px-default justify-center bg-primary-300  text-on-primary items-center text-xs relative cursor-pointer opacity-50 whitespace-nowrap;
}
.tag-timeslot.selected {
  @apply opacity-100;
}
.white-space {
  width: 6.75px;
}
</style>
