<template>
  <div :class="hasProducts ? 'h-full grid place-items-center' : null">
    <div v-if="hasProducts" class="text-center text-secondary text-lg py-lg px-xs">
      {{ $t('shop.noItems') }}
    </div>
    <div v-else :class="calcGridItems(group.id)" class="grid-group-shop" v-for="group in displayedGroups" :key="group.id" v-show="group.items.length">
      <div class="flex flex-col full-row sticky">
        <!-- <span class="categorie-title ubuntuRegular">{{generateTitle(group.id)}}</span> -->
        <span class="subcategorie-title ubuntuMedium">{{$te(`products.filters.${group.id}`) ? $t(`products.filters.${group.id}`) : group.title}} ({{group.items.length}})</span>
      </div>
      <component :selectedZoneId="selectedZoneId" :is="cardFormat(item)" v-for="item in group.items" :key="item.id" :item="item"
                 :selectedCard="selectedCardId" :selectedCategory="selectedCategory" :groupTitle="group.title"  @card-selected="clickCard($event)"/>
    </div>
  </div>
</template>

<script>
import cardPresta from './components/cardPresta.vue';
import cardProduct from './components/cardProduct.vue';
import { sortGroup } from '@/utils/products';

const ITEM_PRESTATION = ['subscription', 'prepaidCard', 'gift-card'];

export default {
  name: 'group-products',
  props: {
    selectedCategory: {
      type: [Number, String],
      required: false
    },
    selectedCardId: {
      type: [Number, String],
      required: false
    },
    products: Array,
    selectedZoneId: [Number]
  },
  computed: {
    displayedGroups () {
      let arrGroups = [];
      if (this.selectedCategory) {
        if (this.selectedCategory === this.$CONSTANTS.CATEGORIES.SERVICES) {
          arrGroups = this.products.filter(gp => this.$CONSTANTS.CATEGORIES.ALL_SERVICES.includes(gp.id));
        } else if (this.selectedCategory === this.$CONSTANTS.CATEGORIES.PRODUCTS) {
          arrGroups = this.products.filter(gp => !this.$CONSTANTS.CATEGORIES.ALL_SERVICES.includes(gp.id));
        } else {
          arrGroups.push(this.products.find(gp => gp.id === this.selectedCategory));
        }
      } else {
        arrGroups = this.products;
      }
      return sortGroup(arrGroups);
    },
    hasProducts () {
      return !this.displayedGroups || this.displayedGroups.length <= 0;
    }
  },
  components: {
    cardPresta,
    cardProduct
  },

  mounted () {
  },

  methods: {
    calcGridItems (groupId) {
      return this.$CONSTANTS.CATEGORIES.ALL_SERVICES.includes(groupId) ? 'grid-group-shop-prestations' : 'grid-group-shop-products';
    },
    cardFormat (item) {
      return ITEM_PRESTATION.includes(item.type) ? 'cardPresta' : 'cardProduct';
    },
    clickCard ({ id, event }) {
      event.stopPropagation(); // prevent to call 'removeCardFocus' on parent component
      this.$emit('update:selectedCardId', id);
    },
    generateTitle (subTitle) {
      if (subTitle === this.$CONSTANTS.CATEGORIES.SERVICES) return this.$t('cart.services');
      else if (subTitle === this.$CONSTANTS.CATEGORIES.PRODUCTS) return this.$t('cart.products');
      else {
        return this.$CONSTANTS.CATEGORIES.ALL_SERVICES.includes(subTitle) ? this.$t('cart.services') : this.$t('cart.products');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.sticky {
  position: sticky;
}
.grid-group-shop {
  width: 100%;
  row-gap: 20px;
  display: grid;
  grid-template-rows: 30px repeat(auto-fit, auto);
  padding: var(--ariane-base);
}
.grid-group-shop-prestations {
  grid-template-columns: repeat(auto-fill, 300px);
  @apply gap-x-default pb-lg;
}
.grid-group-shop-products {
  grid-template-columns: repeat(auto-fit, 300px);
  @apply pb-lg gap-x-default;
}
.grid-group-shop-products .full-row {
  grid-column: span;
}
.full-row {
  grid-column: 1 / -1;
}
.categorie-title {
  @apply text-secondary;
  font-size: var(--ariane-font-size-1);
}
.subcategorie-title {
  @apply text-primary;
  font-size: var(--ariane-font-size-4);
}
@media (max-width: 640px) {
  .grid-group-shop-prestations {
    grid-template-columns: 100%;
  }
}
@media (max-width: 640px) {
  .grid-group-shop-products {
    grid-template-columns: repeat(2, minmax(49%, auto));
    column-gap: 5px;
  }
}
</style>
