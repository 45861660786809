import {
  getDaySectionsTemplate,
  setDaySectionProperties,
  addTimeSlotToDaySection,
  sortEachDaySectionChronologically
} from './daySection';
import * as datetime from '@/utils/datetime';
import CONSTANTS from '@/utils/constants';

export function generateAvailabilityTimeslots (availabilities) {
  let availabilityTimeslots = getDaySectionsTemplate();
  for (const index in availabilities) {
    const timeslot = _formatAvailability(availabilities[index]);
    availabilityTimeslots = addTimeSlotToDaySection(availabilityTimeslots, timeslot);
  }
  availabilityTimeslots = sortEachDaySectionChronologically(availabilityTimeslots);
  return setDaySectionProperties(availabilityTimeslots);
}

export function groupAvailabilitiesByDay (availabilities) {
  const formattedAvailabilities = {};
  for (const index in availabilities) {
    const formattedAvailability = _formatAvailability(availabilities[index]);
    const availabilityDay = datetime.toISODate(formattedAvailability.date);
    if (!formattedAvailabilities[availabilityDay]) {
      formattedAvailabilities[availabilityDay] = [];
    }
    formattedAvailabilities[availabilityDay].push(formattedAvailability);
  }
  return formattedAvailabilities;
}

function _formatAvailability (availability) {
  availability.date = datetime.fromISODate(availability.isoDate);
  availability.startTime = datetime.getLocalTime(availability.date);
  availability.type = CONSTANTS.CALENDAR_MODES.AVAILABILITY;
  return availability;
}
