import HTTP from '../../services/HTTP';
import CONSTANTS from '@/utils/constants';

export default {
  namespaced: true,
  state: {
    zones: [],
    personsInCharge: [],
    domain: null,
    appState: CONSTANTS.APP_STATE.INITIAL,
    loadingMessage: '',
    showFreeProductOnSignUpModal: false,
    freeProductOnSignUpModalAlreadyShown: false,
    domainConfig: []
  },
  mutations: {
    SET_ZONES (state, zones) {
      state.zones = zones;
    },
    SET_DOMAIN_CONFIG (state, config) {
      state.domainConfig = config;
    },
    SET_DOMAIN (state, domain) {
      state.domain = domain;
    },
    SET_APP_STATE (state, appState) {
      state.appState = appState;
    },
    SET_LOADING_MESSAGE (state, message) {
      state.loadingMessage = message;
    },
    SET_FREE_PRODUCT_MODAL (state, newValue) {
      state.showFreeProductOnSignUpModal = newValue;
    },
    SET_FREE_PRODUCT_MODAL_ALREADY_SHOWN (state) {
      state.freeProductOnSignUpModalAlreadyShown = true;
    }
  },
  actions: {
    setDomain ({ commit }, domain) {
      commit('SET_DOMAIN', domain);
    },
    async getZones ({ commit }) {
      const res = await HTTP.domain.getZones();
      commit('SET_ZONES', res.data);
      if (res.data.length === 1) {
        commit('config/SET_SELECTED_ZONE', res.data[0], { root: true });
      }
    },
    async getDomainConfig ({ commit }) {
      const res = await HTTP.domain.getDomainConfig();
      commit('SET_DOMAIN_CONFIG', res.data);
    },
    setAppState ({ commit }, { newState, loadingMessage }) {
      commit('SET_APP_STATE', newState);
      if (loadingMessage) {
        commit('SET_LOADING_MESSAGE', loadingMessage);
      }
    },
    showFreeProductOnSignUpModal ({ commit }, newValue) {
      commit('SET_FREE_PRODUCT_MODAL', newValue);
      if (!newValue) {
        commit('SET_FREE_PRODUCT_MODAL_ALREADY_SHOWN');
      }
    }
  },
  getters: {
    isMultiSite: state => state.zones.length > 1,
    hasWebPayment: state => state.zones.some(zone => zone.hasWebPayment),
    isZoneHasWebPayment: (state) => zoneId => state.zones.some(zone => zone.id === zoneId && zone.hasWebPayment),
    domain: state => state.domain,
    getFirstZone: state => {
      return state.zones[0];
    },
    getCalendarZone: (state, getters, rootState) => {
      return state.zones.find(
        zone =>
          rootState.config.calendarConfigs.zone &&
          zone.id === rootState.config.calendarConfigs.zone?.id
      );
    },
    getZoneColor: (state, getters) => {
      return (
        getters.getCalendarZone?.color || getters.getFirstZone?.color || '#8f7c84'
      );
    },
    getZoneLogoPath: (state, getters) => {
      return getters.getCalendarZone?.imageclub;
    },
    getDomainConfigForDomain: (state, getters, rootState) => {
      return state.domainConfig?.find(
        club =>
          club.zoneId === rootState.config.calendarConfigs.zone?.id
      );
    },
    getZonesVisibleOnline: state => state.zones.filter(zone => zone.isVisibleOnline),
    getZonesWithWebPayment: state => state.zones.filter(zone => zone.isVisibleOnline && zone.hasWebPayment),
    getActiveZone: (state) => zoneId => {
      return state.zones?.find(zone => zoneId ? zone.id === parseInt(zoneId) : true && zone.isVisibleOnline) || null;
    }
  }
};
