export const infiniteVideoListScrolled = ({
  getMoreVideos,
  filters,
  extractData,
  continueInfiniteScroll,
  stopInfiniteScroll,
  handleError
}) => {
  getMoreVideos(filters).then((response) => {
    const newData = extractData(response);
    if (newData.length) {
      continueInfiniteScroll(newData);
    } else {
      stopInfiniteScroll();
    }
  }).catch(error => {
    stopInfiniteScroll();
    handleError(error);
  });
};
