import { findDomain } from '../../utils/url';
import * as Sentry from '@sentry/vue';

const getCurrentSettings = () => {
  const settingsName = `deciplus__${findDomain()}__settings`;
  let currentSettings = null;
  try {
    currentSettings = window.localStorage.getItem(settingsName);
  } catch (error) {
    Sentry.captureException(error);
  }

  if (currentSettings) {
    return JSON.parse(currentSettings);
  } else {
    return {};
  }
};

const updateSetting = (key, value) => {
  const currentSettings = getCurrentSettings();
  currentSettings[key] = value;
  setSettings(currentSettings);
};

const setSettings = (newSettings) => {
  const settingsName = `deciplus__${findDomain()}__settings`;
  try {
    window.localStorage.setItem(settingsName, JSON.stringify(newSettings));
  } catch (error) {
    Sentry.captureException(error);
  }
};

export default {
  getDomainToken: () => {
    const settings = getCurrentSettings();
    return settings?.domainToken || null;
  },
  getDeciplusToken: () => {
    const settings = getCurrentSettings();
    return settings?.deciplusToken || null;
  },
  setDomainToken: (token) => {
    updateSetting('domainToken', token);
  },
  setDeciplusToken: (token) => {
    updateSetting('deciplusToken', token);
  },
  getLanguage: () => {
    const settings = getCurrentSettings();
    return settings?.language || navigator?.language || navigator?.userLanguage || 'fr';
  },
  setLanguage: (language) => {
    updateSetting('language', language);
  },
  getCurrentProduct: () => {
    const settings = getCurrentSettings();
    return settings?.currentProduct;
  },
  setCurrentProduct: (productId) => {
    updateSetting('currentProduct', productId);
  },
  getCurrentSettings
};
