import { createI18n } from 'vue-i18n';

import en from './messages/en';
import enElementUILocale from 'element-plus/dist/locale/en';

import fr from './messages/fr';
import frElementUILocale from 'element-plus/dist/locale/fr';

import es from './messages/es';
import esElementUILocale from 'element-plus/dist/locale/es';

import browserStorage from '@/services/browserStorage';

const MESSAGES = {
  en: {
    ...enElementUILocale,
    ...en
  },
  fr: {
    ...frElementUILocale,
    ...fr
  },
  es: {
    ...esElementUILocale,
    ...es
  }
};
const i18n = createI18n({
  locale: browserStorage.getLanguage(),
  fallbackLocale: 'en',
  messages: MESSAGES
});

export default i18n;
