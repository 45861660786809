import {
  memberAPI
} from '../request';
import { adaptUrl } from '@/utils/url';
export default {
  getMyVideoLibrary: ({ pageFilter } = { pageFilter: 1 }) => {
    const url = adaptUrl('videos/library', {
      queries: {
        page: pageFilter
      }
    });
    return memberAPI.get(url);
  },
  likeVideo: (videoId) => {
    const url = adaptUrl('videos/:videoId/like', {
      params: {
        videoId
      }
    });
    return memberAPI.post(url);
  },
  getVideoCategories: ({ by = 'activity' }) => {
    const url = adaptUrl('videos/categories', {
      queries: {
        groupBy: by
      }
    });
    return memberAPI.get(url);
  },
  getVideosByActivity: ({ activityFilter = [], pageFilter = 1 }) => {
    const url = adaptUrl('videos', {
      queries: {
        activityId: activityFilter,
        page: pageFilter
      }
    });
    return memberAPI.get(url);
  },
  getVideosByCoach: ({ coachFilter = [], pageFilter = 1 }) => {
    const url = adaptUrl('videos', {
      queries: {
        coachId: coachFilter,
        page: pageFilter
      }
    });
    return memberAPI.get(url);
  },
  getVideosForMember: () => {
    return memberAPI.get('videos');
  },
  getVideoDetails: (videoId) => {
    const url = adaptUrl('videos/:videoId', {
      params: {
        videoId
      }
    });
    return memberAPI.get(url);
  }
};
