<template>
  <div class="h-full" data-theme="main">
    <router-view />
    <modalMandatoryFields v-if="!modalPrivacyVisible"/>
    <modalLocalStorageUnavailable/>
    <modalMigrationWallet v-if="!modalPrivacyVisible && userDomainInfo"/>
    <modalPrivacyPolicy v-if="modalPrivacyVisible" />
    <modalMemberRequirements v-if="loggedInUser" />
  </div>
</template>

<script>
import './assets/styles/styles.less';
import './assets/styles/extra.less';

import 'element-plus/theme-chalk/index.css';
import modalMandatoryFields from '@/core/components/modalMandatoryFields.vue';
import modalLocalStorageUnavailable from '@/core/components/modalLocalStorageUnavailable.vue';
import modalMigrationWallet from '@/core/components/modalMigrationWallet.vue';
import modalPrivacyPolicy from '@/core/components/modalPrivacyPolicy.vue';
import modalMemberRequirements from '@/core/components/modalMemberRequirements.vue';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'App',
  components: {
    modalMandatoryFields,
    modalLocalStorageUnavailable,
    modalMigrationWallet,
    modalPrivacyPolicy,
    modalMemberRequirements
  },
  computed: {
    ...mapState('user', ['userDomainInfo']),
    ...mapGetters('user', ['loggedInUser']),
    modalPrivacyVisible () {
      return this.loggedInUser && this.userDomainInfo && !this.userDomainInfo?.privacyPolicy;
    }
  },
};
</script>
