<template>
  <div>
    <NarrowContainer
      :canGoBack="true"
    >
      <MyPassedBookings/>
    </NarrowContainer>
  </div>
</template>

<script>
import MyPassedBookings from '@/modules/myProfile/components/MyPassedBookings.vue';
import NarrowContainer from '@/layouts/NarrowContainer.vue';

export default {
  name: 'PassedBookingsPage.vue',
  components: { MyPassedBookings, NarrowContainer },
  data () {
    return {};
  }
};
</script>

<style scoped>

</style>
