<template>
  <div
    class="choose-zone choose-x"
  >
    <el-row type="flex" justify="center" v-if="purpose">
      <el-col :span="24" :xl="12" :lg="20" :md="18" :sm="18" :xs="22">
        <h1 class="content-title text-center">
          {{ purpose }}
        </h1>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="24" :lg="14" :xl="12" :md="18" :sm="16" :xs="22">
        <el-form>
          <el-form-item>
            <label class="el-form-item__label el-form-item__label--center">{{
              $t("landing.searchZoneLabel")
            }}</label>
            <el-input v-model="searchTerm" clearable></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="24" :lg="14" :xl="12" :md="18" :sm="16" :xs="22">
        <el-scrollbar wrap-class="zone-list" :native="false" wrap-style="max-height: 35vh;">
          <ul>
            <li
              v-for="zone in searchResults"
              :id="`choose-zone-item-${zone.id}`"
              :key="zone.id"
              class="zone-list__item"
              @click="selectZone(zone)"
              :class="zone.id == selectedZoneId ? 'is-active' : null"
            >
              <div class="zone-list__title">{{ zone.clubName }}</div>
              <div class="zone-list__description">
                {{ zone.city }}
              </div>
              <div class="zone-list__contacts">
                <span>{{ zone.adr1 }} {{ zone.adr1 && zone.postalCode ? ", " : "" }} {{ zone.postalCode }}</span>
                <span>{{ zone.phone }} {{ zone.phone && zone.email ? ", " : "" }} {{ zone.email }}</span>
              </div>
            </li>
          </ul>
        </el-scrollbar>
      </el-col>
    </el-row>
    <el-row>
      <slot></slot>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="24" :lg="14" :xl="12" :md="18" :sm="16" :xs="22">
        <div class="buttons text-center" style="margin-top: 20px;">
          <AriButton
            filled
            class="w-full cy-button-next"
            :disabled="!canGoToNextStep"
            :content=" $t('next')"
            @click="onNext"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    alreadySelectedZone: Object,
    purpose: String,
    canGoToNextStep: Boolean
  },
  mounted () {
    if (this.alreadySelectedZone) {
      this.selectedZone = this.alreadySelectedZone;
    }
  },
  data () {
    return {
      searchTerm: '',
      selectedZone: {}
    };
  },
  computed: {
    ...mapGetters('domain', ['getZonesVisibleOnline']),
    selectedZoneId () {
      return this.selectedZone.id;
    },
    isZoneSelected () {
      return this.selectedZoneId;
    },
    searchResults () {
      if (!this.searchTerm || this.searchTerm.length < 3) {
        return this.getZonesVisibleOnline;
      } else {
        const search = this.searchTerm.toLowerCase();
        return this.getZonesVisibleOnline.filter(zone => {
          const clubName = zone.clubName || '';
          const matchClubName = clubName.toLowerCase().includes(search);
          const cityName = zone.city || '';
          const matchCityName = cityName.toLowerCase().includes(search);
          const matchZipCode = zone.postalCode && zone.postalCode.includes(search);
          return matchClubName || matchCityName || matchZipCode;
        });
      }
    }
  },
  methods: {
    selectZone (zone) {
      this.selectedZone = zone;
      this.$emit('zoneClick', this.selectedZone);
      this.onNext();
    },
    onNext () {
      this.$emit('next', this.selectedZone);
    }
  }
};
</script>
