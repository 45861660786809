<template>
  <div class="see-more">
    <el-row class="description__wysiwyg-style">
      <p v-html="textToShow"></p>
    </el-row>
    <el-row
      class="see-more__toggle"
      @click="toggleSeeMore()"
    >
      <b><u>{{ toggleToShow }}</u></b>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    showLessIndication: {
      type: String,
      required: true
    },
    showMoreIndication: {
      type: String,
      required: true
    },
    seeMoreLimit: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      showMore: false
    };
  },
  computed: {
    textToShow () {
      if (this.showMore) {
        return this.text;
      } else {
        return this.text.substring(0, this.seeMoreLimit) + '...';
      }
    },
    toggleToShow () {
      if (this.showMore) {
        return this.showLessIndication;
      } else {
        return this.showMoreIndication;
      }
    }
  },
  methods: {
    toggleSeeMore () {
      this.showMore = !this.showMore;
      this.$emit('show-more-toggle', this.showMore);
    }
  }
};
</script>

<style lang="less">
.see-more {
  padding-top: 0.5rem;
  .see-more__toggle {
    cursor: pointer;
  }
}
</style>
