<template>
  <div class="card-item-shop gap-xxs" :class="[stateCardItem]" @click="onCardSelection(item.id, $event)">
    <img v-if="item.imageUrl && !imgError" @error="imgError = true" class="img-product-card" :src="item.imageUrl" alt="img-product"/>
    <img v-else class="img-product-card" src="@/assets/images/product-img-placeholder.svg" alt="img-product"/>
    <div class="padding-content-products flex-grow">
      <div class="flex flex-col">
        <div class="flex justify-between items-center">
          <span class="card-title">{{item.name}}</span>
          <span class="title-product-shop ubuntuMedium whitespace-nowrap">{{item.totalPrice}} {{ currency }}</span>
        </div>
        <span v-if="isStockAvailable(item)" class="text-xs">{{$t('cart.stock.in')}}</span>
        <span v-else class="text-error text-xs">{{$t('cart.stock.out')}}</span>
      </div>
    </div>
    <div class="flex justify-end container-buttons product-btn" v-if="isSelectedCard && isStockAvailable(item)">
      <AriButton :content="$t('cart.buy')" outlined @click="goToCart(item.id)" />
      <AriButton :content="$t('cart.addToCart')" filled @click="addProductToCart(item.id)"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProductsControl from '@/modules/products/mixins/productsControl';

export default {
  name: 'card-item',
  mixins: [ProductsControl],
  data () {
    return {
      imgError: false
    };
  },
  props: {
    item: Object,
    selectedCard: String,
    groupTitle: String
  },
  computed: {
    ...mapGetters('config', ['currency']),
    ...mapGetters('user', ['loggedInUser']),
    stateCardItem () {
      if (this.selectedCard === null) return '';
      else if (this.isSelectedCard) return 'card-selected';
      else return 'card-not-selected';
    },
    isSelectedCard () {
      return this.selectedCard === this.calculatedId;
    },
    calculatedId () {
      return `${this.item.id}-${this.groupTitle}`;
    }
  },
  components: {
  },

  mounted () {
  },

  methods: {
    ...mapActions('cart', ['addProduct']),
    goToCart (itemId) {
      if (!this.loggedInUser) return this.redirectToSignIn(itemId);
      this.addProduct({ id: itemId });
      this.$router.push({ name: 'cart' });
    },
    async addProductToCart (productId) {
      if (!this.loggedInUser) return this.redirectToSignIn(productId);
      const added = await this.addProduct({ id: productId });
      if (added) {
        this.$information.alert({
          message: this.$t('cart.product.added'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
      }
    },
    onCardSelection (id, event) {
      this.$emit('card-selected', { id: this.calculatedId, event });
    },
    isStockAvailable (item) {
      return item.stockAvailable == null || item.stockAvailable > 0;
    }
  }
};
</script>

<style lang="less" scoped>
// VOir cardPresta pour les classes générique aux cards du shop
.item-price-currency {
  @apply text-primary text-sm whitespace-nowrap;
}
.card-item-presta {
  padding: 0px;
}
.card-item-shop {
  cursor: pointer;
  @apply rounded cursor-pointer w-full;
  justify-self: center;
  background-color: transparent;
  &.card-selected {
    background-color: #FFFFFF;
  }
}
.card-item-shop.card-selected, .card-selected .img-product-card {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}

.img-product-card {
  object-fit: cover;
  width: 300px;
  height: 250px;
}
.padding-content-products {
  @apply p-sm;
}
.product-btn {
  margin: 0.5rem;
  flex-wrap: wrap;
  width: fit-content;
  > button {
    flex: 1;
    margin: 5px 0px;
  }
}
@media (max-width: 1440) {
  .padding-content-products {
    padding: 5px 10px 5px 10px;
  }
}
.title-product-shop {
  @apply text-primary font-medium text-sm;
}
@media (max-width: 640px) {
.img-product-card, .card-item-shop {
  min-height: auto !important;
  max-height: auto !important;
  min-width: 100% !important;
  max-width: 100% !important;
}
}
</style>
