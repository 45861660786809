import { ElMessage } from 'element-plus';
import CONSTANTS from './constants';

export const alert = (
  {
    message = null,
    showClose = true,
    type = CONSTANTS.USER_MESSAGE_TYPES.RED_ERROR,
    duration = CONSTANTS.USER_MESSAGE_DURATIONS.SHORT
  } = {}
) => {
  if (message) {
    ElMessage({
      showClose,
      message,
      type,
      duration
    });
  }
};

export const capture = (
  {
    error = null,
    inform = false,
    component = '',
    method = '',
    tags = {}
  } = {}) => {
  if (error && inform) {
    // Vue.prototype.$sentry.captureExceptionWithTags(error, tags, component, method);
  }
};

export const track = {
  initFacebookPixel: (pixelId) => {
    window.fbq('init', pixelId);
  },
  initGoogleAnalytics: (trackingCode = undefined) => {
    prepareGoogleTrackingDataLayer();
    if (trackingCode) {
      insertGoogleTrackingScript(trackingCode);
      initGoogleTracking(trackingCode);
    }
    insertGoogleTrackingScript(CONSTANTS.PUBLIC_KEYS.GOOGLE_ANALYTICS_INTERNAL);
    initGoogleTracking(CONSTANTS.PUBLIC_KEYS.GOOGLE_ANALYTICS_INTERNAL);
  },
  pageViewWithFacebook: () => {
    window.fbq('track', 'PageView');
  },
  viewContentWithFacebook: (productTitle) => {
    window.fbq('track', 'ViewContent', {
      nom: productTitle
    });
  },
  viewContentWithGoogle: () => {
    window.gtag('event', 'view_item', { method: 'Google' });
  },
  registrationWithFacebook: () => {
    window.fbq('track', 'CompleteRegistration');
  },
  registrationWithGoogle: () => {
    window.gtag('event', 'sign_up', { method: 'Google' });
  },
  addPaymentInfoWithFacebook: () => {
    window.fbq('track', 'AddPaymentInfo');
  },
  addPaymentInfoWithGoogle: () => {
    window.gtag('event', 'add_payment_info', { method: 'Google' });
  },
  // properties are in French for reporting compatibility reasons with  V1
  addToBasketWithFacebook: (productTitle, quantity = 1) => {
    window.fbq('track', 'AddToCart', {
      nom: productTitle,
      quantite: quantity
    });
  },
  addToBasketWithGoogle: (productTitle, quantity = 1) => {
    window.gtag('event', 'add_to_cart', { method: 'Google' });
  },
  startPurchaseWithFacebook: () => {
    window.fbq('track', 'InitiateCheckout');
  },
  startPurchaseWithGoogle: () => {
    window.gtag('event', 'begin_checkout', { method: 'Google' });
  },
  purchaseCompletedWithFacebook: () => {
    window.fbq('track', 'Purchase');
  },
  purchaseCompletedWithGoogle: () => {
    window.gtag('event', 'purchase_complete', {
      event_category: 'ecommerce',
      event_label: 'Purchase Complete'
    });
  },
  bookingMadeWithFacebook: () => {
    window.fbq('track', 'Schedule');
  },
  bookingMadeWithGoogle: () => {
    window.gtag('event', 'schedule', {
      event_category: 'engagement',
      event_label: 'Schedule'
    });
  }
};

const insertGoogleTrackingScript = (trackingCode) => {
  const scriptInsertion = document.createElement('script');
  scriptInsertion.type = 'text/javascript';
  scriptInsertion.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingCode;
  scriptInsertion.setAttribute('async', '');
  const head = document.getElementsByTagName('head');
  head[0].appendChild(scriptInsertion);
};

const prepareGoogleTrackingDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () { window.dataLayer.push(arguments); };
  window.gtag('js', new Date());
};

const initGoogleTracking = (trackingCode) => {
  window.gtag('config', trackingCode);
};
