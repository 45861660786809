<template>
  <div class="flex items-center padding-deci-title">
    <div class="flex flex-col title">
      <span class="flex deci-title ubuntuMedium" :class="dynamicClasses">{{ text }}</span>
      <span v-if="subTitle" class="deci-sub-title flex fs-16 ubuntuLight">{{subTitle}}</span>
    </div>
    <el-divider v-if="showDivider" class="divider-small"/>
  </div>
</template>

<script>
export default {
  name: 'DeciTitle',
  props: {
    text: {
      type: String,
      required: true
    },
    subTitle: {
      Type: String,
      default: null
    },
    isLined: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'big',
      validator: (value) => ['small', 'medium', 'big'].indexOf(value) !== -1
    },
    showDivider: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dynamicClasses () {
      let classes = '';
      if (this.isLined) {
        classes += ' deci-title--lined';
      }
      if (this.size === 'small') {
        classes += ' deci-title--small-text';
      }
      if (this.size === 'medium') {
        classes += ' deci-title--medium-text';
      }
      if (this.size === 'big') {
        classes += ' deci-title--big-text';
      }
      return classes;
    }
  }
};
</script>

<style lang="less">
@import "../../../assets/styles/vars.less";
.title {
  flex-shrink: 0;
  padding-right: 30px;
}
.padding-deci-title {
  padding-bottom: 10px;
}
.deci-sub-title {
  color: #747474;
}
.deci-title {
  overflow: hidden;
  color: #333;
}
.deci-title--lined {
  &:after {
    display: inline-block;
  }
}
.deci-title--big-text{
  font-size: 1.375rem;
}
.deci-title--medium-text{
  font-size: @big-text-size;
}
.deci-title--small-text{
  font-size: @big-text-size;
}
</style>
