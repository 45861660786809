<template>
  <div>
    <div class="my-profile__section">
      <div>
        <p>
          {{ $t("profile.personalDetails.explanation") }}
          <a :href="'mailto:' + usersZoneDetails.email">{{
            usersZoneDetails.email
          }}</a>
        </p>
      </div>
      <div class="my-personal-rights__actions-container">
        <div class="ariane-pr-lg">
          <AriButton filled error :content="$t('profile.deleteMyData.action')" @click="openDeleteMyDataForm"/>
        </div>
        <div>
          <AriButton
            outlined
            error
            :content="$t('profile.extractMyData.action')"
            buttonType="emptyDanger"
            @click="openExtractMyDataForm"
          />
        </div>
      </div>
    </div>
    <ariModal v-if="showExtractMyDataForm" showClose :modalTitle="$t('profile.personalDetails.extractTitle')" @close="showExtractMyDataForm = false">
      <template v-slot:body>
        <el-form>
          <div class="personal-detail">
            <p>
              {{ $t("profile.personalDetails.extractBody") }}
            </p>
          </div>
        </el-form>
        <el-row class="modal-actions" type="flex" :gutter="12" justify="space-between">
          <AriButton
            :content="$t('close')"
            text
            @click="showExtractMyDataForm = false"
          />
          <AriButton
            :content="$t('confirm')"
            outlined
            @click="onExtractData"
          />
        </el-row>
      </template>
    </ariModal>
    <ariModal v-if="showDeleteMyDataForm" showClose :modalTitle="$t('profile.personalDetails.deleteTitle')" @close="showDeleteMyDataForm = false">
      <template v-slot:body>
        <el-form>
          <div>
            <p> {{ $t("profile.personalDetails.deleteBody") }}</p>
            <br />
            <p> <b> {{ $t("profile.personalDetails.deleteWarning") }} </b> </p>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="flex justify-between pt-sm">
          <AriButton :content="$t('noGoBack')" text @click="showDeleteMyDataForm = false"/>
          <AriButton :content="$t('yesDelete')" filled error @click="onDeleteData" />
        </div>
      </template>
    </ariModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('user', ['usersZoneDetails'])
  },

  data () {
    return {
      showEditDetailsModal: false,
      showExtractMyDataForm: false,
      showDeleteMyDataForm: false
    };
  },

  methods: {
    openExtractMyDataForm () {
      this.showExtractMyDataForm = true;
    },
    openDeleteMyDataForm () {
      this.showDeleteMyDataForm = true;
    },
    async onExtractData () {
      try {
        await this.$services.HTTP.personalData.extractPersonalData();
        this.showExtractMyDataForm = false;
        this.$information.alert({
          message: this.$t('requestTransmitted'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
      } catch (error) {
        this.$information.capture({ error, inform: true });
        this.$information.alert({
          message: this.$t('calendar.errors.GENERAL_ERROR')
        });
      }
    },
    async onDeleteData () {
      try {
        await this.$services.HTTP.personalData.deletePersonalData();
        this.showDeleteMyDataForm = false;
        this.$information.alert({
          message: this.$t('requestTransmitted'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
      } catch (error) {
        this.$information.capture({ error, inform: true });
        this.$information.alert({
          message: this.$t('calendar.errors.GENERAL_ERROR')
        });
      }
    }
  }
};
</script>

<style lang="less">
.modal-actions{
  padding-top: 1rem;
  flex-direction: column-reverse;
  align-items: center;
  .deci-button{
    margin: 0.5rem;
  }
}
.my-personal-rights__actions-container{
  padding-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
}
.el-checkbox__label{
  white-space: normal !important;
}
@media (min-width:500px){
  .modal-actions{
    padding-top: 1rem;
    flex-direction: row;
  }
}
@media (min-width:992px){
  .my-personal-rights__actions-container{
    flex-direction: row;
  }
}
</style>
