<template>
 <div class="grid-content">
    <el-row type="flex" justify="center">
      <el-col :span="24" :xl="12" :lg="14" :md="18" :sm="16" :xs="22">
        <el-steps v-if="!hideHeader"
                  :active="currentStep"
                  finish-status="success">
          <el-step
            v-for="step in steps"
            :key="step.stepId"
            @click="step.openStep"
          ></el-step>
        </el-steps>
      </el-col>
    </el-row>
    <slot name="stepperContent"></slot>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    steps: {
      type: Array,
      required: true
    },
    hideHeader: {
      type: Boolean,
      required: false
    }
  }
};
</script>
