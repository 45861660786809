<template>
  <div class="calendar__main" v-if="!daySectionAllEvents || !daySectionAllEvents.isEmpty" :class="{'calendar__main-first' : rowNumber === 1}">
    <el-row :gutter="12" class="row">
      <el-col :span="2">
        <DaySectionIndicator
          :hideIndicator="hideDaySectionIndicators"
          :daySectionName="daySectionName"
        />
      </el-col>
      <template :key="index" v-for="index in $CONSTANTS.CALENDAR.DAYS_IN_WEEK">
        <el-col :span="3" >
          <BodyBox
            :dayContainer="getDayTimeslots(index - 1)"
            :isExpand="isExpanded"
          />
        </el-col>
      </template>
      <el-col
        v-if="daySectionAllEvents && daySectionAllEvents.isExpandable"
        :span="21"
        :offset="2"
      >
        <div class="buttons buttons-full-width">
          <AriButton
            class="w-full"
            filled
            :content="isExpanded ? $t('zone.hideCalendar') : $t('zone.seeAllCalendar')"
            @click="expand"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import BodyBox from './BodyBox.vue';
import DaySectionIndicator from './DaySectionIndicator.vue';

export default {
  data () {
    return {
      isExpanded: false
    };
  },
  components: {
    BodyBox,
    DaySectionIndicator
  },
  props: {
    rowNumber: Number,
    daySectionAllEvents: Object,
    daySectionName: String,
    hideDaySectionIndicators: Boolean
  },
  methods: {
    expand () {
      this.isExpanded = !this.isExpanded;
    },
    getDayTimeslots (day) {
      return this.daySectionAllEvents ? this.daySectionAllEvents.timeslots[day] : [];
    }
  }
};
</script>
