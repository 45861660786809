<template>
    <mq-responsive target="xl+" class="flex flex-col">
      <el-row>
        <el-col>
          <BodyRow v-for="index in 3" :key="index" :rowNumber="index" :daySectionAllEvents="getCurrentDaySection(index)"
            :daySectionName="daySectionName(index)" :hideDaySectionIndicators="hideDaySectionIndicators" />
        </el-col>
      </el-row>
    </mq-responsive>
    <mq-responsive target="lg-" class="flex flex-grow flex-col">
      <div v-for="(dayContainer, dayIndex) in allEvents" :key="dayIndex">
        <div class="calendar__day-header">
          {{ $datetime.getLocalMediumDate($datetime.fromISODate(dayIndex)) }}
        </div>
        <div v-for="(timeslot, index) in dayContainer" :key="index">
          <layoutTimeslot v-if="isTimeslotShown(timeslot)" :timeslot="updateBookingStatus(timeslot)" />
        </div>
      </div>
    </mq-responsive>
</template>

<script>
import BodyRow from './BodyRow.vue';
import CalendarControl from '../mixins/calendarControl.js';
import layoutTimeslot from './Timeslot/layout.timeslot.vue';
import { isObjectNotEmpty } from '@/utils/dataType.js';

export default {
  mixins: [CalendarControl],
  components: {
    BodyRow,
    layoutTimeslot
  },
  props: {
    allEvents: Object
  },
  computed: {
    hideDaySectionIndicators () {
      for (let i = 1; i <= 3; i++) {
        const daySectionName = this.$CONSTANTS.SECTIONS_OF_DAY[this.daySectionName(i)].name;
        if (this.allEvents[daySectionName] && this.allEvents[daySectionName].isExpandable) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    getCurrentDaySection (rowNumber) {
      return this.allEvents[this.$CONSTANTS.SECTIONS_OF_DAY[this.daySectionName(rowNumber)].name];
    },
    daySectionName (rowNumber) {
      let daySection = '';
      if (rowNumber === 1) {
        daySection = 'morning';
      } else if (rowNumber === 2) {
        daySection = 'day';
      } else {
        daySection = 'evening';
      }
      return daySection;
    },
    isTimeslotShown (timeslot) {
      return isObjectNotEmpty(timeslot);
    }
  }
};
</script>

<style lang="less" scoped>
.calendar-body-container{
  overflow: auto;
}
.calendar__day-header{
  padding-top:20px;
}
</style>
