<template>
  <footer>
    <div class="footer-container">
      <span class="link" @click="isMobileAppBetterModalOpen = true" v-if="$route.name !== 'signIn'">{{$t('getOnMobile')}}</span>

      <a
        class="link"
        :class="{'text-center grow': $route.name === 'signIn'}"
        href="https://www.deciplus.fr/"
        target="_blank"
      >
        <span>{{$t('madeBy1') + ' / ' + $t('madeBy2')}}</span>
      </a>
      <LanguagePicker />
    </div>
    <ModalMobileAppBetter
      :visibility="isMobileAppBetterModalOpen"
      @on-cancel="isMobileAppBetterModalOpen = false"
    />
  </footer>
</template>

<script>
import LanguagePicker from '@/modules/common/LanguagePicker.vue';
import ModalMobileAppBetter from '@/modules/auth/components/ModalMobileAppBetter.vue';

export default {
  name: 'FooterMemberWeb',

  components: {
    LanguagePicker,
    ModalMobileAppBetter
  },

  data () {
    return {
      isMobileAppBetterModalOpen: false
    };
  }
};
</script>

<style lang="less">
@import '../../assets/styles/vars.less';
@import '../../assets/styles/mixins.less';
.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.footer-container{
  display: flex;
  flex-direction: row;
  margin: 1px 5px;
  justify-content: space-between;
  align-items: center;
  .footer-container__visit-deciplus{
    padding: 00.51rem;
    text-decoration: none;
    display: none;
  }
}
@media (min-width: 992px){
  .footer-container{
    .footer-container__visit-deciplus{
      display: block;
    }
  }
}
</style>
