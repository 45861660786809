<template>
  <AriModal class="memberRequirementsModal" v-if="walletRequired && (walletRequired.severity === 'block' || isThresholdExceeded)" :showClose="false" max-width="450px" @close="close()">
    <template #header>
      <div class="header">
        <span class="text-lg font-medium text-primary">{{$t('requirementsModal.walletRequired.title')}}</span>
        <AriButton
          v-if="walletRequired.severity === 'info'"
          :style="closeButtonStyle"
          rounded
          filled
          useCustomColor
          useCustomBgColor
          preIcon="fas fa-times"
          @click="close()"
        />
      </div>
    </template>
    <template #body>
      <div class="body">
        <div>
        {{$t("requirementsModal.walletRequired.body")}}
        </div>
        <div class="contract" v-if="walletRequired.data">
          <span class="text-base font-medium text-primary">{{walletRequired.data.context.name}}</span>
          <span class="text-secondary">N° {{walletRequired.data.context.ref}}</span>
        </div>
        <div class="text-secondary">
        {{$t("requirementsModal.walletRequired.subtext")}}
        </div>
      </div>
    </template>
    <template #footer>
      <div class="footer">
        <AriButton
          v-if="walletRequired.severity === 'info'"
          text
          :content="$t('requirementsModal.walletRequired.cancel')"
          @click="close()"
        />
        <AriButton
          attention
          filled
          :content="$t('requirementsModal.walletRequired.button')"
          @click="showManageWallet = true"
        />
      </div>
    </template>
  </AriModal>
  <ModalManageWallet :visibility="showManageWallet" @close="onManageWalletClose" />

</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, onMounted, computed } from 'vue';
import services from '@/services';
import ModalManageWallet from "@/core/components/ModalManageWallet.vue";
const store = useStore();
const router = useRouter();

let requirements = ref([]);
let showManageWallet = ref(false);
let lastTimestamp = ref(window.localStorage.getItem('walletRequired'));

const closeButtonStyle = {
  '--custom-color': '#7D7C82',
  '--custom-bg-color': '#FFFFFF'
};

onMounted( () => {
  loadMemberRequirements();
});

const walletRequired = computed(() => {
  const walletRequiredAlert = requirements.value.find(requirement => requirement.id === 'walletRequired');
  let zoneHasWebPayment = true;
  if(walletRequiredAlert && walletRequiredAlert.data) {
    zoneHasWebPayment = store.getters['domain/isZoneHasWebPayment'](walletRequiredAlert.data?.context?.zoneId);
  }

  return zoneHasWebPayment ? walletRequiredAlert : null;
});

const close = () => {
  setLastTimestamp();
};

const setLastTimestamp = () => {
  lastTimestamp.value =  new Date().getTime();
  window.localStorage.setItem('walletRequired', lastTimestamp.value);
};

const isThresholdExceeded  = computed(() => {
  if(!lastTimestamp.value) {
    return true;
  }
  // 24h en secondes
  const threshold = 86400;
  const currentTimestamp = new Date().getTime();
  const diffTimestamp = (currentTimestamp - lastTimestamp.value) / 1000;
  return diffTimestamp > threshold;
});


const onManageWalletClose = () => {
  showManageWallet.value = false;
  loadMemberRequirements();
};

const loadMemberRequirements = () => {
  services.HTTP.user.getRequirements().then(response => {
    if(response.data) {
      requirements.value = response.data;
    }
  });
};
</script>

<style>
</style>

<style scoped>
.contract {
  @apply flex flex-col p-default border-1 border-default rounded mt-sm mb-sm;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
}
.header, .footer {
  @apply p-default;
  background-color: #F7F6F8;
}
.header {
  @apply flex flex-row items-center justify-between
}
.body {
  @apply p-default;
}
.footer {
  @apply flex justify-end;
}
</style>
