<template>
  <div class="choose-activity choose-x">
    <el-row type="flex" justify="center">
      <el-col :span="24" :xl="12" :lg="20" :md="18" :sm="16" :xs="22">
        <h1 class="content-title text-center">
          {{ $t("landing.chooseActivityTitle") }}
        </h1>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="24" :xl="12" :lg="14" :md="18" :sm="16" :xs="22">
        <el-form>
          <el-form-item>
            <el-input
              :placeholder="$t('landing.searchActivityLabel')"
              v-model="searchTerm"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="24" :lg="16" :xl="16" :md="18" :sm="16" :xs="22">
        <el-scrollbar
          wrap-class="activities-list"
          :native="false"
          wrap-style="max-height: 35vh;"
        >
          <div class="choose-activity__activity-checkboxes choose-activity__activity-checkboxes--required">
            <el-checkbox-group
              v-model="selectedActivities"
            >
              <el-checkbox
                v-for="(activity, index) in filteredActivities"
                :key="index"
                :checked="isActivitySelected(activity.id)"
                :label="activity.id"
              >
                {{ activity.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <ul class="choose-activity__activity-cards cards flex justify-center choose-activity__activity-cards--hidden">
            <li
              v-for="activity in filteredActivities"
              :key="activity.name"
              class="cards__item"
              @click="toggleActivitySelection(activity)"
              :class="isActivitySelected(activity.id) ? 'is-selected' : null"
            >
              <i
                :class="[activity.icon ? activity.icon : 'fas fa-running']"
              ></i>
              <div class="cards-title">{{ activity.name }}</div>
            </li>
          </ul>
        </el-scrollbar>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="24" :xl="12" :lg="14" :md="18" :sm="16" :xs="22">
        <div class="flex buttons justify-between">
          <AriButton
            class="w-full"
            outlined
            v-if="canGoToPreviousStep"
            :content=" $t('back')"
            @click="onBack"
          />
          <AriButton
            class="w-full cy-button-next"
            filled
            :content=" $t('next')"
            @click="onNext"
          />
        </div>
      </el-col>
    </el-row>
    <el-row>
      <slot></slot>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    alreadySelectedActivities: Array,
    canGoToPreviousStep: Boolean
  },
  created () {
    this.loadAllActivities()
      .then(() => {
        if (this.activities.length <= 1) {
          this.onNext();
        }
      })
      .catch((error) => {
        this.$information.capture({ error });
      });
  },
  mounted () {
    this.selectedActivities = this.alreadySelectedActivities;
  },
  data () {
    return {
      searchTerm: '',
      selectedActivities: []
    };
  },
  computed: {
    ...mapState('zone', ['activities']),
    filteredActivities () {
      if (!this.searchTerm || this.searchTerm.length < 3) {
        return this.activities;
      }

      return this.activities.filter((activity) => {
        const activityName = activity.name || '';
        return activityName.toLowerCase().includes(this.searchTerm);
      });
    }
  },
  methods: {
    ...mapActions('zone', ['loadAllActivities']),
    onNext () {
      this.$emit('next', this.selectedActivities);
    },
    onBack () {
      this.$emit('back');
    },
    toggleActivitySelection (activity) {
      const selectionIndex = this.selectedActivities.indexOf(activity.id);
      if (selectionIndex === -1) {
        this.selectActivity(activity);
      } else {
        this.deselectActivity(selectionIndex);
      }
    },
    selectActivity (activity) {
      this.selectedActivities.push(activity.id);
    },
    deselectActivity (selectionIndex) {
      this.selectedActivities.splice(selectionIndex, 1);
    },
    isActivitySelected (activityId) {
      return this.selectedActivities.indexOf(activityId) !== -1;
    }
  }
};
</script>

<style lang="less">
@import "../../../assets/styles/vars.less";
@import "../../../assets/styles/mixins.less";
.landing-page {
  .choose-activity {
    .el-scrollbar {
      .activities-list {
        margin: 0;
        padding: var(--ariane-base);
        margin-bottom: -6px !important; // WTF element-ui !
        .choose-activity__activity-checkboxes {
          .el-checkbox {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 5rem;
            padding: 0.4rem;
            margin: initial;
            white-space: initial;
          }
        }
        .choose-activity__activity-cards {
          display: none;
          .cards__item {
            &:hover {
              transform: translateY(-2px);
              cursor: pointer;
            }
            &:active,
            &:focus {
              .focused-card();
            }
            &.is-selected {
              .focused-card();
            }
            .cards-title {
              margin: 0 5px;
            }
            i {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
    .buttons {
      justify-content: center;
      margin-top: 20px;
    }
  }
}

@media (min-width: 992px) {
  .landing-page {
    .choose-activity {
      .el-scrollbar {
        .activities-list {
          text-align: center;
          .choose-activity__activity-checkboxes {
            display: none;
            &.choose-activity__activity-checkboxes--required {
              display: block;
            }
          }
          .choose-activity__activity-cards {
            display: inline-flex;
            &.choose-activity__activity-cards--hidden {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .choose-activity {
    .activities-list {
      .choose-activity__activity-checkboxes {
        &.choose-activity__activity-checkboxes--required {
          padding-right: 4rem;
          padding-left: 4rem;
        }
      }
    }
  }
}
</style>
