<template>
  <div
    class="manage-balance"
  >
    <DeciTitle
      showDivider
      :text="$t('account.balance.title')"
    />
    <div
      v-if="isMemberBalanceShown"
      class="manage-balance__balance-container"
    >
      <div class="balance-icon flex items-center justify-center">
       <i class="fa-solid fa-piggy-bank fa-2x "></i>
      </div>
      <div class="ariane-text-title-4 ariane-pt-base">{{ $t("profile.balance.amountTitle") }}</div>
      <div class="ariane-text-title-1 ariane-txt-color-secondary ariane-pt-medium">{{ $t("profile.balance.amount", { memberBalance, currency }) }}</div>
      <div
        v-if="isRedeemButtonShown"
        class="manage-balance__action-container ariane-pt-medium"
      >
        <AriButton
          text
          :content="$t('giftCard.redeemCode')"
          @click="redirectToRedeemGiftCardPage()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';

export default {
  name: 'ManageBalance',
  components: {
    DeciTitle
  },
  mounted () {
    this.manageBalanceSectionOpened({
      showMemberBalance: this.showMemberBalance,
      showRedeemButton: this.showRedeemButton
    });
  },

  data () {
    return {
      isMemberBalanceShown: false,
      isRedeemButtonShown: false
    };
  },

  computed: {
    ...mapGetters('config', ['currency']),
    ...mapState('user', ['userBalance']),
    memberBalance () {
      return this.userBalance?.balance || 0;
    }
  },

  methods: {
    manageBalanceSectionOpened ({ showMemberBalance, showRedeemButton }) {
      showMemberBalance();
      showRedeemButton();
    },
    showMemberBalance () {
      this.isMemberBalanceShown = true;
    },
    showRedeemButton () {
      this.isRedeemButtonShown = true;
    },
    redirectToRedeemGiftCardPage () {
      this.$router.push({ name: 'redeemGiftCard' });
    }
  }
};
</script>

<style lang="less">
.balance-icon {
  margin-right: auto;
  margin-left: auto;
  width: 69px;
  height: 69px;
  box-shadow: 0px 3px 10px #0000001A;
  border-radius: 50%;
}
.balance-icon i{
  color: var(--ariane-primary-400)
}
.manage-balance{
  .manage-balance__balance-container{
    text-align: center;
    line-height: 2rem;
    .manage-balance__action-container{
      display: inline-block;
    }
  }
}
</style>
