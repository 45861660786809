<template>
  <AriModal v-if="isVisible" :modalTitle="title" @close="isVisible = false">
    <template #body>
      <div class="html-viewer__upper-body" >
        <span v-html="dangerousHtmlContent"></span>
      </div>
    </template>
    <template #footer>
      <AriButton class="ariane-mt-big-base" outlined
          :content="$t('back')" @click="isVisible = false" />
    </template>
  </AriModal>
</template>

<script>
export default {
  name: 'HtmlViewerModal',

  props: {
    visibility: Boolean,
    title: {
      type: String,
      default: ''
    },
    dangerousHtmlContent: String
  },

  computed: {
    isVisible: {
      get () {
        return this.visibility;
      },
      set () {
        this.$emit('on-cancel');
      }
    }
  }
};
</script>

<style lang="less">
.html-viewer__container{
  &.is-fullscreen {
    width: 100% !important;
  }
}
</style>
