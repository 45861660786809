<template>
    <div class="flex flex-col timeslot-details-page" >
      <GoBack class="flex"/>
      <DetailedTimeSlot class="flex scroll padding-content-mobile" :class="{ 'flex-grow': !isIframe()}" :timeslot="timeslotForDetailsPage" />
    </div>
</template>

<script>
import DetailedTimeSlot from './components/Timeslot/DetailedTimeslot/DetailedTimeSlot.vue';
import GoBack from '@/modules/common/generic/GoBack.vue';
import { isEmptyObject } from '@/utils/dataType.js';
import CalendarControl from './mixins/calendarControl.js';
import { mapState } from 'vuex';

export default {
  name: 'TimeslotDetailsPage',

  components: {
    GoBack,
    DetailedTimeSlot
  },

  mixins: [CalendarControl],

  mounted () {
    window.scroll(0, 0);
    this.checkForSavedTimeslot();
  },

  computed: {
    ...mapState('user', ['timeslotForDetailsPage'])
  },

  methods: {
    isIframe () {
      try {
        return window.self !== window.top;
      } catch (err) {
        return false;
      }
    },
    checkForSavedTimeslot () {
      if (isEmptyObject(this.timeslotForDetailsPage)) {
        this.$router.replace({ name: 'calendar' });
      }
    }
  }
};
</script>

<style lang="less">
.padding-content-mobile {
  padding: 0 0px;
}
.timeslot-details-page{
  height: 100%;
  @apply bg-content;
  .grid-content{
    height: 100%;
  }
}
</style>
