<template>
  <div class="video-gallery__video-thumbnail" @click="onOpenVideo(video)">
    <img class="video-thumbnail__background" :src="video.thumbnailUrl" />
    <div class="video-thumbnail__on-video-infos">
      <div class="video-thumbnail__duration" v-if="isDurationShown">
        <i class="fas fa-clock pr-xxs" />
        {{ durationInMinutes }} {{ $t('calendar.minutesShort') }}
      </div>
    </div>
    <div class="video-thumbnail__bottom-text"
          :class="singleLineTitle ? 'video-thumbnail__bottom-text--short' : 'video-thumbnail__bottom-text--long'">
      <div>
        <div class="color-darken truncate video-thumbnail__title ubuntuRegular" :title="video.title" v-if="showTitle">
          {{ video.title }}
        </div>
        <div class="flex justify-between w-full">
          <div class="video-thumbnail__coach-avatar" v-if="showCoach">
            <CoachAvatar :coachId="this.video.coach.id" miniAvatar />
          </div>
          <div class="video-thumbnail__price" :class="{'video-thumbnail__price--included' : isCoveredByMembership}" v-if="isPriceShown">
            <p>{{ videoPrice }}</p>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import CoachAvatar from '@/modules/common/CoachAvatar.vue';
import ProductsControl from '@/modules/products/mixins/productsControl.js';

export default {
  name: 'VideoThumbnail',

  components: {
    CoachAvatar
  },

  mixins: [ProductsControl],

  props: {
    video: Object,
    showTitle: {
      type: Boolean,
      default: true
    },
    showCoach: {
      type: Boolean,
      default: true
    },
    showDuration: {
      type: Boolean,
      default: true
    },
    showPrice: {
      type: Boolean,
      default: true
    },
    singleLineTitle: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    durationInMinutes () {
      return Math.ceil(this.video.duration / this.$CONSTANTS.CALENDAR.SECONDS_IN_A_MINUTE);
    },
    isPriceShown () {
      return this.showPrice;
    },
    isDurationShown () {
      return this.video.duration && this.showDuration;
    },
    isCoveredByMembership () {
      return this.video.memberHasMembership;
    },
    videoPrice () {
      if (this.isCoveredByMembership) {
        return this.$t('videoGallery.videoIncludedInMembership');
      }
      if (this.video.membershipIsRequired) {
        return this.$t('videoGallery.membershipRequired');
      }
      if (this.video.price === 0) {
        return this.$t('products.free');
      }
      if (!this.video.price) {
        return '';
      }
      const videoService = this.preparePricePresentation(this.video.price);
      return videoService.price + ' ' + videoService.currency;
    }
  },

  methods: {
    videoOpened ({ redirectToVideoPage, video }) {
      redirectToVideoPage(video);
    },
    onOpenVideo (video) {
      this.videoOpened({
        redirectToVideoPage: this.redirectToVideoPage,
        video
      });
    },
    redirectToVideoPage ({ id }) {
      this.$router.push({ name: 'videoDetails', params: { id } });
    }
  }
};
</script>

<style lang="less">
@import "../../../assets/styles/vars.less";
@import "../../../assets/styles/mixins.less";
.video-gallery__video-thumbnail{
  position: relative;
  cursor: pointer;

  .video-thumbnail__on-video-infos{
    text-align: center;
    .video-thumbnail__duration{
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      font-size: 10px;
      padding-left: 10px;
      background-color: #7474744D;
      box-shadow: 6px 6px 12px #0000002E;
      backdrop-filter: blur(14px);
      border-radius: 3px;
      margin-left: 0.3rem;
      margin-top: 0.3rem;
      padding: 0.2rem;
      font-weight: @heavy-font-weight;
      .fa-clock{
        font-weight: @heavy-font-weight;
        color: white;
      }
    }
  }
  .video-thumbnail__background{
    width: 100%;
    &:hover{
    .box-shadow();
    }
  }

  .video-thumbnail__bottom-text{
    padding-top: 0.5rem;
    justify-content: space-between;
    align-items: center;
  }
  .video-thumbnail__bottom-text--short{
    .video-thumbnail__title{
      .single-line-text();
    }
    .video-thumbnail__price{
      display: none;
    }
  }
  .video-thumbnail__bottom-text--long{
    .video-thumbnail__title{
      text-align: left;
    }
    .video-thumbnail__price{
      flex-shrink: 0;
      height: 18px;
      padding: 0 0.3rem;
      text-align: right;
      color:var(--ariane-primary-400);
      font-size: @big-text-size;
    }
    .video-thumbnail__price--included{
      @apply flex justify-center items-center px-default py-xxs bg-valid text-valid rounded gap-sm;
      font-size: var(--ariane-bold-emphatize-font-size);
    }
  }

}

@media (min-width: 500px){
  .video-gallery__video-thumbnail{
    font-size: @big-text-size;
  }
}
</style>
