<template>
  <div
    class="my-payment-methods"
  >
    <ModalManageWallet :memberWalletId="pendingWallet ? pendingWallet.id : null" :visibility="showManageWallet" @on-close="showManageWallet=false" @close="onManageWalletClose" />
    <div
      class="my-profile__section"
    >
      <div
        class="my-payment-methods__payment-method"
      >
        <div v-if="walletList && walletList.length === 0" class="mb-default">{{ $t('profile.paymentMethods.noWallet') }}</div>
          <table class="mb-default w-full">
            <tr class="border-b-1">
              <th class="text-left pb-sm">{{$t('profile.paymentMethods.cardNumber')}}</th>
              <th class="text-left pb-sm">{{$t('profile.paymentMethods.expirationDate')}}</th>
              <th class="pb-sm">{{$t('profile.paymentMethods.actions')}}</th>
            </tr>
            <tr v-for="wallet in walletList" :key="wallet.id" class="border-b-1 border-default">
              <td class="py-sm">
                <div v-if="wallet.cardNumber">
                  <i @click="handleFavoriteMemberWallet(wallet)" @mouseenter="walletHovered = wallet.id" @mouseleave="walletHovered = null" :class="((wallet.isFavorite && walletHovered === null) || walletHovered === wallet.id ? 'fa-solid' : 'fa-regular') + ' fa-star text-pending cursor-pointer'"></i>
                  {{wallet.cardNumber}}
                </div>
                <div v-else>{{ $t('profile.paymentMethods.checkInProgress') }}</div>
              </td>
              <td class="py-sm"><span v-if="wallet.cardNumber != ''">{{this.$datetime.dateOnlyFormat(wallet.cardExpiration)}}</span></td>
              <td class="py-sm flex  justify-center">
                <AriButton
                v-if="wallet.cardNumber !== ''"
                filled
                error
                :disabled="wallet.cardNumber !== '' && productNeedActiveWallet && validCardsInWallet === 1"
                :title="wallet.cardNumber !== '' && productNeedActiveWallet && validCardsInWallet === 1 ? $t('profile.paymentMethods.deleteDisabled') : undefined"
                preIcon="filled fas fa-trash"
                @click="handleDeleteWallet(wallet)"
              />
              </td>
            </tr>
            </table>
        <AriButton
          class="float-right"
          filled
          :content="$t('profile.paymentMethods.addCard')"
          @click="handleAddCard()"
        />
        </div>
        <DeciTitle
        :text="$t('account.balance.title')"
        :isLined="false"
        size="small"
      />
      <p>{{ $t('account.balance.explanation1') }}</p>
      <p>{{ $t('account.balance.explanation2') }}</p>
      <br />
      <div
        class="my-payment-methods__payment-method"
      >
        <AriButton
          filled
          :content="$t('profile.paymentMethods.manageBalance')"
          @click="onClickManageBalance"
        />
      </div>
    </div>
    <div
      class="my-profile__section payments_section"
    >
      <div
        class="my-profile__half_section"
      >
        <DeciTitle
          :text="$t('profile.scheduledPayments.title')"
          :isLined="false"
          size="medium"
        />
        <AriButton
          filled
          neutralGrey
          :content="$t('profile.scheduledPayments.action')"
          @click="onOpenScheduledPayments"
        />
      </div>
      <div
        class="my-profile__half_section"
      >
        <DeciTitle
          :text="$t('profile.invoices.title')"
          :isLined="false"
          size="medium"
        />
        <AriButton
          filled
          neutralGrey
          :content="$t('profile.invoices.action')"
          @click="onOpenInvoices"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';
import ModalManageWallet from '@/core/components/ModalManageWallet.vue';
import {mapActions, mapState} from "vuex";

export default {
  name: 'MyPaymentMethods',
  mixins: [ProductsControl],
  components: {
    DeciTitle,
    ModalManageWallet
  },

  data () {
    return {
      showManageWallet: false,
      memberWalletId: null,
      walletList: [],
      walletHovered: null,
    };
  },
  mounted() {
    this.loadMemberWallets();
  },

  computed: {
    ...mapState('user', ['userDomainInfo', 'myProducts']),
    productNeedActiveWallet () {
      return !this.userDomainInfo.allowMissingWallet && this.myProducts.some(contract => contract.product.requireActiveWallet);
    },
    validCardsInWallet () {
      return this.walletList.filter(card => card.enabled && card.cardNumber && this.$datetime.isTodayOrLater(new Date(card.cardExpiration))).length;
    },
    pendingWallet () {
      return this.walletList.find(card => !card.enabled && !card.cardNumber);
    }
  },

  methods: {
    ...mapActions('user', ['loadUserProducts']),
    async onClickManageWallet () {
      try {
        await this.registerWallet();
      } catch (error) {
        this.$information.capture({
          error,
          inform: true,
          component: 'MyPaymentMethods',
          method: 'onClickManageWallet'
        });
      }
    },
    onClickManageBalance () {
      this.$router.push({ name: 'manageBalance' });
    },
    onOpenScheduledPayments () {
      this.$router.push({ name: 'scheduledPayments' });
    },
    onOpenInvoices () {
      this.$router.push({ name: 'invoices' });
    },
    handleAddCard () {
      this.showManageWallet = true;
    },
    handleDeleteWallet (wallet) {
      this.$services.HTTP.user.deleteWallet(wallet.walletId, wallet.walletCardIndex).then(()=> {
        this.$information.alert({
          message: this.$t('profile.paymentMethods.successDelete'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
        this.loadMemberWallets();
      });
    },
    handleFavoriteMemberWallet (wallet) {
      this.$services.HTTP.user.setFavoriteMemberWallet(wallet.id).then(()=> {
        this.$information.alert({
          message: this.$t('profile.paymentMethods.successSetFavorite', { cardNumber: wallet.cardNumber }),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
        this.loadMemberWallets();
      });
    },
    onManageWalletClose () {
      this.loadMemberWallets();
    },
    loadMemberWallets () {
      this.loadUserProducts();
      this.$services.HTTP.user.getActiveWallets(true).then(result=> {
        this.walletList = result?.data;
      });
    }
  }
};
</script>

<style lang="less">
.my-payment-methods{
  .my-payment-methods__payment-method{
    width: 100%;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  .my-profile__section{
     &.payments_section{
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       margin-bottom: 1rem;
       margin-top: 1rem;
       padding: 0 1rem 0 0;
       h2{
         margin-bottom: 1rem;
       }
     }
  }
}
@media (min-width: 500px){
  .my-payment-methods{
    text-align: initial;
    .my-payment-methods__payment-method{
      display: inline-block;
    }
    .my-profile__section{
      &.payments_section{
       flex-direction: row;
      }
      .my-profile__half_section{
        margin-right: 1rem;
      }
    }
  }
}
@media (min-width: 992px){
  .my-payment-methods{
    .my-profile__section{
      &.payments_section{
       width: 70%;
      }
    }
  }
}
</style>
