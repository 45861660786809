<template>
<div class="bg-trivial rounded " :class="{ 'px-default pb-default': isSoldOut }">
  <div class="text-pending py-xs" v-if="isSoldOut">
    <span v-if="product.config.stockAvailable > 0">
      {{$t('cart.stock.warning.1', { stock: product.config.stockAvailable, name: product.config.name })}}
    </span>
    <span v-else>
      {{$t('cart.stock.warning.2')}}
    </span>
  </div>
  <div class="white-card card-item-cart">
    <div class="flex flex-col ubuntuMedium">
      <div class="flex justify-between ariane-mb-s cart-item">
        <img v-if="product.config.imageUrl && !imgError && !dontShowImg" @error="imgError = true" class="cart-img" :src="product.config.imageUrl" alt="img-product"/>
        <img v-else-if="product.config.imageUrl && imgError && !dontShowImg" class="cart-img" src="@/assets/images/product-img-placeholder.svg" alt="img-product"/>
        <img v-else-if="product.config.type === 'video'" class="cart-img" :src="getVideoThumbnail" alt="img-product"/>
        <div class="cart-details">
          <div class="cart-product-name">
            <span class="text-primary  ariane-mb-xs ariane-mr-s product-title">{{ product.config.name }}</span>
            <span v-if="hasSchedulerAndIsNotGift && payNow === false" class="a-link fs-12" @click="showScheduler = true">
              {{ $t('cart.showScheduler') }}
            </span>
          </div>
          <div class="flex">
            <ProductPrice class="ml-auto" :product="product.config" :quantity="product.quantity" :cashPayment="product.cashPayment"/>
            <i v-if="payNow === false && isRenewable(product.config)" class="pl-xxs fa fa-arrow-rotate-right text-valid fs-12" :title="$t('cart.autoRenewal')"></i>
          </div>
        </div>
      </div>
      <div class="flex items-baseline">
        <div v-if="hasSchedulerAndIsNotGift">
          <el-radio-group v-model="payNow" @change="onPaymentRadioChange">
            <el-radio v-show="!memberAppConfigs.onlyScheduledPaymentsOnline" :label="true" class="ariane-pr-medium ariane-pv-xs">{{ $t("cart.cashPayment") }}</el-radio>
            <el-radio :label="false" class="ariane-pv-xs">{{ $t("cart.schedulePayment") }}</el-radio>
          </el-radio-group>
        </div>
        <div class="ml-auto flex items-center">
          <i class="fas fa-trash ariane-pv-s ariane-ph-s ariane-pr-medium pointer" @click="onDeleteProduct"></i>
          <AriDropdown mini class="quantity-selector"
            :disabled="isQuantityLocked(product.config.id)"
            :datas="getQuantities(product.config.id)"
            v-model="quantity"
            :labelKey="null"
            :valueKey="null"
            :clearable="false"
          />
        </div>
      </div>
      <div v-if="product.giftConfig && product.giftConfig.email">
        <el-divider class="low-padding-divider" />
        <div class="flex gap-sm line-clamp-1 " v-if="product.giftConfig && product.giftConfig.email">
          <span class="text-secondary">{{ $t('cart.gift.beneficiary') }}: </span>
          <span>{{product.giftConfig.email}}</span>
        </div>
        <div class="flex gap-sm line-clamp-3" :title="product.giftConfig.message" v-if="product.giftConfig && product.giftConfig.message">
          <span class="text-secondary">{{ $t('cart.gift.message') }}: </span>
          <span>{{product.giftConfig.message}}</span>
        </div>
      </div>
      <div v-if="product.isGift">
        <span class="text-secondary">{{ $t('cart.gift.expiration') }}</span>
      </div>
    </div>
    <PaymentScheduleModal :product="product" :show="showScheduler" @on-close="showScheduler = false" />
    <QuantityAlertModal :show="showQuantityAlert" @on-close="onQuantityAlertClose($event)" />
  </div>
</div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
import ProductPrice from './productPrice.vue';
import PaymentScheduleModal from './paymentScheduleModal.vue';
import QuantityAlertModal from './quantityAlertModal.vue';

export default {
  name: 'cart-item',
  components: {
    ProductPrice,
    PaymentScheduleModal,
    QuantityAlertModal
  },
  data () {
    return {
      showScheduler: false,
      showQuantityAlert: false,
      payNow: null,
      imgError: false,
      timeOutPreCheckPaymentMode: null
    };
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
    this.timeOutPreCheckPaymentMode = setTimeout(() => {
      this.payNow = !this.memberAppConfigs?.onlyScheduledPaymentsOnline || !this.product.config.scheduleCount || this.product.config.scheduleCount === 1 ? this.product.cashPayment : false;
      this.onPaymentRadioChange();
    }, 50);
  },
  beforeUnmount () {
    clearTimeout(this.timeOutPreCheckPaymentMode);
  },
  computed: {
    ...mapGetters('zone', ['getVideoByProductId']),
    ...mapGetters('cart', ['isQuantityLocked', 'isStockAvailable', 'isRenewable', 'getQuantities']),
    ...mapState('cart', ['soldOutProducts', 'products']),
    ...mapState('config', ['memberAppConfigs']),
    hasSchedulerAndIsNotGift () {
      const isNotGift = this.$CONSTANTS.PRODUCT_TYPES.GIFT_CARD !== this.product.config.type && this.product.giftConfig == null;
      return isNotGift && this.product.config.scheduler;
    },
    dontShowImg () {
      const ARR_PRESTA = ['subscription', 'gift-card', 'prepaidCard'];
      return ARR_PRESTA.includes(this.product.config.type);
    },
    quantity: {
      get () {
        return this.product.quantity;
      },
      set (value) {
        this.UPDATE_PRODUCT_QUANTITY({
          id: this.product.config.id,
          quantity: value
        });
      }
    },
    isSoldOut () {
      return this.soldOutProducts && this.soldOutProducts.includes(this.product.config.id);
    },
    getVideoThumbnail () {
      return this.getVideoByProductId(this.product.config.id)?.thumbnailUrl;
    }
  },
  methods: {
    ...mapMutations('cart', ['UPDATE_PRODUCT_QUANTITY', 'DELETE_PRODUCT']),
    ...mapActions('cart', ['updateCashPayment']),
    onPaymentRadioChange () {
      if (this.memberAppConfigs?.onlyScheduledPaymentsOnline && this.product.config.scheduleCount > 1) {
        this.payNow = false;
      }

      if (this.payNow === false && this.quantity > 1) {
        this.showQuantityAlert = true;
        this.payNow = !this.payNow; // must cancel edition and wait modal response
      } else {
        this.updatePayment(this.payNow);
      }
    },
    onDeleteProduct () {
      this.DELETE_PRODUCT(this.product.config.id);
    },
    onQuantityAlertClose (canEdit) {
      this.showQuantityAlert = false;
      if (canEdit) {
        this.payNow = !this.payNow;
        this.updatePayment(this.payNow);
      }
    },
    updatePayment (value) {
      this.updateCashPayment({
        id: this.product.config.id,
        cashPayment: value
      });
    }
  },
  watch: {
    products () {
      this.payNow = !this.memberAppConfigs?.onlyScheduledPaymentsOnline ? this.product.cashPayment : false;
    }
  }
};
</script>

<style lang="less" scoped>
.cart-img {
  object-fit: contain;
  width: auto;
  height: auto;
  max-height: 124px;
  max-width: 70px;
  margin-right: var(--ariane-s);
  flex: 1;
}
.low-padding-divider {
  margin: 10px !important;
}
.card-item-cart {
  @apply p-md rounded;
}
.cart-details {
  @apply flex flex-col md:flex-row md:justify-between flex-grow;
}
.cart-product-name {
  @apply flex flex-col flex-grow;
}
.a-link {
  cursor: pointer;
  @apply text-secondary-500;
  &:hover { text-decoration: underline; }
}
.quantity-selector {
  display: inline-block;
}
.product-title {
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 580px) {
  .cart-item {
    flex-direction: column;
  }
  .cart-img {
    max-width: fit-content;
    margin-right: 0;
    margin-bottom: var(--ariane-s);
  }
}
</style>
