const publicPageRoutes = {
  landing: ['zones', 'activities'],
  calendar: ['bookings', 'activities', 'resources', 'users'],
  mobileSearch: ['bookings', 'activities', 'resources', 'users'],
  timeslotDetails: ['bookings', 'activities', 'resources', 'users'],
  shop: ['shop', 'zones']
};

export function areRoutesForPageOpen (page, scopesStatus) {
  if (!publicPageRoutes[page]) {
    throw new Error('No public route configuration for given page');
  }
  for (const route of publicPageRoutes[page]) {
    if (!scopesStatus[route]) {
      return false;
    }
  }
  return true;
}
