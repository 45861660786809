<template>
  <div class="gap-sm flex flex-col" v-if="guests && guests.length > 0">
    <div class="flex gap-sm overflow-y-auto">
      <div @click="selectGuest(index)" v-for="(guest, index) in guests" :key="index" class="cursor-pointer">
        <AriAvatar :class="{ 'opacity-40': (selectedGuestIndex || selectedGuestIndex === 0) && selectedGuestIndex !== index }" :size="30" :fontSize="0.8"
                   :user="guests[index] && guests[index].username"/>
      </div>
    </div>
    <!-- absolute bottom-0 z-40 w-full left-0 h-5/6 -->
    <div v-if="selectedGuestIndex || selectedGuestIndex === 0" class="p-sm bg-default rounded ">
      <div class="flex justify-between items-center">
        <span class="text-primary text-sm font-medium">{{displayedGuestName}}</span>
        <div class="container-buttons-edit-guest">
          <AriButton :content="$t('app.delete')" error text @click="deleteGuest()" v-if="showDeleteButton"/>
          <AriButton :content="$t('app.save')" text @click="editGuest()"/>
        </div>
      </div>
      <AriForm ref="formGuest">
        <template v-slot="form">
          <div class="flex flex-col gap-sm">
            <AriInput whiteBg medium :label="$t('username')" :form="form" :formatter="required" v-model="selectedGuestDatas.username"/>
            <AriInput medium whiteBg :label="$t('mail')" :form="form" :formatter="[required, mail]" v-model="selectedGuestDatas.email"/>
            <AriInput small whiteBg :label="$t('telephone')" v-model="selectedGuestDatas.phone" :formatter="phone" />
          </div>
        </template>
      </AriForm>
    </div>
  </div>
</template>

<script>
import { mail, required, phone } from '@/utils/inputFormatters';
export default {
  name: 'GuestManager',
  data () {
    return {
      selectedGuestIndex: null,
      selectedGuestDatas: {}
    };
  },
  computed: {
    showDeleteButton () {
      return !this.isRessource  || (this.isRessource && (!this.getCurrentActivity?.minPlacesToBook || (this.guests.length >= this.getCurrentActivity?.minPlacesToBook)));
    },
    displayedGuestName () {
      return this.selectedGuestDatas?.username ? this.selectedGuestDatas.username : this.$t('guests.anonymeGuest');
    },
    formatterMail () {
      return this.selectedGuestDatas?.username ? [mail, { fx: required, payload: this.$t('errorInput.requiredWithMail') }] : mail;
    },
    formatterUsername () {
      return this.selectedGuestDatas.email ? [{ fx: required, payload: this.$t('errorInput.requiredWithUsername') }] : null;
    }
  },
  mounted () {
    this.selectFirstGuestIfPossible();
  },
  methods: {
    phone,
    mail,
    required,
    selectFirstGuestIfPossible () {
      if (this.guests.length && this.selectedGuestIndex === null) {
        this.selectedGuestIndex = 0;
        this.selectedGuestDatas = { ...this.guests[0] };
      }
    },
    selectGuest (index) {
      if (this.selectedGuestIndex === index) this.resetSelectedGuest();
      else {
        this.selectedGuestIndex = index;
        if (this.guests[index] === {}) {
          this.selectedGuestDatas = {
            username: null,
            email: null,
            phone: null
          };
        } else this.selectedGuestDatas = { ...this.guests[index] };
      };
    },
    deleteGuest () {
      const visitId = this.guests[this.selectedGuestIndex]?.visitId;
      if (visitId) this.$emit('delete:guest', visitId);
      else {
        const cpArrGuest = [...this.guests];
        cpArrGuest.splice(this.selectedGuestIndex, 1);
        this.$emit('update:guests', cpArrGuest);
        this.resetSelectedGuest();
      };
    },
    editGuest () {
      const isFormValid = this.$refs.formGuest.validateForm();
      if (isFormValid) {
        const cpArrGuest = [...this.guests];
        cpArrGuest[this.selectedGuestIndex] = this.selectedGuestDatas;
        this.$emit('update:guests', cpArrGuest);
        return true;
      } else return false;
    },
    resetSelectedGuest () {
      this.selectedGuestDatas = {};
      this.selectedGuestIndex = null;
    }
  },
  watch: {
    guests () {
      this.selectFirstGuestIfPossible();
    }
  },
  props: {
    guests: {
      type: Array
    },
    getCurrentActivity: Object,
    isRessource: Boolean
  }
};
</script>

<style scoped>
.container-buttons-edit-guest {
 @apply flex gap-sm flex-col-reverse sm:flex-row;
}
</style>
