<template>
  <transition name="fade">
    <div v-if="visible" class="pop-over" @blur="closeDropdown" ref="popover">
      <div class="header" v-if="$slots.header">
        <slot name="header"></slot>
      </div>
      <div class="pop-over-body">
        <slot name="body" class=""></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'popover',
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean
    }
  },
  watch: {
    visible () {
      if (this.visible) {
        this.$nextTick(() => this.$refs.popover.focus());
      }
    }
  },
  methods: {
    closeDropdown () {
      this.$emit('update:visible', false);
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../assets/styles/vars.less';
@import '../../assets/styles/mixins.less';

.pop-over {
  cursor: default !important;
  width: fit-content;
  height: fit-content;
  position: absolute;
  background-color: var(--ariane-content-background);
  border: 1px solid var(--ariane-border);
  margin-top: var(--ariane-base);
  top: 0;
  right: var(--ariane-big-base);
  border-radius: 6px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--ariane-medium);
  line-height: initial;
  z-index: 4;
  outline: none;
}
.header {
  display: flex;
  flex-direction: column;
  gap: var(--ariane-medium);
  padding-top: var(--ariane-big-base);
  align-items: center;
}
.pop-over-body {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: var(--ariane-medium);
  align-items: flex-start;
}
</style>
