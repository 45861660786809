<template>
  <div v-if="userDomainInfo" class="my-personal-details-short">
    <div class="personal-details-advanced__detail">
      <div class="personal-details-advanced__names">
        <MyPersonalDetail
          :label="$t('surname')"
          :info="userDomainInfo.surname"
        />
        <MyPersonalDetail
          :label="$t('name')"
          :info="userDomainInfo.name"
        />
      </div>
    </div>
    <div class="personal-details-advanced__detail">
      <MyPersonalDetail :label="$t('email')" :info="userDomainInfo.email"/>
      <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyEmail"/>
    </div>
    <div class="personal-details-advanced__detail">
      <MyPersonalDetail
        :label="$t('cellphoneNumber')"
        :info="formatPhoneNumber(userDomainInfo.cellphoneNumber)"
      />
      <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyPhoneNumber"/>
    </div>
    <ariModal v-if="isEditFormShown" :modalTitle="editForm.title" @close="isEditFormShown = false" class="personal-details-advanced__edit-form">
      <template v-slot:body>
        <AriForm ref="editForm" class="flex items-baseline gap-xs p-default">
          <template v-slot="form">
            <span>{{ editForm.label }}</span>
            <AriInput :form="form" v-model="editForm.currentValue" :formatter="editForm.formatter"/>
          </template>
        </AriForm>
      </template>
      <template #footer>
        <div class="edit-form__actions">
          <AriButton text class="ariane-pr-medium" :content=" $t('cancel')" @click="isEditFormShown = false" />
          <AriButton filled :content=" $t('submit')" @click="editForm.action" />
          </div>
      </template>
    </ariModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MyPersonalDetail from '@/modules/myProfile/components/MyPersonalDetail.vue';
import CommonControl from '@/modules/common/mixins/commonControl';
import TextInput from '@/modules/common/generic/TextInput.vue';
import EditDetailControl from '@/modules/myProfile/mixins/editDetailControl.js';

export default {
  name: 'MyPersonalDetailsShort',

  components: {
    MyPersonalDetail,
    TextInput
  },

  mixins: [CommonControl, EditDetailControl],

  computed: {
    ...mapState('user', ['userDomainInfo'])
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';

.my-personal-details-short{
  background: @background-gray;
  padding: 1rem;
  .rectangle-rounded();
  .personal-detail {
    margin-bottom: 15px;
    display: inline-block;
  }
}
</style>
