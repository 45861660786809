import HTTP from '@/services/HTTP';
import { track } from '@/utils/information';
import cartStorage from '@/services/browserStorage/cart';
import CONSTANTS from '@/utils/constants';

cartStorage.clearOldCarts();

export default {
  namespaced: true,
  state: {
    productToBuy: {},
    zoneInfoForProduct: {},
    initiatePayment: false,
    pendingResa: null,
    useCredit: false,
    selectedWallet: null,
    products: {},
    _productIndex: 0,
    soldOutProducts: null
  },
  getters: {
    sortedProducts: state => Object.values(state.products).sort((p1, p2) => p2.order - p1.order),
    isEmpty: state => Object.keys(state.products).length <= 0,
    cartSize: state => Object.keys(state.products).length,
    isInCart: state => productId => state.products[productId],
    isStockAvailable: state => productId => {
      const inStock = (p) => p.config.stockAvailable == null || (p.quantity > 0 && p.quantity <= p.config.stockAvailable);
      if (productId == null) {
        return Object.values(state.products).some(p => {
          return inStock(p);
        });
      }
      return inStock(state.products[productId]);
    },
    isProductInCart: state => Object.values(state.products).some(p => p.config?.type === CONSTANTS.PRODUCT_TYPES.GOODS),
    hasCashPaymentNotDefined: (state, getters, rootState) => {
      return Object.values(state.products).some(p => {
        const isNotGift = p.config.type !== CONSTANTS.PRODUCT_TYPES.GIFT_CARD && p.giftConfig == null;
        return p.cashPayment === null && p.config.scheduleCount > 1 && isNotGift && !rootState.config.memberAppConfigs.onlyScheduledPaymentsOnline;
      });
    },
    hasGift: state => {
      return Object.values(state.products).some(p => {
        return p.config.type === CONSTANTS.PRODUCT_TYPES.GIFT_CARD || p.giftConfig != null;
      });
    },
    hasOneScheduledPayment: state => Object.values(state.products).some(p => p.cashPayment === false),
    isRenewable: state => item => item.isRenewable === 'O' && item.scheduleCount > 1,
    isCashPayment: state => productId => {
      const cashPayment = state.products[productId]?.cashPayment;
      return cashPayment == null || cashPayment === true;
    },
    getProductPrice: state => (productId, regularPrice = false) => {
      const product = state.products[productId];
      let price = product.config.totalPrice;
      if (regularPrice && product.config.regularTotalPrice != null) {
        price = product.config.regularTotalPrice;
      }
      if (product.cashPayment === false && product.config.scheduleCount > 1) {
        if (regularPrice && product.config.regularScheduler != null) {
          price = product.config.regularScheduler[0].price;
        } else {
          price = product.config.scheduler[0].price;
        }
      }
      return price;
    },
    totalPrice: (state, getters) => {
      const getProductPrice = getters.getProductPrice;
      return Object.values(state.products).reduce((c, p) => +(c + p.quantity * getProductPrice(p.config.id)).toFixed(2), 0);
    },
    regularTotalPrice: (state, getters) => {
      const getProductPrice = getters.getProductPrice;
      return Object.values(state.products).reduce((c, p) => +(c + p.quantity * getProductPrice(p.config.id, true)).toFixed(2), 0);
    },
    isQuantityLocked: (state, getters, rootState) => productId => {
      const product = state.products[productId];
      const config = product?.config;
      if (config) {
        const isNotCashPayment = product.cashPayment === false;
        const hasSeveralPendingResa = state.pendingResa?.recommendedSubscriptions?.length > 1;
        const hasPromotion = config.promotion?.code != null;
        const isZeroPriceOrQuantity = config.totalPrice === 0 || product.quantity === 0;
        const isVideo = config.type === CONSTANTS.PRODUCT_TYPES.VIDEO;
        const isGift = config.type === CONSTANTS.PRODUCT_TYPES.GIFT_CARD || product.giftConfig != null;
        const isCard = config.type === CONSTANTS.PRODUCT_TYPES.PREPAID_CARD2;
        const isSubscription = config.type === CONSTANTS.PRODUCT_TYPES.SUBSCRIPTION2;
        const isConvertingService = (isCard || isSubscription) && !!config.upgradeToMemberCat;

        const clientCategoryId = rootState.user.userDomainInfo.categoryId;
        const memberWillBeConverted =
          (clientCategoryId !== config.upgradeToMemberCat) && isConvertingService;

        return hasSeveralPendingResa ||
               isNotCashPayment ||
               isSubscription ||
               memberWillBeConverted ||
               isZeroPriceOrQuantity ||
               hasPromotion ||
               isVideo ||
               isGift;
      }
      return false;
    },
    hasZoneTos: (state) => {
      return state.zoneInfoForProduct && state.zoneInfoForProduct.termsOfSale && state.zoneInfoForProduct.termsOfSale !== 'null';
    },
    getQuantities: (state, getters) => productId => {
      const MAX_QUANTITY = 100;
      let quantities = [...Array(MAX_QUANTITY).keys()].slice(1);

      if (productId && state.products[productId]) {
        const isQuantityLocked = getters.isQuantityLocked;
        const product = state.products[productId];

        if (isQuantityLocked(productId)) {
          quantities = [product.quantity];
        } else if (product.config.stockAvailable != null && product.config.stockAvailable > 0) {
          const currentStock = product.config.stockAvailable + 1;
          if (currentStock < MAX_QUANTITY) {
            quantities = [...Array(currentStock).keys()].slice(1);
          }
        }
      }

      return quantities;
    }
  },
  mutations: {
    ADD_SOLD_OUT_PRODUCTS (state, soldout) {
      state.soldOutProducts = soldout;
    },
    ADD_PRODUCT_TO_CART (state, { product, onlyScheduledPaymentsOnline }) {
      // eslint-disable-next-line no-prototype-builtins
      const isAlreadyInCart = state.products.hasOwnProperty(product.id);
      const isNotSubscription = product.type !== CONSTANTS.PRODUCT_TYPES.SUBSCRIPTION2;
      const noPromotionToAddedProduct = product.promotion == null;
      let cashPayment = null;

      if (isAlreadyInCart && isNotSubscription && noPromotionToAddedProduct) {
        if (!state.products[product.id].promotion) state.products[product.id].quantity++;
      } else {
        delete state.products[product.id];
        if(onlyScheduledPaymentsOnline && product.scheduleCount && product.scheduleCount > 1) {
          cashPayment = false;
        }
        state.products[product.id] = { quantity: product.quantity || 1, cashPayment };
        state.products[product.id].config = product;
        state.products[product.id].promotion = product?.promotion?.code ? product.promotion : null;
        state.products[product.id].order = ++state._productIndex;
        state.products[product.id].giftConfig = product.giftConfig;
        state.products[product.id].code = product.promotion?.code || null;
        if (product.promotion?.scheduler) {
          state.products[product.id].config.regularScheduler = state.products[product.id].config.scheduler;
          state.products[product.id].config.scheduler = product.promotion.scheduler;
        }
        if (product.promotion?.price) {
          state.products[product.id].config.regularTotalPrice = state.products[product.id].config.totalPrice;
          state.products[product.id].config.totalPrice = product.promotion?.price;
        }
        if (product.giftConfig) {
          state.products[product.id].isGift = true;
          state.products[product.id].quantity = 1;
          state.products[product.id].cashPayment = true;
        }
        if (product.isLinkedToTimeSlot) {
          state.products[product.id].isLinkedToTimeSlot = true;
        }
      }
      state.products = cartStorage.save(state.products);
    },
    UPDATE_PRODUCT_CASH_PAYMENT (state, { id, cashPayment }) {
      if (state.products[id]) {
        state.products[id].cashPayment = cashPayment;
      }
      state.products = cartStorage.save(state.products);
    },
    DELETE_PRODUCT (state, id) {
      delete state.products[id];
      state.products = cartStorage.save(state.products);
    },
    DELETE_PROMO (state, id) {
      Object.keys(state.products).forEach(p => {
        if (+p === id) {
          const newProduct = state.products[id];
          newProduct.config = { ...newProduct.config };
          delete newProduct.promotion;
          delete newProduct.code;
          delete newProduct.config.promotion;
          newProduct.config.scheduler = newProduct.config.regularScheduler;
          delete newProduct.config.regularScheduler;
          newProduct.config.totalPrice = newProduct.config.regularTotalPrice;
          delete newProduct.config.regularTotalPrice;
          state.products[id] = newProduct;
        }
      });
      state.products = cartStorage.save(state.products);
    },
    UPDATE_PRODUCT_QUANTITY (state, { id, quantity }) {
      if (state.products[id]) {
        state.products[id].quantity = quantity && quantity > 0 ? quantity : 0;
      }
      state.products = cartStorage.save(state.products);
    },
    UPDATE_PRODUCT_STOCK (state, { id, stockAvailable }) {
      if (state.products[id]) {
        state.products[id].config.stockAvailable = stockAvailable;
      }
      state.products = cartStorage.save(state.products);
    },
    SET_PRODUCT_TO_BUY (state, product) {
      state.productToBuy = product;
    },
    SET_ZONE_INFO_FOR_PRODUCT (state, zoneInfo) {
      state.zoneInfoForProduct = zoneInfo;
    },
    SET_INITIATE_PAYMENT (state, value) {
      state.initiatePayment = value;
    },
    SET_PENDING_RESA (state, value) {
      const products = Object.values(state.products).filter(p => p.isLinkedToTimeSlot === true);
      if (products.length > 0) {
        products.forEach(p => delete state.products[p.config.id]);
        state.products = cartStorage.save(state.products);
      }
      state.pendingResa = value;
    },
    SET_USE_CREDIT (state, value) {
      state.useCredit = value;
    },
    SET_SELECTED_WALLET (state, value) {
      state.selectedWallet = value;
    },
    RESET_CART (state) {
      cartStorage.clear();
      state.productToBuy = {};
      state.zoneInfoForProduct = {};
      state.initiatePayment = false;
      state.pendingResa = null;
      state.selectedWallet = null;
      state.useCredit = false;
      state.products = {};
      state._productIndex = 0;
    },
    PERSIST_PRODUCTS  (state, value) {
      state.products = cartStorage.save(value);
    },
    INIT_PRODUCTS  (state) {
      state.products = cartStorage.restore() || {};
    }
  },
  actions: {
    async getProductDetails ({ commit }, productId) {
      const response = await HTTP.product.getProduct(productId);
      commit('SET_PRODUCT_TO_BUY', response.data);
    },
    async getZoneDetailsForProduct ({ rootGetters, commit }, zoneId) {
      let response;
      if (rootGetters['user/loggedInUser']) {
        response = await HTTP.zone.getZoneInfoFromMembersApi(zoneId);
      } else {
        response = await HTTP.zone.getZoneInfoFromPublicApi(zoneId);
      }
      commit('SET_ZONE_INFO_FOR_PRODUCT', response.data);
    },
    async addProduct ({ commit, rootGetters,  rootState, state }, { id, giftConfig }) {
      const product = rootGetters['zone/getProduct'](id);
      const facebookTrackingId = rootGetters['config/facebookTrackingId'];
      if (giftConfig) {
        product.giftConfig = giftConfig;
      }
      if (product) {
        const isCard = product.type === CONSTANTS.PRODUCT_TYPES.PREPAID_CARD2;
        const isSubscription = product.type === CONSTANTS.PRODUCT_TYPES.SUBSCRIPTION2;
        const quantity = state.pendingResa?.recommendedSubscriptions?.find(rs => rs.id === id)?.quantity || 1;
        const config = {
          isSubscription,
          isConvertingService: (isCard || isSubscription) && !!product.upgradeToMemberCat,
          quantity,
          isLinkedToTimeSlot: state.pendingResa != null
        };
        if (facebookTrackingId) {
          track.addToBasketWithFacebook(product.name);
        }
        track.addToBasketWithGoogle(product.name);
        commit('ADD_PRODUCT_TO_CART', { product: { ...product, ...config }, onlyScheduledPaymentsOnline: rootState.config.memberAppConfigs?.onlyScheduledPaymentsOnline });
        return true;
      }
      return false;
    },
    async updateCashPayment ({ commit }, { id, cashPayment }) {
      commit('UPDATE_PRODUCT_CASH_PAYMENT', { id, cashPayment });
      if (cashPayment === false) {
        commit('UPDATE_PRODUCT_QUANTITY', { id, quantity: 1 });
      }
    },
    async checkProductsStock ({ state, commit }) {
      const sanitizedProducts = [];
      Object.keys(state.products).forEach(key => {
        const productInCart = state.products[key];
        sanitizedProducts.push({ quantity: productInCart.quantity, productId: productInCart.config.id });
      });
      if (sanitizedProducts.length > 0) {
        const res = await HTTP.cart.checkStocks(sanitizedProducts);
        commit('ADD_SOLD_OUT_PRODUCTS', res.data.soldOutProducts);
        res.data.currentStocks?.forEach(product => {
          if (product.stock != null) {
            commit('UPDATE_PRODUCT_STOCK', { id: product.productId, stockAvailable: product.stock });
          }
          if (res.data.soldOutProducts?.includes(product.productId)) {
            commit('UPDATE_PRODUCT_QUANTITY', { id: product.productId, quantity: product.stock });
          }
        });
      }
    },
    updateCartProductsConfig ({ commit, rootGetters, state }) {
      const savedProducts = { ...state.products };
      Object.values(savedProducts).forEach(product => {
        const productId = product.config.id;
        const currentProduct = rootGetters['zone/getProduct'](productId);
        if (currentProduct) {
          product.config = currentProduct;
          product.config.promotion = product.promotion;
          if (product.promotion?.scheduler) {
            product.config.regularScheduler = currentProduct.scheduler;
            product.config.scheduler = product.promotion.scheduler;
          }
          if (product.promotion?.price) {
            product.config.regularTotalPrice = currentProduct.totalPrice;
            product.config.totalPrice = product.promotion?.price;
          }
        } else {
          delete savedProducts[productId];
        }
      });
      commit('PERSIST_PRODUCTS', savedProducts);
    }
  }
};
