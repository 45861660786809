import {
  memberAPI,
  publicAPI
} from '../request';
import { findDomain, adaptUrl } from '@/utils/url';

export default {
  getResourcesFromPublicApi: (zoneId) => {
    const url = adaptUrl(':domain/resources', {
      params: {
        domain: findDomain()
      },
      queries: {
        zoneId
      }
    });
    return publicAPI.get(url);
  },
  getResourcesFromMembersApi: (zoneId) => {
    const url = adaptUrl('resources', {
      queries: {
        zoneId
      }
    });
    return memberAPI.get(url);
  }
};
