<template>
  <div
    class="narrow-container"
  >
    <div
      class="narrow-container__inner"
    >
      <GoBack
        v-if="canGoBack"
      />
      <div
        class="narrow-container__content"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import GoBack from '@/modules/common/generic/GoBack.vue';

export default {
  name: 'NarrowContainer',

  components: {
    GoBack
  },

  props: {
    canGoBack: Boolean
  }
};
</script>

<style lang="less">
@import '../assets/styles/vars.less';
@import '../assets/styles/mixins.less';
.narrow-container{
  .narrow-container__inner{
    max-width: 992px;
    margin: auto;
    .narrow-container__content{
      background: white;
      padding: 1rem 0.5rem 1rem 0.5rem;
    }
  }
}

@media (min-width: 500px){
  .narrow-container{
    .narrow-container__inner{
      .narrow-container__content{
        padding: 2rem 3rem;
      }
    }
  }
}

@media (min-width: 992px){
  .narrow-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
    .narrow-container__inner{
      .narrow-container__content{
        .rectangle-rounded();
      }
    }
  }
}
</style>
