<template>
  <div id="app">
    <section class="h-full overflow-hidden el-container is-vertical flex bg-pri" v-if="appCreated">
      <HeaderMemberWeb />
      <main>
        <span v-if="shouldDisplayFreeProductOnSignUpModal">
          <FreeProductsOnSignUp :visibility="shouldDisplayFreeProductOnSignUpModal"
                                @on-cancel="showFreeProductOnSignUpModal(false)"/>
        </span>
        <router-view />
      </main>
      <FooterMemberWeb  class="footer flex flex-col fs-10"/>
    </section>
    <AppLoading v-if="!appCreated" />
  </div>
</template>

<script>
import HeaderMemberWeb from './Header.vue';
import FooterMemberWeb from './Footer.vue';
import AuthControl from '@/modules/auth/mixins/authControl.js';
import FreeProductsOnSignUp from '@/modules/tunnel/FreeProductsOnSignUp.vue';
import AppLoading from './AppLoading.vue';
import browserStorage from '@/services/browserStorage';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { generateDatathemeElement } from '@devteam/ariane-components/dist/tailwindPreset.mjs';
import { generateTheme } from '@devteam/ariane-design-system/dist/index.js';

export default {
  components: {
    HeaderMemberWeb,
    FooterMemberWeb,
    FreeProductsOnSignUp,
    AppLoading
  },
  mixins: [AuthControl],
  data () {
    return {
      loadingTimeout: null,
      loadingIcon: null,
      appCreated: false
    };
  },
  beforeCreate () {
    this.$store
      .dispatch('user/loadDomainUserInfo') // automatic login attempt
      .catch((error) => {
        this.$information.capture({ error });
        if (browserStorage.getDomainToken()) {
          browserStorage.setDomainToken(null);
          window.location.reload();
        }
      })
      .finally(() => {
        Promise.all([
          this.getMemberAppConfigs(),
          this.setUsersDeviceFormat(),
          this.setZoneColor(),
          this.setActiveZone()
        ])
          .then(() => {
            this.appCreated = true;
          })
          .catch((error) => {
            this.$information.capture({
              error,
              inform: true,
              component: 'AppContainer',
              method: 'beforeCreate'
            });
            this.appCreated = true;
          });
      });
  },
  mounted () {
    if (Object.keys(browserStorage.getCurrentSettings()).length === 0) {
      this.SET_LOCALSTORAGE_UNAVAILABLE();
    }
    if (this.shouldDisplayFreeProductOnSignUpModal) {
      this.showFreeProductOnSignUpModal(true);
    }
  },
  unmounted () {
    this._deregisterListenerForFormatChange();
  },
  watch: {
    '$store.state.domain.appState': function () {
      const currentAppState = this.$store.state.domain.appState;
      if (currentAppState === this.$CONSTANTS.APP_STATE.LOADING) {
        this._displayLoadingIcon();
      } else if (currentAppState === this.$CONSTANTS.APP_STATE.LOADED) {
        this._removeLoadingIcon();
      }
    },
    '$store.state.config.calendarConfigs.zone': function () {
      this.setZoneColor();
    }
  },
  computed: {
    ...mapState('domain', [
      'domain',
      'loadingMessage',
      'freeProductOnSignUpModalAlreadyShown'
    ]),
    ...mapState('user', ['deviceFormat', 'userDomainInfo']),
    ...mapGetters('user', ['loggedInUser']),
    ...mapGetters('domain', [
      'getZoneColor',
      'isMultiSite',
      'getFirstZone',
      'getActiveZone'
    ]),
    ...mapGetters('config', [
      'facebookTrackingId',
      'googleTrackingId',
      'unconnectedWelcomeModalByTunnel'
    ]),
    shouldDisplayFreeProductOnSignUpModal () {
      return (
        !this.freeProductOnSignUpModalAlreadyShown &&
        !this.loggedInUser &&
        this.unconnectedWelcomeModalByTunnel &&
        this.unconnectedWelcomeModalByTunnel ===
          this.$CONSTANTS.TUNNEL_OPTIONS.FREE_PRODUCT_ON_SIGNUP
      );
    }
  },
  inject: ['mq'],
  methods: {
    ...mapActions('domain', ['showFreeProductOnSignUpModal']),
    ...mapActions('zone', [
      'loadAllCoaches',
      'loadAllActivities',
      'loadAllProducts'
    ]),
    ...mapActions('user', ['setDeviceFormat']),
    ...mapMutations('config', ['SET_SELECTED_ZONE', 'SET_LOCALSTORAGE_UNAVAILABLE']),
    async getMemberAppConfigs () {
      if (this.facebookTrackingId) {
        await this.activateFacebookPixel();
      }
      await this.activateGoogleAnalytics();
    },
    setZoneColor () {
      if (this.getZoneColor) {
        // APPLY ZONE COLOR
        const DATATHEME_MAIN_ID = 'DATATHEME_MAIN';
        const currentDataThemeMain = document.getElementById(DATATHEME_MAIN_ID);
        if (currentDataThemeMain) currentDataThemeMain.remove();

        const myDatathemeElement = generateDatathemeElement(generateTheme({ primary: this.getZoneColor || '#7FB037', style: 'default' }), 'main');
        myDatathemeElement.id = DATATHEME_MAIN_ID;
        document.getElementsByTagName('body')[0].appendChild(myDatathemeElement);
      }
    },
    async setActiveZone () {
      if (!this.isMultiSite) {
        this.SET_SELECTED_ZONE(this.getFirstZone);
      }
      if (this.loggedInUser) {
        this.SET_SELECTED_ZONE(this.getActiveZone(this.userDomainInfo?.zone));
      }
      Promise.allSettled([
        this.loadAllCoaches(),
        this.loadAllActivities(),
        this.loadAllProducts()
      ]).then(() => true).catch((error) => {
        this.$information.capture({ error });
      });
    },
    setUsersDeviceFormat () {
      if (window.innerWidth < this.$CONSTANTS.DEVICE_FORMAT_BREAKPOINTS.FROM_MD_TO_LG) {
        this.setDeviceFormat(this.$CONSTANTS.DEVICE_FORMATS.MOBILE);
      } else {
        this.setDeviceFormat(this.$CONSTANTS.DEVICE_FORMATS.WEB);
      }
      this._registerListenerForFormatChange();
    },
    _displayLoadingIcon () {
      this.loadingTimeout = setTimeout(() => {
        this.loadingIcon = this.$loading({
          lock: true,
          text: this.loadingMessage,
          background: 'rgba(0, 0, 0, 0.7)'
        });
        // loading icon is displayed only if still loading after a small moment to prevent flashing on the screen
      }, 150);
    },
    _removeLoadingIcon () {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
      }
      if (this.loadingIcon) {
        this.loadingIcon.close();
      }
    },
    _resizeEventHandler () {
      if (this.deviceFormat === this.$CONSTANTS.DEVICE_FORMATS.WEB &&
        window.innerWidth < this.$CONSTANTS.DEVICE_FORMAT_BREAKPOINTS.FROM_MD_TO_LG
      ) {
        this.setDeviceFormat(this.$CONSTANTS.DEVICE_FORMATS.MOBILE);
      }
      if (
        this.deviceFormat === this.$CONSTANTS.DEVICE_FORMATS.MOBILE &&
        window.innerWidth >=
          this.$CONSTANTS.DEVICE_FORMAT_BREAKPOINTS.FROM_MD_TO_LG
      ) {
        this.setDeviceFormat(this.$CONSTANTS.DEVICE_FORMATS.WEB);
      }
    },
    _registerListenerForFormatChange () {
      window.addEventListener('resize', this._resizeEventHandler);
    },
    _deregisterListenerForFormatChange () {
      window.removeEventListener('resize', this._resizeEventHandler);
    },
    activateFacebookPixel () {
      return new Promise((resolve, reject) => {
        this.$information.track.initFacebookPixel(this.facebookTrackingId);
        this.$information.track.pageViewWithFacebook();
        resolve();
      });
    },
    activateGoogleAnalytics () {
      return new Promise((resolve, reject) => {
        this.$information.track.initGoogleAnalytics(this.googleTrackingId);
        resolve();
      });
    }
  }
};
</script>

<style lang="less">
@import '../../assets/styles/vars.less';
@import '../../assets/styles/mixins.less';
#app {
  height: 100vh;
}
@media (max-width: 992px) {
  .footer {
    display: none;
  }
  .page-content {
    padding: 6px 10px 0px 10px !important;
  }
  .my-products__toggle-expired {
    text-align: left !important;
  }
  .home-item {
    width: calc(100% - 20px) !important;
    margin: 10px 10px !important;
  }
  .padding-deci-title {
    padding-bottom: 10px !important;
  }
  .deci-title {
    display: flex;
    justify-content: center;
  }
}
main {
  background: @background-light;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto !important;
}
.el-loading-spinner svg {
  display: inline-block;
}
</style>
