<template>
  <div class="deci-details">
    <div class="deci-details__inner-body">
      <slot name="header"></slot>
      <slot name="body"></slot>
    </div>
    <div class="deci-details__bottom">
      <div>
        <slot name="footer"></slot>
      </div>
      <div class="deci-details__actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DetailsMemberWeb'
};
</script>

<style lang='less'>
@import '../../assets/styles/vars.less';
  .deci-details{
    margin: auto;
    width: 100%;
    max-width: 700px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  .deci-details__bottom {
    display: flex;
    flex-direction: column;
  }
  .deci-details__inner-body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;
  }
</style>
