<template>
  <el-col
    :offset="day === 0 ? 2 : 0"
    :span="3"
    class="text-center">
    <div class="header-box">
      <span class="text-secondary text-sm font-normal flex weekday-title">{{ dayOfTheWeekName(day) }}</span>
      <span class="text-primary text-sm font-medium flex">{{ dayOfTheMonth(day) }}</span>
    </div>
  </el-col>
</template>

<script>

export default {
  props: {
    day: Number,
    date: Date
  },
  methods: {
    dayOfTheWeekName (day) {
      return this.$datetime.getLocalWeekDayName(this.date);
    },
    dayOfTheMonth (day) {
      return this.$datetime.getDayOfMonth(this.date);
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.header-box{
  @apply gap-xs flex justify-center;
}
.weekday-title:first-letter {
  text-transform: uppercase;
}
</style>
