<template>
  <div class="timeslot text-base justify-between activity-indicator" :style="`background-color: ${colorBackground}; --my-activity-color:  ${currentActivityColor || 'var(--ariane-primary-500)'} ;`">
    <div class="flex flex-col">
      <span class="text-base font-medium text-primary flex items-center"> {{ timeslot.startTime }} </span>
      <span class="text-secondary font-normal text-sm">{{ minMaxDurations }}</span>
      <span class="timeslot-create-title" >{{timeslotTitle}}</span>
    </div>
    <div class="flex justify-between items-center">
      <span class="text-xs font-normal text-secondary justify-end" >{{slotText}}</span>
      <span class="text-xxs font-light text-secondary text-right" v-if="timeslot.tarificationType">
        {{slotPricingLabel(timeslot.tarificationType)}}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { generateTheme } from '@devteam/ariane-design-system/dist/index.js';
export default {
  props: {
    timeslot: Object
  },
  data () {
    return {
      colorBackground: 'var(--ariane-content-background)'
    };
  },
  mounted() {
    if (this.currentActivityColor && this.memberAppConfigs?.LDC_coloredTimeslot) {
      const palette = generateTheme({ primary: this.currentActivityColor, style: 'default' });
      this.colorBackground = palette.lightModeTokens.theme['primary-background'];
    }
  },
  computed: {
    ...mapState('zone', ['activities']),
    ...mapState('config', ['memberAppConfigs']),
    ...mapGetters('config', ['resourceLabel', 'slotPricingLabel']),
    timeslotTitle () {
      for (const activity of this.activities) {
        if (activity.id === this.timeslot.activityId) {
          return activity.name;
        };
      };
      return '';
    },
    currentActivityColor () {
      return this.activities.find(activity => activity.id === this.timeslot.activityId)?.color;
    },
    slotText () {
      let response = '';
      response = this.$tc('calendar.timeslot.resourcesAvailable', this.timeslot.resources.length, {
        nbResources: this.timeslot.resources.length,
        resource: this.resourceLabel
      });
      return response;
    },
    handleStatus () {
      let response = '';
      const status = this.timeslot.status;
      if (status === this.$CONSTANTS.STATUSES.AVAILABLE) {
        response = { text: this.handleShowNbPlaces(), css: '' };
      } else if (this.timeslot.status === this.$CONSTANTS.STATUSES.NOT_AVAILABLE_YET) {
        return { text: this.$t('calendar.timeslot.notAvailableYet'), css: 'not-available-yet' };
      } else if (status === this.$CONSTANTS.STATUSES.BOOKED) {
        response = { text: this.$t('calendar.timeslot.alreadyBooked'), css: 'booked' };
      } else if (status === this.$CONSTANTS.STATUSES.FULL) {
        response = { text: this.$t('calendar.timeslot.full'), css: 'full' };
      } else if (status === this.$CONSTANTS.STATUSES.QUEUED) {
        response = { text: this.$t('calendar.timeslot.inWaitingList'), css: 'in-waiting-list' };
      } else {
        response = { text: this.$t('calendar.timeslot.unavailable'), css: 'unavailable' };
      }
      return response;
    },
    additionalClass () {
      return 'slot--available';
    },
    minMaxDurations () {
      if (this.timeslot.availableDurations.length > 1) {
        return `${this.timeslot.availableDurations[0]} ${this.$t('calendar.minutesShort')}...${this.timeslot.availableDurations[this.timeslot.availableDurations.length - 1]} ${this.$t('calendar.minutesShort')}`;
      }
      return `${this.timeslot.availableDurations[0]} ${this.$t('calendar.minutesShort')}`;
    }
  }
};
</script>
<style scoped>
.timeslot-create-title {
  @apply text-sm font-normal text-secondary line-clamp-2 overflow-hidden;
}</style>
