<template>
  <div class="my-scheduled-payments">
    <div class="my-profile__section">
      <div class="my-profile__title">
        <h2 class="content-subtitle">{{ $t('profile.passedBookings.title') }}</h2>

        <el-table
          :data="preparedBookings"
          :show-header="false"
          style="width: 100%">
          <el-table-column
            prop="activityName"
            label="activity"
            min-width="75"
          >
          </el-table-column>

          <el-table-column
            prop="startDate"
            label="date"
            min-width="75"
          >
          </el-table-column>
          <el-table-column
            prop="startTime"
            label="heure"
            min-width="75"
          >
          </el-table-column>
          <el-table-column
            prop="type"
            label="type"
            min-width="75"
          >
          </el-table-column>
          <el-table-column
            prop="absentCount"
            label="absence"
            min-width="75"
          >
          </el-table-column>
        </el-table>
        <AriButton
          v-if="displayMore"
          class="decibutton"
          :content="$t('profile.passedBookings.showMore')"
          filled
          @click="loadMore"
        />

      </div>
    </div>
  </div>
</template>

<script>
import { prepareDate, prepareTime } from '../../../utils/bookingSessions';

export default {
  name: 'MyPassedBookings',
  data () {
    return {
      page: 1,
      count: 0,
      perPage: 20,
      bookings: []
    };
  },
  computed: {
    preparedBookings () {
      return this.bookings.map((booking) => {
        return {
          startDate: prepareDate(booking.booking.startDate),
          startTime: prepareTime(booking.booking.startDate),
          activityName: booking.booking?.activity?.name,
          type: booking.booking.type === 'lesson'
            ? this.$t('profile.passedBookings.lesson')
            : this.$t('profile.passedBookings.ressource'),
          absentCount: this.handleAbsence(booking?.visits)
        };
      });
    },
    displayMore () {
      return this.perPage * this.page < this.count;
    }
  },
  async mounted () {
    this.getBookings();
  },
  methods: {
    async getBookings (page = 1) {
      try {
        const { data } = await this.$services.HTTP.user.getPassedBookings({ page });
        if (page === 1) {
          this.count = data.count;
          this.bookings = data.rows;
        } else {
          this.page = page;
          this.bookings = [...this.bookings, ...data.rows];
        }
      } catch (error) {
        this.$information.alert({
          message: this.$t('profile.passedBookings.error'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
        });
        this.$information.capture({ error });
      }
    },
    loadMore () {
      const page = this.page + 1;
      this.getBookings(page);
    },
    handleAbsence (visits) {
      let result = ' - -';
      if (visits.length) {
        result = visits.find(visit => visit.absentCount > 0) ? this.$t('profile.passedBookings.absent') : this.$t('profile.passedBookings.present');
      }

      return result;
    }
  }
};
</script>

<style scoped>

</style>
