<template>
  <div class="personal-details-advanced">
    <NarrowContainer :canGoBack="true">
      <DeciTitle showDivider :text="$t('profile.personalDetails.myInformationTitle')"/>
      <MyPersonalDetails />
      <DeciTitle showDivider :text="$t('profile.personalDetails.myCommunicationTitle')" />
      <div class="personal-details-advanced__detail">
        <MyPersonalDetail :label="$t('email')" :info="userDomainInfo.email"/>
        <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyEmail"/>
      </div>
      <DeciTitle :text="$t('profile.personalDetails.communicationOptionsTitle')" :isLined="false" size="small"/>
      <div class="personal-details-advanced__communication ubuntuLight ariane-text-emphatize">
        <span>{{ $t('profile.personalDetails.notification') }}</span>
        <div prop="notification" :label="$t('profile.personalDetails.notification')">
          <el-select v-model="notificationConfig">
            <el-option
              v-for="method in notificationMethods"
              :label="method.name"
              :value="method.id"
              :key="method.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="personal-details-advanced__detail personal-details-advanced__detail--pronounced">
        <el-checkbox v-model="newsletterConfig">
          {{ $t("profile.personalDetails.newsletter") }}
        </el-checkbox>
      </div>
      <div class="personal-details-advanced__detail personal-details-advanced__detail--pronounced">
        <el-checkbox v-model="reminderConfig">
          {{ $t("profile.personalDetails.reminder") }}
        </el-checkbox>
      </div>
    </NarrowContainer>
    <ariModal v-if="isEditFormShown" :modalTitle="editForm.title" @close="isEditFormShown = false" class="personal-details-advanced__edit-form">
      <template #body>
        <AriForm ref="editForm" class="flex items-baseline gap-xs py-default">
          <template v-slot="form">
            <span>{{ editForm.label }}</span>
            <AriInput :form="form" v-model="editForm.currentValue" :formatter="editForm.formatter"/>
          </template>
        </AriForm>
      </template>
      <template #footer>
        <div class="edit-form__actions">
          <AriButton text class="ariane-pr-medium" :content="$t('cancel')" @click="isEditFormShown = false"/>
          <AriButton filled :content="$t('submit')" @click="editForm.action" />
        </div>
      </template>
    </ariModal>
  </div>
</template>

<script>
import NarrowContainer from '@/layouts/NarrowContainer.vue';
import MyPersonalDetails from '@/modules/myProfile/components/MyPersonalDetails.vue';
import MyPersonalDetail from '@/modules/myProfile/components/MyPersonalDetail.vue';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';
import TextInput from '@/modules/common/generic/TextInput.vue';
import CommonControl from '@/modules/common/mixins/commonControl';
import EditDetailControl from '@/modules/myProfile/mixins/editDetailControl.js';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'MyPersonalDetailsAdvancedPage',

  components: {
    NarrowContainer,
    MyPersonalDetails,
    MyPersonalDetail,
    DeciTitle,
    TextInput
  },

  mixins: [CommonControl, EditDetailControl],

  data () {
    return {
      notificationMethods: [
        {
          id: this.$CONSTANTS.NOTIFICATION_METHODS.MAIL_AND_SMS,
          name: this.$t('profile.notificationMethods.mailAndSms')
        },
        {
          id: this.$CONSTANTS.NOTIFICATION_METHODS.MAIL,
          name: this.$t('profile.notificationMethods.mail')
        },
        {
          id: this.$CONSTANTS.NOTIFICATION_METHODS.SMS,
          name: this.$t('profile.notificationMethods.sms')
        },
        {
          id: this.$CONSTANTS.NOTIFICATION_METHODS.NONE,
          name: this.$t('profile.notificationMethods.none')
        }
      ]
    };
  },

  computed: {
    ...mapState('user', ['userDomainInfo']),
    notificationConfig: {
      get () {
        return this.userDomainInfo?.notification;
      },
      set (newValue) {
        this.updateCommunication('notification', newValue);
      }
    },
    newsletterConfig: {
      get () {
        return this.userDomainInfo?.newsletter === 'O';
      },
      set (newValue) {
        this.updateCommunication('newsletter', newValue ? 'O' : 'N');
      }
    },
    reminderConfig: {
      get () {
        return this.userDomainInfo?.reminder === 'O';
      },
      set (newValue) {
        this.updateCommunication('reminder', newValue ? 'O' : 'N');
      }
    }
  },

  methods: {
    ...mapActions('user', ['loadDomainUserInfo']),
    // communication settings
    async updateCommunication (property, value) {
      try {
        const newDetail = {};
        newDetail[property] = value;
        await this.$services.HTTP.user.updateDomainUserInfo(newDetail);
        this.loadDomainUserInfo();
        this.showConfirmation();
      } catch (error) {
        this.$information.capture({ error, inform: true });
        this.$information.alert({
          message: this.$t('calendar.errors.GENERAL_ERROR')
        });
      }
    }
  }
};
</script>

<style lang="less">
@import '../../assets/styles/vars.less';
.personal-details-advanced__title{
  margin: 0rem 1rem 2rem 0;
  padding-top: 1.5rem;
}
.personal-details-advanced__detail{
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &.personal-details-advanced__detail--pronounced{
    background: @background-gray;
    margin: 1rem 0;
    padding: 1rem;
  }
  .personal-details-advanced__names{
    display: flex;
  }
  .interactive-div{
    max-width: 150px;
  }
}
.personal-details-advanced__communication{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.personal-details-advanced__edit-form{
  .edit-form__actions{
    @apply flex justify-between items-center;
    .interactive-div{
      max-width: 150px;
      margin: 1rem 0 0 1rem;
    }
  }
}

@media (min-width: 500px){
  .personal-details-advanced__detail{
    flex-direction: row;
    text-align: initial;
  }
}
</style>
