<template>
  <AriModal :modalTitle="$t('profile.paymentMethods.manageCards')" v-if="visibility && !this.redirectionMode" @close="$emit('on-close')">
    <template #body>
      <div class="flex body-modale">
        <iframe ref="iframe" id="iframe-manage-wallet" :src="manageWalletUrl" height="525" width="100%"></iframe>
        <AriButton
          text
          :content="$t('profile.paymentMethods.manualRedirect')"
          @click="redirectionMode = true"
        />
    </div>
    </template>
  </AriModal>
</template>

<script>
import ProductsControl from '@/modules/products/mixins/productsControl.js';

export default {
  name: 'ModalManageWallet',
  mixins: [ProductsControl],
  props: {
    visibility: Boolean,
    memberWalletId: String
  },
  data () {
    return {
      manageWalletUrl: null,
      redirectionMode: false,
    };
  },
  methods: {
    getManageWalletUrl() {
      this.manageWalletUrl = this.$services.HTTP.user.getManageWalletUrl(!this.memberWalletId, this.memberWalletId);
    },
  },
  watch: {
    visibility () {
      if (this.visibility) {
        if(this.redirectionMode) {
          this.registerWallet(this.memberWalletId);
        }else {
          this.getManageWalletUrl();
        }
      }
    },
    redirectionMode () {
      if(this.redirectionMode) {
        this.registerWallet(this.memberWalletId);
      }
    }
  }
};
</script>

<style lang="less" scoped>
  #iframe-manage-wallet {
    border: 0;
  }
  .body-modale {
    overflow: auto;
    gap: 30px;
    flex-direction: column;
  }
  // Prevent modal overflow because of Payline iframe width
  @media (min-width:640px){
    .body-modale {
      min-width: 380px;
    }
  }
</style>
