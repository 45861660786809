<template>
  <el-card
    class="my-invoice"
  >
    <div
      class="my-invoice__container"
    >
      <div
        class="my-invoice__icon-container"
      >
        <i class="fas fa-file-invoice-dollar"></i>
      </div>
      <div>
        <div>
          <b>{{ $datetime.getLocalFullDate($datetime.fromISODate(invoice.date)) }}</b>
        </div>
        <div>
          {{ invoicePrice }}
        </div>
      </div>
      <interactive-div
        class="my-invoice__icon-container"
        @on-interaction="$emit('download-invoice')"
      >
        <i class="fas fa-download"></i>
      </interactive-div>
    </div>
  </el-card>
</template>

<script>
import ProductsControl from '@/modules/products/mixins/productsControl.js';

export default {
  name: 'MyInvoice',

  mixins: [ProductsControl],

  props: {
    invoice: Object
  },

  computed: {
    invoicePrice () {
      const invoice = this.preparePricePresentation(this.invoice.total);
      return invoice.price + ' ' + invoice.currency;
    }
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.my-invoice{
  min-width: 285px;
  &.is-always-shadow{
    box-shadow: initial !important;
    border: 1px solid #C4C4C4;
  }
  .rectangle-rounded();
  background: @background-gray;
  .my-invoice__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    .my-invoice__icon-container{
      i{
        font-size: @biggest-text-size;
        .full-rounded();
        padding: 0.5rem;
        &.fa-download{
          background: @secondary-color;
          color: var(--ariane-primary-400);
          &:hover{
            opacity: 0.7;
          }
        }
      }
    }
  }
}
</style>
