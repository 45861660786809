export const downloadPdfBufferAsPdf = (buffer, fileName) => {
  const bytes = new Uint8Array(buffer);
  const blob = new Blob([bytes], { type: 'application/pdf' });
  const blobURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobURL;
  link.download = fileName;
  link.click();
  link.remove();
  window.URL.revokeObjectURL(blobURL);
};
