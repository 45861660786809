import {
  getDaySectionsTemplate,
  setDaySectionProperties,
  addTimeSlotToDaySection,
  sortEachDaySectionChronologically
} from './daySection';
import CONSTANTS from '@/utils/constants';
import * as datetime from '@/utils/datetime';

export function removeUnsuitableSessions (bookingSessions) {
  return bookingSessions.filter(bookingSession => {
    const status = _detectSessionStatus(bookingSession);
    bookingSession.memberStatus = { ...bookingSession.status };
    bookingSession.status = status;
    return status !== CONSTANTS.STATUSES.INACTIVE && status !== CONSTANTS.STATUSES.UNKNOWN;
  });
}

function _detectSessionStatus (bookingSession) {
  if (datetime.isCurrentTimeOrLater(datetime.fromISODate(bookingSession.bookable_at))) {
    return CONSTANTS.STATUSES.NOT_AVAILABLE_YET;
  } else if (bookingSession.maxBookings > bookingSession.bookedMembers) {
    return CONSTANTS.STATUSES.AVAILABLE;
  } else if (bookingSession.maxBookings <= bookingSession.bookedMembers) {
    return CONSTANTS.STATUSES.FULL;
  } else {
    return CONSTANTS.STATUSES.UNKNOWN;
  }
}

export function getSessionStatus (bookingSession) {
  return _detectSessionStatus(bookingSession);
}

export function generateSessionTimeslots (bookingSessions) {
  let sessionTimeslots = getDaySectionsTemplate();
  bookingSessions.map(bookingSession => {
    const timeslot = _generateNewSessionSlot(bookingSession);
    sessionTimeslots = addTimeSlotToDaySection(sessionTimeslots, timeslot);
  });
  sessionTimeslots = sortEachDaySectionChronologically(sessionTimeslots);
  return setDaySectionProperties(sessionTimeslots);
}

export function groupSessionsByDay (bookingSessions) {
  const sessionsList = {};
  bookingSessions.map(bookingSession => {
    const formattedSession = _generateNewSessionSlot(bookingSession);
    const sessionDay = datetime.getDateForMobile(formattedSession.date);
    if (!sessionsList[sessionDay]) {
      sessionsList[sessionDay] = [];
    }
    sessionsList[sessionDay].push(formattedSession);
  });
  return sessionsList;
}

function _generateNewSessionSlot (bookingSession) {
  return {
    ...bookingSession,
    type: CONSTANTS.CALENDAR_MODES.SESSION,
    date: datetime.fromISODate(bookingSession.startDate),
    freeSpots: Number.parseInt(bookingSession.maxBookings) - Number.parseInt(bookingSession.bookedMembers),
    startTime: datetime.getLocalTime(datetime.fromISODate(bookingSession.startDate)),
    endTime: datetime.getLocalTime(datetime.getNSecondsLater(datetime.fromISODate(bookingSession.startDate), bookingSession.duration))
  };
}

export function prepareDate (rawDateTime) {
  return datetime.getLocalMediumDate(
    datetime.fromISODate(rawDateTime)
  );
}
export function prepareTime (rawDateTime) {
  return datetime.getLocalTime(
    datetime.fromISODate(rawDateTime)
  );
}
export function prepareBookingDateAndTime (isoDate) {
  return datetime.getLocalMediumDateAndTime(
    datetime.fromISODate(isoDate)
  );
}
export function prepareZoneName (idz, zones) {
  for (const zone of zones) {
    if (zone.id === idz) {
      return zone.clubName;
    }
  }
}

export function prepareEndTime (rawDateTime, duration) {
  return datetime.getLocalTime(
    datetime.getNSecondsLater(
      datetime.fromISODate(
        rawDateTime
      ), duration)
  );
}

export function prepareApiSession (apiSession) {
  const next = { ...apiSession };
  const startDate = apiSession.startDate;
  next.originalDate = apiSession.startDate;
  next.startDate = prepareDate(startDate);
  next.startTime = prepareTime(startDate);
  next.startDateAndTime = prepareBookingDateAndTime(startDate);
  next.endTime = prepareEndTime(startDate, next.duration);
  return next;
}
