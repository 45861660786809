<template>
  <div class="body-box">
    <template v-if="dayContainer">
        <Timeslot
          @booking-success="$emit('booking-success')"
          v-for="(timeslot, index) in timeslots"
          :key="index"
          :timeslot="updateBookingStatus(timeslot)"
        />
    </template>
    <div v-if="dayContainer.length === 0" class="empty-day-spacer"></div>
  </div>
</template>

<script>
import Timeslot from './Timeslot/layout.timeslot.vue';
import CalendarControl from '../mixins/calendarControl.js';
import { mapState } from 'vuex';

export default {
  name: 'BodyBox',
  components: {
    Timeslot
  },
  mixins: [CalendarControl],
  props: {
    dayContainer: Array,
    isExpand: Boolean
  },
  data () {
    return {
      showModal: false,
      currentSlot: {}
    };
  },
  computed: {
    ...mapState('user', ['bookings', 'waitingListRegistrations']),
    timeslots () {
      return this.isExpand ? this.dayContainer : this.dayContainer.slice(0, 3);
    }
  }
};
</script>

<style lang="less" scoped>
.empty-day-spacer{
  width:100%;
  height: 100px;
}
.body-box {
  @apply flex flex-col gap-xxs;
}
</style>
