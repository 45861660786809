import zone from './modules/zone';
import user from './modules/user';
import domain from './modules/domain';
import product from './modules/product';
import videos from './modules/videos';
import vouchers from './modules/vouchers';
import personalData from './modules/personal-data';
import scheduledPayments from './modules/scheduled-payments';
import authenticate from './modules/authenticate';
import invoices from './modules/invoices';
import booking from './modules/booking';
import session from './modules/session';
import coach from './modules/coach';
import resource from './modules/resource';
import activitie from './modules/activitie';
import cart from './modules/cart';
import contracts from './modules/contracts';

export default {
  domain,
  zone,
  user,
  product,
  videos,
  vouchers,
  personalData,
  scheduledPayments,
  authenticate,
  invoices,
  booking,
  session,
  coach,
  resource,
  activitie,
  cart,
  contracts
};
