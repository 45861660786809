import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapState('domain', ['zones']),
    ...mapState('user', ['userDomainInfo']),
    ...mapGetters('domain', ['domain']),
    ...mapGetters('user', ['loggedInUser']),
    queriesWithoutToPage () {
      const { to, ...remaining } = { ...this.$router.currentRoute.value.query };
      this.$information.capture({ component: to });
      return remaining;
    },
    goToRouteReminder () {
      return this.$router.currentRoute.value.query.to || null;
    }
  },
  methods: {
    ...mapActions('user', ['loadDomainUserInfo', 'logout']),
    ...mapActions('zone', ['loadAllProducts', 'loadAllCoaches', 'loadAllActivities']),
    ...mapMutations('config', ['SET_SELECTED_ZONE', 'SET_TUNNEL_CONFIGURATIONS']),
    alertSignInMandatory () {
      this.$information.alert({
        message: this.$t('authenticate.loginFirst'),
        showClose: true
      });
    },
    async preAuth () {
      try {
        await this.logout();
      } catch (error) {
        this.$information.capture({ error });
      }
    },
    async postAuth () {
      if (this.loggedInUser) {
        await this.loadDomainUserInfo();
        this.SET_TUNNEL_CONFIGURATIONS({});
        const userZone = this.zones.find(zone => zone.id === this.userDomainInfo.zone);
        this.SET_SELECTED_ZONE(userZone);
        return Promise.all([
          this.loadAllActivities(),
          this.loadAllCoaches(),
          this.loadAllProducts()
        ]).then(() => {
          this.redirectAfterAuth();
        }).catch(error => {
          this.$information.capture({ error });
          this.redirectAfterAuth();
        });
      }
    },
    redirectAfterAuth () {
      const route = this.$router.currentRoute.value.query.route;
      if (route) {
        this.$router.replace({ path: route });
      } else if (this.goToRouteReminder) {
        const newRoute = {
          name: this.goToRouteReminder,
          query: this.queriesWithoutToPage
        };
        if (newRoute.name === "videoDetails") {
          newRoute.params = { id: this.$router.currentRoute.value.query.toVideo };
        }
        this.$router.replace(newRoute);
      } else {
        this.$router.replace({
          name: 'calendar'
        });
      }
    }
  }
};
