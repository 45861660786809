<template>
  <div class="flex flex-col gap-md w-full">
    <div class="flex flex-col gap-sm">
      <span class="font-medium text-base text-primary" v-if="allowGuests">{{$t('timeslot.guest.parameter')}}</span>
      <div class="flex justify-between w-full" v-if="allowGuests">
        <ItemTimeslot :label="$t('timeslot.guest.title')" :content="$t('timeslot.guest.howMuch')">
          <AriInputNumber class="flex" @update:modelValue="handleChangeInvitedMembers($event)" :modelValue="lessonGuests.length + 1"
                          :max="timeslot.maxBookings - timeslot.bookedMembers" :min="1" small :disabled="isFull"/>
        </ItemTimeslot>
      </div>
      <guestManager :is-ressource="false" :getCurrentActivity="getCurrentActivity" :guests="lessonGuests" @update:guests="$emit('update:lessonGuests', $event)"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import guestManager from '../../components/GuestManager.vue';
import ItemTimeslot from '../components/components/item-timeslot.vue';
export default {
  name: 'TimeSlotBookSeance',
  components: {
    guestManager,
    ItemTimeslot
  },
  data () {
    return {
      showGuest: false,
      arrGuest: []
    };
  },
  computed: {
    arrTags () {
      const arrayTags = [];
      const arrKeys = Object.keys(this.timeslot.tags);
      arrKeys.forEach(key => {
        arrayTags.push({ name: key, value: this.timeslot.tags[key] });
      });
      return arrayTags;
    },
    ...mapGetters('config', ['resourceLabel']),
    ...mapState('config', ['memberAppConfigs']),
    allowGuests () {
      const allowInvitations = (this.getCurrentActivity.allowInvitations === 'O' || this.getCurrentActivity.allowInvitations === 'L');
      const notAlreadyBooked = !this.alreadyBooked;
      const autoCheckInEnabled = this.memberAppConfigs?.autoCheckIn;
      return allowInvitations && notAlreadyBooked && autoCheckInEnabled;
    }
  },
  methods: {
    deleteGuest (index) {
      const cpArr = [...this.lessonGuests];
      cpArr.splice(index, 1);
      this.$emit('update:lessonGuests', cpArr);
    },
    handleChangeInvitedMembers (nbInvitedmembers) {
      const arrGuest = [];
      for (let i = 0; i < nbInvitedmembers - 1; i++) {
        if (this.lessonGuests[i]) {
          arrGuest.push(this.lessonGuests[i]);
        } else arrGuest.push({});
      }
      this.$emit('update:lessonGuests', arrGuest);
    }
  },
  props: {
    isFull: Boolean,
    alreadyBooked: Boolean,
    lessonGuests: Array,
    timeslot: Object,
    getCurrentActivity: Object,
    getRessource: Object,
    handleDescriptions: Object
  }
};
</script>

<style lang="less" scoped>
</style>
