<template>
  <div
    class="price-summary"
  >
    <el-col
      class="price-summary__pricing_container"
    >
      <el-row
        type="flex"
        v-for="(payment, index) in paymentAmounts"
        :key="index"
      >
        <el-col
          class="price-summary__price-info"
        >
          <div
            v-if="useCredit && index === 0"
          >
            <b>{{ getPriceLabel(firstPaymentAmountAfterCredit(payment.price)) }}</b>
            <del> {{ getPriceLabel(paymentAmount(payment.price)) }}</del>
          </div>
          <div
            v-else
          >
            <b>{{ getPriceLabel(paymentAmount(payment.price)) }}</b>
          </div>
        </el-col>
        <el-col
          class="price-summary__date_info"
        >
          <span>{{ paymentDate(payment.paymentDate) }}</span>
          <span
            v-if="payment.renewal === 'O'"
          > ({{ $t('product.isRenewal') }})</span>
        </el-col>
      </el-row>
    </el-col>
  </div>
</template>

<script>
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import { mapState } from 'vuex';

export default {
  name: 'PriceSummary',

  mixins: [ProductsControl],

  props: {
    paymentAmounts: {
      type: Array,
      required: true
    },
    quantity: {
      type: Number,
      default: 1
    },
    useCredit: {
      type: Boolean
    }
  },

  computed: {
    ...mapState('user', ['userBalance'])
  },

  methods: {
    paymentAmount (price) {
      const cumulatedPrice = price * this.quantity;
      return (cumulatedPrice).toFixed(2);
    },
    firstPaymentAmountAfterCredit (price) {
      const cumulatedPrice = price * this.quantity;
      if (this.userBalance?.balance) {
        const newCumulatedPrice = cumulatedPrice - this.userBalance.balance;
        return newCumulatedPrice < 0 ? '0.00' : (newCumulatedPrice).toFixed(2);
      } else {
        return (cumulatedPrice).toFixed(2);
      }
    },
    paymentDate (date) {
      const jsDate = this.$datetime.fromISODate(date);
      if (this.$datetime.isToday(jsDate)) {
        return this.$t('now');
      }
      return this.$datetime.getLocalNumericDate(jsDate);
    },
    getPriceLabel (price) {
      const p = this.preparePricePresentation(price);
      return p.price + ' ' + p.currency;
    }
  }
};
</script>

<style lang="less">
.price-summary{
  .price-summary__price-info{
    text-align: right;
    margin: 0 1rem 0 0;
  }
  .price-summary__date_info{
    text-align: left;
    margin: 0;
  }
}
</style>
