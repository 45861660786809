
import { createStore } from 'vuex';

import zone from './modules/zone';
import user from './modules/user';
import domain from './modules/domain';
import config from './modules/config';
import cart from './modules/cart';

const store = createStore({
  modules: {
    zone,
    user,
    domain,
    config,
    cart
  }
});
export default store;
