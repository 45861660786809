export const createPlaceCoordinates = (placeMap) => {
  const coordinates = {};
  placeMap.places.map(place => _addCoordinateForPlace(coordinates, place));
  return coordinates;
};

const _addCoordinateForPlace = (coordinates, place) => {
  const newCoordinate = `${place.x}-${place.y}`;
  coordinates[newCoordinate] = { ...place };
};

export const getCellContentHtml = ({ cell, bookedCellId, isReadOnly }) => {
  const isCellBookedByMe = cell.id === bookedCellId;
  const isCellBookedByAnother = cell.isAvailable === 'O' && cell.isBooked;
  const isCellForCoach = cell.isCoach === 'O';
  const isCellAvailable = cell.isAvailable === 'O';
  const isCellUnavailable = cell.isAvailable === 'N';
  const isCellAc = cell.name === 'ac';
  const isCellDoor = cell.name === 'door';
  const isCellEmergencyExit = cell.name === 'emergencyExit';
  const isCellSpeaker = cell.name === 'speaker';
  const isCellPlatform = cell.name === 'platform';

  let cellHtml = '';
  if (isCellBookedByMe) {
    cellHtml += `<div class="place-map__cell-content place-map__cell-content--booked">${cell.name}</div>`;
  } else if (isCellBookedByAnother) {
    cellHtml += `<div class="place-map__cell-content place-map__cell-content--no-longer-available">${cell.name}</div>`;
  } else if (isCellAc) {
    cellHtml += '<i class="fas fa-fan"></i>';
  } else if (isCellDoor) {
    cellHtml += '<i class="fas fa-door-open"></i>';
  } else if (isCellEmergencyExit) {
    cellHtml += '<i class="fas fa-running"></i>';
  } else if (isCellSpeaker) {
    cellHtml += '<i class="fas fa-volume-up"></i>';
  } else if (isCellPlatform) {
    cellHtml += '<i class="fas fa-box"></i>';
  } else if (isCellForCoach) {
    cellHtml += `<div class="place-map__cell-content place-map__cell-content--coach">${cell.name}</div>`;
  } else if (isCellAvailable) {
    let cssClasses = 'place-map__cell-content place-map__cell-content--available';
    cssClasses += isReadOnly ? '' : ' place-map__cell-content--clickable';
    cellHtml += `<div class="${cssClasses}">${cell.name}</div>`;
  } else if (isCellUnavailable) {
    cellHtml += `<div class="place-map__cell-content place-map__cell-content--unavailable">${cell.name}</div>`;
  }
  return cellHtml;
};
