<template>
  <div class="flex flex-grow flex-col pr-sm gap-md">
    <span class="text-primary text-base font-medium">{{$t('timeslot.settingResa')}}</span>
    <resourceHandler :timeslotResources="timeslot.resources" v-model:selectedResource="schedule.resourceId" :minPlacesToBook="minPlacesToBook" />
    <template v-if="schedule.resourceId && loggedInUser">
      <itemTimeslot :label="$t('timeslot.duration')" :content="$t('timeslot.durationContent')">
        <el-select :disabled="!schedule.resourceId" v-model="schedule.duration">
          <el-option v-for="(duration, index) in timeslot.availableDurations" :label="duration"
            :value="duration" :key="index" />
        </el-select>
      </itemTimeslot>
      <itemTimeslot :content="visibilityBooleanPublic ? $t('timeslot.publicVisibility') : $t('timeslot.privateVisibility')" v-if="publicSessionsActivated && timeslot.allowPublicSessions && getRessource.maxPlayerCount > 1">
        <template #label>
          <span class="pr-xs">{{$t('timeslot.visibility')}} : </span>
          <span class="text-main">{{visibilityBooleanPublic ?  $t('timeslot.visibilityPublic') : $t('timeslot.visibilityPrivate') }}</span>
        </template>
        <AriToggle v-model="visibilityBooleanPublic" :disabled="!schedule.resourceId && timeslot.allowPublicSessions"/>
      </itemTimeslot>
      <itemTimeslot :label="$t('timeslot.capacity')" :content="$t('timeslot.capacityContent')">
        <AriInputNumber v-model="schedule.capacity" :max="getRessource.maxPlayerCount" :min="minPlacesToBook" small/>
      </itemTimeslot>
      <itemTimeslot :label="$t('timeslot.guest.title')" :content="$t('timeslot.guest.howMuch')" v-if="autoCheckInEnabled && getCurrentActivity.allowInvitations === 'T' || getCurrentActivity.allowInvitations === 'O'">
        <AriInputNumber @update:modelValue="handleChangeInvitedMembers($event)" :modelValue="schedule && schedule.invitedMembers && schedule.invitedMembers.length + 1"
                        :max="schedule.capacity" :min="minPlacesToBook" small/>
      </itemTimeslot>
      <guestManager v-if="autoCheckInEnabled" :getCurrentActivity="getCurrentActivity" v-model:guests="schedule.invitedMembers" :is-ressource="true"/>
      <itemTimeslot colMobile :content="visibilityBooleanPublic ? $t('timeslot.TagsDescrPublic') : $t('timeslot.TagsDescrPrivate')" :label="$t('timeslot.tags')" v-if="publicSessionsActivated && timeslot.allowPublicSessions && getCurrentActivity.reservationTags && getCurrentActivity.reservationTags.length > 0">
        <tagManager v-if="allTagsSelected" @click="forceShowTag = !forceShowTag" :modelValue="schedule.tags" displayMode/>
      </itemTimeslot>
      <tagManager v-if="publicSessionsActivated" ref="tagManager" :mandatory="visibilityBooleanPublic" :class="{ hidden: allTagsSelected && !forceShowTag }"
                  v-model="schedule.tags" :availableTags="getCurrentActivity.reservationTags"/>
      <itemTimeslot :content="$t('timeslot.addDescription')" v-if="schedule.visibility === 'O'">
        <AriToggle v-model="addDescription"/>
      </itemTimeslot>
      <textarea v-if="addDescription" rows="5" class="w-full no-resize clean-textarea" v-model="schedule.details"/>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import itemTimeslot from '../components/components/item-timeslot.vue';
import guestManager from '../../components/GuestManager.vue';
import tagManager from './components/tag-manager.vue';
import resourceHandler from './components/ressourceHandler.vue';
export default {
  name: 'timeslotCreateResa',
  components: {
    resourceHandler,
    itemTimeslot,
    guestManager,
    tagManager
  },
  model: {
    prop: 'schedule',
    event: 'change'
  },
  data () {
    return {
      addDescription: false,
      forceShowTag: false
    };
  },
  props: {
    step: Number,
    schedule: Object,
    timeslot: Object,
    getCurrentActivity: Object,
    getRessource: Object
  },
  computed: {
    visibilityBooleanPublic: {
      get () {
        return this.schedule.visibility === 'O';
      },
      set (value) {
        this.schedule.visibility = value ? 'O' : 'P';
      }
    },
    publicSessionsActivated () {
      return this.getDomainConfigForDomain?.config.displayPublicSession;
    },
    allTagsSelected () {
      return this.schedule?.tags && this.schedule?.tags.every(tag => tag) && this.schedule.tags?.length === this.getCurrentActivity.reservationTags?.length;
    },
    mandatoryTags () {
      const requiredTag = [];
      this.getCurrentActivity.reservationTags.filter(groupTag => {
        if (groupTag.required) requiredTag.push(groupTag);
      });
      return requiredTag;
    },
    ...mapGetters('domain', ['getDomainConfigForDomain']),
    ...mapState('zone', ['resources']),
    ...mapGetters('config', ['resourceLabel']),
    ...mapState('config', ['memberAppConfigs']),
    ...mapGetters('user', ['loggedInUser']),
    autoCheckInEnabled () {
      return this.memberAppConfigs.autoCheckIn;
    },
    minPlacesToBook () {
      if (this.timeslot?.resources?.length > 0 && this.schedule.resourceId) {
        const myResource = this.timeslot.resources.find(r => r.id === this.schedule.resourceId);
        return this.schedule.visibility === 'O' ? myResource.minPlacesToBookPublic : myResource.minPlacesToBookPrivate;
      } else return 1;
    }
  },
  watch: {
    'schedule.resourceId' () {
      setTimeout(() => { this.schedule.capacity = this.getRessource?.maxPlayerCount; });
    },
    'schedule.capacity' (newValue) {
      if (newValue && newValue < this.schedule.invitedMembers.length + 1) {
        while (this.schedule.invitedMembers.length > newValue - 1) this.schedule.invitedMembers.pop();
      } else if (newValue > this.getCurrentActivity?.minPlacesToBook &&
        this.getCurrentActivity?.minPlacesToBook > this.schedule.invitedMembers.length) {
        this.handleChangeInvitedMembers(this.getCurrentActivity?.minPlacesToBook);
      }
    }
  },
  methods: {
    checkStates () {
      this.$refs.tagManager?.checkState();
    },
    handleChangeInvitedMembers (nbInvitedmembers) {
      this.schedule.invitedMembers = [];
      for (let i = 0; i < nbInvitedmembers - 1; i++) {
        this.schedule.invitedMembers.push({});
      }
    },
    addNewTag (tag) {
      const lastTag = [...tag].pop();
      let groupOfLastTag = null;
      this.getCurrentActivity.reservationTags.forEach(tagGroup => {
        const isItThisGroup = tagGroup.values.find(tag2 => tag2 === lastTag);
        if (isItThisGroup) groupOfLastTag = tagGroup;
      });
      for (let i = 0; i < tag.length; i++) {
        if (groupOfLastTag.values.includes(tag[i]) && tag[i] !== lastTag) tag.splice(i, 1);
      }
      this.schedule.tags = tag;
    },
    buildResourceIdentifier (resourceId) {
      for (const resource of this.resources) {
        if (resource.id === resourceId) {
          return `${resource.name} (${this.$t('calendar.timeslot.resourceLimit', { maxPlayerCount: resource.maxPlayerCount })})`;
        }
      }
    }
  }
};
</script>

<style>
  .clean-textarea, .clean-textarea:focus-visible {
    @apply border-1 border-default border-solid bg-default outline-none resize-y;
  }
</style>
