import i18n from '@/services/translation/i18n';
import {checkPasswordStrength} from "./passwordChecker";
const { t, tc } = i18n.global;

export function mail (value) {
  const REGEXP_EMAIL = /^\S{1,}@\S{1,}\.\S{2,}$/;
  if (!REGEXP_EMAIL.exec(value) && value) return t('errorInput.email');
};

export function required (value, displayedError = null) {
  const error = displayedError || t('errorInput.required');
  return (value && (typeof value !== 'string' || value.trim() !== '')) ? '' : error;
};

export function phone (value) {
  // eslint-disable-next-line no-useless-escape
  const REGEXP_PHONE = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (!REGEXP_PHONE.exec(value) && value) return t('errorInput.phone');
};

export function minLength (inputValue, minLength) {
  if (inputValue?.length < minLength) return tc('errorInput.minLength', minLength, { length: minLength });
};

export function maxLength (inputValue, maxLength) {
  if (inputValue?.length > maxLength) return tc('errorInput.maxLength', maxLength, { length: maxLength });
};

export function specificLength (inputValue, length) {
  if (inputValue?.length !== length) return tc('errorInput.specificLength', length, { length });
};

export function password (inputValue, { pass1, pass2}) {
  if (inputValue.length && pass1 && pass2 && pass1 !== pass2) return t('errorInput.samePassword');
}

export function passwordStrength (inputValue, { name, surname, email, birthdate }) {
  const passwordError = checkPasswordStrength(inputValue,  name, surname, email, birthdate);
  if(passwordError) {
    return t(`authenticate.passwordRules.${passwordError}`);
  }
}
