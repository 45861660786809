import { DateTime } from 'luxon';
import store from '@/store';
import CONSTANTS from './constants';

export function isOlderThanCurrentTime (date) {
  return DateTime.fromJSDate(date).diffNow().milliseconds < 0;
}

export function isOlderThanToday (date) {
  return DateTime.fromJSDate(date).diffNow(['days']).days < -1;
}

export function isToday (date) {
  return Math.trunc(Math.abs(DateTime.fromJSDate(date).diffNow(['days']).days)) === 0;
}

export function isTodayOrLater (date) {
  return Math.trunc(DateTime.fromJSDate(date).diffNow(['days']).days) >= 0;
}

export function isCurrentTimeOrLater (date) {
  return DateTime.fromJSDate(date).diffNow().milliseconds >= 0;
}

export function isNotLaterThanXMinutesFromNow (date, minutes) {
  return DateTime.fromJSDate(date).diffNow().milliseconds <= minutes * CONSTANTS.CALENDAR.MILISECONDS_IN_A_MINUTE;
}

export function getDifferenceInHours (date, otherDate) {
  const otherDateJS = DateTime.fromJSDate(otherDate);
  return DateTime.fromJSDate(date).diff(otherDateJS, 'hours').toObject().hours;
}

/**
 * 3
 */
export function getDayOfMonth (date) {
  return DateTime.fromJSDate(date).day;
}

/**
 * 2
 */
export function getNumericDayOfWeek (date) {
  return DateTime.fromJSDate(date).weekday;
}

/**
 * 10/3/2020
 */
export function getLocalNumericDate (date) {
  return DateTime.fromJSDate(date).setLocale(store.getters['user/language']).toLocaleString();
}

/**
 * November 3, 2020
 */
export function getLocalFullDate (date) {
  if (!date) {
    return null;
  }
  return DateTime.fromJSDate(date).setLocale(store.getters['user/language']).toLocaleString(DateTime.DATE_FULL);
}

/**
 * Tue, Nov 3, 2020
 */
export function getLocalMediumDate (date) {
  return DateTime.fromJSDate(date).setLocale(store.getters['user/language']).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
}

/**
 * Tue, Nov 3, 2020, 12:00 AM
 */
export function getLocalMediumDateAndTime (date) {
  return DateTime.fromJSDate(date).setLocale(store.getters['user/language']).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
}

/**
 * Tuesday
 */
export function getLocalWeekDayName (date) {
  return DateTime.fromJSDate(date).setLocale(store.getters['user/language']).weekdayLong;
}

/**
 * 12:00 AM
 */
export function getLocalTime (date) {
  return DateTime.fromJSDate(date).setLocale(store.getters['user/language']).toLocaleString(DateTime.TIME_SIMPLE);
}

export function getNDaysLater (date, day) {
  return DateTime.fromJSDate(date).plus({ day }).toJSDate();
}

export function getNDaysEarlier (date, day) {
  return DateTime.fromJSDate(date).minus({ day }).toJSDate();
}

export function getNYearsEarlier (date, year) {
  return DateTime.fromJSDate(date).minus({ year }).toJSDate();
}

export function getNSecondsLater (date, seconds) {
  return DateTime.fromJSDate(date).plus({ seconds }).toJSDate();
}

export function getCurrentWeekStart () {
  return getWeekStart(new Date());
}

export function getWeekStart (date) {
  const weekYear = DateTime.fromJSDate(date).weekYear;
  const weekNumber = DateTime.fromJSDate(date).weekNumber;
  return DateTime.fromObject({ weekYear, weekNumber }).toJSDate();
}

export function fromISODate (date) {
  if (!date) {
    return null;
  }
  const dateContainsTimezone = date.includes('T');
  return DateTime.fromISO(dateContainsTimezone ? date.slice(0, -5) : date).setZone('Europe/Paris').toJSDate();
}
export function dateOnlyFormat (strDate, separator = '/') {
  const date = new Date(strDate);
  if (!date || !(date instanceof Date)) {
    return undefined;
  }
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const year = date.getFullYear();
  return [day, month, year].join(separator);
}
export function toISODate (date) {
  return DateTime.fromJSDate(date).setZone('Europe/Paris').toISODate();
}
export function getDateForMobile (date) {
  // TODO/ Utiliser la timezone du club et pas la tz local
  const siteTz = store.state.config.memberAppConfigs.LDC_serveurTimeZone || 'Europe/Paris';
  return DateTime.fromJSDate(date).setZone(siteTz).toISODate();

}
export function toResourceReservationSpecificDateFormat (date) {
  return DateTime.fromJSDate(date).toFormat('yyyy-LL-dd\'T\'HH\':\'mm\':\'ss');
}

export function toDefaultDateFormat (date) {
  return DateTime.fromJSDate(date).toFormat('yyyy-LL-dd');
}

export function detectDaySection (date) {
  const hour = DateTime.fromJSDate(date).hour;
  if (hour >= CONSTANTS.SECTIONS_OF_DAY.morning.start && hour <= CONSTANTS.SECTIONS_OF_DAY.morning.finish) {
    return 'morning';
  } else if (hour >= CONSTANTS.SECTIONS_OF_DAY.day.start && hour <= CONSTANTS.SECTIONS_OF_DAY.day.finish) {
    return 'day';
  } else {
    return 'evening';
  }
}
