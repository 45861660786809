<template>
  <HomeItem
    :itemDetails="myProductDetails"
    :product="product"
    :dateEnd="product.dateEnd"
  >
    <template v-slot:header>
      <div class="flex gap-sm">
        {{ product.product.title }}
        <div class="badge-shared with-me" v-if="product.subscriber"><i class="fa fa-arrow-down-to-bracket"></i>{{ $t('products.sharedWithMe') }}</div>
        <div class="badge-shared from-me" v-if="product.nbBeneficiaries > 0"><i class="fa fa-arrow-up-from-bracket"></i>{{ $t('products.shared') }}</div>
      </div>
    </template>
  </HomeItem>
</template>

<script>
import HomeItem from './HomeItem.vue';

export default {
  components: {
    HomeItem
  },
  props: {
    product: Object
  },
  computed: {
    myProductDetails () {
      return [
        {
          title: !this.product.autoRenewal ? this.$t('account.myProducts.expiresOn') : this.$t('account.myProducts.status'),
          info: !this.product.autoRenewal ? this.product.expiration : this.$t('account.myProducts.active')
        },
        {
          title: this.$t('account.myProducts.remainingCredits'),
          info: this.product.remaining
        },
        {
          title: this.$t('account.myProducts.associatedActivities'),
          info: this.product.associatedActivities
        }
      ];
    }
  }
};
</script>
<style lang="less">
.badge-shared {
  width: fit-content;
  @apply rounded-full text-xxs font-normal py-xxs px-sm flex gap-xxs items-center;
}
.badge-shared.with-me {
@apply bg-trivial-background text-on-trivial-background;

}
.badge-shared.from-me {
  @apply bg-secondary-background text-on-secondary-background;
}
</style>
