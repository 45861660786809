<template>
  <AriModal modalTitle="Mise à jour requise de vos moyens de paiement" v-if="showModal" @close="lastMigrationWalletTimestamp = new Date().getTime()">
    <template #body>
      <div class="flex body-modale">
      <div v-if="!displayMigrationIframe" class="flex flex-grow body-modale-inner">
        <div class="bloc-egale">
          <div class="bloc-egale justify-center">
            <span class="club-info justify-center">Afin d'être conforme à la nouvelle norme de sécurité des transactions par carte bancaire, veuillez mettre à jour votre moyen de paiement.</span>
            <span v-if="userDomainInfo.hasWalletPaymentSchedule" class="club-info justify-center">Attention : Vous avez des échéances prévues par carte bancaire (wallet). Pour continuer, mettez à jour votre mode de paiement.</span>
          </div>
        </div>
      </div>
      <div v-if="!displayMigrationIframe" class="justify-end flex">
        <AriButton
          filled
          :content="'Mettre à jour mon wallet'"
          @click="displayMigrationIframe = true"
        />
      </div>
      <div v-if="displayMigrationIframe" class="justify-end flex">
        <iframe id="iframe-migration-wallet" :src="manageWalletUrl" height="525" width="100%"></iframe>
      </div>
    </div>
    </template>
  </AriModal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'modalMigrationWallet',
  data () {
    return {
      manageWalletUrl: null,
      localStorageKey: null,
      lastMigrationWalletTimestamp: null,
      displayMigrationIframe: false
    };
  },
  mounted () {
    this.localStorageKey = `${this.domain}-${this.userDomainInfo.id}-last-migration-wallet-timestamp`;
    this.lastMigrationWalletTimestamp = this.getLastMigrationWalletTimestamp();
    this.setLastMigrationWalletTimestamp();
    this.manageWalletUrl = this.$services.HTTP.user.getManageWalletUrl(true);
  },
  methods: {
    getLastMigrationWalletTimestamp () {
      let lastMigrationWalletTimestamp = window.localStorage.getItem(this.localStorageKey);
      if (lastMigrationWalletTimestamp) lastMigrationWalletTimestamp = parseInt(lastMigrationWalletTimestamp);
      return lastMigrationWalletTimestamp || 0;
    },
    setLastMigrationWalletTimestamp () {
      return window.localStorage.setItem(this.localStorageKey, new Date().getTime());
    },
    isThresholdExceeded () {
      // 24h en secondes
      const threshold = 86400;
      const currentTimestamp = new Date().getTime();
      // On divise par 1000 pour passer d'un timestamp milliseconds à second pour pas avoir des ordres de grandeurs trop élevé ;)
      const diffTimestamp = (currentTimestamp - this.lastMigrationWalletTimestamp) / 1000;
      return diffTimestamp > threshold;
    }
  },
  computed: {
    ...mapGetters('user', ['loggedInUser']),
    ...mapState('user', ['userDomainInfo']),
    ...mapState('domain', ['domain']),
    showModal () {
      return !!this.loggedInUser && this.userDomainInfo?.hasWalletV1 && this.isThresholdExceeded();
    }
  }
};
</script>

<style lang="less" scoped>
#iframe-migration-wallet {
  border: 0;
}
  .body-modale {
    overflow: auto;
    gap: 30px;
    flex-direction: column;
  }
  @media screen and (max-width: 767px) {
    .body-modale-inner {
      flex-direction: column;
    }
    .bloc-city {
      flex-direction: column;
      gap: 0px;
    }
  }
  .fg-2 {
    flex-grow: 2;
  }
.f-shrink-0 {
  flex-shrink: 0;
  flex-basis: 0;
}
  .title {
    text-align: center;
    font-size: 28px;
    color: #333333;
  }
  .bloc-egale {
    gap: 18px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-basis: 0;
    flex-grow: 1;
  }
  .decibutton {
    width: fit-content !important;
    display: flex;
  }
  .justify-end {
    justify-content: center;
    align-items: center;
  }
  .club-info {
    text-align: center;
  }
</style>
