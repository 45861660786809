// Lachement volé du projet suivant : https://github.com/atanas-dev/vue-router-multiguard/blob/master/index.js
// Au vu de la simplicité, on a préféré Le cp pour pouvoir plus facilement le debugger / améliorer

function isUndefined (value) {
  return value === undefined;
}

async function evaluateGuards (guards, to, from, next) {
  const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift();
  if (isUndefined(nextGuard)) {
    next();
    return;
  }

  await nextGuard(to, from, (nextArg) => {
    if (isUndefined(nextArg)) {
      evaluateGuards(guardsLeft, to, from, next);
      return;
    }
    next(nextArg);
  });
}

export default function (guards) {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards');
  }

  return (to, from, next) => {
    return evaluateGuards(guards, to, from, next);
  };
};
