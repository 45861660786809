import { memberAPI } from '../request';
import CONSTANTS from '@/utils/constants';

export default {
  loadUpcomingUserBookings: () => memberAPI.get('bookings/upcoming'),
  bookSession: async ({ bookingId, placeId, invitedMembers, uniqueId, fullError = false }) => {
    try {
      const { data } = await memberAPI.post(
        'booking/:bookingId/addMember'.replace(':bookingId', bookingId),
        { placeId, invitedMembers, uniqueId }
      );
      if (data.booking && data.booking.bookingState === 'init') {
        return CONSTANTS.CALENDAR.SUCCESSFUL_BOOKING;
      }
    } catch (err) {
      if (fullError) return err;
      else if (err.response) {
        return err.response.data.message;
      } else {
        return err.message;
      }
    }
  },
  bookResourceMO (body) {
    return memberAPI.post('booking/bookMemberToResource', body);
  },
  bookResource: async (schedule, invitedMembers) => {
    try {
      const body = {
        invitedMembers,
        schedule
      };
      const { data } = await memberAPI.post('booking/bookMemberToResource', body);
      if (data.booking && data.booking.bookingState === 'init') {
        return CONSTANTS.CALENDAR.SUCCESSFUL_BOOKING;
      }
    } catch (err) {
      if (err.response) {
        return err.response.data.message;
      } else {
        return err.message;
      }
    }
  },
  registerToWaitingList: async (bookingId) => {
    try {
      const { data } = await memberAPI.post(
        'booking/:bookingId/addMemberInQueue'.replace(':bookingId', bookingId)
      );
      const awaitingMembers = data.booking.awaitingMembers;
      return {
        waitingListOrder: awaitingMembers[awaitingMembers.length - 1].order,
        registrationResponse: CONSTANTS.CALENDAR.SUCCESSFUL_BOOKING
      };
    } catch (err) {
      if (err.response) {
        throw new Error(err.response.data.message);
      } else {
        throw new Error(err);
      }
    }
  },
  cancelBooking: (bookingId) => memberAPI.delete(
    'booking/:bookingId/cancelMember'.replace(':bookingId', bookingId)
  ),
  patchSessionFromMembersApiById (idSession, body) {
    return memberAPI.patch(`booking/${idSession}`, body);
  },
  deleteGuest (idSession, arrGuestToDelete) {
    return memberAPI.post(`booking/${idSession}/removeGuest`, arrGuestToDelete);
  },
  putGuestsResa (idSession, arrGuest) {
    return memberAPI.put(`booking/${idSession}/guests`, arrGuest);
  }
};
