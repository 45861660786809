<template>
  <div class="birthday-input">
    <el-form
      :model="birthdateForm"
      :rules="birthdateFormRules"
      ref="birthdateForm">
      <el-form-item
        class="birthday-input-title"
        :label="$t('birthdate.title')">
      </el-form-item>
      <el-form-item
        class="input-element input-day birthday-input-small"
        :label="dayLabel"
        prop="day">
        <el-input
          type="number"
          :min="minDay"
          :max="maxDay"
          name="birthDay"
          ref="dayInput"
          v-model.number="birthdateForm.day"
          @change="onBirthdateChange"
          @keyup="onBirthdateChange"
        />
      </el-form-item>
      <el-form-item
        class="input-element input-month birthday-input-small"
        :label="monthLabel"
        prop="month">
        <el-input
          type="number"
          :min="minMonth"
          :max="maxMonth"
          name="birthMonth"
          ref="monthInput"
          v-model.number="birthdateForm.month"
          @change="onBirthdateChange"
          @keyup="onBirthdateChange"
        />
      </el-form-item>
      <el-form-item
        class="input-element input-year"
        :label="yearLabel"
        prop="year">
        <el-input
          type="number"
          :min="minYear"
          :max="maxYear"
          name="birthYear"
          ref="yearInput"
          v-model.number="birthdateForm.year"
          @change="onBirthdateChange"
          @keyup="onBirthdateChange"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'BirthdayInput',
  props: {
    prefill: {
      type: Date
    },
    minDay: {
      type: Number,
      default: 1
    },
    maxDay: {
      type: Number,
      default: 31
    },
    minMonth: {
      type: Number,
      default: 1
    },
    maxMonth: {
      type: Number,
      default: 12
    },
    minYear: {
      type: Number,
      default: 1900
    },
    maxYear: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
  data () {
    return {
      birthdateForm: {
        day: null,
        month: null,
        year: null
      },
      birthdateFormRules: {
        day: [
          {
            required: true,
            message: this.$t('birthdate.pleaseEnterDay'),
            trigger: 'blur'
          },
          {
            type: 'number',
            min: this.minDay,
            max: this.maxDay,
            message: this.$t('birthdate.pleaseEnterNumberBetween', {
              min: this.minDay,
              max: this.maxDay
            }),
            trigger: 'blur'
          }
        ],
        month: [
          {
            required: true,
            message: this.$t('birthdate.pleaseEnterMonth'),
            trigger: 'blur'
          },
          {
            type: 'number',
            min: this.minMonth,
            max: this.maxMonth,
            message: this.$t('birthdate.pleaseEnterNumberBetween', {
              min: this.minMonth,
              max: this.maxMonth
            }),
            trigger: 'blur'
          }
        ],
        year: [
          {
            required: true,
            message: this.$t('birthdate.pleaseEnterYear'),
            trigger: 'blur'
          },
          {
            type: 'number',
            min: this.minYear,
            max: this.maxYear,
            message: this.$t('birthdate.pleaseEnterNumberBetween', {
              min: this.minYear,
              max: this.maxYear
            }),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  mounted () {
    if (this.prefill) {
      this.birthdateForm.day = this.prefill.getDate();
      this.birthdateForm.month = this.prefill.getMonth() + 1;
      this.birthdateForm.year = this.prefill.getFullYear();
    }
  },
  inject: ['mq'],
  computed: {
    canSwitchToMonth () {
      return this.birthdateForm.day >= 4;
    },
    canSwitchToYear () {
      return this.birthdateForm.month >= 2;
    },
    yearLabel () {
      return this.mq.current === 'sm' ? this.$t('birthdate.yearShort') : this.$t('birthdate.year');
    },
    monthLabel () {
      return this.mq.current === 'sm' ? this.$t('birthdate.monthShort') : this.$t('birthdate.month');
    },
    dayLabel () {
      return this.mq.current === 'sm' ? this.$t('birthdate.dayShort') : this.$t('birthdate.day');
    }
  },
  methods: {
    onBirthdateChange (e) {
      if (this.canSwitchToYear && !this.birthdateForm.year) {
        this.$refs.yearInput.focus();
      } else if (this.canSwitchToMonth && !this.birthdateForm.month) {
        this.$refs.monthInput.focus();
      }
      this.validate();
    },
    async validate () {
      let isValid = false;
      await this.$refs.birthdateForm.validate((validForm) => {
        if (validForm) {
          this.$emit('on-date-change',
            new Date(this.birthdateForm.year, this.birthdateForm.month - 1, this.birthdateForm.day)
          );
        } else {
          this.$emit('on-date-change', null);
        }
        isValid = validForm;
      });
      return isValid;
    }
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.birthday-input-small {
  min-width: 38px;
}
.birthday-input{
  min-width: 270px;
  .el-form{
      label.el-form-item__label{
        line-height: initial !important;
        padding: 0 !important;
    }
  }
  .input-element{
    display: inline-block;
    width: 20%;
    margin-right: 7%;
    margin-top:-15px;
  }
  .input-year{
    width: 46%;
    margin-right: 0;
  }
}
</style>
