<template>
  <div class="table-container waiting-list-container grid-content">
    <DeciTitle
      :isLined="false"
      :text="$t('account.waitinglist.title')"
    />
    <MyWaitingList
      v-for="(registration, index) in waitingListRegistrations"
      :key="index"
      :registration="registration"
      @on-cancel-registration="onCancelRegistration"
    />
  </div>
</template>

<script>
import MyWaitingList from './MyWaitingList.vue';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';

export default {
  name: 'MyWaitingLists',

  components: {
    MyWaitingList,
    DeciTitle
  },

  props: {
    waitingListRegistrations: Array
  },

  methods: {
    onCancelRegistration ({ cancelId, bookingDateAndTime, bookingType }) {
      this.$emit('on-cancel-waiting-list', { cancelId, bookingDateAndTime, bookingType });
    }
  }
};
</script>
