<template>
  <HomeItem :itemDetails="myBookingDetails" :booking="booking.booking" @on-click-action="onSeeMyPlace()" :arrGuest="arrGuest" :participants="participants">
    <template v-slot:header>
      <div class="flex gap-default w-full">
        <span>{{ booking.startDate }} - {{ booking.startTime }}</span>
        <InfoBox v-if="booking.booking.details" :info="booking.booking.details"
                  icon="fas color-accent fa-question-circle my-booking-info-icon "/>
        <div @click="redirectToLivestream(booking.booking.livestreamUrl)">
          <InfoBox v-if="hasLiveStream" :info="livestreamMessage"  ref="popover" :popper-style="canShowLiveStreamLink(this.booking.booking.startDate) ? 'visibility: hidden;' : ''"
                  icon="far color-accent fa-play-circle my-booking-info-icon" />
        </div>

        <span v-if="booking.booking.visibility && publicSessionsActivated">{{$t('booking.visibility.' + booking.booking.visibility)}}</span>
      </div>
    </template>
    <template v-slot:modal>
      <AriModal :modalTitle="$t('booking.editOpenmatch')" v-if="editModal" minWidth="35%" @close="editModal = false">
        <template #body>
          <div class="flex gap-lg flex-col">
            <div class="flex gap-default items-center" v-if="getCurrentActivity.reservationTags && getCurrentActivity.reservationTags.length > 0">
              <span>Vos tags:</span>
              <el-select multiple @change="addNewTag($event)" :modelValue="editedValues.tags">
                <el-option-group v-for="(group, indexGroup) in getCurrentActivity.reservationTags" :key="indexGroup"
                  :label="group.title + (group.required && editedValues.visibility !== 'private' ? ' (Obligatoire)' : '')">
                  <el-option v-for="(tag, index) in group.values" :label="tag"
                    :value="tag" :key="index" />
                </el-option-group>
              </el-select>
            </div>
            <div class="flex gap-default items-center">
              <span>Votre description:</span>
              <textarea class="w-full no-resize clean-textarea" v-model="editedValues.details"/>
            </div>
            <div class="flex gap-default flex-col">
              <div class="flex gap-default">
                <span>Visibilité publique:</span>
                <div class="mr-5">
                  <Ari-toggle v-model="editedValues.visibility"
                              :custom-values="{true: 'public', false: 'private'}">
                  </Ari-toggle>
                </div>
              </div>
              <span v-if="visibilityDisabled && booking.booking.visibility === 'public'" class="fs-10 color-red" >Une session publique ne peut pas repasser privée.</span>
              <span v-else-if="getRessource.maxPlayerCount === 1" class="fs-10 color-red">Une session publique doit pouvoir comporter plusieurs places.</span>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="w-full justify-end flex pt-xxs gap-sm">
            <AriButton class="text-right" :content="$t('close')" @click="editModal = false" />
            <AriButton class="text-right" :content="$t('edit')" @click="save()" />
          </div>
        </template>
      </AriModal>
    </template>
    <template v-slot:headerActionOnlyDesktop>
      <div class="flex gap-default">
        <AriButton text preIcon="fas fa-cog text-main" v-if="booking.booking.visibility && booking.status.isHost" :title="$t('account.booking.edit')" @click="onClickEdit" />
        <AriButton text preIcon="fas fa-trash-alt text-main" @click="onClickCancel" :title="$t('app.cancel')" />
        <AriButton text preIcon="fa-arrow-up-from-bracket fas text-main" @click="onClickShare" :title="$t('account.booking.share')" />
        <AriButton text preIcon="fas fa-calendar-circle-plus text-main" @click="generateICS()" :title="$t('account.booking.ics')" />
      </div>
    </template>
    <template v-slot:bottomActionOnlyMobile>
      <div class="cancel-booking-bottom-button gap-default flex justify-center">
        <AriButton text preIcon="fas fa-cog text-main" v-if="booking.booking.visibility && booking.status.isHost" :title="$t('account.booking.edit')" @click="onClickEdit" />
        <AriButton text preIcon="fas fa-trash-alt text-main" @click="onClickCancel" :title="$t('app.cancel')" />
        <AriButton text preIcon="fas fa-arrow-up-from-bracket text-main" @click="onClickShare" :title="$t('account.booking.share')" />
        <AriButton text preIcon="fas fa-calendar-circle-plus text-main" @click="generateICS()" :title="$t('account.booking.ics')" />
      </div>
    </template>
  </HomeItem>
</template>

<script>

import { mapGetters, mapState, mapActions } from 'vuex';
import HomeItem from './HomeItem.vue';
import InfoBox from '@/modules/common/generic/InfoBox.vue';
import CalendarControl from '@/modules/calendar/mixins/calendarControl.js';
import { displayBookingErrors } from '@/utils/errors';
import * as ics from 'ics';

export default {
  components: {
    HomeItem,
    InfoBox
  },
  emits: ['on-cancel-booking'],
  mixins: [CalendarControl],
  props: {
    booking: Object,
    bookingIndex: Number
  },
  data () {
    return {
      editedValues: {
        details: '',
        tags: '',
        visibility: ''
      },
      nbParticipants: [],
      arrGuest: [],
      participants: [],
      editModal: false
    };
  },
  mounted () {
    this.loadSessionDetails();
    this.loadAllResources();
  },
  computed: {
    visibilityDisabled () {
      return this.booking.booking.visibility === 'public' || this.getRessource.maxPlayerCount === 1;
    },
    publicSessionsActivated () {
      return this.getDomainConfigForDomain?.config.displayPublicSession;
    },
    getRessource () {
      const resource = this.resources.find(resource => resource.id === this.booking.booking.resource.id);
      return resource || {};
    },
    ...mapState('zone', ['activities', 'resources']),
    getCurrentActivity () {
      return this.activities.find(activity => activity.id === this.booking.booking.activity.id) || {};
    },
    ...mapState('config', ['memberAppConfigs']),
    ...mapGetters('config', ['resourceLabel']),
    ...mapGetters('domain', ['getDomainConfigForDomain']),
    activityTitle () {
      if (this.booking.booking.type === 'lesson') {
        return this.$t('account.booking.session');
      } else {
        return this.$t('account.booking.resource');
      }
    },
    generateSanitizedtags () {
      const sanitizedTags = {};
      if (this.getCurrentActivity?.reservationTags) {
        this.getCurrentActivity.reservationTags.forEach(tagGroup => {
          if (this.editedValues.tags) {
            this.editedValues.tags.forEach(tag => {
              if (tagGroup.values.includes(tag)) sanitizedTags[tagGroup.code] = tag;
            });
          }
        });
      }
      return sanitizedTags;
    },
    myBookingDetails () {
      let place = this.booking.zoneName;
      if (this.booking.booking.resource?.metadata?.address) {
        place += '<br>' + this.$t('timeslot.location', {
          location: `${this.booking.booking.resource.metadata.address} ${(this.booking.booking.resource.metadata?.postalCode || this.booking.booking.resource.metadata?.city) ? '-' : '' } ${this.booking.booking.resource.metadata?.postalCode || ''} ${this.booking.booking.resource.metadata?.city || ''}`
        });
      }
      const details = [
        {
          title: this.activityTitle,
          info: this.booking.booking.activity.name
        },
        {
          title: this.$t('account.booking.zone'),
          info: place
        },
        {
          title: this.$t('account.booking.resourceTitle', { resource: this.resourceLabel }),
          info: this.booking.booking.resource.name
        }
      ];
      const typeResa = this.booking.booking.type === 'lesson' ? 'L' : 'T';
      if ((this.getCurrentActivity.allowInvitations === 'O' || this.getCurrentActivity.allowInvitations === typeResa) && this.memberAppConfigs.autoCheckIn) {
        details.push({
          title: 'Invité(s): ',
          info: this.booking.numberOfReservedPlaces - 1
        });
      }
      if (this.nbParticipants && this.booking.booking.type !== 'lesson') {
        details.push({
          title: 'Participant(s): ',
          info: this.nbParticipants
        });
      }
      if (this.booking.placeId > 0) {
        details.push({
          title: '',
          info: this.$t('account.booking.showPlace'),
          is_action: true
        });
      }
      return details;
    },
    hasLiveStream () {
      return this.booking.booking.livestreamUrl;
    },
    livestreamMessage () {
      if (!this.canShowLiveStreamLink(this.booking.booking.startDate)) {
        return this.$t('account.booking.livestreamCountdown', { countdownMinutes: this.$CONSTANTS.CALENDAR.COUNTDOWN_MINUTES_TO_SHOW_LIVESTREAM });
      } else return '';
    }
  },
  watch: {
    booking () {
      this.loadSessionDetails();
    }
  },
  methods: {
    ...mapActions('zone', [
      'loadAllResources'
    ]),
    redirectToLivestream (url) {
      if (this.canShowLiveStreamLink(this.booking.booking.startDate)) {
        window.open(url);
      }
    },
    generateICS () {
      const startDate = this.booking.booking.startDate.split('-');
      const arrHour = startDate[2].split('T')[1].split(':');
      const day = parseInt(startDate[2].split('T')[0]);
      const month = parseInt(startDate[1]);
      const year = parseInt(startDate[0]);
      const event = {
        start: [year, month, day, parseInt(arrHour[0]), parseInt(arrHour[1])],
        title: this.booking.booking.activity?.name
      };
      ics.createEvent(event, (error, value) => {
        if (error) {
          console.log(error);
        } else {
          const link = document.createElement('a');
          link.href = 'data:text/calendar;charset=utf-8,' + escape(value);
          link.download = `${event.title}_${day}-${month}-${year}.ics`;
          link.click();
          // window.open('data:text/calendar;charset=utf-8,' + escape(value));
        }
      });
    },
    loadSessionDetails () {
      //  Pouvoir afficher le nombre de participant d'ici puis clean homeDetails de cet appel
      if (this.booking?.booking.id) {
        this.$services.HTTP.session.getSessionsFromMembersApiById(this.booking?.booking.id).then(res => {
          this.participants = res.data.booking.participants;
          this.nbParticipants = res.data.booking.bookedMembers;
          this.arrGuest = [];
          res.data.booking.status.visits.forEach(visitData => {
            if (visitData.guestDetails) {
              this.arrGuest.push({ username: visitData.guestDetails?.username, email: visitData.guestDetails?.email, visitId: visitData.id, phone: visitData.guestDetails?.phone });
            }
          });
        }).catch(error => {
          this.$information.capture({ error });
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    ...mapActions('user', ['loadUpcomingUserBookings']),
    save () {
      const body = { ...this.editedValues };
      if (this.editedValues.tags) body.tags = this.generateSanitizedtags;
      this.$services.HTTP.booking.patchSessionFromMembersApiById(this.booking.booking.id, body).then(res => {
        this.$information.alert({
          message: this.$t('booking.edit.success'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
        this.loadUpcomingUserBookings();
        this.editModal = false;
      }).catch((err) => {
        displayBookingErrors(err);
      });
    },
    addNewTag (tag) {
      const lastTag = [...tag].pop();
      let groupOfLastTag = null;
      this.getCurrentActivity.reservationTags.forEach(tagGroup => {
        const isItThisGroup = tagGroup.values.find(tag2 => tag2 === lastTag);
        if (isItThisGroup) groupOfLastTag = tagGroup;
      });
      for (let i = 0; i < tag.length; i++) {
        if (groupOfLastTag.values.includes(tag[i]) && tag[i] !== lastTag) tag.splice(i, 1);
      }
      this.editedValues.tags = tag;
    },
    onClickEdit () {
      this.$services.HTTP.session.getSessionsFromMembersApiById(this.booking.booking.id).then(res => {
        this.editedValues.details = res.data.booking?.details || '';
        this.editedValues.tags = res.data.booking?.tags ? Object.values(res.data.booking?.tags) : [];
        this.editedValues.visibility = this.booking.booking.visibility;
        this.editModal = true;
      }).catch(() => {
        this.$information.alert({
          message: this.$t('booking.errorsEdit')
        });
      });
    },
    onClickCancel () {
      this.$emit('on-cancel-booking', {
        cancelId: this.booking.booking.id,
        bookingDateAndTime: this.booking.startDateAndTime,
        bookingType: this.$CONSTANTS.BOOKING_TYPES.BOOKING,
        sanctionText: this.booking.booking.sanctionText,
        cancelDateSanction: this.booking.booking.cancelDateSanction
      });
    },
    onClickShare () {
      this.$emit('on-share-booking', {
        bookingId: this.booking.booking.id
      });
    },
    onSeeMyPlace () {
      this.$emit('on-show-booked-place', this.booking);
    }
  }
};
</script>

<style lang="less">
.gap-default {
  gap: 15px;
}
.color-accent {
  color: var(--ariane-primary-400);
}
.icon-button {
  color: var(--ariane-primary-400);
}
.my-booking{
  .my-booking-info-icon{
    color:var(--ariane-primary-400);
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.2rem;
  }
  .cancel-booking-bottom-button{
    .deci-button{
      margin:auto;
    }
  }
}
</style>
