<template>
  <div class="flex lg:flex-row flex-col-reverse justify-between">
    <div class="flex gap-xs items-center justify-center lg:justify-start">
      <AriButton preIcon="fas fa-chevron-left" @click="prev" text/>
      <span class="text-xl font-bold text-secondary">{{$t('calendar.displayWeek', { month: $t(`calendar.month.${month + 1}`), dayNumber: dayNumber })}}</span>
      <AriButton preIcon="fas fa-chevron-right" @click="next" text/>
    </div>
    <FilterBarCollapsed v-if="isCustomMenuInHeader" />
  </div>
</template>

<script>
import FilterBarCollapsed from './FilterCollapsed.vue';
import { mapGetters } from 'vuex';
export default {
  components: { FilterBarCollapsed },
  props: {
    prev: Function,
    next: Function,
    isPrevDisabled: Boolean,
    month: [Number, String],
    dayNumber: [Number, String]
  },
  computed: {
    ...mapGetters('config', ['isCustomMenuInHeader']),
  }
};
</script>

<style scoped>
</style>
