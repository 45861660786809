<template>
  <div
    class="my-invoices"
  >
    <div
      class="my-profile__section"
    >
      <div
        class="my-profile__title"
      >
        <h2 class="content-subtitle">{{ $t('profile.invoices.title') }}</h2>
      </div>
      <div
         v-if="invoices.length"
         class="my-invoices__invoices-container"
      >
        <div
          v-if="showAll"
          class="my-invoices__invoice-container"
        >
          <MyInvoice
            v-for="(invoice, index) in invoices" :key="index"
            :invoice="invoice"
            @download-invoice="onClickInvoice(invoice)"
          />
        </div>
        <div
          v-else
          class="my-invoices__invoice-container"
        >
          <MyInvoice
            v-for="(invoice, index) in limitedInvoices" :key="index"
            :invoice="invoice"
            @download-invoice="onClickInvoice(invoice)"
          />
        </div>

        <SeeMore
          v-if="invoices.length > defaultInvoiceNumberLimit"
          :showMoreIndication="$t('showMore')"
          :showLessIndication="$t('showLess')"
          @show-more-toggle="onShowMoreToggle"
        />
      </div>
      <el-col
        v-else
      >
        {{ $t('profile.invoices.notFound') }}
      </el-col>
    </div>
  </div>
</template>

<script>
import MyInvoice from './MyInvoice.vue';
import SeeMore from '@/modules/common/generic/SeeMore.vue';
import { mapState, mapActions } from 'vuex';
import { downloadPdfBufferAsPdf } from '@/utils/file';

export default {
  components: {
    MyInvoice,
    SeeMore
  },
  data () {
    return {
      showAll: false,
      defaultInvoiceNumberLimit: 5
    };
  },
  mounted () {
    this.loadUserInvoices().then(() => {
      window.scroll(0, 0);
    });
  },
  computed: {
    ...mapState('user', ['invoices']),
    limitedInvoices () {
      return this.invoices.slice(0, 5);
    }
  },
  methods: {
    ...mapActions('user', ['loadUserInvoices']),
    async onClickInvoice (invoice) {
      try {
        const { data } = await this.$services.HTTP.invoices.openInvoice(invoice.id);
        downloadPdfBufferAsPdf(data, this.invoiceName(invoice));
      } catch (error) {
        this.$information.capture({ error, inform: true });
        this.$information.alert({
          message: this.$t('calendar.errors.GENERAL_ERROR')
        });
      }
    },
    invoiceName (invoice) {
      return `${invoice.id}_${invoice.date}`;
    },
    onShowMoreToggle (show) {
      this.showAll = show;
    }
  }
};
</script>

<style lang="less">
.my-invoices{
  .my-invoices__invoices-container {
    .my-invoices__invoice-container {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }
}
</style>
