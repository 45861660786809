<template>
  <div class="stickyheader">
    <el-row :span="24">
      <HeaderBox
        v-for="index in $CONSTANTS.CALENDAR.DAYS_IN_WEEK"
        :day="index - 1"
        :key="index"
        :date="$datetime.getNDaysLater(calendarStartDate, index - 1)"
      />
    </el-row>
  </div>
</template>

<script>
import HeaderBox from './HeaderBox.vue';

export default {
  components: {
    HeaderBox
  },
  props: {
    calendarStartDate: Date
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.stickyheader{
  margin-bottom: 10px;
  font-weight: 600;
  padding-top:10px;
  z-index: 9;
}
</style>
