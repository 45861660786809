import CONSTANTS from '@/utils/constants';

/**
 * Réduit une liste d'activité à une valeur numérique selon l'attribut isWeb
 * L = +1
 * T = -1
 * O = 0
 */
export const reduceActivitiesIsWeb = function (total, activity) {
  if (activity.isWeb === 'L') {
    return total + 1;
  } else if (activity.isWeb === 'T') {
    return total - 1;
  } else {
    return total;
  }
};

export const getCalendarModeFromIsWebReduce = function (total) {
  let newCalendarMode = '';
  if (total >= 0) {
    newCalendarMode = CONSTANTS.CALENDAR_MODES.SESSION;
  } else {
    newCalendarMode = CONSTANTS.CALENDAR_MODES.AVAILABILITY;
  }
  return newCalendarMode;
};
