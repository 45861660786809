<template>
  <AriModal v-if="visibility" :modalTitle="$t('tunnel.domainAccountVerification.title')" :showClose="canCloseModal">
    <template v-slot:body>
      <div class="p-sm glex flex-col gap-sm">
        <div class="modal-content flex flex-col pb-sm">
          <span>{{ purpose }}</span>
          <span>{{ $t('tunnel.domainAccountVerification.choose') }}</span>
        </div>
        <div class="flex flex-col sm:flex-row gap-md">
          <el-card class="account-confirmation-option" @click="onClickAssociateAccount()">
            <p> {{ $t('tunnel.domainAccountVerification.existingAccount1') }} <b id="existingAccount">{{ $t('tunnel.domainAccountVerification.existingAccount2') }}</b> </p>
          </el-card>
          <el-card class="account-confirmation-option" @click="onClickCreateAccount()">
            <p>{{ $t('tunnel.domainAccountVerification.noAccount1') }}<b>{{ $t('tunnel.domainAccountVerification.noAccount2') }}</b></p>
          </el-card>
        </div>
        <template v-if="showDomainSignInForm">
          <AriForm ref="formSignIn" class="form-auth-signin">
            <template v-slot="form">
              <div class="form-item">
                <span>{{ $t('email') }}</span>
                <ariInput whiteBg v-model="domainCredentialsForm.email" :form="form" :formatter="[required, mail]" />
              </div>
              <div class="form-item">
                <span>{{ $t('authenticate.password') }}</span>
                <ariInput whiteBg v-model="domainCredentialsForm.password" :form="form" :formatter="[required]" password />
                <AriButton id="forgottenPassword" class="item__forgot-password mb-default self-end" text
                           @click="onRecoverAccount" :content="$t('authenticate.forgottenPassword')" />
              </div>
            </template>
          </AriForm>
          <AriButton id="continue-btn" class="w-full mt-default" filled
                :content="$t('continue')" @click="onSignIn" />
        </template>
      </div>
    </template>
  </AriModal>
  <ModalRecoverAccount
    :visibility="showRecoverAccountModal"
    @on-cancel="showRecoverAccountModal=false"
  />
</template>

<script>
import AuthControl from '../mixins/authControl.js';
import { mapActions } from 'vuex';
import { mail, required } from '@/utils/inputFormatters';
import ModalRecoverAccount from './ModalRecoverAccount.vue';

export default {
  name: 'ModalDomainAccountVerification',
  mixins: [AuthControl],
  props: {
    visibility: Boolean,
    purpose: String
  },
  components: {
    ModalRecoverAccount
  },
  data () {
    return {
      showDomainSignInForm: false,
      showRecoverAccountModal: false,
      canCloseModal: false,
      domainCredentialsForm: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t('authenticate.pleaseInputEmail'),
            trigger: 'change'
          },
          {
            required: true,
            message: this.$t('authenticate.pleaseInputEmail'),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('authenticate.pleaseInputPassword'),
            trigger: 'change'
          },
          {
            required: true,
            message: this.$t('authenticate.pleaseInputPassword'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions('user', ['signInClub']),
    required,
    mail,
    onClickCreateAccount () {
      this.$emit('redirectToSignUp');
      this.$router.replace({
        name: 'signUp',
        query: {
          todo: 'linkAccounts'
        }
      });
    },
    onClickAssociateAccount () {
      this.showDomainSignInForm = true;
    },
    async onSignIn () {
      try {
        const isFormValid = await this.$refs.formSignIn.validateForm();
        if (!isFormValid) {
          throw new Error();
        }
        await this.startAccountLinkProcedure();
      } catch (error) {
        this.$information.alert({
          message: this.$t('authenticate.pleaseCheckCredentials'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
        });
        this.$information.capture({ error });
      }
    },
    async startAccountLinkProcedure () {
      await this.$services.HTTP.user.linkClubAccountToGlobalAccount(this.domainCredentialsForm);
      await this.signInClub(this.domainCredentialsForm);
      await this.postAuth();
    },
    onRecoverAccount () {
      this.showRecoverAccountModal = true;
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
  .modal-content{
    word-break:break-word !important;
    margin-bottom: 10px;
  }
  .account-confirmation-option{
    @apply justify-center items-center flex;
    word-break:break-word !important;
    min-height: 100px;
    text-align: center;
    &:hover{
      .focused-card();
    }
  }
</style>
