import PasswordValidator from 'password-validator';

export function containsPartOfEasyInfo (value, easyInfo) {
  return easyInfo.toLowerCase()
    .split(' ')
    .filter(element => element.length > 3)
    .some(element => value.toLowerCase().includes(element));
}

export function checkPasswordStrength (password, ...easyInfos) {
  let failedValidations = [];

  easyInfos.forEach(easyInfo => {
    if (containsPartOfEasyInfo(password, easyInfo)) {
      failedValidations.push('easyToGuessPassword');
    }
  });

  const schema = new PasswordValidator();
  schema
    .is().min(8)
    .has().letters()
    .has().digits();
  failedValidations = failedValidations.concat(schema.validate(password, { list: true }));
  return failedValidations[0] || '';
}
