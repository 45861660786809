<template>
  <a
    :href="storeDeepLink"
    class="mobile-app-badge__link"
    target="_blank"
  >
    <img
      class="mobile-app-badge__img"
      :class="badgeSpecificClass"
      :src="linkImgUrl"
      :alt="linkImgAlt"
    />
  </a>
</template>

<script>
export default {
  props: {
    storeDeepLink: {
      type: String,
      required: true
    },
    linkImgUrl: {
      type: String,
      required: true
    },
    linkImgAlt: {
      type: String,
      required: true
    }
  },
  computed: {
    badgeSpecificClass () {
      let foundClass = null;
      if (this.storeDeepLink.includes('apple')) {
        foundClass = 'mobile-app-badge__img--apple';
      } else if (this.storeDeepLink.includes('appgallery')) {
        foundClass = 'mobile-app-badge__img--huawei';
      } else if (this.storeDeepLink.includes('google')) {
        foundClass = 'mobile-app-badge__img--google';
      }
      return foundClass;
    }
  }
};
</script>

<style lang="less">
.mobile-app-badge__link{
  display: block;
  overflow: hidden;
  border-radius: 13px;
  height: 100%;
  .mobile-app-badge__img{
    border-radius: 13px;
    height: auto;
    max-height: 53px;
  }
  .mobile-app-badge__img--apple, .mobile-app-badge__img--huawei, .mobile-app-badge__img--google{
    margin-bottom: 12px;
    width: 88%;
  }
}
</style>
