<template>
  <div>
    <template v-if="isOnMobile">
      <div @click="onClickTimeSlot">
        <BodyAvailabilityTimeslot  v-if="isAvailabilitySlot(timeslot)" :timeslot="timeslot"/>
        <BodySessionTimeslot v-else :timeslot="timeslot" />
      </div>
    </template>
    <template v-else>
      <div>
        <AriModal v-if="timeslotOpen" @close="timeslotOpen = false" maxWidth="750px">
          <template #body>
            <DetailedTimeSlot @close="timeslotOpen = false" :timeslot="timeslot"/>
          </template>
        </AriModal>
        <div @click="onClickTimeSlot">
          <BodyAvailabilityTimeslot v-if="isAvailabilitySlot(timeslot)" :timeslot="timeslot"/>
          <BodySessionTimeslot v-else :timeslot="timeslot" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BodyAvailabilityTimeslot from './components/BodyAvailabilityTimeslot.vue';
import BodySessionTimeslot from './components/BodySessionTimeslot.vue';
import CalendarControl from '@/modules/calendar/mixins/calendarControl.js';
import { mapActions, mapMutations, mapState } from 'vuex';
import AuthControl from '@/modules/auth/mixins/authControl.js';
import DetailedTimeSlot from './DetailedTimeslot/DetailedTimeSlot.vue';

export default {
  name: 'layout.timeslot',

  components: {
    BodyAvailabilityTimeslot,
    BodySessionTimeslot,
    DetailedTimeSlot
  },

  mixins: [CalendarControl, AuthControl],
  data () {
    return {
      isLoading: false,
      addDescription: false,
      timeslotOpen: false,
      timeoutLoadPreSigninResa: null
    };
  },
  props: {
    timeslot: Object
  },
  mounted () {
    this.timeoutLoadPreSigninResa = setTimeout(() => {
      if (this.saveCalendarStateForLogin && !this.saveCalendarStateForLogin.calendarForm) {
        if (this.saveCalendarStateForLogin.timeslotId === this.timeslot.id) {
          this.timeslotOpen = true;
          this.SAVE_CALENDAR_STATE(null);
        };
      }
    }, 1000);
  },
  beforeUnmount () {
    clearTimeout(this.timeoutLoadPreSigninResa);
  },
  computed: {
    ...mapState('user', ['timeslotForDetailsPage', 'userDomainInfo', 'saveCalendarStateForLogin']),
    isMandatoryModalVisible () {
      return this.userDomainInfo && this.userDomainInfo.missingFields && this.userDomainInfo.missingFields.mandatoryForUsing;
    },
    appElement () {
      return document.querySelector('.calendar');
    },
    ...mapState('user', ['deviceFormat']),
    isOnMobile () {
      return this.deviceFormat === this.$CONSTANTS.DEVICE_FORMATS.MOBILE;
    }
  },

  methods: {
    ...mapActions('user', ['saveTimeslotForDetailsPage']),
    ...mapMutations('user', ['SAVE_CALENDAR_STATE']),
    onClickTimeSlot () {
      this.saveTimeslotForDetailsPage(this.timeslot);
      this.timeslotOpen = true;
      if (this.isOnMobile) {
        this.$router.push({ name: 'timeslotDetails' });
      }
    },
    handleShowPopover () {
      let showPopover = false;
      if (!this.isAvailabilitySlot(this.timeslot)) {
        showPopover = Object.keys(this.timeslotForDetailsPage).length && this.timeslot.id === this.timeslotForDetailsPage.id;
      } else {
        showPopover = Object.keys(this.timeslotForDetailsPage).length &&
          this.timeslot.activityId === this.timeslotForDetailsPage.activityId &&
          this.timeslot.isoDate === this.timeslotForDetailsPage.isoDate;
      }
      return !!showPopover;
    }
  }
};
</script>

<style lang="less">
  .icon-arrow {
    position: absolute;
    font-size: 15px;
    left: 0px;
    top: 7px;
    color: #FFF;
    padding: 2px 6px 2px 6px;
    cursor: pointer;
  }
  .tooltip-arrow {
    border: 5px solid ;
    position: absolute;
    margin: 5px;
    border-color: #FFF;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 20px 20px 0 20px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -20px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 20px 20px 20px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -20px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 20px 20px 20px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -20px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 20px 0 20px 20px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -20px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
@import '@/assets/styles/vars.less';
@import '@/assets/styles/mixins.less';
.paddings-container-session {
  padding: 20px;
}
.small-button {
  height: 40px;
}
.cercled-check {
  width: 6.875rem;
  height: 6.875rem;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 50%;
  display: flex;
  color: #11DF33;
  font-size: 3rem;
  align-items: center;
  justify-content: center;
}
.popoverInnerClass > div {
  height: inherit;
}
.floux-small .el-input--small, .floux-small .el-input-number {
  height: 13px !important;
}
.floux-small .el-input__inner {
  height: 13px;
}
.el-tag--small {
  height: 11px;
  font-size: 10px;
  display: flex;
  align-items: center;
}
.el-tag--small .el-icon-close {
  height: 13px;
  width: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popoverInnerClass {
  height: 100%;
}
.wrapperPopoverSmall {
  width: 350px;
  height: auto;
  background-color: #fff;
}
.wrapperPopover {
  min-width: 265px;
  max-height: 500px;
  background-color: #fff;
  border-radius: 6px;
  z-index: 9;
}
.padding-clock {
  padding-right: 30px;
}
.inner-popover {
  padding: 15px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}
.bg-header {
  background-color: var(--ariane-primary-400);
  padding: 9px 0px;
}
.timeslot{
  border:1px solid;
  height: 125px;
  @apply p-sm rounded flex flex-col text-xs w-full cursor-pointer border-default bg-content relative;
  word-break: break-word;
  &:hover{
    .box-shadow-light();
  }
  &.slot--not-available-yet{
    background: darken(@background-light, 6%);
  }
  .slot__class{
    font-size:14px;
  }
}
</style>
