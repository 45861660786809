<template>
  <VDropdown popoverBaseClass="filterFlouxPopoverInput" popoverWrapperClass="wrapperPopover"
             popoverInnerClass="popoverInnerClass" offset="5" :placement="placement"
             :boundariesElement="elementPageWrapper">
    <div @mouseenter="isHovered = true" @mouseleave="isHovered = false"
       class="flex relative items-center flex-col cursor-pointer"
       :class="{ filled, searchBar: search, isHovered, isFocus }">
      <div class="flex items-center w-full h-full">
        <input class="input ubuntuMedium w-full"
              :class="cssgenerated + ' ' +  (isError ? 'error-input' : '')" :autofocus="autofocus"
              @focus="isFocus = true" @blur="isFocus = false"
              :value="bindedLabel || searchTxt || modelValue"
              :type="number ? 'number' : type ? type : null" :disabled="disabled"
              @input="searchTxt = $event.target.value"
              @[trackingMethod]="handleChange($event.target.value)"
              :placeholder="generatedPlaceholder" ref="myInput"/>
        <i v-if="preIcon || search" :class="preIconGenerated + ' icon'" />
      </div>
      <span class="placement-error-text accent-error-red fs-10" v-if="isError && !isFocus">
        <i class="fas fa-exclamation-circle" />
        {{error}}
      </span>
    </div>
    <template v-slot:popover>
      <div class="popover-autocomplete w-fc flex flex-col" v-show="suggestion && suggestion.length > 0">
        <span v-for="data in suggestion" :key="data[customValueKey]" @click="selectSuggestion(data)"
              class="fs-13 suggestion text-one-line items-center flex">
          {{sanitizeText(useCustomLabelKey(data))}}
        </span>
      </div>
    </template>
  </VDropdown>

</template>

<script>
export default {
  name: 'FlouxInput',
  data () {
    return {
      isHovered: false,
      suggestion: [],
      error: null,
      isFocus: false,
      searchTxt: '', // valeur de la search
      bindedLabel: '' // si en autocomplétion, label après avoir cliqué sur une suggestion
    };
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    isError () {
      return this.error && this.searchTxt.length > 0;
    },
    elementPageWrapper () {
      const docu = document.querySelector('#page-wrapper');
      return docu || document.querySelector('body');
    },
    trackingMethod () {
      return this.input ? 'input' : 'change';
    },
    generatedPlaceholder () {
      let placeholder = '';
      if (this.placeholder) {
        placeholder = this.placeholder;
      } else if (this.search) {
        placeholder = this.$t('app.search');
      }
      return placeholder;
    },
    preIconGenerated () {
      const arrcss = [];
      this.search ? arrcss.push('far fa-search') : arrcss.push(this.preIcon);
      this.filled ? arrcss.push('color-grey-search') : arrcss.push('color-classic');
      return arrcss.join(' ');
    },
    cssgenerated () {
      const arr = [];
      this.preIcon || this.search ? arr.push('indent') : arr.push('mini-indent');
      if (this.disabled) arr.push('disabled');
      return arr.join(' ');
    }
  },
  methods: {
    setManualBindedLabel (bindedLabel) {
      this.bindedLabel = bindedLabel;
    },
    focus () {
      this.$refs.myInput.focus();
    },
    useCustomLabelKey (value) {
      if (typeof this.customLabelKey === 'string') {
        return value[this.customLabelKey];
      } else if (typeof this.customLabelKey === 'function') {
        return this.customLabelKey(value);
      }
    },
    selectSuggestion (suggestion) {
      this.$emit('update:modelValue', suggestion[this.customValueKey]);
      this.suggestion = [];
      this.bindedLabel = this.useCustomLabelKey(suggestion);
      this.searchTxt = '';
    },
    cleanBindedLabel () {
      this.bindedLabel = '';
      this.searchTxt = '';
      this.$emit('update:modelValue', null);
    },
    sanitizeText: function (value) {
      if (!value) return '';
      let token = 0;
      let result = '';
      for (token = 0; token < value.length; token++) {
        if (token === 0) result += value[token].toUpperCase();
        else result += value[token].toLowerCase();
      }
      return result;
    },
    handleChange (value) {
      const lengthBelowMinLimit = !!this.minChar && value.length < this.minChar;
      if (this.autocomplete) {
        if (this.bindedLabel) {
          // Si l'on a déja un id de séléctionné et donc un label lié a cette id, on clean tout
          this.cleanBindedLabel();
        } else {
          this.searchTxt = value;
          this.handleAutocomplete(lengthBelowMinLimit ? null : value);
        }
      } else {
        if (this.email) {
          const REGEXP_EMAIL = /^\S{1,}@\S{2,}\.\S{2,}$/;
          if (!REGEXP_EMAIL.exec(value)) this.error = this.$t('errorInput.email');
          else this.error = null;
        }
        if (!this.error) {
          this.$emit('update:modelValue', lengthBelowMinLimit ? '' : value);
        } else this.$emit('update:modelValue', '');
        this.searchTxt = value;
      }
    },
    async handleAutocomplete (value) {
      if (value) {
        if (Array.isArray(this.autocomplete)) {
          this.suggestion = this.autocomplete.filter(element => this.useCustomLabelKey(element).toLowerCase().search(value.toLowerCase()) !== -1);
        } else if (typeof this.autocomplete === 'function') {
          this.suggestion = await this.autocomplete(value);
        }
      } else {
        this.suggestion = [];
        return null;
      }
    }
  },
  props: {
    type: {
      type: String
    },
    input: Boolean,
    minChar: {
      type: [Number, null],
      default: null
    },
    placement: {
      type: String,
      default: 'auto'
    },
    autocomplete: {
      type: [Function, Array]
    },
    customValueKey: {
      type: String,
      default: 'id'
    },
    customLabelKey: {
      type: [String, Function],
      default: 'name'
    },
    filled: {
      type: Boolean,
      default: false
    },
    email: Boolean,
    number: Boolean,
    search: {
      type: Boolean,
      default: false
    },
    tel: {
      type: Boolean,
      required: false
    },
    disabled: Boolean,
    placeholder: {
      type: String,
      required: false
    },
    modelValue: {
    },
    autofocus: Boolean,
    preIcon: {
      type: String
    }
  }
};
</script>

<style scoped>
  .error-input {
    border-color: var(--accent-error-red) !important;
  }
  .searchBar {
    height: 40px;
    border-radius: 6px;
  }
  /* INPUT GENERIQUE  */
  .indent {
    text-indent: 30px;
  }
  .filled {
    background-color: #F8F8F8;
    border: none;
    color: #E6E6E6;
  }
  .color-grey-search  {
    color: #D6D6D6;
  }
  .disabled {
    color: var(--floux-grey) !important;
  }
  .mini-indent {
    text-indent: 5px;
  }
  .floux-div-selected-data .input {
    border-radius: 6px;
    height: 40px;
    border: none;
    font-size: 14px;
    border-radius: 4px;
    color: #333;
    background-color: transparent;
    border: none;
  }
  .input {
    border-radius: 6px;
    height: 40px;
    border: none;
    font-size: 14px;
    border-radius: 4px;
    color: #333;
    background-color: #FBFBFC;
    border: 1px solid #EDEDEE;
  }
  .placement-error-text {
    position: absolute;
    bottom: 1px;
    right: 3px;
  }
  .suggestion {
    min-height: 35px;
    max-height: 35px;
    flex-wrap: nowrap;
    display: flex;
    pointer-events: all;
    align-items: center;
    padding: 0px 15px 0px 14px;
    color: var(--floux-grey);
  }
  .suggestion:hover {
      cursor: pointer;
      background-color: var(--bg-color-overlay);
  }
  input:focus {
    outline: none;
  }
  .icon {
    padding-left: 5px;
    position: absolute;
    font-weight: bold;
  }
  .isHovered .icon, .isFocus .icon {
    color: #333;
  }
 .input::placeholder{
    color: #D6D6D6 !important;
    font-weight: 400;
    font-style: normal;
  }
  .popover-autocomplete {
    max-height: 250px;
    overflow: auto;
    top: 5px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #CFCFCF;
    transition: all 0.5s;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border: 1px solid #e4e7ed;
  }
</style>
