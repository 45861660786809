<template>
  <ariModal showClose v-if="visible" :modalTitle="$t('products.alert.payments-schedules.title')" @close="$emit('on-cancel')">
    <template v-slot:body>
      <p>{{$t('products.alert.payments-schedules.text')}}</p>
      <el-row :gutter="24" class="button-container">
        <el-col :xs="12" :sm="12" :md="10" :lg="8" :xl="8">
          <AriButton
            :content="$t('close')"
            text
            @click="onCancel"
          />
        </el-col>
      </el-row>
    </template>
    <template #footer>
      <AriButton :content="$t('continue')" outlined @click="onValidate"/>
    </template>
  </ariModal>
</template>

<script>
export default {
  name: 'ModalAlertPaymentSchedules',
  props: {
    visible: Boolean
  },
  methods: {
    onCancel () {
      this.$emit('on-cancel');
    },
    onValidate () {
      this.$emit('on-validate');
    }
  }
};
</script>

<style scoped>
  .button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
  }
</style>
