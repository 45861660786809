<template>
  <div class="calendar__search">
    <el-row class="wrapper-filter">
        <el-col v-if="isMultiSite"  :xs="24" :sm="12" :md="12" :lg="4" :xl="4" >
          <AriDropdown :datas="zoneChoices" :filterable="true" :clearable="false" labelKey="clubName" id="zone-selector"
            v-model="calendarForm.zoneId" @update:modelValue="reloadCalendar()" valueKey="id" :label="$t('calendar.selectZone')"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
          <AriDropdown showAll multiple id="activity-selector" :emptyLabel="$t('calendar.allActivities')" :clearable="false" v-if="activitiesFilter"
            :datas="activitiesFilter" :filterable="true" labelKey="name" v-model="calendarForm.activityIds" :showAllLabel="$t('calendar.allActivities')"
            @update:modelValue="onActivitySelected()" valueKey="id"  :label="$t('calendar.selectActivity')"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
          <AriDropdown showAll cypressId="cy-resource-selector" multiple id="resource-selector" :emptyLabel="$t('calendar.allResources')" :clearable="false" v-if="resourcesFilter" :datas="resourcesFilter"
                         :filterable="true" labelKey="name" :showAllLabel="$t('calendar.allResources')" v-model="calendarForm.resources" @update:modelValue="onResourceSelected()"
                         valueKey="id" :label="$t('calendar.selectResource', { resource: resourceLabel })" />
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="4" v-if="withCoachFilter">
          <AriDropdown showAll multiple id="coach-selector" :emptyLabel="$t('calendar.allCoaches')" :clearable="false"
                         :datas="coachesFilter" :filterable="true" :showAllLabel="$t('calendar.allCoaches')" labelKey="name"
                         v-model="calendarForm.coachIds" @update:modelValue="onCoachSelected()" valueKey="id"  :label="$t('calendar.selectCoach')"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
          <div class="relative">
            <span class="position-label-datepicker" id="start-date-selector">{{$t('calendar.selectADate')}}</span>
            <DatePicker
              :defaultDate="calendarForm.startDate"
              :disableDates="mq.current === 'md' || mq.current === 'sm' ? isOlderThanToday : isOlderThanCurrentWeekStart"
              :selectionType="mq.current === 'md' || mq.current === 'sm' ? 'date' : 'week'"
              :isClearable="false"
              @on-date-change="onDateSelected" />
          </div>

        </el-col>
        <el-col v-if="hasBothCalendarModes" :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
          <div class="relative">
            <span class="position-label-resa" id="calendarMode-selector" :label="$t('calendar.selectTimeslotType')">
              {{$t('calendar.selectTimeslotType')}}
              </span>
              <el-select v-model="calendarForm.calendarMode" @change="onReservationModeToggle()">
                <el-option
                  v-for="index in 2"
                  :label="calendarModes[index-1].label"
                  :value="calendarModes[index-1].id"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
        </el-col>
      <slot name="mobileFilterCloser"></slot>
    </el-row>
  </div>
</template>

<script>
import CalendarControl from '../mixins/calendarControl.js';
import DatePicker from '@/modules/common/generic/DatePicker.vue';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import * as datetime from '@/utils/datetime';
import EventBus from '@/services/eventBus/eventBus';
import { getCalendarModeFromIsWebReduce, reduceActivitiesIsWeb } from '@/utils/calendar';

export default {
  components: {
    DatePicker
  },
  inject: ['mq'],
  mixins: [CalendarControl],
  async mounted () {
    await this.prepareStoreForCalendarConfigurations();
    EventBus.$on('saveCalendarStateForLoggedIn', (timeslotId) => {
      this.SAVE_CALENDAR_STATE({ timeslotId, calendarForm: this.calendarForm });
    });
    if (this.saveCalendarStateForLogin && this.saveCalendarStateForLogin.calendarForm) {
      this.calendarForm = this.saveCalendarStateForLogin.calendarForm;
      this.SAVE_CALENDAR_STATE({ timeslotId: this.saveCalendarStateForLogin.timeslotId });
      this.reloadCalendar();
    }
  },
  beforeUnmount () {
    EventBus.$off();
  },
  data () {
    return {
      calendarForm: {
        activityIds: [],
        resources: [],
        coachIds: [],
        zoneId: '',
        coach: '',
        resource: '',
        startDate: new Date(),
        calendarMode: null
      },
      rules: {
        zoneId: [
          {
            required: true,
            message: this.$t('zone.selectZone'),
            trigger: 'change'
          }
        ],
        activityIds: [
          {
            required: false,
            message: this.$t('zone.selectActivity'),
            trigger: 'change'
          }
        ],
        resources: [
          {
            required: false,
            message: this.$t('zone.selectResource', { resource: this.resourceLabel }),
            trigger: 'change'
          }
        ],
        startDate: [
          {
            required: true,
            message: this.$t('zone.selectStartDate'),
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    selectedDate () {
      this.calendarForm.startDate = this.selectedDate;
    },
    selectedCalendarMode () {
      this.calendarForm.calendarMode = this.selectedCalendarMode;
    },
    selectedActivities () {
      this.calendarForm.activityIds = this.selectedActivities;
    },
    selectedResources () {
      this.calendarForm.resources = this.selectedResources;
    },
    selectedCoaches () {
      this.calendarForm.coachIds = this.selectedCoaches;
    },
    selectedZoneId () {
      this.calendarForm.zoneId = this.selectedZoneId;
    }
  },
  computed: {
    ...mapState('user', ['userDomainInfo', 'timeslotForDetailsPage', 'saveCalendarStateForLogin']),
    ...mapState('domain', ['domain', 'zones']),
    ...mapState('config', ['memberAppConfigs']),
    ...mapState('zone', {
      activitiesInStore: 'activities',
      coachesInStore: 'coaches'
    }),
    ...mapGetters('user', ['loggedInUser']),
    ...mapGetters('config', [
      'selectedDate',
      'selectedCalendarMode',
      'hasBothCalendarModes',
      'showCoaches',
      'forcedZoneByTunnel',
      'forcedActivityByTunnel',
      'forcedResourceByTunnel',
      'zoneFilterByTunnel',
      'coachFilterByTunnel',
      'resourceLabel',
      'selectedActivities',
      'selectedResources',
      'selectedCoaches',
      'selectedZoneId'
    ]),
    ...mapState('config', ['tunnelConfigurations']),
    ...mapGetters('domain', ['isMultiSite', 'getZonesVisibleOnline', 'getActiveZone']),
    ...mapGetters('zone', ['getResources']),
    calendarModes () {
      return [
        {
          id: this.$CONSTANTS.CALENDAR_MODES.SESSION,
          label: this.memberAppConfigs.webConfig_bookingCategoriesConfig?.lessonsTitle || this.$t('calendar.sessionsMode')
        },
        {
          id: this.$CONSTANTS.CALENDAR_MODES.AVAILABILITY,
          label: this.memberAppConfigs.webConfig_bookingCategoriesConfig?.resourcesTitle || this.$t('calendar.availabilityMode')
        }
      ];
    },
    activitiesFilter () {
      return this.activitiesInStore?.length > 1 ? this.activitiesInStore : null;
    },
    resourcesInStore () {
      return this.getResources(this.calendarForm.calendarMode);
    },
    resourcesFilter () {
      return this.resourcesInStore?.length > 1 ? this.resourcesInStore : null;
    },
    coachesFilter () {
      return this.coachesInStore?.length > 0 ? this.coachesInStore : null;
    },
    isOlderThanToday () {
      return datetime.isOlderThanToday;
    },
    isOlderThanCurrentWeekStart () {
      return date => date < datetime.getCurrentWeekStart();
    },
    withCoachFilter () {
      return this.showCoaches && this.calendarForm.calendarMode === this.$CONSTANTS.CALENDAR_MODES.SESSION && this.coachesFilter;
    },
    zoneChoices () {
      let zones = [];
      if (this.forcedZoneByTunnel) {
        zones = this._zonesWithZoneForcing();
      } else {
        zones = this.getZonesVisibleOnline;
      }
      return zones;
    }
  },
  methods: {
    ...mapActions('user', ['saveTimeslotForDetailsPage']),
    ...mapMutations('user', ['SAVE_CALENDAR_STATE']),
    ...mapMutations('config', ['SET_SELECTED_ZONE', 'SET_SELECTED_COACHES', 'SET_SELECTED_CALENDAR_MODE', 'SET_SELECTED_RESOURCES', 'SET_SELECTED_ACTIVITIES']),
    async prepareStoreForCalendarConfigurations () {
      const tunnelCMname = this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.CUSTOM_MENU];
      if (tunnelCMname) return;
      if (this.isComingFromBookingAttempt) {
        this.SET_SELECTED_CALENDAR_MODE(this.timeslotForDetailsPage.type);
        this.calendarForm.startDate = this.$datetime.getWeekStart(this.timeslotForDetailsPage.date);
        this.$router.replace({ query: null });
      }
      if (this.zoneFilterByTunnel) {
        this.SET_SELECTED_ZONE(this.getActiveZone(this.zoneFilterByTunnel));
      }
      await this.loadZoneDetailsFromStore();
      if (this.forcedActivityByTunnel) {
        if (Array.isArray(this.forcedActivityByTunnel)) this.SET_SELECTED_ACTIVITIES(this.forcedActivityByTunnel);
        else this.SET_SELECTED_ACTIVITIES([this.forcedActivityByTunnel]);
      }
      if (this.forcedResourceByTunnel) {
        if (Array.isArray(this.forcedResourceByTunnel)) this.SET_SELECTED_RESOURCES(this.forcedResourceByTunnel);
        else this.SET_SELECTED_RESOURCES([this.forcedResourceByTunnel]);
      }
      if (this.coachFilterByTunnel) {
        this.SET_SELECTED_COACHES(this.coachFilterByTunnel);
      }
      this.prepareCalendar();
    },
    redirectToLanding () {
      this.$router.replace('/' + this.domain);
    },
    async prepareCalendar () {
      this.loadSearchParameters();
      await this.getAllTimeslotsFromStore();
    },
    loadSearchParameters () {
      // updating form with store details or (if empty) with default values
      this.setCalendarFiltersInComponent();
      // syncronizing with store in case store was empty
      this.setCalendarFiltersInStore();
    },
    async reloadCalendar (resetConfig = { activityIds: true }) {
      try {
        this.saveTimeslotForDetailsPage({});
        this.setCalendarFiltersInStore();
        await this.loadZoneDetailsFromStore();
        this.resetMultipleSelections(resetConfig);
        await this.getAllTimeslotsFromStore();
      } catch (error) {
        this.$information.capture({ error });
      }
    },
    resetMultipleSelections (resetConfig = { activityIds: true }) {
      if (resetConfig?.activityIds) {
        this.calendarForm.activityIds = [];
      }
      this.calendarForm.resources = [];
      this.calendarForm.coachIds = [];
    },
    onReservationModeToggle () {
      const newCalendarMode = (this.selectedCalendarMode === this.$CONSTANTS.CALENDAR_MODES.SESSION)
        ? this.$CONSTANTS.CALENDAR_MODES.AVAILABILITY
        : this.$CONSTANTS.CALENDAR_MODES.SESSION;
      this.calendarForm.resources = [];
      this.calendarForm.calendarMode = newCalendarMode;
      this.SET_SELECTED_CALENDAR_MODE(newCalendarMode);
      this.reloadCalendar({ activityIds: false });
    },
    onActivitySelected () {
      if (this.calendarForm.activityIds === null) this.calendarForm.activityIds = [];
      this.setCalendarFiltersInStore();
      this.updateCalendarModeOnActivityChange();
      this.getAllTimeslotsFromStore();
      this.saveTimeslotForDetailsPage({});
    },
    updateCalendarModeOnActivityChange () {
      if (this.calendarForm.activityIds?.length) {
        const activities = this.activitiesInStore;
        const newSelectedActivities = this.calendarForm.activityIds.map(
          activityId => activities.find(activity => activity.id === activityId)
        );
        const result = newSelectedActivities.reduce(reduceActivitiesIsWeb, 0);
        const newCalendarMode = getCalendarModeFromIsWebReduce(result);
        this.SET_SELECTED_CALENDAR_MODE(newCalendarMode);
      }
    },
    onResourceSelected () {
      if (this.calendarForm.resources === null) this.calendarForm.resources = [];
      this.setCalendarFiltersInStore();
      this.getAllTimeslotsFromStore();
      this.saveTimeslotForDetailsPage({});
    },
    onCoachSelected () {
      if (this.calendarForm.coachIds === null) this.calendarForm.coachIds = [];
      this.setCalendarFiltersInStore();
      this.getAllTimeslotsFromStore();
      this.saveTimeslotForDetailsPage({});
    },
    onDateSelected (selection) {
      this.calendarForm.startDate = selection;
      this.setCalendarFiltersInStore();
      this.getAllTimeslotsFromStore();
      this.saveTimeslotForDetailsPage({});
    },
    _zonesWithZoneForcing () {
      const zones = this.getZonesVisibleOnline.filter(zone => zone.id === this.forcedZoneByTunnel);
      if (this.loggedInUser && this.forcedZoneByTunnel !== this.userDomainInfo.zone) {
        const usersZone = this.getActiveZone(this.userDomainInfo.zone);
        zones.push(usersZone);
      }
      return zones;
    }
  }
};
</script>

<style lang="less">
.position-label-resa {
  position: absolute;
  top: -11px;
  left: 12px;
  background-color: #FFF;
  z-index: 2;
  font-size: 14px;
  color: var(--floux-grey);
}
.position-label-datepicker {
  position: absolute;
  top: -11px;
  left: 6px;
  z-index: 2;
  font-size: 14px;
  background-image: linear-gradient(180deg, transparent 50%, var(--ariane-content-background) 50%);
  @apply text-secondary;
}
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.wrapper-filter {
  padding: 20px;
  display:flex;
  justify-content: space-around;
  align-items: center
}
#app{
  .calendar__search{
    .box-shadow();
    background:#fff;
    border-radius: 10px;
    border:1px dotted @border-color;
    padding-left:12px;
    padding-right:12px;
    margin: 10px 0 10px 0;
    .el-form{
      input, .el-tag{
        color:var(--ariane-primary-400) !important;
        font-weight: @heavy-font-weight;
        font-size: @standard-text-size;
      }
      .el-form-item{
        margin-top:5px;
        margin-right:5px;
        .el-form-item__content{
          margin-left:0 !important;
          .el-select{
            width:100%;
          }
          .el-date-editor{
            &.el-range-editor{
              &.el-input__inner{
                margin-top:0;
                .el-range-input{
                  padding-left: 5%;
                  &:nth-of-type(2){
                    margin-left:12px;
                    width: 40%;
                  }
                  font-family: 'Poppins', 'Arial', sans-serif;
                  margin-top: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width:1279px){
  .wrapper-filter {
    flex-direction: column;
    gap: 15px;
  }
  #app{
    .el-container{
      .calendar__search{
        .el-form-item{
          &.el-form-item__search{
            width:100%;
            .el-button--primary{
              width:100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width:992px){
  #app{
    .calendar__search{
      &.calendar__search-mobile{
        border:none !important;
        margin:0 !important;
        padding-left:0 !important;
        padding-right:0 !important;
        .buttons-icon{
          margin-bottom:0px !important;
          .el-button{
            i{
              font-size: 20px;
            }
          }
          &.buttons-icon-calendar{
            .el-button{
              &:hover{
                background:@secondary-color;
              }
              &:focus{
                background:darken(@secondary-color,2%);
              }
            }
          }
        }
        .el-form-item{
          margin-right:0 !important;
        }
      }
    }
  }
}
</style>
