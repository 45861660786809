import CONSTANTS from './constants';
import { alert } from '@/utils/information';
import i18n from '@/services/translation/i18n';
const { t } = i18n.global;

export function displayBookingErrors (errors) {
  const errArr = errors.response?.data?.data?.rules || [];
  if (errArr.length) {
    errArr.forEach(error => {
      const errString = error.code;
      const errMessage = t(`resa.errors.${errString}`);
      setTimeout(() => alert({
        message: errMessage.startsWith('resa.errors.') ? t('resa.errors.generique') : errMessage
      }), 100);
    });
  } else {
    setTimeout(() => alert({
      message: t('resa.errors.generique')
    }), 100);
  }
}
export function getBookingErrorTypes (bookingResponse = CONSTANTS.ERRORS.API_ERROR_MESSAGES.GENERAL_ERROR) {
  const errorMessages = [];
  for (const message in CONSTANTS.ERRORS.API_ERROR_MESSAGES) {
    if (bookingResponse.includes(CONSTANTS.ERRORS.API_ERROR_MESSAGES[message])) {
      errorMessages.push(message);
    }
  }
  return errorMessages;
}
