import HTTP from '@/services/HTTP';
import browserStorage from '@/services/browserStorage';
import CONSTANTS from '@/utils/constants';
import i18n from '@/services/translation/i18n';
import * as datetime from '@/utils/datetime';
import { capture, alert } from '@/utils/information';
import { findDomain } from '@/utils/url';
const { t } = i18n.global;

export default {
  namespaced: true,
  state: {
    language: browserStorage.getLanguage(),
    config: [],
    domainToken: null,
    deciplusToken: null,
    reinitToken: '',
    userDomainInfo: null,
    userBalance: null,
    myProducts: [],
    bookings: [],
    invoices: [],
    scheduledPayments: [],
    unpaidScheduledPayments: [],
    waitingListRegistrations: [],
    productToPurchaseLater: {},
    wallets: [],
    deviceFormat: null,
    bookingDuringPurchase: null,
    timeslotForDetailsPage: {},
    productForDetailsPage: undefined,
    saveCalendarStateForLogin: null
  },
  mutations: {
    SAVE_CALENDAR_STATE (state, objState = null) {
      state.saveCalendarStateForLogin = objState;
    },
    SET_DOMAIN_TOKEN (state, token) {
      if (token === "invalidToken") return;
      state.domainToken = token;
      browserStorage.setDomainToken(token);
    },
    SET_DECIPLUS_TOKEN (state, token) {
      state.deciplusToken = token;
      browserStorage.setDeciplusToken(token);
    },
    CHANGE_LANGUAGE (state, language) {
      state.language = language;
    },
    SET_USER_DOMAIN_INFO (state, userInfo) {
      state.userDomainInfo = userInfo;
    },
    SET_USER_BALANCE (state, userBalance) {
      state.userBalance = userBalance;
    },
    CLEAR_USER_INFO (state) {
      state.domainToken = null;
      state.deciplusToken = null;
      state.userDomainInfo = null;
      state.bookings = [];
      state.myProducts = [];
      state.userBalance = undefined;
      state.productForDetailsPage = undefined;
      browserStorage.setDomainToken(null);
      browserStorage.setDeciplusToken(null);
    },
    SET_USER_PRODUCTS (state, products) {
      state.myProducts = products;
    },
    SET_BOOKINGS (state, bookings) {
      state.bookings = bookings;
    },
    SET_WAITING_LIST_REGISTRATIONS (state, waitingList) {
      state.waitingListRegistrations = waitingList;
    },
    SET_PRODUCT_TO_PURCHASE (state, product) {
      state.productToPurchaseLater = product;
    },
    SET_INVOICES (state, invoices) {
      state.invoices = invoices;
    },
    SET_SCHEDULED_PAYMENTS (state, scheduledPayments) {
      state.scheduledPayments = scheduledPayments;
    },
    SET_UNPAID_SCHEDULED_PAYMENTS (state, scheduledPayments) {
      state.unpaidScheduledPayments = scheduledPayments;
    },
    SET_DEVICE_FORMAT (state, format) {
      state.deviceFormat = format;
    },
    SET_WALLETS (state, wallets) {
      state.wallets = wallets;
    },
    STORE_REINIT_TOKEN (state, token) {
      state.reinitToken = token;
    },
    SET_BOOKING_DURING_PURCHASE (state, booking) {
      state.bookingDuringPurchase = booking;
    },
    SET_TIMESLOT_FOR_DETAILS_PAGE (state, timeslot) {
      state.timeslotForDetailsPage = timeslot;
    },
    SET_PRODUCT_FOR_DETAILS_PAGE (state, productId) {
      state.productForDetailsPage = productId;
    }
  },
  actions: {
    changeLanguage ({ commit }, language = 'en') {
      i18n.locale = language;
      commit('CHANGE_LANGUAGE', language);
      browserStorage.setLanguage(language);
    },
    async signUpForDomain ({ commit }, signUpData) {
      const { data } = await HTTP.authenticate.signUpForDomain(signUpData);
      commit('SET_DOMAIN_TOKEN', data.token);
    },
    async signInGlobal ({ dispatch, rootGetters }, userCredentials) {
      const domain = rootGetters['domain/domain'];
      const { data } = await HTTP.authenticate.signInGlobal(userCredentials);
      // eslint-disable-next-line no-prototype-builtins
      const alreadyMemberAtDomain = data.tokens.clubs.hasOwnProperty(domain);
      dispatch('_setTokensFromGlobalTokensObject', data.tokens);
      return alreadyMemberAtDomain;
    },
    async signInClub ({ commit }, { email, password }) {
      const { data } = await HTTP.authenticate.signInClub({ email, password });
      commit('SET_DOMAIN_TOKEN', data.token);
      return data;
    },
    async signInWithFacebook ({ dispatch }, accessToken) {
      const { data } = await HTTP.authenticate.signInWithFacebook(accessToken);
      dispatch('_setTokensFromGlobalTokensObject', data.userTokens);
    },
    async signInWithGoogle ({ dispatch }, accessToken) {
      const { data } = await HTTP.authenticate.signInWithGoogle(accessToken);
      dispatch('_setTokensFromGlobalTokensObject', data.userTokens);
    },
    async _setTokensFromGlobalTokensObject ({ commit, rootGetters }, tokens) {
      const domain = rootGetters['domain/domain'];
      // eslint-disable-next-line no-prototype-builtins
      if (tokens.clubs && tokens.clubs.hasOwnProperty(domain)) {
        commit('SET_DOMAIN_TOKEN', tokens.clubs[domain][0].token);
      }
      if (tokens.deciplus) {
        commit('SET_DECIPLUS_TOKEN', tokens.deciplus);
      }
    },
    async confirmResetPassword ({ commit }, { pin, email, newPassword }) {
      const apiResponse = await HTTP.authenticate.confirmResetPassword({ pin, email, newPassword });
      return apiResponse.data.status;
    },
    async logout ({ commit }) {
      commit('CLEAR_USER_INFO');
      await HTTP.authenticate.logout();
    },
    async loadDomainUserInfo ({ commit, dispatch }) {
      const domainToken = browserStorage.getDomainToken();
      commit('SET_DOMAIN_TOKEN', domainToken);
      const deciplusToken = browserStorage.getDeciplusToken();
      if (deciplusToken) {
        commit('SET_DECIPLUS_TOKEN', deciplusToken);
      }
      // Carefull, need to set domain before fetching data...
      dispatch('domain/setDomain', findDomain(), { root: true });
      const userData = await HTTP.user.getDomainUserInfo();
      commit('SET_USER_DOMAIN_INFO', userData.data);
      dispatch('loadUserBalance');
      dispatch('domain/getDomainConfig', {}, { root: true });
    },
    async buyProduct ({ commit }, productId) {
      const paymentURL = await HTTP.user.buyProduct({ productId });
      window.location.replace(paymentURL);
    },
    async loadUserProducts ({ commit }) {
      const { data } = await HTTP.user.loadUserProducts();
      commit('SET_USER_PRODUCTS', data);
    },
    async loadUserWallet ({ commit }) {
      try {
        const { data } = await HTTP.user.getActiveWallets();
        commit('SET_WALLETS', data);
      } catch (error) {
        if (error.message === CONSTANTS.ERRORS.API_ERROR_MESSAGES.ERROR_404) {
          return false;
        } else {
          capture({
            error,
            inform: true,
            component: 'user store',
            method: 'loadUserWallet',
            tags: { possibleReason: 'something other than the expected 404' }
          });
        }
      }
    },
    async loadUserBalance ({ commit }) {
      const userBalance = await HTTP.user.getBalance();
      commit('SET_USER_BALANCE', userBalance.data);
    },
    async loadUpcomingUserBookings ({ commit }) {
      const { data } = await HTTP.booking.loadUpcomingUserBookings();
      commit('SET_BOOKINGS', data.bookings || []);
      commit('SET_WAITING_LIST_REGISTRATIONS', data.waitingBookings || []);
    },
    async bookSession ({ dispatch }, { bookingId, placeId, invitedMembers, fullError = false, uniqueId }) {
      dispatch('domain/setAppState', { newState: CONSTANTS.APP_STATE.LOADING, loadingMessage: t('calendar.timeslot.loadingBookAction') }, { root: true });
      let bookingResponse = '';
      try {
        bookingResponse = await HTTP.booking.bookSession({ bookingId, placeId, invitedMembers, fullError, uniqueId });
      } catch (err) {
        bookingResponse = err.message;
      }
      dispatch('domain/setAppState', { newState: CONSTANTS.APP_STATE.LOADED }, { root: true });
      return bookingResponse;
    },
    async bookResource ({ dispatch }, { schedule, invitedMembers }) {
      dispatch('domain/setAppState', { newState: CONSTANTS.APP_STATE.LOADING, loadingMessage: t('calendar.timeslot.loadingBookAction') }, { root: true });
      let bookingResponse = '';
      try {
        bookingResponse = await HTTP.user.bookResource(schedule, invitedMembers);
      } catch (err) {
        bookingResponse = err.message;
      }
      dispatch('domain/setAppState', { newState: CONSTANTS.APP_STATE.LOADED }, { root: true });
      return bookingResponse;
    },
    async registerToWaitingList ({ dispatch }, bookingId) {
      dispatch('domain/setAppState', { newState: CONSTANTS.APP_STATE.LOADING, loadingMessage: t('calendar.timeslot.loadingWaitingListAction') }, { root: true });
      let order = 0;
      let response = '';
      try {
        const { waitingListOrder, registrationResponse } = await HTTP.booking.registerToWaitingList(bookingId);
        order = waitingListOrder;
        response = registrationResponse;
      } catch (err) {
        response = err.message;
        const messages = CONSTANTS.ERRORS.API_ERROR_MESSAGES;
        const errID = Object.keys(messages).find(key => messages[key] === response) || 'generique';
        alert({ message: t(`resa.errors.${errID}`) });
      }
      dispatch('domain/setAppState', { newState: CONSTANTS.APP_STATE.LOADED }, { root: true });
      return {
        waitingListOrder: order,
        registrationResponse: response
      };
    },
    saveBookingDuringPurchase ({ commit }, bookingId) {
      commit('SET_BOOKING_DURING_PURCHASE', bookingId);
    },
    saveProductForLater ({ commit }, product) {
      commit('SET_PRODUCT_TO_PURCHASE', product);
    },
    async loadUserInvoices ({ commit }) {
      try {
        const { data } = await HTTP.invoices.loadUserInvoices();
        commit('SET_INVOICES', data || []);
      } catch (err) {
        return err;
      }
    },
    async loadUserScheduledPayments ({ commit }) {
      try {
        const { data } = await HTTP.scheduledPayments.loadUserScheduledPayments();
        commit('SET_SCHEDULED_PAYMENTS', data || []);
      } catch (err) {
        return err;
      }
    },
    async loadUnpaidScheduledPayments ({ commit }) {
      const { data } = await HTTP.scheduledPayments.loadUserUnpaidSchedules();
      commit('SET_UNPAID_SCHEDULED_PAYMENTS', data || []);
    },
    setDeviceFormat ({ commit }, format) {
      commit('SET_DEVICE_FORMAT', format);
    },
    saveTimeslotForDetailsPage ({ commit }, timeslot) {
      commit('SET_TIMESLOT_FOR_DETAILS_PAGE', timeslot);
    },
    saveProductForDetailsPage ({ commit }, productId) {
      commit('SET_PRODUCT_FOR_DETAILS_PAGE', productId);
    }
  },
  getters: {
    language (state) {
      return state.language;
    },
    loggedInUser (state) {
      return (state.userDomainInfo || state.domainToken) || false;
    },
    userEmail (state) {
      return state.userDomainInfo?.email || 'EMAIL_MISSING';
    },
    usersZoneDetails (state, getters, rootState) {
      if (getters.loggedInUser) {
        return rootState.domain.zones.find(zone => zone.id === state.userDomainInfo.zone);
      } else
      return rootState.domain.zones[0];
    },
    upcomingScheduledPayments (state) {
      return state.scheduledPayments?.filter(payment => {
        return datetime.isTodayOrLater(datetime.fromISODate(payment?.date));
      }) || [];
    },
    getConfigByName: (state) => (name) => {
      return state.config.find(config => config.name === name)?.value;
    }
  }
};
