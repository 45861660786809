<template>
  <div v-if="userDomainInfo" class="my-personal-details">
    <div class="personal-details-advanced__detail">
      <div class="personal-details-advanced__names">
        <MyPersonalDetail :label="$t('surname')" :info="userDomainInfo.surname" />
        <MyPersonalDetail :label="$t('name')" :info="userDomainInfo.name" />
      </div>
    </div>
    <div class="personal-details-advanced__detail" >
      <MyPersonalDetail :label="$t('cellphoneNumber')" :info="formatPhoneNumber(userDomainInfo.cellphoneNumber)"/>
      <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyPhoneNumber" />
    </div>
    <div class="personal-details-advanced__detail">
      <MyPersonalDetail :label="$t('birthdate.title')" :info="$datetime.getLocalFullDate($datetime.fromISODate(userDomainInfo.birthdate))" />
      <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyBirthdate" />
    </div>
    <div class="personal-details-advanced__detail">
      <MyPersonalDetail :label="$t('adr1')" :info="userDomainInfo.adr1" />
      <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyAdr1"/>
    </div>
    <div class="personal-details-advanced__detail">
      <MyPersonalDetail :label="$t('adr2')" :info="userDomainInfo.adr2"/>
      <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyAdr2" />
    </div>
    <div class="personal-details-advanced__detail">
      <MyPersonalDetail :label="$t('adr3')" :info="userDomainInfo.adr3"/>
      <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyAdr3" />
    </div>
    <div class="personal-details-advanced__detail">
      <MyPersonalDetail :label="$t('postalCode')" :info="userDomainInfo.postalCode" />
      <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyPostalCode" />
    </div>
    <div class="personal-details-advanced__detail">
      <MyPersonalDetail :label="$t('city')" :info="userDomainInfo.city" />
      <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyCity"/>
    </div>
    <div class="personal-details-advanced__detail" >
      <MyPersonalDetail :label="memberAppConfigs.LDC_nommageEntreprise ? memberAppConfigs.LDC_nommageEntreprise : $t('company')" :info="userDomainInfo.company"/>
      <AriButton filled neutralGrey :content="$t('modify')" @click="onModifyCompany" />
    </div>
    <AriModal v-if="isEditFormShown" :modalTitle="editForm.title" @close="isEditFormShown = false" class="personal-details-advanced__edit-form">
      <template #body>
        <AriForm ref="editForm" class="flex items-baseline gap-xs py-default">
          <template v-slot="form">
            <span>{{ editForm.label }}</span>
            <AriInput :form="form" v-model="editForm.currentValue" :formatter="editForm.formatter"/>
          </template>
        </AriForm>
      </template>
      <template #footer>
        <div class="footer-modal-end">
          <AriButton text class="pr-xs" :content=" $t('cancel')" @click="() => isEditFormShown = false"/>
          <AriButton filled :content=" $t('submit')" @click="editForm.action"/>
        </div>
      </template>
    </AriModal>
    <AriModal v-if="isBirthdateFormShown" :modalTitle="$t('birthdate.pleaseEnterDay')" @close="isBirthdateFormShown = false" class="personal-details-advanced__edit-form">
       <template #body>
        <el-form ref="birthdateForm">
          <BirthdayInput ref="birthdateInput" :prefill="$datetime.fromISODate(userDomainInfo.birthdate)" @on-date-change="trackBirthdate"/>
        </el-form>
      </template>
      <template #footer>
        <div class="footer-modal-end">
          <AriButton text class="pr-xs" :content=" $t('cancel')" @click="() => isBirthdateFormShown = false"/>
          <AriButton filled :content=" $t('submit')" @click="modifyBirthdate"/>
        </div>
      </template>
    </AriModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MyPersonalDetail from '@/modules/myProfile/components/MyPersonalDetail.vue';
import BirthdayInput from '@/modules/common/generic/BirthdayInput.vue';
import TextInput from '@/modules/common/generic/TextInput.vue';
import CommonControl from '@/modules/common/mixins/commonControl';
import EditDetailControl from '@/modules/myProfile/mixins/editDetailControl.js';

export default {
  name: 'MyPersonalDetails',

  components: {
    BirthdayInput,
    MyPersonalDetail,
    TextInput
  },

  mixins: [CommonControl, EditDetailControl],

  computed: {
    ...mapState('user', ['userDomainInfo']),
    ...mapState('config', ['memberAppConfigs']),
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';

.my-personal-details{
  .personal-detail {
    margin-bottom: 15px;
    display: inline-block;
  }
}
.footer-modal-end {
  @apply flex justify-end gap-sm;
}
</style>
