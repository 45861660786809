<template>
  <el-row class="page-content video-gallery-page">
    <el-col :span="24" v-if="usersZoneDetails.isVisibleOnline">
      <div class="grid-content video-gallery-title">
        <el-row>
          <DeciTitle
            :subTitle="$t('videoGallery.subTitle')"
            :isLined="false"
            :text="$t('videoGallery.title')"
          />
        </el-row>
      </div>
      <div class="video-gallery__body">
        <div v-if="showNoVideosFound">
          <el-col>
            {{ $t('videoGallery.noVideosFound') }}
          </el-col>
        </div>
        <el-row v-if="areRecentVideosShown" class="pt-xxs video-gallery__recent-videos video-gallery__section">
          <StaticVideoList
            :title="$t('videoGallery.recent')"
            :videos="recentVideos"
          />
        </el-row>
        <el-row v-if="areVideosByCoachShown" class="pt-xxs video-gallery__coach-groupings video-gallery__section">
          <div>
            <VideoCoachGrouping :groupings="videoGroupingsByCoach"/>
          </div>
        </el-row>
        <el-row v-if="areVideosByActivityShown" class="pt-xxs video-gallery__activity-groupings video-gallery__section">
          <VideoActivityGrouping :groupings="videoGroupingsByActivity"/>
        </el-row>
      </div>
    </el-col>
    <CenterNotVisibleOnline />
  </el-row>
</template>

<script>
import VideoActivityGrouping from './components/VideoActivityGrouping.vue';
import VideoCoachGrouping from './components/VideoCoachGrouping.vue';
import StaticVideoList from './components/StaticVideoList.vue';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';
import CenterNotVisibleOnline from '@/core/components/centerNotVisibleOnline.vue';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'VideoGallery',

  components: {
    VideoActivityGrouping,
    VideoCoachGrouping,
    StaticVideoList,
    DeciTitle,
    CenterNotVisibleOnline
  },

  mounted () {
    this.videoGalleryOpened({
      loadVideoGroupingsByActivity: this.loadVideoGroupingsByActivity,
      loadVideoGroupingsByCoach: this.loadVideoGroupingsByCoach,
      loadRecentVideos: this.loadRecentVideos,
      showVideoGroupingsByActivity: this.showVideoGroupingsByActivity,
      showVideoGroupingsByCoach: this.showVideoGroupingsByCoach,
      showRecentVideos: this.showRecentVideos,
      noVideosFound: this.noVideosFound,
      showNoVideosFoundSection: this.showNoVideosFoundSection,
      handleVideoGalleryOpeningError: this.handleVideoGalleryOpeningError
    });
    window.scroll(0, 0);
  },

  data () {
    return {
      videoGroupingsByActivity: [],
      areVideosByActivityShown: false,
      areVideosByCoachShown: false,
      areRecentVideosShown: false,
      showNoVideosFound: false,
      showAlertZone: false
    };
  },

  computed: {
    ...mapState('zone', ['videos']),
    ...mapGetters('user', ['usersZoneDetails']),
    recentVideos () {
      return this.videos.slice(0, 5);
    }
  },

  methods: {
    ...mapActions('zone', ['loadVideos']),
    videoGalleryOpened ({
      loadVideoGroupingsByActivity, loadVideoGroupingsByCoach, loadRecentVideos, showVideoGroupingsByActivity, showVideoGroupingsByCoach, showRecentVideos,
      noVideosFound, showNoVideosFoundSection, handleVideoGalleryOpeningError
    }) {
      Promise.all([
        loadVideoGroupingsByActivity(),
        loadVideoGroupingsByCoach(),
        loadRecentVideos()
      ]).then(() => {
        if (noVideosFound()) {
          showNoVideosFoundSection();
        } else {
          showRecentVideos();
          showVideoGroupingsByCoach();
          showVideoGroupingsByActivity();
        }
      }).catch(error => handleVideoGalleryOpeningError(error));
    },
    async showVideoGroupingsByActivity () {
      if (this.videoGroupingsByActivity.length) {
        this.areVideosByActivityShown = true;
      }
    },
    async loadVideoGroupingsByActivity () {
      const { data } = await this.$services.HTTP.videos.getVideoCategories({ by: this.$CONSTANTS.VIDEO_GROUPINGS.ACTIVITY });
      this.videoGroupingsByActivity = data;
    },
    async showVideoGroupingsByCoach () {
      if (this.videoGroupingsByCoach.length) {
        this.areVideosByCoachShown = true;
      }
    },
    async loadVideoGroupingsByCoach () {
      const { data } = await this.$services.HTTP.videos.getVideoCategories({ by: this.$CONSTANTS.VIDEO_GROUPINGS.COACH });
      this.videoGroupingsByCoach = data;
    },
    async showRecentVideos () {
      if (this.recentVideos.length) {
        this.areRecentVideosShown = true;
      }
    },
    async loadRecentVideos () {
      await this.loadVideos();
    },
    noVideosFound () {
      return !this.recentVideos.length;
    },
    showNoVideosFoundSection () {
      this.showNoVideosFound = true;
    },
    handleVideoGalleryOpeningError (error) {
      this.$information.capture({ error });
      this.$information.alert({
        message: this.$t('calendar.errors.GENERAL_ERROR')
      });
    }
  }
};
</script>

<style lang="less">
@import '../../assets/styles/vars.less';

.video-gallery-page{
  .video-gallery__section{
    min-height: @one-thirds-area;
    padding-bottom: 1rem;
    .section_title{
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.5rem;
    }
  }
}
@media (min-width:500px){
  .video-gallery-page{
    .video-gallery__section{
      height: auto;
    }
  }
}
@media (min-width:992px){
  .video-gallery-page{
    .video-gallery__body{
      .video-gallery__activity-groupings{
        width: 100%;
      }
      .video-gallery__coach-groupings{
        width: 100%;
      }
    }
  }
}
</style>
