<template>
  <div class="video-player-blocked">
    <VideoThumbnail :video="video" :showTitle="false" :showCoach="false" :showDuration="false" :showPrice="false" />
    <div class="video-player-blocked__overlay">
      <div v-if="showDirectVideoPurchase" class="video-player-blocked__call-to-action">
        <div class="call-to-action__explanation">
          <div class="call-to-action__explanation-title">
          {{ $t('videoDetails.directPurchaseTitle') }}
          </div>
          <div v-if="canBuy" class="call-to-action__explanation-body">
            {{ videoDescription }}
          </div>
        </div>
        <div class="call-to-action__button">
          <AriButton v-if="canBuy" :content="buyVideoLabel" outlined @click="addProductToCart()"/>
          <div v-else class="video-explanation">{{ $t('products.domainHasNoWebPayment') }}</div>
        </div>
      </div>
      <div v-else>
        <i class="fas fa-lock"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VideoThumbnail from './VideoThumbnail.vue';
import ProductsControl from '@/modules/products/mixins/productsControl.js';

export default {
  components: {
    VideoThumbnail
  },

  mixins: [ProductsControl],

  props: {
    video: Object,
    showDirectVideoPurchase: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      descriptionLimit: 250
    };
  },

  computed: {
    ...mapGetters('domain', ['getZonesWithWebPayment']),
    ...mapGetters('user', ['usersZoneDetails']),
    videoDescription () {
      if (this.video.description && this.video.description.length > this.descriptionLimit) {
        return this.video.description.substring(0, this.descriptionLimit) + '...';
      }
      return this.video.description;
    },
    buyVideoLabel () {
      const videoPrice = this.preparePricePresentation(this.video.price);
      return `${this.$t('cart.buy')} : ${videoPrice.price} ${videoPrice.currency}`;
    },
    canBuy () {
      return this.getZonesWithWebPayment.some(z => this.usersZoneDetails && z.id === this.usersZoneDetails.id);
    }
  },

  methods: {
    ...mapActions('cart', ['addProduct']),
    addProductToCart () {
      const added = this.addProduct({ id: this.video.productId });
      if (added) {
        this.$information.alert({
          message: this.$t('cart.service.added'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
      }
    }
  }
};
</script>

<style lang="less">
@import "../../../assets/styles/vars.less";

.video-player-blocked{
  position: relative;
  .video-player-blocked__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 2;

    .video-explanation {
      @apply text-lg bg-attention rounded px-default py-sm;
      max-width: 500px;
    }

    .fa-lock{
      position: absolute;
      font-size: 4rem;
      left: calc(50% - 2rem);
      top: calc(50% - 2rem);
      color: white;
    }

    .video-player-blocked__call-to-action{
      background-color: rgba(0, 0, 0, 0.75);
      height: 80%;
      width: 80%;
      margin: auto;
      margin-top: 5%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      color: white;
      .call-to-action__explanation{
        width: 80%;
        .call-to-action__explanation-title{
          font-size: @big-text-size;
          font-weight: @heavy-font-weight;
          padding-bottom: 0.5rem;
        }
        .call-to-action__explanation-body{
          display: none;
        }
      }
    }
  }
}

@media (min-width:500px){
  .video-player-blocked{
    .video-player-blocked__overlay{
      .video-player-blocked__call-to-action{
        .call-to-action__explanation{
          display: block;
          .call-to-action__explanation-title{
            font-size: @bigger-text-size;
          }
          .call-to-action__explanation-body{
              display: block;
          }
        }
      }
    }
  }
}
@media (min-width:1280px){
  .video-player-blocked{
    .video-player-blocked__overlay{
      .video-player-blocked__call-to-action{
        .call-to-action__explanation{
          .call-to-action__explanation-title{
            font-size: @biggest-text-size;
          }
          .call-to-action__explanation-body{
            display: block;
          }
        }
      }
    }
  }
}
</style>
