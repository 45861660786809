<template>
  <div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
  mounted () {
    this.saveTunnelConfigurations(this.$router.currentRoute.value.query);
    const nextPage = this.decideNextPage();
    this.$router.replace({
      name: nextPage
    });
  },
  computed: {
    ...mapState('config', ['tunnelConfigurations']),
    ...mapGetters('user', ['loggedInUser']),
    ...mapGetters('domain', ['getActiveZone'])
  },
  methods: {
    ...mapMutations('config', ['SET_TUNNEL_CONFIGURATIONS']),
    saveTunnelConfigurations (configurations) {
      this.SET_TUNNEL_CONFIGURATIONS(configurations);
    },
    decideNextPage () {
      const defaultNextPage = 'landing';
      const tunnelWithCustomMenu = this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.CUSTOM_MENU];

      if (tunnelWithCustomMenu) return 'calendar';
      if (this.loggedInUser) {
        return this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.CONNECTED_WELCOME_PAGE] || defaultNextPage;
      } else {
        if (this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.SHOP_BY_ZONE]) {
          const zoneIdShop = parseInt(this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.SHOP_BY_ZONE]);
          const isZoneExist = this.getActiveZone(zoneIdShop); 
          if (isZoneExist) return 'shop';
        }
        return this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.UNCONNECTED_WELCOME_PAGE] || defaultNextPage;
      }
    }
  }
};
</script>
