<template>
  <el-card>
    <template v-slot:header>
      <div class="card__header">
        <p>
          <span>{{ product.name }}</span>
          <span
            v-if="product.type==='prepaidCard'"
            v-text="totalCredits">
          </span>
        </p>
      </div>
    </template>

    <div
      v-if="canShowValidityDuration"
    >
      <span>{{ $t('products.summaryPartOne') }} </span>
      <span class="strong"> {{ validityDuration(product) }} </span>
    </div>
    <div
      v-if="canShowValidityZone"
    >
      <span>{{ $t('products.summaryPartThree') }} </span>
      <span class="strong">{{ validityZone(product) }}</span>
    </div>
    <div class="card-actions">
      <span class="price">{{ productPrice }} </span>
      <del class="price disabled">{{ productOriginalPrice }}</del>
    </div>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductsControl from '../mixins/productsControl.js';

export default {
  name: 'ProductCard',

  mixins: [ProductsControl],

  props: {
    product: Object
  },

  computed: {
    ...mapGetters('domain', ['isMultiSite']),
    totalCredits () {
      if (this.product.remainingCredit) {
        return ` (${this.product.remainingCredit} ${this.$tc('products.credit', this.product.remainingCredit)})`;
      } else {
        return '';
      }
    },
    isNotVideoOrGiftCard () {
      return this.product.type !== this.$CONSTANTS.PRODUCT_TYPES.VIDEO && this.product.type !== this.$CONSTANTS.PRODUCT_TYPES.GIFT_CARD;
    },
    canShowValidityZone () {
      return this.isMultiSite && this.product.type !== this.$CONSTANTS.PRODUCT_TYPES.VIDEO;
    },
    canShowValidityDuration () {
      return this.isNotVideoOrGiftCard;
    },
    productPrice () {
      const p = this.productPricing(this.product);
      return p ? p.price + ' ' + p.currency : '-';
    },
    productOriginalPrice () {
      const p = this.productOriginalPricing(this.product);
      return p ? p.price + ' ' + p.currency : '-';
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';

.el-card{
  cursor: pointer;
  .full-rounded();
  .box-shadow-light();
  &:hover{
    .box-shadow();
  }
  .card__header{
    color:var(--ariane-primary-400);
    filter: brightness(90%);
    text-align: left;
    font-weight: @heavy-font-weight;
    font-size: @big-text-size;
    p{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .el-card__body{
    span{
      color: @main-color;
    }
    .strong{
      font-weight: @heavy-font-weight;
    }
    .card-actions{
      text-align: right;
      margin-top: 25px;
      .price{
        font-weight: bold;
        color: var(--ariane-primary-400);
        filter: brightness(90%);
        font-size: @bigger-text-size;
        &.disabled{
          color: @main-color-light;
        }
      }
    }
  }
}

</style>
