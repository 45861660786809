import { publicAPI, memberAPI } from '../request';
import { findDomain, adaptUrl } from '@/utils/url';

export default {
  getZoneInfoFromPublicApi: (zoneId) => {
    const url = adaptUrl(':domain/zones/:zoneId', {
      params: {
        domain: findDomain(),
        zoneId
      }
    });
    return publicAPI.get(url);
  },
  getZoneInfoFromMembersApi: (zoneId) => {
    const url = adaptUrl('clubs/:zoneId', {
      params: {
        zoneId
      }
    });
    return memberAPI.get(url);
  },
  getSessionsFromPublicApiForMobile: ({ activityIds, resources, coachIds, zoneId, from, to } = {}) => {
    const url = adaptUrl(':domain/v2/lessons/paginated', {
      params: {
        domain: findDomain()
      },
      queries: {
        zoneId,
        from,
        to,
        activityIds,
        resources,
        coachIds
      }
    });
    return publicAPI.get(url);
  },
  //  C'est très spécifique, je sais pas om le ranger
  getSessionsFromMembersApiForMobile: ({ activityIds, resources, coachIds, zoneId, from, to } = {}) => {
    const url = adaptUrl('v2/lessons/paginated', {
      queries: {
        zoneId,
        from,
        to,
        activityIds,
        resources,
        coachIds
      }
    });
    return memberAPI.get(url);
  },
  getSessionInfoFromPublicApi: (bookingId) => {
    const url = adaptUrl(':domain/classes/:bookingId', {
      params: {
        domain: findDomain(),
        bookingId
      }
    });
    return publicAPI.get(url);
  },
  getSessionInfoFromMembersApi: (bookingId) => {
    const url = adaptUrl('classes/:bookingId', {
      params: {
        bookingId
      }
    });
    return memberAPI.get(url);
  },
  getNextSessionFromMemberAPi: (from, zoneId, activityIds, resourceIds, coachIds) => {
    const url = adaptUrl('sessions/next-available', {
      queries: {
        zoneId,
        activityIds,
        resourceIds,
        coachIds,
        from
      }
    });
    return memberAPI.get(url);
  },
  getNextSessionFromPublicAPi: (from, zoneId, activityIds, resourceIds, coachIds) => {
    const url = adaptUrl(':domain/sessions/next-available', {
      params: {
        domain: findDomain()
      },
      queries: {
        zoneId,
        activityIds,
        resourceIds,
        coachIds,
        from
      }
    });
    return publicAPI.get(url);
  },
  getAvailabilitiesFromPublicApi: ({ activityIds, resources, zoneId, from, to } = {}) => {
    const url = adaptUrl(':domain/v3/resourcetimeslots', {
      params: {
        domain: findDomain()
      },
      queries: {
        zoneId,
        from,
        to,
        activityIds,
        resources
      }
    });
    return publicAPI.get(url);
  },
  getAvailabilitiesForMembersWeb: ({ activityIds, resources, zoneId, from, to } = {}) => {
    const url = adaptUrl('v3/resourcetimeslots', {
      queries: {
        zoneId,
        from,
        to,
        activityIds,
        resourceIds: resources
      }
    });
    return memberAPI.get(url);
  },
  getMemberAppConfigs: () => {
    const url = adaptUrl(':domain/configs/member-app', {
      params: {
        domain: findDomain()
      }
    });
    return publicAPI.get(url);
  }
};
