<template>
  <HomeItem
    :itemDetails="myWaitingListRegistrationDetails"
  >
    <template v-slot:header>
      {{ registration.startDate }} - {{ registration.startTime }}
    </template>
    <template v-slot:headerActionOnlyDesktop>
      <AriButton
        :content="$t('account.booking.cancel')"
        text
        @click="onClickCancel"
      />
    </template>
    <template v-slot:bottomActionOnlyMobile>
      <div class="cancel-booking-bottom-button">
        <AriButton
          :content="$t('account.booking.cancel')"
          text
          @click="onClickCancel"
        />
      </div>
    </template>
  </HomeItem>
</template>

<script>
import HomeItem from './HomeItem.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    HomeItem
  },
  props: {
    registration: Object
  },
  computed: {
    ...mapGetters('config', ['resourceLabel']),
    myWaitingListRegistrationDetails () {
      return [
        {
          title: this.$t('account.waitinglist.order'),
          info: this.registration.waitIndex
        },
        {
          title: this.$t('account.waitinglist.activity'),
          info: this.registration.booking.activity.name
        },
        {
          title: this.$t('account.booking.zone'),
          info: this.registration.zoneName
        },
        {
          title: this.$t('account.booking.resourceTitle', { resource: this.resourceLabel }),
          info: this.registration.booking.resource.name
        }
      ];
    }
  },
  methods: {
    onClickCancel () {
      this.$emit('on-cancel-registration', {
        cancelId: this.registration.booking.id,
        bookingDateAndTime: this.registration.startDateAndTime,
        bookingType: this.$CONSTANTS.BOOKING_TYPES.WAITING_LIST
      });
    }
  }
};
</script>

<style lang="less" scoped>
.cancel-booking-bottom-button{
  .deci-button{
    margin:auto;
  }
}
</style>
