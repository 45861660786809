import { publicAPI, memberAPI } from '../request';
import { findDomain, adaptUrl } from '@/utils/url';

export default {
  getZones: () => {
    const url = adaptUrl(':domain/zones', {
      params: {
        domain: findDomain()
      }
    });
    return publicAPI.get(url);
  },
  getDomainConfig: () => {
    return memberAPI.get('clubs');
  }
};
