<template>
  <AriModal :show-close="false" class="reinit-password-dialog">
    <template #body>
      <div class="el-image logo">
          <img src="../../assets/images/xplor_deciplus_stacked_positive_RGB.png"
            class="el-image__inner logo-xplor-small" style="object-fit: fill;"/>
        </div>
        <el-row :gutter="24" class="center-page-content page-content reinit-password">
          <div>
            <div v-if="currentState === reinitPageStates.USER_IDENTIFY_FAIL" class="reinit-password__section">
              <div class="reinit-password__section reinit-password__headline headline--error text-center text-xl mb-md">
                {{ $t('authenticate.reinit.tokenExpiredTitle') }}
              </div>
              <div class="reinit-password__section">
                <p class="text-center mb-md">
                  {{ $t('authenticate.reinit.tokenExpired') }}
                </p>
              </div>
              <div class="reinit-password__section">
                <el-form :model="restartReinitForm" :rules="restartReinitFormRules" ref="restartReinitForm"
                  @keyup.enter="onResetPassword()"
                   class="flex flex-col gap-default items-center"
                >
                  <el-form-item :label="$t('email')" prop="email">
                    <TextInput
                      type="email" v-model="restartReinitForm.email"
                    />
                  </el-form-item>
                  <el-form-item
                    class="reinit-password__confirm-container"
                  >
                    <AriButton
                      class="w-full"
                      filled
                      :content="$t('authenticate.reinit.restart')"
                      @click="onResetPassword"
                    />
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div v-if="currentState === reinitPageStates.USER_RETRY_CONFIRM" class="reinit-password__section">
                {{ $t('authenticate.reinit.reinitTriggered') }}
            </div>
            <div v-if="currentState === reinitPageStates.USER_SOCIAL_RECOGNISED">
              <div class="reinit-password__section reinit-password__headline">
                {{ $t('authenticate.reinit.socialAccountFoundTitle', { methods: hasSocialAccountAssociation.join('/') }) }}
              </div>
              <div class="reinit-password__section">
                <p class="reinit-password__reinit-complete">
                  {{ $t('authenticate.reinit.socialAccountFound1', { methods: hasSocialAccountAssociation.join('/') }) }}
                </p>
              </div>
            </div>
            <div v-if="currentState === reinitPageStates.USER_DECIPLUS_RECOGNISED">
              <div class="reinit-password__section">
                <div class="reinit-password__section reinit-password__headline">
                  {{ $t('authenticate.reinit.globalAccountFoundTitle', { methods: hasSocialAccountAssociation.join('/') }) }}
                </div>
                <div class="reinit-password__section">
                  <p class="reinit-password__reinit-complete">
                    {{ $t('authenticate.reinit.globalAccountFound1', { globalAccountEmail: globalAccounts.deciplusMemberLogin }) }}
                  </p>
                  <p class="reinit-password__reinit-complete">
                    {{ $t('authenticate.reinit.globalAccountFound2') }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="currentState === reinitPageStates.USER_IDENTIFY_SUCCESS">
              <div class="reinit-password__section reinit-password__headline text-center text-xl mb-md">
                {{ $t('authenticate.definePassword') }}
              </div>
              <div class="reinit-password__section">
                <div class="reinit-password__domain-reset-container">
                  <el-form
                    class="flex flex-col gap-default items-center"
                    :model="resetDomainPasswordForm"
                    :rules="resetDomainPasswordFormRules"
                    ref="resetDomainPasswordForm"
                    @keyup.enter="onConfirmResetDomainPassword()"
                    label-position="top"
                  >
                    <el-form-item
                      prop="newPassword"
                      :label="$t('authenticate.recover.enterNewPassword')"
                    >
                      <TextInput type="password" showPassword v-model="resetDomainPasswordForm.newPassword" />
                    </el-form-item>
                    <el-form-item
                      prop="confirmPassword"
                      :label="$t('authenticate.recover.confirmNewPassword')"
                    >
                      <TextInput type="password" :disabled="resetDomainPasswordForm.newPassword === ''" show-password v-model="resetDomainPasswordForm.confirmPassword" />
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="reinit-password__bottom-actions">
                <div
                  class="reinit-password__section flex justify-content-center"
                >
                  <AriButton
                    id="next-signup-password"
                    filled
                    :content="$t('next')"
                    @click="onConfirmResetDomainPassword"
                  />
                </div>
              </div>
            </div>
            <div class="reinit-password__section" >
              <div class="reinit-password__section reinit-password__headline" v-if="currentState === reinitPageStates.USER_RESET_SUCCESS">
                {{ $t('authenticate.passwordDefined') }}
              </div>
              <div
                class="reinit-password__section"
              >
                <p
                  class="reinit-password__reinit-complete mt-md"
                >
                  {{ $t('authenticate.recover.proposeMobileApp') }}
                </p>
                <MobileApps />
              </div>
            </div>
            <div class="reinit-password__bottom-actions">
              <div
                v-if="currentState === reinitPageStates.USER_RESET_SUCCESS
                  || currentState === reinitPageStates.USER_SOCIAL_RECOGNISED
                  || currentState === reinitPageStates.USER_DECIPLUS_RECOGNISED"
                class="reinit-password__section"
              >
                <div
                  class="reinit-password__confirm-container flex justify-content-center"
                >
                  <AriButton
                    filled
                    :content="$t('authenticate.reinit.goToCenterWebsite')"
                    @click="goToCenterWebsite"
                  />
                </div>
              </div>
              <div
                v-if="currentState === reinitPageStates.USER_SOCIAL_RECOGNISED
                  || currentState === reinitPageStates.USER_DECIPLUS_RECOGNISED"
                class="reinit-password__section"
              >
                <div
                  class="reinit-password__confirm-container"
                >
                  <AriButton
                    outlined
                    :content="$t('authenticate.reinit.reinitAnyway')"
                    @click="goToPasswordReset"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-row>
    </template>
  </AriModal>
</template>

<script>
import { checkPasswordStrength } from '@/utils/passwordChecker';
import { mapGetters, mapState } from 'vuex';
import FooterMemberWeb from '@/modules/common/Footer.vue';
import TextInput from '@/modules/common/generic/TextInput.vue';
import MobileApps from '@/modules/common/MobileApps.vue';

export default {
  name: 'ReinitPasswordPage',

  components: {
    FooterMemberWeb,
    MobileApps,
    TextInput
  },

  data () {
    return {
      reinitPageStates: {
        USER_IDENTIFY_FAIL: 'USER_IDENTIFY_FAIL',
        USER_RETRY_CONFIRM: 'USER_RETRY_CONFIRM',
        USER_IDENTIFY_SUCCESS: 'USER_IDENTIFY_SUCCESS',
        USER_RESET_SUCCESS: 'USER_RESET_SUCCESS',
        USER_DECIPLUS_RECOGNISED: 'USER_DECIPLUS_RECOGNISED',
        USER_SOCIAL_RECOGNISED: 'USER_SOCIAL_RECOGNISED'
      },
      currentState: null,
      globalAccounts: {},
      resetDomainPasswordForm: {
        newPassword: '',
        confirmPassword: ''
      },
      resetDomainPasswordFormRules: {
        newPassword: [
          {
            trigger: 'change',
            validator: this.validatePass
          },
          {
            trigger: 'blur',
            validator: this.validatePass
          }
        ],
        confirmPassword: [
          {
            trigger: 'change',
            validator: this.validateConfirmation
          },
          {
            trigger: 'blur',
            validator: this.validateConfirmation
          }
        ]
      },
      restartReinitForm: {
        email: ''
      },
      restartReinitFormRules: {
        email: [
          {
            required: true,
            message: this.$t('authenticate.pleaseInputEmail'),
            trigger: 'change'
          },
          {
            required: true,
            message: this.$t('authenticate.pleaseInputEmail'),
            trigger: 'blur'
          }
        ]
      }
    };
  },

  computed: {
    ...mapState('user', ['reinitToken']),
    ...mapGetters('config', ['webUrl']),
    hasSocialAccountAssociation () {
      const socialAccounts = [];
      if (this.globalAccounts.hasFacebookAssociation) {
        socialAccounts.push('Facebook');
      }
      if (this.globalAccounts.hasGoogleAssociation) {
        socialAccounts.push('Google');
      }
      return socialAccounts;
    }
  },

  mounted () {
    this.loadGlobalAccounts();
  },

  methods: {
    async loadGlobalAccounts () {
      try {
        const response = await this.$services.HTTP.user.getGlobalAccounts({ reinitToken: this.reinitToken });
        this.globalAccounts = response.data;
        this.decideIdentificationType();
      } catch (error) {
        this.currentState = this.reinitPageStates.USER_IDENTIFY_FAIL;
        this.$information.capture({ error });
      }
    },
    decideIdentificationType () {
      if (this.globalAccounts?.hasFacebookAssociation || this.globalAccounts?.hasGoogleAssociation) {
        this.currentState = this.reinitPageStates.USER_SOCIAL_RECOGNISED;
      } else if (this.globalAccounts?.deciplusMemberId) {
        this.currentState = this.reinitPageStates.USER_DECIPLUS_RECOGNISED;
      } else {
        this.currentState = this.reinitPageStates.USER_IDENTIFY_SUCCESS;
      }
    },
    async onResetPassword () {
      try {
        await this.$refs.restartReinitForm.validate();
        await this.$services.HTTP.authenticate.startResetPassword({ email: this.restartReinitForm.email });
        this.currentState = this.reinitPageStates.USER_RETRY_CONFIRM;
      } catch (error) {
        this.$information.alert({
          message: this.$t('authenticate.recover.pleaseCheckInfo')
        });
        this.$information.capture({ error });
      }
    },
    async onConfirmResetDomainPassword () {
      try {
        await this.$refs.resetDomainPasswordForm.validate();
        await this.$services.HTTP.authenticate.resetPasswordAtDomain({
          newPassword: this.resetDomainPasswordForm.newPassword,
          reinitToken: this.reinitToken
        });
        this.currentState = this.reinitPageStates.USER_RESET_SUCCESS;
      } catch (error) {
        this.$information.capture({ error });
      }
    },
    validatePass (rule, value, callback) {
      const passwordError = checkPasswordStrength(
        value
      );
      if (passwordError !== '') {
        callback(new Error(this.$t('authenticate.passwordRules.' + passwordError)));
      } else {
        if (this.resetDomainPasswordForm.confirmPassword !== '') {
          this.$refs.resetDomainPasswordForm.validateField('confirmPassword');
        }
        callback();
      }
    },
    validateConfirmation (rule, value, callback) {
      if (value === '' || value !== this.resetDomainPasswordForm.newPassword) {
        callback(new Error(this.$t('authenticate.pleaseInputRepeatPassword')));
      } else {
        callback();
      }
    },
    goToCenterWebsite () {
      if (this.webUrl) {
        if (this.webUrl.includes('http')) {
          window.location.href = this.webUrl;
        } else {
          window.location.href = '//' + this.webUrl;
        }
      } else {
        this.$information.alert({
          message: this.$t('authenticate.reinit.noWebSiteFound'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.LONG,
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
      }
    },
    goToPasswordReset () {
      this.currentState = this.reinitPageStates.USER_IDENTIFY_SUCCESS;
    }
  }
};
</script>

<style lang="less">
@import '../../assets/styles/vars.less';
@import '../../assets/styles/mixins.less';
.logo-xplor-small {
  width: 170px;
}
.center-page-content {
  @apply w-full justify-center;
}
.reinit-password-dialog{
  .reinit-password__identification-success{
    text-decoration: underline blue;
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 0.2rem;
  }
}
</style>
