<template>
  <el-card class="home-item" shadow="never">
    <template v-slot:header>
      <div class="home-item__header flex justify-between items-center">
        <span class="flex flex-grow flex-col gap-xxs">
          <slot name="header"></slot>
          <span class="text-next-schedule" v-if="product && product.nextSchedule">{{$t('validity.nextSchedule')}} {{ $datetime.dateOnlyFormat(product.nextSchedule.date) }} - {{ product.nextSchedule.amount }}{{ memberAppConfigs.currency.symbol }}</span>
          <span class="text-next-schedule placeholder" v-else-if="product"></span>
        </span>
        <span class="home-item__header-action flex">
          <slot name="headerActionOnlyDesktop"></slot>
        </span>
        <i v-if="product && product.product" class="fal cursor-pointer" :class="{ 'fa-chevron-down': !expandInfo, 'fa-chevron-up': expandInfo }" @click="expandInfo =  !expandInfo"/>
      </div>
    </template>
    <slot name="modal"></slot>
    <!-- get sur session -->
    <AriModal :modalTitle="$t('guests.editGuest')" v-if="showInvited" @close="showInvited = false"
              width="30%" v-loading="isLoading">
      <template #body>
        <div class="flex justify-between h-auto items-center gap-default">
          <span class="text-primary">{{$t('guests.nbGuests')}}:</span>
          <AriInputNumber small :min="arrGuest.length" @update:modelValue="changeGuestNumber" :modelValue="arrGuest.length"/>
        </div>
        <guestManager :isRessource="booking.type === 'ressource'" :getCurrentActivity="getCurrentActivity" :guests="arrGuest" @update:guests="updateGuests($event)" @delete:guest="deleteGuest($event)"/>
      </template>
      <template #footer>
        <div class="footer-modal-edit-guest">
          <AriButton :content="$t('app.close')" text @click="showInvited = false" />
        </div>
      </template>
    </AriModal>
    <AriModal v-if="showParticipants" @close="showParticipants = false" :modalTitle="$t('places')" width="35%">
      <template #body>
        <div class="gap-default flex flex-col">
          <div v-for="(participant, index) in participants" :key="index" class="pt-xs gap-sm border-ghost padding-20 p-default flex flex-col">
            <span>Adhérent : <span class="font-medium">{{participant.username}}</span></span>
            <div v-if="participant.guests" class="flex flex-col gap-sm">
              <span class="fw-300">à invité :</span>
              <span class="pl-default" v-for="(guest, index) in participant.guests" :key="index"> - {{guest.username || 'Invité anonyme'}}</span>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <AriButton class="text-right" :content="$t('close')" @click="showParticipants = false" />
      </template>
    </AriModal>
    <div class="flex card-resa wrap" v-if="!product">
      <div v-for="(itemDetail, index) in itemDetails" :key="index" v-show="itemDetail.info || itemDetail.info === 0"
           class="home-item__detail w-50 gap-xxs flex flex-col" @click="onClickItemDetail(itemDetail)" >
        <div class="flex ubuntuLight" :class="(itemDetail.title === 'Invité(s): ' || itemDetail.title === 'Participant(s): ' ? 'pointer' : '')">
          <div  v-if="itemDetail.info || itemDetail.info === 0" class="home-item__detail-title">
            <span v-html="itemDetail.title"></span>
          </div>
          <i v-if="itemDetail.title === 'Invité(s): '" class="pl-default fas fa-edit"></i>
          <i v-if="itemDetail.title === 'Participant(s): '" class="pl-default fas fa-eye"></i>
        </div>
        <div :class="(itemDetail.title === 'Invité(s): ' ? 'pointer' : '')">
          <div v-if="itemDetail.is_action" class="home-item__detail-action">
            <AriButton :content="itemDetail.info" @click="onClickAction"/>
          </div>
          <div v-else class="home-item__detail-info font-medium" :class="showFullInfo ? 'home-item__detail-info--full' : 'home-item__detail-info--single-line'">
            <span v-html="itemDetail.info"></span>
          </div>
        </div>
      </div>
    </div>
    <!--Affichage spécifique aux prestas -->
    <div v-if="product" class="flex gap-default">
      <div class="home-item__detail presta-left-col">
        <div v-if="product.product.type === 'abo'">
          <div class="home-item__detail-title">{{ $t('account.myProducts.status') }}</div>
          <div class="home-item__detail flex items-center gap-xs font-medium"><div class="active-bullet ok"></div>{{ $t('account.myProducts.active') }}</div>
        </div>
        <div v-else>
          <div class="home-item__detail-title">{{ $t('account.myProducts.remainingCredits') }}</div>
          <div class="home-item__detail flex items-center gap-xs font-medium"><div class="active-bullet" :class="product.remainingCredit > 0 ? 'ok' : 'ko'"></div>{{ parseFloat(product.remainingCredit).toFixed() }}</div>
        </div>
      </div>
      <div class="home-item__detail">
        <div>
          <div class="home-item__detail-title">{{ $t('products.validity2') }}</div>
          <template v-if="product.product.type === 'abo'">
            <div class="home-item__detail  wrapper-detail">
              <!-- <span v-if="product.autoRenewal">{{ $datetime.dateOnlyFormat(dateEnd) }}</span> -->
              <span>{{ $t('products.validity.until') }} {{ $datetime.dateOnlyFormat(product.dateEnd) }}</span>
            </div>
          </template>
          <div v-else>
            <div class="home-item__detail flex items-center gap-xs font-medium wrapper-detail">{{ $t('products.validity.until') }} {{ $datetime.dateOnlyFormat(product.dateEnd) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-show="expandInfo">
      <div v-if="product" class="flex gap-default">
        <div class="home-item__detail presta-left-col">
          <div class="home-item__detail-title">{{ $t('products.activities2') }}</div>
          <div class="home-item__detail flex items-center gap-xs font-medium home-item__detail-info--single-line">
            {{ itemDetails[2].info }}
          </div>
        </div>
        <div class="home-item__detail" v-if="product.subscriber">
          <div class="home-item__detail-title">{{ $t('products.subscriber') }}</div>
          <div class="home-item__detail flex items-center gap-xs font-medium">
            {{ product.subscriber.firstName }} {{ product.subscriber.lastName }}
          </div>
        </div>
        <div v-else class="w-1/3">
        </div>
      </div>
      <div class="block-resiliation" @click="showTerminationModal = true" v-if="product && product.isCancellable && !product.subscriber && memberAppConfigs.webConfig_contractTermination">
        <div class="flex gap-default items-center">
          <i class="fa fa-times"></i>
          <span class="title-resiliation">{{$t('resiliation.ask')}}</span>
        </div>
        <i class="fa fa-chevron-right"></i>
      </div>
    </div>
    <div>
      <span class="home-item__bottom-action">
        <slot name="bottomActionOnlyMobile"></slot>
      </span>
    </div>
  </el-card>
  <modalTerminationContract :exitOnOutside="false" :exitOnEsc="false" :product="product.product" v-if="showTerminationModal" @close="showTerminationModal = false" />
</template>

<script>
import { displayBookingErrors } from '@/utils/errors';
import { mapActions, mapState } from 'vuex';
import guestManager from '../../calendar/components/Timeslot/components/GuestManager.vue';
import { mail, required } from '@/utils/inputFormatters';
import modalTerminationContract from './modalTerminationContract.vue';
export default {
  name: 'HomeItem',
  components: {
    guestManager,
    modalTerminationContract
  },
  props: {
    dateEnd: String,
    itemDetails: Array,
    booking: Object,
    arrGuest: Array,
    participants: [Array, null],
    product: Object
  },
  data () {
    return {
      showInvited: false,
      showFullInfo: false,
      showParticipants: false,
      isLoading: false,
      width: window.innerWidth,
      expandInfo: false,
      showTerminationModal: false
    };
  },
  computed: {
    ...mapState('zone', ['activities']),
    ...mapState('config', ['memberAppConfigs']),
    getCurrentActivity () {
      return this.activities.find(activity => activity.id === this.booking?.activity.id) || {};
    }
  },
  methods: {
    mail,
    required,
    ...mapActions('user', ['loadUpcomingUserBookings']),
    changeGuestNumber (newLength) {
      const tmpArr = [...this.arrGuest];
      tmpArr.length = newLength;
      for (let i = 0; tmpArr.length > i; i++) {
        if (!tmpArr[i]) tmpArr[i] = {};
      }
      this.updateGuests(tmpArr);
    },
    deleteGuest (visitId) {
      this.isLoading = true;
      this.$services.HTTP.booking.deleteGuest(this.booking?.id, { visitIds: [visitId] }).then(async () => {
        this.$information.alert({
          message: this.$t('guests.deletedGuest'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
        await this.loadUpcomingUserBookings();
      }).catch(err => {
        displayBookingErrors(err);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    updateGuests (arrGuest) {
      this.isLoading = true;
      this.$services.HTTP.booking.putGuestsResa(this.booking?.id, { invitedMembers: arrGuest }).then(async () => {
        await this.loadUpcomingUserBookings();
      }).catch((err) => {
        const errString = err.response?.data?.data?.rules[0]?.code;
        this.$information.alert({
          message: errString ? this.$t(`resa.errors.${errString}`) : this.$t('guests.editedError'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.RED_ERROR
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    onClickAction () {
      this.$emit('on-click-action');
    },
    onClickItemDetail (itemDetail) {
      if (itemDetail.title === 'Invité(s): ') this.showInvited = true;
      if (itemDetail.title === 'Participant(s): ') this.showParticipants = true;
      else this.showFullInfo = !this.showFullInfo;
    }
  }
};
</script>

<style lang="less" >
.placeholder {
  height: 14px;
}
.wrapper-detail {
  @apply flex items-center gap-xs font-medium h-xl;
}
.presta-left-col {
  width: 70%;
}
.active-bullet {
  width: 10px;
  height: 10px;
  border-radius: 99px;
}
.active-bullet.ok {
  background: #30AF84;
}
.active-bullet.ko {
  background: #ff0000;
}
.card-resa {
  gap: 10px 0px;
}
.fw-300 {
  font-weight: 300;
}
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.pb-20 {
  padding-bottom: 20px !important;
}
.fs-18 {
  font-size: 18px;
}
.pt-sm {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.text-right {
  text-align: right
}
.el-card__header {
  @apply p-default;
}
.el-card{
  &.home-item{
    display: inline-block;
    margin: 1rem;
    width: 100%;
    height: fit-content;
    .home-item__header{
      @apply text-primary;
      filter: brightness(90%);
      font-weight: @heavy-font-weight;
      font-size: @big-text-size;
      .home-item__header-action{
        float: right;
        font-weight: initial;
        display: none;
      }
    }
    .home-item__detail{
      padding-bottom: 10px;
      &:last-child{
        padding-bottom: 0;
      }
      .home-item__detail-title{
        @apply text-secondary;
        font-size: @small-text-size;
      }
      .home-item__detail-info{
        &.home-item__detail-info--single-line{
          .single-line-text();
        }
        &.home-item__detail-info--full{
          .cancel-single-line-text();
        }
      }
      .home-item__detail-action{
        margin-bottom: 15px;
      }
    }
  }
}
@media (min-width:992px){
  .el-card{
    &.home-item{
      width: calc(50% - 2rem);
      .home-item__header{
        .home-item__header-action{
          display: block;
        }
      }
      .home-item__bottom-action{
        display: none;
      }
    }
  }
}

.checkbox-circle input[type="checkbox"] {
  display: none;
}
.checkbox-circle input[type="checkbox"] + label {
  display: block;
  position: relative;
  font: 14px/20px "Open Sans", Arial, sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.checkbox-circle input[type="checkbox"]:hover + label:before {
  border: 1px solid;
  @apply border-default;
  width: 0.8em;
  height: 0.8em;
  border: 2px solid #fff;
  background: #50565a;
  box-shadow: 0 0 0 1px #000;
}
.checkbox-circle input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}
.checkbox-circle input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  border: 1px solid #343a3f;
  border-radius: 1em;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.2s, transform 0.3s ease-in-out;
  transition: all 0.2s, transform 0.3s ease-in-out;
  background: #f3f3f3;
}
.checkbox-circle input[type="checkbox"]:checked + label:before {
  border-radius: 1em;
  border: 2px solid #fff;
  width: 0.8em;
  height: 0.8em;
  background: #50565a;
  box-shadow: 0 0 0 1px #000;
}
</style>
