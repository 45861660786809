<script>
import { capitalizeFirstLetter } from '@/utils/common';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import CalendarControl from '@/modules/calendar/mixins/calendarControl';

export default {
  name: 'Header',
  data () {
    return {
      customMenuIndexUsed: null
    };
  },
  mixins: [CalendarControl],
  computed: {
    ...mapGetters('domain', ['domain', 'getZoneLogoPath']),
    ...mapGetters('user', ['loggedInUser']),
    ...mapGetters('config', ['cornerLogoRemovedByTunnel', 'showLogo', 'showLandingPage', 'customMenus']),
    ...mapState('user', ['deviceFormat']),
    ...mapState('config', ['memberAppConfigs']),
    ...mapState('domain', ['zones'])
  },
  watch: {
    '$route' (from, to) {
      if (to.name === 'calendar') {
        this.customMenuIndexUsed = null;
      }
    }
  },
  methods: {
    capitalizeFirstLetter,
    ...mapGetters('config', ['selectedZoneId']),
    ...mapState('config', ['calendarConfigs']),
    ...mapMutations('config', ['SET_SELECTED_ACTIVITIES', 'SET_SELECTED_RESOURCES', 'SET_SELECTED_COACHES', 'SET_SELECTED_ZONE', 'SET_SELECTED_CALENDAR_MODE', 'SET_SELECTED_DATE']),
    ...mapActions('zone', [
      'loadAllResources',
      'loadAllActivities',
      'loadAllCoaches'
    ]),
    useCustomMenu: async function (customMenu, index) {
      this.customMenuIndexUsed = index;
      if (!this.calendarConfigs.date) this.SET_SELECTED_DATE(this.$datetime.getCurrentWeekStart(new Date()));
      if (customMenu?.filters) {
        if (customMenu.filters.type === 'resource') {
          this.SET_SELECTED_CALENDAR_MODE(this.$CONSTANTS.CALENDAR_MODES.AVAILABILITY);
        } else {
          this.SET_SELECTED_CALENDAR_MODE(this.$CONSTANTS.CALENDAR_MODES.SESSION);
        }

        const activityIds = customMenu.filters.activityIds || [];
        this.SET_SELECTED_ACTIVITIES(activityIds);

        const resourceIds = customMenu.filters.resourceIds || [];
        this.SET_SELECTED_RESOURCES(resourceIds);

        const coachIds = customMenu.filters.coachIds || [];
        this.SET_SELECTED_COACHES(coachIds);
        const zoneId = customMenu.filters.zoneId || this.loggedInUser.zone || this.selectedZoneId;
        const zone = this.zones.find(zone => zone.id === zoneId);
        this.SET_SELECTED_ZONE(zone);

        await this.loadZoneDetailsFromStore();
      } else {
        this.SET_SELECTED_ACTIVITIES([]);
        this.SET_SELECTED_RESOURCES([]);
        this.SET_SELECTED_COACHES([]);
      }
      this.showCustomMenusPopover = false;
      if (this.$route.name === 'calendar') {
        await this.getAllTimeslotsFromStore();
        window.scroll(0, 0);
      } else {
        this.$router.push({ name: 'calendar' });
      }
    },
    async loadZoneDetailsFromStore () {
      try {
        await this.loadAllActivities();
        await this.loadAllResources();
        await this.loadAllCoaches();
      } catch (err) {
        this.$information.capture({ err });
      }
    }
  }
};
</script>
