<template>
  <el-popover
    placement="top-start"
    trigger="click"
    class="info-box"
    :content="info"
    v-model="isVisible"
    v-bind="$attrs"
  >
    <slot></slot>
    <template #reference>
      <i v-poper:popover :class="icon" class="info-box__icon cursor-pointer"
        tabindex="0" @keypress="toggleVisibility"/></template>

  </el-popover>
</template>

<script>
export default {
  name: 'InfoBox',

  props: {
    info: String,
    icon: String
  },

  data () {
    return {
      isVisible: false
    };
  },

  methods: {
    toggleVisibility () {
      this.isVisible = !this.isVisible;
    }
  }
};
</script>

<style lang="less">
.info-box{
  .info-box__icon{
    cursor: pointer;
    &:focus{
      border: 1px dashed rgba(49, 41, 41, 0.5);
    }
  }
}
</style>
