<template>
  <AriModal v-if="visibility" :modalTitle="$t('tunnel.mobileAppBetterTitle')" @close="$emit('on-cancel')">
    <template v-slot:body>
      <div class="mobile-app-better">
        <p>{{ $t(isDeciplusApp ? 'tunnel.mobileAppBetter' : 'authenticate.recover.proposeMobileApp') }}</p>
        <p v-if="isDeciplusApp" class="mobile-app-better__center-code">
          <i>{{ $t('tunnel.mobileAppMemberCode') }}</i>
          <b> {{ centerCode }}</b>
        </p>
        <MobileApps />
      </div>
    </template>
    <template v-slot:footer>
      <AriButton class="w-full" filled :content="$t('close')" @click="$emit('on-cancel')"/>
    </template>
  </AriModal>
</template>

<script>
import MobileApps from '@/modules/common/MobileApps.vue';
import { findDomain, findCenterCode } from '@/utils/url';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalMobileAppBetter',
  components: {
    MobileApps
  },
  props: {
    visibility: Boolean
  },
  computed: {
    ...mapGetters('config', ['reservationLink', 'appStoreUrls']),
    centerCode () {
      return findCenterCode(this.reservationLink) || findDomain();
    },
    isDeciplusApp () {
      return this.appStoreUrls?.PLAYSTORE.includes('com.lodecom.apps.deciplusmember');
    }
  }
};
</script>

<style lang="less">
.mobile-app-better{
  padding-bottom: 0;
  p{
    margin-bottom: 10px;
  }
  .deci-button{
    margin: auto;
  }
  .mobile-app-better__center-code{
    text-align: center;
  }
  .strongFilled{
    width: 100%;
  }
}
@media (min-width: 992px){
  .mobile-app-better{
    padding-bottom: 2rem;
    text-align: left;
    .strongFilled{
      width: auto;
    }
    .mobile-app-better__confirm{
      margin-top: 2rem;
      position: absolute;
      right: 20px;
      bottom: 25px;
    }
  }
}
</style>
