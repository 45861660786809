<template>
  <div class="flex">
    <div class="flex flex-col w-full">
      <div class="flex justify-between items-center gap-xxs0 w-full">
        <itemTimeslot :label="resourceLabel" :content="$t('timeslot.resourceToBook', { resourceLabel })">
          <div v-if="!showResourcesCards" class="flex gap-xs hover:opacity-30 items-center cursor-pointer text-main" @click="editResource = true">
            <span class="whitespace-nowrap text-xs">{{getResourceName(selectedResource)}}</span>
            <span v-if="getResourcePricing(selectedResource)" class="card-title">
              ({{getResourcePricing(selectedResource)}})
            </span>
            <i class="fas fa-pen text-xxs text-secondary" />
          </div>
        </itemTimeslot>
      </div>
      <div class="flex gap-sm overflow-auto p-sm" v-if="showResourcesCards">
        <div :title="getRessource(resource.id).description" class="container-card-resource" v-for="resource in timeslotResources" :key="resource.id" @click="selectResource(resource.id)">
          <div class="flex justify-between">
            <div class="text-primary text-sm font-medium flex gap-xxs items-center">
              <span>{{getResourceName(resource.id)}}</span>
              <i v-if="getRessource(resource.id).description" class="fal fa-info-circle" />
            </div>
            <input type="checkbox" :checked="isChecked(resource.id)"/>
          </div>
          <div>
            <span class="card-title">{{$t('calendar.timeslot.disponibility')}} : </span>
            <span class="card-content">{{getMinMaxDuration(resource)}}</span>
          </div>
          <div>
            <span class="card-title">{{$t('timeslot.timeslot.places')}} : </span>
            <span class="card-content">{{getPlaces(resource)}}</span>
          </div>
          <div v-if="resource.tarification">
            <span class="card-title">
              {{slotPricingLabel(resource.tarification)}}
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import itemTimeslot from './item-timeslot.vue';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'ResourceHandler',
  components: { itemTimeslot },
  data () {
    return {
      showGuest: false,
      arrGuest: [],
      editResource: false
    };
  },
  computed: {
    ...mapState('zone', ['resources']),
    ...mapGetters('config', ['resourceLabel', 'slotPricingLabel']),
    showResourcesCards () {
      return !this.selectedResource || this.editResource;
    }
  },
  methods: {
    getRessource (resourceId) {
      return this.resources.find(r => r.id === resourceId);
    },
    getResourceName (resourceId) {
      return this.resources.find(r => r.id === resourceId)?.name;
    },
    getResourcePricing (resourceId) {
      const pricing = this.timeslotResources.find(t => t.id === resourceId)?.tarification;
      return this.slotPricingLabel(pricing);
    },
    getMinMaxDuration (resource) {
      return `${resource.availableDurations[0]} ${this.$t('app.at')} ${resource.availableDurations[resource.availableDurations.length - 1]} min`;
    },
    isChecked (resourceId) {
      return parseInt(resourceId) === parseInt(this.selectedResource);
    },
    getPlaces (resource) {
      return `${this.minPlacesToBook} ${this.$t('app.at')} ${resource.maxPlayerCount}`;
    },
    selectResource (resourceId) {
      this.editResource = false;
      this.$emit('update:selectedResource', resourceId);
    }
  },
  props: {
    timeslotResources: Array,
    selectedResource: [Number, String],
    minPlacesToBook: Number
  }
};
</script>

<style lang="less" scoped>
  .container-card-resource {
    border: 1px solid;
    background: #fff;
    min-width: fit-content;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1);
    @apply border-default gap-xxs p-sm rounded flex flex-col cursor-pointer;
  }
  .card-title {
    @apply text-xs text-secondary;
  }
  .card-content {
    @apply text-primary text-xs font-light;
  }
</style>
