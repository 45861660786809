<template>
  <div>
    <span></span>
    <DeciTitle :text="title" size="medium" />
    <div class="video-gallery__static-video-list pt-xxs wrap">
      <div v-for="(video, index) in videos" :key="index" class="flex f-shrink-0 flex-grow static-video-list__video">
        <VideoThumbnail :video="video" />
      </div>
    </div>
  </div>
</template>

<script>
import VideoThumbnail from './VideoThumbnail.vue';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';

export default {
  name: 'StaticVideoList',

  components: {
    VideoThumbnail,
    DeciTitle
  },

  props: {
    videos: Array,
    title: String
  }
};
</script>

<style lang="less">
.video-gallery__static-video-list{
  text-align: center;
  display: flex;
  gap:15px;
  .static-video-list__video {
    display: inline-block;
    width: 100%;
    max-width: 400px;
  }
}
@media (min-width:500px){
  .video-gallery__static-video-list{
    .static-video-list__video{
      min-width: 250px;
    }
  }
}
@media (min-width:992px){
  .video-gallery__static-video-list{
    .static-video-list__video{
      min-width: 280px;
      flex-wrap: wrap;
    }
  }
}
@media (min-width:1280px){
  .video-gallery__static-video-list{
    text-align: left;
    .static-video-list__video{
      min-width: 290px;
    }
  }
}
</style>
