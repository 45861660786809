
export const calendarTypeMap = {
  SESSION: 'course',
  AVAILABILITY: 'ressource'
};

export function filterResourcesByType (type) {
  return function (resource) {
    return (resource.isOnline === true || type === resource.isOnline);
  };
}
