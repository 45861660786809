<template>
  <AriModal v-if="show" :modalTitle="$t('scheduleShow')" @close="$emit('on-close')">
    <template v-slot:body>
      <h2 class="categorie-title text-primary ariane-mb-xs">{{ product.config.name }}</h2>
      <div class="categorie-title ariane-mb-base">{{ $t('cart.schedule') }}</div>
      <div class="flex flex-col payment-schedule-table">
        <div class="flex justify-content-around"
          v-for="(schedule, index) of product.config.scheduler"
          :key="index"
        >
          <div class="ariane-ph-big-medium ariane-pv-s">{{ formatDate(schedule.paymentDate) }}</div>
          <div class="ariane-ph-big-medium ariane-pv-s">{{ getPrice(schedule) }} {{ currency }}</div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
    <AriButton class="w-full ariane-mt-base" :content="$t('ok')" filled @click="close()" />
    </template>
  </AriModal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'payment-schedule-modal',
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('config', ['currency'])
  },
  methods: {
    close () {
      this.$emit('on-close');
    },
    formatDate (date) {
      const datetime = this.$datetime.fromISODate(date);
      if (this.$datetime.isToday(datetime)) {
        return this.$t('today');
      }
      return this.$datetime.getLocalNumericDate(datetime);
    },
    getPrice (schedule) {
      return this.product.quantity * schedule.price;
    }
  }
};
</script>

<style lang="less" scoped>
.payment-schedule-table {
  max-height: 328px;
  overflow-y: auto;
  > div {
    text-align: center;
    border-bottom: solid 1px lightgray;
    &:first-child > div:nth-child(2) {
      color: var(--ariane-primary);
    }
    &:last-child {
      border-bottom: none;
    }
    &:nth-child(odd) {
      background: #F8F9FA;
    }
    > div {
      width: 100%;
      min-width: 130px;
      word-break: keep-all;
      &:nth-child(1) {
        border-right: solid 0.5px lightgray;
      }
      &:nth-child(2) {
        border-left: solid 0.5px lightgray;
      }
    }
  }
}
</style>
