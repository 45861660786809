<template>
  <el-row class="product-summary">
    <div v-if="isDurationShown">
      <span>{{ $t("products.validity") }} </span>
      <b>{{ validityDuration(productToBuy) }}</b>
    </div>
    <div v-if="isCreditsShown">
      <span>{{ $t("products.credits") }} </span>
      <b>{{ productToBuy.remainingCredit }}</b>
    </div>
    <div v-if="isActivityShown">
      <span>{{ $t("products.activities") }} </span>
      <b>{{ associatedActivities }}</b>
    </div>
    <div v-if="isZoneShown">
      <span>{{ $t("products.zones") }} </span>
      <b>{{ validityZone(productToBuy) }}</b>
    </div>
    <div class="product__description" v-if="productDescription">
      <span>{{ $t("products.description") }} :</span>
      <SeeMore
        v-if="productDescription.length > productShortDescriptionLimit"
        :text="productDescription"
        :seeMoreLimit="productShortDescriptionLimit"
        :showMoreIndication="$t('showMore')"
        :showLessIndication="$t('showLess')"
      />
      <p v-else v-html="productDescription">
      </p>
    </div>
  </el-row>
</template>

<script>
import SeeMore from '@/modules/common/generic/SeeMore.vue';
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ProductSummary',

  components: {
    SeeMore
  },

  mixins: [ProductsControl],

  mounted () {
    if (this.productToBuy) {
      this.showDuration = true;
      this.showActivities = true;
      this.showCredits = true;
      this.showZones = true;
    }
  },

  data () {
    return {
      showDuration: false,
      showActivities: false,
      showCredits: false,
      showZones: false,
      productShortDescriptionLimit: 100
    };
  },

  computed: {
    ...mapState('cart', ['productToBuy']),
    ...mapGetters('domain', ['isMultiSite']),
    associatedActivities () {
      if (!this.productToBuy.associatedActivities) {
        return '';
      }
      const activityNames = [];
      this.productToBuy.associatedActivities.map(activity => activityNames.push(activity.name));
      if (activityNames.length) {
        return activityNames.join(', ');
      }
      return this.$t('products.validForAllActivities');
    },
    isDurationShown () {
      return this.showDuration && this.productToBuy.validityLength;
    },
    isCreditsShown () {
      return this.showCredits && this.productToBuy.remainingCredit;
    },
    isZoneShown () {
      return this.canShowZoneSection && this.showZones;
    },
    isActivityShown () {
      return this.canShowActivitySection && this.showActivities;
    },
    isNotVideoOrGiftCard () {
      return this.productToBuy.type !== this.$CONSTANTS.PRODUCT_TYPES.VIDEO && this.productToBuy.type !== this.$CONSTANTS.PRODUCT_TYPES.GIFT_CARD;
    },
    canShowActivitySection () {
      return this.isNotVideoOrGiftCard;
    },
    canShowZoneSection () {
      return this.isMultiSite && this.productToBuy.type !== this.$CONSTANTS.PRODUCT_TYPES.VIDEO;
    },
    productDescription () {
      return this.productToBuy.descriptionHtml || this.productToBuy.description;
    }
  }
};
</script>

<style lang="less">

.product-summary{
  text-align: initial;
  .product__description{
      margin-top: 1em;
  }
}
</style>
