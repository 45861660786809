<template>
  <div class="flex gap-lg">
    <div class="auth-container">
      <h1 id="auth-welcome" class="content-title text-center pt-default">
        {{$t('authenticate.welcome', {domainName: commonDomainName})}}
      </h1>
      <SignIn v-if="componentmode==='signIn'"/>
      <SignUp v-else class="w-full"/>
      <SocialConnection class="w-full">
        <template v-slot:alternativeLabel>
          {{ alternativeLabel }}
        </template>
      </SocialConnection>
    </div>
    <div class="w-6/12 landing-visual">
      <LandingVisual />
    </div>
  </div>
</template>

<script>
import SocialConnection from './components/SocialConnection.vue';
import SignIn from './components/SignIn.vue';
import SignUp from './components/SignUp.vue';
import LandingVisual from '@/modules/landing/components/LandingVisual.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Auth',

  props: {
    componentmode: String
  },

  components: {
    SignUp,
    SignIn,
    SocialConnection,
    LandingVisual
  },

  created () {
    if (this.loggedInUser) {
      this.redirectToCalendar();
    }
  },

  mounted () {
    window.scrollTo(0, 0);
  },

  computed: {
    ...mapState('domain', ['domain', 'zones']),
    ...mapGetters('domain', ['isMultiSite']),
    ...mapGetters('user', ['loggedInUser']),
    ...mapGetters('config', ['domainName', 'forcedZoneByTunnel']),
    alternativeLabel () {
      return this.componentmode === 'signIn' ? this.$t('authenticate.orSignInWith') : this.$t('authenticate.orSignUpWith');
    },
    commonDomainName () {
      if (this.forcedZoneByTunnel && this.zones) {
        const forcedZoneDetails = this.zones.find(zone => zone.id === this.forcedZoneByTunnel);
        return forcedZoneDetails?.clubName;
      } else if (this.domainName) {
        return this.domainName;
      } else if (this.isMultiSite) {
        return this.domain.charAt(0).toUpperCase() + this.domain.slice(1);
      } else {
        return this.zones[0].clubName;
      }
    }
  },

  methods: {
    redirectToCalendar () {
      this.$router.push({ name: 'calendar' });
    }
  }
};
</script>

<style lang="less">
.auth-container {
  @apply flex flex-col items-center w-full md:w-6/12 ;
}
.choose-x{
  .el-form-item__label{
    line-height: 1rem;
  }
}
.el-steps{
  padding-bottom: 1rem;
}
.buttons {
  justify-content: center;
  margin-top: 20px;
  gap: 30px;
}
.landing-visual {
  @apply hidden md:block;
}
@media (max-width:736px){
  #app {
    #auth-welcome{
      font-size: 26px;
      line-height: inherit;
    }
  }
}
@media (max-width:812px){
  #app {
    #auth-welcome{
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }
}
</style>
