<template>
  <div>
    <AriCheckbox class="py-xs" v-model="zoneConfirmationsForm.softwareNotifications" @change="onSoftwareNotificationsChange" >
      {{ $t('authenticate.domain.softwareNotifications') }}
    </AriCheckbox>
    <AriCheckbox class="py-xs" v-model="zoneConfirmationsForm.commercialNotifications" @change="onCommercialNotificationsChange" >
      {{ $t('authenticate.domain.commercialNotifications') }}
    </AriCheckbox>
  </div>


</template>

<script>
export default {
  name: 'SignUpZoneConfirmations',
  props: {
    zone: Object
  },
  data () {
    return {
      zoneConfirmationsForm: {
        commercialNotifications: false,
        softwareNotifications: false,
      }
    };
  },
  methods: {
    onSoftwareNotificationsChange (change) {
      this.$emit('on-software-notifications-changed', change.target.checked);
    },
    onCommercialNotificationsChange (change) {
      this.$emit('on-commercial-notifications-changed', change.target.checked);
    }
  }
};
</script>
