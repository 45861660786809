import CONSTANTS from '@/utils/constants';

export function productsAdapter (productsInfo) {
  return productsInfo.filter(productGrouping =>
    productGrouping.id === CONSTANTS.PRODUCT_TYPES.PREPAID_CARD ||
    productGrouping.id === CONSTANTS.PRODUCT_TYPES.SUBSCRIPTION ||
    productGrouping.id === CONSTANTS.PRODUCT_TYPES.SPOTLIGHT ||
    productGrouping.id === CONSTANTS.PRODUCT_TYPES.GIFT_CARD
  );
}

export function findProductByPublicInfo (products, publicProductInfo) {
  return products
    .find(productGroup => {
      if (publicProductInfo.type === 'subscription') {
        return productGroup.id === CONSTANTS.PRODUCT_TYPES.SUBSCRIPTION;
      }
      return productGroup.id === CONSTANTS.PRODUCT_TYPES.PREPAID_CARD;
    }).items
    .find(product =>
      product.id === publicProductInfo.id
    );
}

export function findNoWalletInstallment (installment) {
  return !installment.paymentNow &&
    (installment.paymentType === 'ESEPA' || installment.paymentType === CONSTANTS.PAYMENT_MODES.PREL);
}

export function sortGroup (productGroups) {
  const GROUP_ORDER = [
    CONSTANTS.PRODUCT_TYPES.GIFT_CARD,
    CONSTANTS.PRODUCT_TYPES.PREPAID_CARD,
    CONSTANTS.PRODUCT_TYPES.SUBSCRIPTION
  ];
  return productGroups.sort((g1, g2) => GROUP_ORDER.indexOf(g2.id) - GROUP_ORDER.indexOf(g1.id));
}
