<template>
  <div
    class="day-section-indicator"
  >
    <div
      class="timerange"
      :class="{'timerange--hidden' : hideIndicator}"
    >
      {{ daySectionInfo.start }}
      <br>
      <div class="timerange-separator">
        <span>|</span>
      </div>
      {{ daySectionInfo.finish }}
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  props: {
    daySectionName: String,
    hideIndicator: Boolean
  },
  computed: {
    daySectionInfo () {
      const daySection = this.$CONSTANTS.SECTIONS_OF_DAY[this.daySectionName];
      return {
        label: this.$t(`calendar.daySections.${daySection.name}`),
        start: `${this.daySectionName === 'morning' ? '0' : ''}${daySection.start}:00`,
        finish: `${daySection.finish}:59`
      };
    }
  }
};
</script>

<style lang="less">
@import "../../../assets/styles/vars.less";
.day-section-indicator{
  .timerange{
    margin-top:10px;
    display: inline-block;
    padding: 5px;
    color: @main-color;
    font-weight: bold;
    .timerange-separator{
      text-align: center;
      padding: 0.5rem 0;
    }
  }
  .timerange--hidden{
    visibility: hidden;
  }
}

@media (max-width:992px){
  #app{
    .el-container{
      .timerange{
        font-size: 12px;
        margin-left:-30px !important;
      }
    }
  }
}
</style>
