<template>
  <AriAvatar :url="loggedInUser.photo" :user="loggedInUser.surname" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserAvatar',
  props: {

  },

  computed: {
    ...mapGetters('user', ['loggedInUser'])
  }
};
</script>

<style lang="less">
@import "../../assets/styles/vars.less";
@import "../../assets/styles/mixins.less";

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 1000px;
  border: 1px solid var(--ariane-border);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-duration: 0.3s !important;
}
</style>
