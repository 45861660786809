<template>
  <div class="video-gallery__coach-grouping ">
    <DeciTitle :text="$t('videoGallery.byCoach')" size="medium" />
    <div class="coach-grouping__coaches gap-xl flex pt-xs wrap">
      <div v-for="(grouping, index) in groupings" :key="index"
        class="coach-grouping__coach-container" :title="grouping.name">
        <interactive-div @on-interaction="onOpenCoachGrouping(grouping)" class="flex gap-lg">
          <CoachAvatar bigAvatar giganticAvatarOmega :coachId="grouping.id" class="flex flex-col"/>
        </interactive-div>
      </div>
    </div>
  </div>
</template>

<script>
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';
import CoachAvatar from '@/modules/common/CoachAvatar.vue';

export default {
  name: 'VideoCoachGrouping',

  components: {
    CoachAvatar,
    DeciTitle
  },

  props: {
    groupings: Array
  },

  methods: {
    videoCoachGroupingOpened ({ redirectToVideosByCoachPage, selectedCoach }) {
      redirectToVideosByCoachPage(selectedCoach);
    },
    onOpenCoachGrouping (coachGrouping) {
      this.videoCoachGroupingOpened({
        redirectToVideosByCoachPage: this.redirectToVideosByCoachPage,
        selectedCoach: coachGrouping.id
      });
    },
    redirectToVideosByCoachPage (selectedCoach) {
      this.$router.push({ name: 'videosByCoach', params: { id: selectedCoach } });
    }
  }
};
</script>

<style lang="less">
@import "../../../assets/styles/vars.less";
@import "../../../assets/styles/mixins.less";
.video-gallery__coach-grouping{
  text-align: left;
  .coach-grouping__coach-container{
    vertical-align: top;
    display: inline-block;
    text-align: center;
    .full-rounded();

    .coach-container__coach-image{
      object-fit: cover;
    }

    .coach-container__coach-name{
      .single-line-text();
      width: 100%;
      font-weight: 500;
      color: @darken-black;
      padding-top: 0.9rem;
      text-align: center;
    }

  }

}
@media (max-width:500px){
  .coach-grouping__coaches {
    justify-content: center;
  }
}
@media (min-width:992px){
  .video-gallery__coach-grouping{
    text-align: left;
  }
}
@media (min-width:1280px){
  .video-gallery__coach-grouping{
  }
}
</style>
