<template>
  <div class="table-container my-products grid-content">
    <el-row class="my-products__title">
      <el-col>
        <DeciTitle :isLined="false" :text="$t('account.myProducts.title', { count: products.length })" />
      </el-col>
    </el-row>
    <div class="wrap-container">
      <MyProduct v-for="(product, index) in products" :key="index" :product="product" />
    </div>
    <div class="no-products-message" v-if="hasNoProducts">
      {{ $t('account.myProducts.notfound') }}
    </div>
  </div>
</template>

<script>
import MyProduct from './MyProduct.vue';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';

export default {
  components: {
    MyProduct,
    DeciTitle
  },
  props: {
    products: Array
  },
  data () {
    return {
    };
  },
  computed: {
    hasNoProducts () {
      return this.products.length === 0;
    }
  },
  methods: {
  }
};
</script>

<style lang="less">
.no-products-message{
  text-align: center;
}
.my-products__title{
  align-items: baseline;
}
@media (min-width: 500px){
  .my-products__title{
    display: flex;
  }
}
</style>
