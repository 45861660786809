<template>
  <nav class="sm:flex sm:flex-col sm:gap-sm sm:pr-sm sm:align-items-end" v-if="windowInnerWidth > 640">
    <div v-for="nav in generateLeftMenu()" class="flex flex-col gap-sm" :key="nav.label">
      <span v-if="nav.categories.length > 0"
        class="categorie-title text-primary ubuntuMedium"
        :class="{ selected: selectedCategory === nav.label, 'child-selected': nav.label === catWithChildSelected }"
        @click="onCategorySelection(nav.label)"
      >
        {{nav.label}}
      </span>
      <span class="categorie-sub-title ubuntuRegular" :class="{ selected: selectedCategory === cat.id}" @click="onCategorySelection(cat.id)"
            v-for="cat in nav.categories" :key="cat.id">
        {{$te(`products.filters.${cat.id}`) ? $t(`products.filters.${cat.id}`) : cat.title}}
      </span>
    </div>
  </nav>
  <div v-else>
    <AriDropdown showAll showAllLabel="Tous les produits" :datas="generateFlatLeftMenu()" valueKey="id" labelKey="title" @update:modelValue="onCategorySelection" :modelValue="selectedCategory" labelValue="id"/>
  </div>
</template>

<script>
import { sortGroup } from '@/utils/products';

export default {
  name: 'leftMenuShop',
  props: {
    selectedCategory: [Number, String],
    products: Array
  },
  data () {
    return {
      windowInnerWidth: window.innerWidth
    };
  },

  mounted () {
  },

  computed: {
    catWithChildSelected () {
      if (this.selectedCategory) {
        if (this.$CONSTANTS.CATEGORIES.ALL_SERVICES.includes(this.selectedCategory) || this.selectedCategory === this.$CONSTANTS.CATEGORIES.SERVICES) return this.$CONSTANTS.CATEGORIES.SERVICES;
        else return this.$CONSTANTS.CATEGORIES.PRODUCTS;
      } else return null;
    }
  },

  methods: {
    generateLeftMenu () {
      const menu = [
        { label: this.$CONSTANTS.CATEGORIES.SERVICES, categories: [] },
        { label: this.$CONSTANTS.CATEGORIES.PRODUCTS, categories: [] }
      ];
      this.products.forEach(p => {
        if (p.items?.length > 0) {
          if (this.$CONSTANTS.CATEGORIES.ALL_SERVICES.includes(p.id)) {
            menu[0].categories.push(p);
          } else {
            menu[1].categories.push(p);
          }
        }
      });
      sortGroup(menu[0].categories);
      return menu;
    },
    generateFlatLeftMenu () {
      const flatLeftMenu = [];
      this.generateLeftMenu().forEach(cat => {
        flatLeftMenu.push({ ...cat, id: cat.label, title: cat.label });
        cat.categories.forEach(product => flatLeftMenu.push(product));
      });
      return flatLeftMenu;
    },
    onCategorySelection (category) {
      const item = this.selectedCategory !== category ? category : null;
      this.$emit('update:selectedCategory', item);
    }
  }
};
</script>

<style lang="less" scoped>
.categorie-title {
  @apply text-primary cursor-pointer font-semibold;
  font-family: 'Ubuntu';
  font-style: normal;
  font-size: var(--ariane-font-size-2);
  &:first-child {
    @apply mt-default;
  }
}
.categorie-sub-title {
  @apply text-secondary cursor-pointer font-medium;
  font-family: 'Ubuntu';
  font-style: normal;
  font-size: var(--ariane-font-size-1);
}
.selected, .child-selected {
  opacity: 1;
  position: relative;
}
.selected:before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background: var(--ariane-primary-400);
  left: -10px;
  position: absolute;
}
</style>
