<template>
  <span>
    <ModalDomainAccountVerification
      :visibility="showDomainAccountVerificationModal"
      :purpose="$t('tunnel.domainAccountVerification.onlySocialSignIn', { email: emailUsed })"
      @redirectToSignUp="showDomainAccountVerificationModal=false"
    />
    <el-row :gutter="12" class="justify-center el-row--flex">
      <el-col
        :xs="20"
        :sm="16"
        :md="16"
        :lg="14"
        :xl="12">
        <div class="or-container">
          <div class="text">
            <slot name="alternativeLabel"></slot>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="12" class="justify-center el-row--flex">
      <el-col
        :xs="20"
        :sm="16"
        :md="16"
        :lg="14"
        :xl="12">
        <div class="flex buttons justify-between" id="social-buttons-container">
          <ariButton class="flex w-full" content="Facebook" useCustomBgColor :style="{'--custom-bg-color': '#4362AD' }"
                     filled preIcon="fab fa-facebook" @click="onSocialConnection('facebook')" />
          <ariButton class="flex w-full" useCustomBgColor :style="{ '--custom-bg-color': '#DA532C' }"
                     content="Google" preIcon="fab fa-google" filled @click="onSocialConnection('google')" />
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="12" class="justify-center el-row--flex">
      <el-col
        :xs="20"
        :sm="16"
        :md="16"
        :lg="14"
        :xl="12">
        <div class="or-container">
          <div class="text">
            {{ $t('authenticate.orDownloadApps') }}
          </div>
        </div>
        <MobileApps :smallFormat="true"/>
        </el-col>
    </el-row>
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalDomainAccountVerification from './ModalDomainAccountVerification.vue';
import AuthControl from '../mixins/authControl.js';
import { signInWithFirebaseFacebook, signInWithFirebaseGoogle } from '@/services/vendor/firebase';
import MobileApps from "@/modules/common/MobileApps.vue";

export default {
  name: 'SocialConnection',
  mixins: [AuthControl],
  components: {
    ModalDomainAccountVerification,
    MobileApps
  },
  data () {
    return {
      showDomainAccountVerificationModal: false,
      emailUsed: '',
      signInType: ''
    };
  },
  computed: {
    ...mapGetters('user', ['loggedInUser'])
  },
  methods: {
    ...mapActions('user', ['signInWithGoogle', 'signInWithFacebook']),
    async onSocialConnection (signInType) {
      try {
        this.signInType = signInType;
        await this.preAuth();
        await this.socialConnection();
        await this.postAuth();
        if (!this.loggedInUser) {
          this.showDomainAccountVerificationModal = true;
        }
      } catch (error) {
        this.$information.capture({
          error,
          inform: true,
          component: 'SocialConnection',
          method: 'onSocialConnection',
          tags: {
            signInType: this.signInType
          }
        });
        this.$information.alert({
          message: this.$t('authenticate.pleaseCheckCredentials')
        });
      }
    },
    async socialConnection () {
      if (this.signInType === 'facebook') {
        const signInResult = await signInWithFirebaseFacebook();
        signInResult?.accessToken && await this.signInWithFacebook(signInResult.accessToken);
        this.emailUsed = signInResult?.email;
      } else if (this.signInType === 'google') {
        const signInResult = await signInWithFirebaseGoogle();
        signInResult?.accessToken && await this.signInWithGoogle(signInResult.accessToken);
        this.emailUsed = signInResult?.email;
      }
    }
  }
};
</script>

<style lang="less">
#app {
  #social-buttons-container{
    margin-top:20px;
    flex: 1;
    .el-button { margin: 0; }
    .button-icon {
      margin-left: -10px;
      padding-right: 10px;
      color: #fff !important;
      font-size: 1.15em;
    }
  }
}
@media (max-width:500px){
  #app {
    #social-buttons-container{
      flex-direction: column;
      .el-button {
        width: 100%;
        margin-left: inherit;
      }
    }
  }
}
</style>
