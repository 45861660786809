<template>
  <div class="flex justify-center items-center flex-col gap-sm container-voucher">
    <span class="text-code-popover">{{$t('giftCard.promoCode')}}</span>
    <AriInput center :placeholder="$t('paymentMode.GIFT_CARD')"
        class="min-width-175"
        v-model="giftCardCode" ref="inputGiftCard"
        input
        @update:modelValue="checkIfCodeIsValid()"
        :formatter="[{ fx: specificLength, payload: 8 }]"
    />
    <span class="text-secondary text-xs" v-if="giftCardValue && !giftCardState">Avec ce code vous recevrez :</span>
    <span class="text-primary font-medium text-2xl" v-if="giftCardValue && giftCardValue.type !== 'abo' && !giftCardState">{{giftCardValue.value}} {{ currency }}</span>
    <span class="text-primary font-medium text-2xl" v-if="giftCardValue && giftCardValue.type === 'abo' && !giftCardState">{{giftCardValue.title}}</span>
    <AriButton text content="Activer le code" @click="useGiftCard()" v-if="giftCardValue && !giftCardState" />
    <div class="floating-icon" v-if="giftCardState" :class="giftCardState === 'ENABLED' ? 'bg-valid' : 'bg-error'">
      <i class="fas fa-check" v-if="giftCardState === 'ENABLED'" />
      <i class="fas fa-times" v-if="['ALREADY-ACTIVATED', 'CANCELLED'].includes(giftCardState)"/>
    </div>

    <div class="text-xs text-center" v-if="giftCardState === 'ENABLED' && giftCardValue.type !== 'abo'">
      <span>{{$t('giftCard.creditedWith')}} {{giftCardValue.value}}</span>
      <span class="font-medium">{{currency}}</span>
    </div>
    <div class="text-center text-xs" v-if="giftCardState === 'ENABLED' && giftCardValue.type === 'abo'">
      <span>{{$t('giftCard.creditedWith')}} {{giftCardValue.title}}</span>
    </div>
    <span v-if="giftCardState === 'ALREADY-ACTIVATED'" class="text-secondary text-xs text-center">{{$t('giftCard.alreadyActivated')}}</span>
    <span v-if="giftCardState === 'CANCELLED'" class="text-secondary text-xs text-center">{{$t('giftCard.cancelled')}}</span>
  </div>
</template>
<script>
import { specificLength } from '@/utils/inputFormatters';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'VouchersHandler',
  data () {
    return {
      giftCardCode: null,
      giftCardState: null,
      giftCardValue: null
    };
  },
  computed: {
    ...mapGetters('config', ['currency', 'selectedZone'])
  },
  methods: {
    ...mapActions('user', ['loadUserBalance']),
    specificLength,
    useGiftCard () {
      this.$services.HTTP.vouchers.activateGiftCard(this.giftCardCode).then(res => {
        this.loadUserBalance().finally(() => {
          this.giftCardState = 'ENABLED';
        });
      });
    },
    checkIfCodeIsValid () {
      setTimeout(() => {
        this.giftCardState = null;
        this.giftCardValue = null;
        if (!this.$refs.inputGiftCard.isError) {
          this.$services.HTTP.vouchers.getGiftCardContents(this.giftCardCode).then(res => {
            this.giftCardValue = { value: res.data.value, type: res.data.type, title: res.data.title };
          }).catch(err => {
            const errmsg = err.response?.data?.message;
            if (errmsg === 'voucher-not-found-for-code') this.$refs.inputGiftCard.inputError = this.$t('giftCard.notFound');
            else if (errmsg === 'voucher-already-activated') this.giftCardState = 'ALREADY-ACTIVATED';
            else if (errmsg === 'voucher-from-another-zone') this.$refs.inputGiftCard.inputError = this.$t('giftCard.fromAnotherZoneErr');
            else if (errmsg.includes('cancelled')) this.giftCardState = 'CANCELLED';
            else this.$refs.inputGiftCard.inputError = this.$t('calendar.errors.GENERAL_ERROR');
          });
        }
      }, 50);
    }
  }
};
</script>
<style scoped>
.fas {
  color: #FFF;
}
.floating-icon {
  width: fit-content;
  @apply p-xs;
  border-radius: 50%;
}
.text-code-popover {
  @apply text-primary font-medium;
}
.min-width-175 {
  min-width: 250px;
}
</style>
