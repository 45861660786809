<template>
  <el-row class="product-purchase">
    <div
      class="product-purchase__action-container action-container"
    >
      <AriButton :content="$t('close')" text @click="$emit('on-cancel')" />
      <div
        v-if="showSaveWalletButton"
        class="product-purchase__action"
      >
        <p class="product-purchase__wallet-required">
          {{ $t("products.registerWallet") }}
        </p>
        <AriButton
          :content="manageWalletLabel"
          outlined
          :disabled="isRedirecting"
          @click="onClickManageWallet()"
        />
      </div>
      <div
        class="product-purchase__action"
        v-else
      >
        <AriButton
          v-if="hasZoneTos"
          :content="$t('products.buy')"
          :disabled="disabled"
          outlined
          @click="onBuyProduct"
        />
      </div>
    </div>
  </el-row>
</template>

<script>

export default {
  name: 'ProductPurchaseConfirm',

  props: {
    hasZoneTos: Boolean,
    showSaveWalletButton: Boolean,
    manageWalletLabel: String,
    isRedirecting: Boolean,
    disabled: Boolean
  },

  mounted () {
  },

  data () {
    return {
    };
  },

  computed: {
  },

  methods: {
    onClickManageWallet () {
      this.$emit('on-manage-wallet');
    },
    onBuyProduct () {
      this.$emit('on-buy-product');
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../../assets/styles/vars.less';
.product-purchase__wallet-required{
  background: @background-light;
  color: @darkred;
}
.product-purchase__action-container.action-container {
  display: flex;
  width: fit-content;
  margin: auto;
}
</style>
