import { initializeApp } from 'firebase/app';
import { getAuth, FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { capture, alert } from '@/utils/information';
import i18n from '@/services/translation/i18n';
const { t } = i18n.global;

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyDfdpz4u33xgSyVNThQFY36KEydhxVUiOs',
  authDomain: 'deciplus-mobile-app.firebaseapp.com',
  databaseURL: 'https://deciplus-mobile-app.firebaseio.com',
  projectId: 'deciplus-mobile-app',
  storageBucket: 'deciplus-mobile-app.appspot.com',
  messagingSenderId: '505797439187',
  appId: '1:505797439187:web:19caea1044091919eeae26',
  measurementId: 'G-40ZBDGV06S'
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

const signInWithFirebaseFacebook = async () => {
  try {
    const provider = new FacebookAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const result = await signInWithPopup(auth, provider);

    const credential = FacebookAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;
    const email = result.additionalUserInfo?.profile?.email;

    if (!accessToken) {
      throw new Error('no access token in ' + JSON.stringify(Object.keys(result.credential)));
    }
    return {
      accessToken,
      email
    };
  } catch (error) {
    handleFirebaseErrors('signInWithFirebaseFacebook', error);
  }
};

const signInWithFirebaseGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ auth_type: 'reauthenticate' });
    const result = await signInWithPopup(auth, provider);

    const credential = GoogleAuthProvider.credentialFromResult(result);
    const accessToken = credential.idToken;
    const email = result.additionalUserInfo?.profile?.email;
    if (!accessToken) {
      throw new Error('no access token in ' + JSON.stringify(Object.keys(result.credential)));
    }
    return {
      accessToken,
      email
    };
  } catch (error) {
    handleFirebaseErrors('signInWithFirebaseGoogle', error);
  }
};

const handleFirebaseErrors = (originMethod, error) => {
  const errorMappings = {
    'auth/account-exists-with-different-credential': t('firebase.errors.account-exists-with-different-credential'),
    'auth/popup-blocked': t('firebase.errors.auth/popup-blocked')
  };
  const ignoredError = ['auth/cancelled-popup-request', 'auth/popup-closed-by-user'];
  if (ignoredError.includes(error.code)) return;
  if (errorMappings[error.code]) {
    alert({
      message: errorMappings[error.code],
      duration: 10000
    });
  } else {
    alert({
      message: error.message
    });
    capture({
      error,
      inform: true,
      component: 'firebase',
      method: originMethod
    });
  }
};

export {
  signInWithFirebaseFacebook,
  signInWithFirebaseGoogle
};
