<template>
  <div
    class="go-back"
    tabindex="0"
    @keypress="onGoBack"
    @click="onGoBack"
  >
    <i class="fas fa-chevron-left"></i>
    <span>{{ $t('back') }}</span>
  </div>
</template>

<script>
export default {
  name: 'GoBack',

  props: {
    goBack: {
      type: Function
    }
  },

  methods: {
    onGoBack () {
      if (typeof this.goBack === 'function') {
        return this.goBack();
      }
      return this.$router.go(-1);
    }
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';

.go-back{
  padding: 0.5rem;
  padding-bottom: 1rem;
  cursor: pointer;
  color: @main-color;
  line-height: 1.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  .fa-chevron-left{
    font-size: 1rem;
    color: inherit;
  }

}
</style>
