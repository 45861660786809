<template>
  <div class="h-full calendar-page">
    <div class="bg-white py-default px-default justify-between flex items-center gap-default" v-if="!isCustomMenuInHeader">
      <div class="tabs-custom-menus" v-if="computedTabs">
        <AriTabs  @update:modelValue="clickOnCustomMenu($event)" :modelValue="currentTab" column iconKey="filters.icon"
                idKey="name" labelKey="name" :tabs="computedTabs"  />
      </div>
      <div v-else></div>
      <FilterBarCollapsed/>
    </div>
    <el-row :gutter="20" class="page-content">
      <Calendar />
    </el-row>
    <modal-share></modal-share>
  </div>
</template>

<script>
import Calendar from './components/Calendar.vue';
import CalendarControl from './mixins/calendarControl.js';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import ModalShare from '@/core/components/modalShare.vue';
import mixinCustomMenu from '@/modules/common/Header/mixin.customMenu.vue';
import FilterBarCollapsed from './components/FilterCollapsed.vue';
export default {
  name: 'CalendarPage',
  components: {
    Calendar,
    ModalShare,
    FilterBarCollapsed
  },
  data () {
    return {
      currentTab: null
    };
  },
  mixins: [CalendarControl, mixinCustomMenu],
  beforeRouteLeave (to, from, next) {
    if (!['signIn', 'timeslotDetails'].includes(to.name)) {
      this.saveTimeslotForDetailsPage({});
    }
    next();
  },
  mounted () {
    if (this.loggedInUser) {
      this.loadUpcomingUserBookings();
    }
    if (!this.computedTabs) this.$router.replace( { query: null } );
    window.scroll(0, 0);
  },

  computed: {
    ...mapGetters('user', ['loggedInUser']),
    ...mapGetters('config', ['customMenus', 'isCustomMenuInHeader']),
    ...mapState('config', ['tunnelConfigurations']),
    computedTabs () {
      if (this.customMenus?.length) {
        const tabsWithAll = [...this.customMenus];
        tabsWithAll.unshift({ filters: { icon: 'fal fa-grid-4' }, name: this.$t('app.all')});
        return tabsWithAll;
      } else return null;
    }
  },

  methods: {
    ...mapMutations('config', ['SET_TUNNEL_CONFIGURATIONS']),
    ...mapActions('user', ['loadUpcomingUserBookings', 'saveTimeslotForDetailsPage']),
    clickOnCustomMenu (newTab) {
      this.currentTab = newTab;
      const tunnelCMname = this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.CUSTOM_MENU];
      if (tunnelCMname) {
        this.useCustomMenu(this.customMenus.find(cm => cm.name === tunnelCMname));
        this.currentTab = tunnelCMname;
        this.SET_TUNNEL_CONFIGURATIONS({});
      } else if (this.$route.query.fromDiscovery === 'true' || Object.keys(this.tunnelConfigurations).length) {
        this.$router.replace( { query: null } );
        this.SET_TUNNEL_CONFIGURATIONS({});
      } else if (newTab === this.$t('app.all')) {
        this.useCustomMenu(null);
      } else {
        this.useCustomMenu(this.customMenus.find(cm => cm.name === newTab));
      }
    }
  }
};
</script>

<style lang="less">
@import '../../assets/styles/vars.less';
@import '../../assets/styles/mixins.less';
#app{
  .el-container{
    .el-icon-caret-top{
      color: var(--ariane-primary-400);
    }
  }
}
.tabs-custom-menus {
  width: 0px;
  @apply grow overflow-auto;
}
.bg-white {
  background-color: #FFF;
}
.calendar-page .ari-tab-icon {
  font-size: 20px;
}
.timeslot__name{
  color:var(--ariane-primary-400);
  filter: brightness(90%);
}

@media (max-width:1700px){
  #app{
    .el-container{
      .timeslot{
        &.slot--available{
          .slot__availability{
            width:100% !important;
          }
        }
      }
    }
  }
}

</style>
