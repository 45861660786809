<template>
  <div class="whitespace-nowrap flex justify-between gap-xs items-center" :class="{ 'line-through opacity-70 text-secondary': beforePromotion }">
    <div v-if="askForPaymentType">{{ $t('cart.selectPayment') }}</div>
    <div v-else-if="totalPrice" class="container-price flex-wrap">
      <div class="price-tag no-wrap flex whitespace-nowrap" :class="{ 'price-tag': bigPrice}">
        <span >{{ priceWithQuantity }} </span>
        <span>{{ currency }}</span>
        <span v-if="period && !cashPayment" class="period-tag">&nbsp;/ {{schedulePeriodUnit > 1 ? schedulePeriodUnit : ''}} {{period }}</span>
      </div>
      <div class="whitespace-nowrap gap-xxs flex items-baseline">
        <template v-if="countDifferentSchedules">
          <span class="options-tag items-center flex"> {{$t('app.during')}} </span>
          <div class="flex gap-xxs container-duration">
            <span>{{countDifferentSchedules * schedulePeriodUnit}} </span>
            <span> {{differentSchedulePeriod}}</span>
          </div>
        </template>
        <div class="container-price" v-if="hasSchedulerDiscount">
          <span class="options-tag">{{$t('app.then')}}</span>
          <div class="flex items-baseline">
            <span class="price-tag_alt">{{scheduler[scheduler.length - 1].price}}</span>
            <span class="price-tag_alt">{{currency}}</span>
            <span class="period-tag">&nbsp;/ {{schedulePeriodUnit > 1 ? schedulePeriodUnit : ''}}  {{period}}</span>
          </div>
        </div>
      </div>

    </div>
    <span v-else class="is-uppercase">{{ $t('products.free') || upperCased }}</span>
    <div v-if="totalPrice && prepaidCardSaleMode && !beforePromotion && credits && configSaleMode === this.$CONSTANTS.PRODUCT_PRICING_FORMATS.TOTAL_AND_UNIT_PRICE" class="flex text-secondary font-medium text-xs gap-xxs">
      <span class="price-tag">{{pricePerCredit}} {{currency}}</span>
      <span class="font-light">{{$t('app.per')}}</span>
      <span>{{$tc('credits')}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'product-price',
  props: {
    beforePromotion: Boolean,
    bigPrice: Boolean,
    quantity: [Number, String],
    cashPayment: [Boolean, null],
    scheduler: Array,
    period: String,
    currency: String,
    credits: Number,
    totalPrice: [Number, String],
    schedulePeriodType: String,
    schedulePeriodUnit: Number,
    configSaleMode: String
  },
  computed: {
    ...mapGetters('config', ['prepaidCardSaleMode']),
    ...mapState('config', ['memberAppConfigs']),
    pricePerCredit () {
      const price = this.totalPrice / (this.credits || 1);
      return price.toFixed(2).replace('.00', '');
    },
    askForPaymentType () {
      return this.cashPayment === null && this.scheduler?.length > 0 && !this.memberAppConfigs?.onlyScheduledPaymentsOnline;
    },
    priceWithQuantity () {
      if (this.cashPayment || !this.scheduler) {
        return (this.totalPrice * this.quantity).toFixed(2).toString().replace(/\.00$/, '');
      } else return this.scheduler[0].price;
    },
    countDifferentSchedules () {
      // On utilise pas ce que le node renvoit comme ça on gère aussi les cas ou les prix de chaques echeances sont différents sans promo( ex: 100/3 = 33.34 pour la première puis 33.33)
      if (!this.scheduler || this.cashPayment) return 0;
      let count = 0;
      const firstSchedulePrice = this.scheduler[0].price;
      this.scheduler.forEach(schedule => {
        // On compare le prix de la première échéance avec le prix de l'échéance actuelle
        // Si la différence de prix est inférieure à 0.25 il s'agit de l'ajustement fait par l'api et on considére que les échéances sont au même prix
        if (Math.abs(schedule.price - firstSchedulePrice) < 0.25) count++;
      });
      return count;
    },
    differentSchedulePeriod () {
      return this.$tc('products.durations.' + this.schedulePeriodType, this.countDifferentSchedules);
    },
    hasSchedulerDiscount () {
      if (this.cashPayment || !this.scheduler) return false;
      else {
        const isRoundedPriceCase = Math.abs(this.scheduler[this.scheduler.length - 1].price - this.scheduler[0].price) < 0.25;
        return !isRoundedPriceCase && this.scheduler[0].price !== this.scheduler[this.scheduler.length - 1].price;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.period-tag {
  @apply text-xs font-semibold whitespace-nowrap text-primary;
}
.no-wrap {
  white-space: nowrap
}
.price-tag {
  @apply text-xl text-primary font-medium items-baseline;
}
.price-tag_alt {
  @apply text-sm text-primary font-medium items-baseline;

}
.options-tag {
  @apply text-secondary font-light text-xs items-baseline;
}
.container-duration {
  @apply text-primary text-xs font-bold;
}
.container-price {
  @apply flex items-baseline whitespace-nowrap;
  gap: 2px;
}
</style>
