import {
  memberAPI
} from '../request';

export default {
  addProductToCart: async purchaseDetails => {
    const { data } = await memberAPI.post('cart/current', purchaseDetails);
    return data.cartId;
  },
  doImmediateWalletPayment: async (cartId, walletId, paymentCardId, useCredit) => {
    return memberAPI.post('cart/current/checkout/immediate', {
      cartId,
      walletId,
      paymentCardId,
      useCredit
    });
  },
  processCart: async (cartId, returnURL, useCredit) => {
    const { data } = await memberAPI.post('cart/current/checkout', {
      cartId,
      returnURL,
      useCredit
    });
    return data.paymentURL || data.result?.shortMessage;
  },
  checkStocks (products) {
    return memberAPI.post('/cart/current/stock', { products });
  }
};
