<template>
  <div class="text-input">
    <el-input :type="type" :show-password="showPassword" :disabled="disabled" v-model="bindedValue" />
  </div>
</template>

<script>
export default {
  name: 'TextInput',

  props: {
    type: String,
    modelValue: String,
    showPassword: Boolean,
    disabled: Boolean
  },

  computed: {
    bindedValue: {
      get () {
        return this.modelValue;
      },
      set (input) {
        this.$emit('update:modelValue', input);
      }
    }
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.text-input{
  .el-input{
    .el-input__inner{
      &:focus{
        .box-shadow-light();
      }
    }
  }
}
</style>
