<template>
    <el-col :span="24">
      <div class="grid-content">
        <div class="gap-sm flex flex-col">
          <CalendarWeekNav :isPrevDisabled="isPrevDisabled" :next="next" :prev="prev" startIndicator=""
                           :dayNumber="getCurrentWeek.dayNumber" :month="getCurrentWeek.month"/>
        </div>
        <el-row :gutter="12">
          <el-col :span="24">
            <div class="calendar" v-if="hasAvailableZone">
              <div class="calendar__calendar-main" :class="{ 'calendar__calendar-main--tight': showTimeslotDetails }" >
                <div class="calendar__header">
                  <nav :isPrevDisabled="isPrevDisabled" :next="next" :prev="prev"
                    :startIndicator="startIndicator" />
                  <mq-responsive target="lg+">
                    <calendarHeaderMainGrid :calendarStartDate="selectedDate" />
                  </mq-responsive>
                </div>
                <div v-if="isCalendarEmpty.value" class="text-center calendar__no-results-container flex flex-col justify-content-center">
                  <span>{{ $t('calendar.noTimeslotFound', {zoneName: selectedZone.clubName}) }}</span>
                  <div v-if="nextSession" class="flex justify-center mt-md">
                    <AriButton @click="goNextTimeSLot" rounded filled :content="buttonNextSlotContent" />
                  </div>
                </div>
                <calendarMainGrid v-if="deviceFormat && allEvents" :allEvents="allEvents"/>
              </div>
            </div><template v-else>
              <centerNotVisibleOnline :note="$t('alert.bookingAllowedOnOtherSite')" />
            </template>
          </el-col>
        </el-row>
      </div>
    </el-col>
</template>

<script>
import CalendarMainGrid from './CalendarMainGrid.vue';
import CalendarWeekNav from './CalendarWeekNav.vue';
import CalendarHeaderMainGrid from './CalendarHeaderMainGrid.vue';
import FilterBarCalendar from './FilterBarCalendar.vue';
import CalendarControl from '../mixins/calendarControl.js';
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';

import { mapState, mapGetters, mapMutations } from 'vuex';
import { isObjectNotEmpty } from '@/utils/dataType.js';
import CenterNotVisibleOnline from '@/core/components/centerNotVisibleOnline.vue';
import { prepareApiSession } from '@/utils/bookingSessions';

export default {
  name: 'Calendar',

  components: {
    CalendarMainGrid,
    CalendarWeekNav,
    CalendarHeaderMainGrid,
    FilterBarCalendar,
    DeciTitle,
    CenterNotVisibleOnline
  },

  mixins: [CalendarControl, ProductsControl],

  data () {
    return {
      showAlertZone: true,
      nextSession: null
    };
  },
  inject: ['mq'],
  mounted () {
    this.rearrangeUrlAfterTransaction();
    this.reactToTransactionResponse();
  },
  computed: {
    ...mapState('zone', ['isCalendarEmpty']),
    ...mapState('user', ['language', 'deviceFormat', 'timeslotForDetailsPage']),
    ...mapState('domain', ['appState']),
    ...mapGetters('config', ['customMenus', 'isCustomMenuInHeader', 'selectedZone', 'selectedDate', 'selectedCalendarMode', 'selectedActivities', 'selectedResources', 'selectedCoaches']),
    ...mapGetters('zone', ['getResourceAvailabilities', 'getResourceAvailabilitiesByDay', 'getSessions', 'getSessionsByDay']),
    ...mapGetters('user', ['loggedInUser']),
    isPrevDisabled () {
      return this.$datetime.isOlderThanCurrentTime(this.selectedDate);
    },
    hasAvailableZone () {
      return this.selectedZone != null;
    },
    buttonNextSlotContent () {
      return `${this.$t('calendar.goNextTimeSlot')}: ${this.$datetime.getLocalNumericDate(new Date(this.nextSession.originalDate))}`;
    },
    newWeekMultiplier () {
      const dayToMilt = {
        0: 1,
        1: 7,
        2: 6,
        3: 5,
        4: 4,
        5: 3,
        6: 2
      };

      const dayOfTheWeek = this.$datetime.getNumericDayOfWeek(this.selectedDate);

      return dayToMilt[dayOfTheWeek];
    },
    getCurrentWeek () {
      const newDate = new Date(this.selectedDate);
      return { month: newDate.getMonth(), dayNumber: newDate.getDate() };
    },
    startIndicator () {
      if (this.selectedDate) {
        return `${this.$t('calendar.startdate')} ${this.$datetime.getLocalFullDate(this.selectedDate)}`;
      }
      return '';
    },
    allEvents () {
      if (this.deviceFormat === this.$CONSTANTS.DEVICE_FORMATS.WEB) {
        return this.allEventsByDaySection;
      } else {
        return this.allEventsByDateRange;
      }
    },
    allEventsByDaySection () {
      if (this.selectedCalendarMode === this.$CONSTANTS.CALENDAR_MODES.SESSION) {
        return this.getSessions;
      } else {
        return this.getResourceAvailabilities;
      }
    },
    allEventsByDateRange () {
      if (this.selectedCalendarMode === this.$CONSTANTS.CALENDAR_MODES.SESSION) {
        return this.getSessionsByDay;
      } else {
        return this.getResourceAvailabilitiesByDay;
      }
    },
    showSearch () {
      return this.mq.lgPlus;
    },
    isOnMobile () {
      return this.deviceFormat === this.$CONSTANTS.DEVICE_FORMATS.MOBILE;
    },
    showTimeslotDetails () {
      return this.mq.lgPlus && isObjectNotEmpty(this.timeslotForDetailsPage) && false;
    },
    currentTimeslotId () {
      return this.timeslotForDetailsPage.id || Math.random();
    },
    getNextSession () {
      if (this.loggedInUser) {
        return this.$services.HTTP.zone.getNextSessionFromMemberAPi;
      } else {
        return this.$services.HTTP.zone.getNextSessionFromPublicAPi;
      }
    }
  },

  watch: {
    isCalendarEmpty ({ value }) {
      if (value) {
        this.getNextSessionAvailable();
      }
    }
  },

  methods: {
    ...mapMutations('config', ['SET_SELECTED_DATE']),
    async next () {
      this.calendarForm.startDate = this.$datetime.getNDaysLater(this.selectedDate, 7);
      this.SET_SELECTED_DATE(this.calendarForm.startDate);
      await this.getAllTimeslotsFromStore();
      window.scroll(0, 0);
    },
    async prev () {
      const prevWeekStartDate = this.$datetime.getNDaysEarlier(this.calendarForm.startDate, 7);
      if (this.$datetime.isTodayOrLater(prevWeekStartDate)) {
        this.calendarForm.startDate = prevWeekStartDate;
      } else {
        this.calendarForm.startDate = this.$datetime.getCurrentWeekStart();
      }
      this.SET_SELECTED_DATE(this.calendarForm.startDate);
      await this.getAllTimeslotsFromStore();
      window.scroll(0, 0);
    },
    redirectToMobileSearchPage () {
      this.$router.push({ name: 'mobileSearch' });
    },
    async getNextSessionAvailable () {
      this.nextSession = null;
      if (this.selectedCalendarMode === this.$CONSTANTS.CALENDAR_MODES.SESSION) {
        const from = this.$datetime.toDefaultDateFormat(this.selectedDate);
        try {
          let { data } = await this.getNextSession(
            from,
            this.selectedZone.id,
            this.selectedActivities,
            this.selectedResources,
            this.selectedCoaches
          );
          if (Array.isArray(data)) data = data[0];
          if (data?.startDate) {
            this.nextSession = prepareApiSession(data);
          }
        } catch (e) {
          this.$information.alert({
            message: this.$t('calendar.timeslot.nextTimeSlotNotAvailable')
          });
        }
      }
    },
    async goNextTimeSLot () {
      if (this.nextSession) {
        const firstDayOfWeekNextBooking = this.$datetime.getWeekStart(new Date(this.nextSession.originalDate));
        this.SET_SELECTED_DATE(firstDayOfWeekNextBooking);
        await this.getAllTimeslotsFromStore();
        window.scroll(0, 0);
      }
    }
  }
};

</script>

<style lang="less">
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';

#app{
  .calendar__title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fa-filter{
      font-size: @bigger-text-size;
      color: var(--ariane-primary-400);
      padding: 0.5rem;
      .full-rounded();
      &:hover{
        background: var(--ariane-primary-400);
        color: white;
      }
    }
  }
  .calendar{
    width: 100%;
    display: flex;
    .calendar__calendar-main{
      width: 100%;
      &.calendar__calendar-main--tight{
        width: 85%;
      }
    }
    .calendar__main{
        padding-top:40px;
        &.calendar__main-first{
          padding-top:0;
        }
        &:last-of-type{
          padding-bottom:40px;
        }
    }
  }
  .calendar{
    .calendar__no-results-container{
      display:table;
      width:100%;
      padding-top: 3rem;
    }
  }
}
@media (min-width:1280px){
  .close-mobile-filters{
    display: none;
  }
}
</style>
