 <template>
  <el-row :gutter="24" class="page-content">
    <el-col class="grid-content">
      <CenterNotVisibleOnline />
      <div v-if="isMyVideosShown">
        <ScrollingVideoList
          :title="$t('myVideoLibrary.title')"
          :showGoBackButton="isGoBackButtonShown"
          :showVideos="isMyVideosShown"
          :videos="myVideos"
          @on-infinite-scroll-trigger="infiniteHandler"
        />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import ScrollingVideoList from '@/modules/video/components/ScrollingVideoList.vue';
import CenterNotVisibleOnline from '@/core/components/centerNotVisibleOnline.vue';

export default {
  name: 'MyVideoLibraryPage',

  components: {
    ScrollingVideoList,
    CenterNotVisibleOnline
  },

  mounted () {
    this.myVideoLibraryPageOpened({
      showGoBackButton: this.showGoBackButton,
      showMyVideosAsInfiniteScroll: this.showMyVideosSection
    });
    window.scroll(0, 0);
  },

  data () {
    return {
      isGoBackButtonShown: false,
      isMyVideosShown: false,
      myVideosPage: 1,
      myVideos: []
    };
  },

  methods: {
    showGoBackButton () {
      this.isGoBackButtonShown = true;
    },
    myVideoLibraryPageOpened ({ showGoBackButton, showMyVideosAsInfiniteScroll }) {
      showGoBackButton();
      showMyVideosAsInfiniteScroll();
    },
    infiniteMyVideosListScrolled ({ getMoreMemberVideos, filters, extractData, showMyVideosSection, continueInfiniteScroll, stopInfiniteScroll, handleError }) {
      getMoreMemberVideos(filters).then(response => {
        const newData = extractData(response);
        showMyVideosSection();
        if (newData.length) {
          continueInfiniteScroll(newData);
        } else {
          stopInfiniteScroll();
        }
      }).catch(error => {
        stopInfiniteScroll();
        handleError(error);
      });
    },
    infiniteHandler ($state) {
      this.infiniteMyVideosListScrolled({
        getMoreMemberVideos: this.$services.HTTP.videos.getMyVideoLibrary,
        extractData: this.extractVideoData,
        filters: { pageFilter: this.myVideosPage },
        showMyVideosSection: this.showMyVideosSection,
        continueInfiniteScroll: this.continueMyVideosScroll($state),
        stopInfiniteScroll: this.stopMyVideosScroll($state),
        handleError: this.handleVideoLibraryLoadingError
      });
    },
    extractVideoData (response) {
      return response.data.rows;
    },
    showMyVideosSection () {
      this.isMyVideosShown = true;
    },
    continueMyVideosScroll ($state) {
      return newData => {
        this.myVideosPage += 1;
        this.myVideos.push(...newData);
        $state.loaded();
      };
    },
    stopMyVideosScroll ($state) {
      return () => {
        $state.complete();
      };
    },
    handleVideoLibraryLoadingError (error) {
      this.$information.capture({ error });
    }
  }
};
</script>

<style lang="less">
</style>
