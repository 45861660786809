import { mapActions, mapState } from 'vuex';
import { phone, required, mail } from '@/utils/inputFormatters';
export default {
  data () {
    return {
      isEditFormShown: false,
      editForm: {
        title: '',
        label: '',
        currentValue: '',
        action: null
      },
      isBirthdateFormShown: false,
      birthdateForm: {
        value: null
      }
    };
  },
  computed: {
    ...mapState('user', ['userDomainInfo']),
    ...mapState('config', ['memberAppConfigs']),
  },
  methods: {
    ...mapActions('user', ['loadDomainUserInfo']),
    showEditForm ({
      title, label, item, formatter
    }) {
      this.editForm.formatter = formatter;
      this.editForm.title = title;
      this.editForm.label = label;
      this.editForm.currentValue = this.userDomainInfo[item];
      this.editForm.action = this.modifyPersonalDetail(item);
      this.isEditFormShown = true;
    },
    modifyPersonalDetail (personalDetailToModify) {
      return async () => {
        try {
          const isFormValid = await this.$refs.editForm.validateForm();
          if (!isFormValid) {
            throw new Error();
          }
          const newDetail = {};
          newDetail[personalDetailToModify] = this.editForm.currentValue;
          await this.$services.HTTP.user.updateDomainUserInfo(newDetail);
          this.hideEditForm();
          this.showConfirmation();
        } catch (error) {
          this.$information.capture({ error, inform: true });
          this.$information.alert({
            message: this.$t('calendar.errors.GENERAL_ERROR')
          });
        }
      };
    },
    async hideEditForm () {
      this.isEditFormShown = false;
      this.loadDomainUserInfo();
    },
    showConfirmation () {
      this.$information.alert({
        message: this.$t('configurationDone'),
        type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
      });
    },
    onModifyCompany () {
      this.showEditForm({
        title: this.$t('profile.personalDetails.pleaseCompany'),
        label: this.memberAppConfigs.LDC_nommageEntreprise ? this.memberAppConfigs.LDC_nommageEntreprise : this.$t('company'),
        item: 'company',
      });
    },
    onModifyPhoneNumber () {
      this.showEditForm({
        title: this.$t('profile.personalDetails.pleaseInputPhone'),
        label: this.$t('cellphoneNumber'),
        item: 'cellphoneNumber',
        formatter: [phone, required]
      });
    },
    onModifyEmail () {
      this.showEditForm({
        title: this.$t('authenticate.pleaseInputEmail'),
        label: this.$t('email'),
        item: 'email',
        formatter: [required, mail]
      });
    },
    onModifyCity () {
      this.showEditForm({
        title: this.$t('profile.personalDetails.pleaseInputCity'),
        label: this.$t('city'),
        item: 'city'
      });
    },
    onModifyPostalCode () {
      this.showEditForm({
        title: this.$t('profile.personalDetails.pleaseInputPostalCode'),
        label: this.$t('postalCode'),
        item: 'postalCode'
      });
    },
    onModifyAdr1 () {
      this.showEditForm({
        title: this.$t('profile.personalDetails.pleaseInputAddress'),
        label: this.$t('adr1'),
        item: 'adr1'
      });
    },
    onModifyAdr2 () {
      this.showEditForm({
        title: this.$t('profile.personalDetails.pleaseInputAddress'),
        label: this.$t('adr2'),
        item: 'adr2'
      });
    },
    onModifyAdr3 () {
      this.showEditForm({
        title: this.$t('profile.personalDetails.pleaseInputAddress'),
        label: this.$t('adr3'),
        item: 'adr3'
      });
    },
    // special birthdate handling
    onModifyBirthdate () {
      this.isBirthdateFormShown = true;
    },
    trackBirthdate (newDate) {
      this.birthdateForm.value = newDate;
    },
    async modifyBirthdate () {
      try {
        const isBirthdateValid = this.$refs.birthdateInput.validate();
        if (!isBirthdateValid) {
          throw new Error();
        }
        await this.$services.HTTP.user.updateDomainUserInfo({
          birthdate: this.birthdateForm.value
        });
        this.isBirthdateFormShown = false;
        this.loadDomainUserInfo();
        this.showConfirmation();
      } catch (error) {
        this.$information.capture({ error });
        this.$information.alert({
          message: this.$t('profile.personalDetails.error')
        });
      }
    }
  }
};
