 <template>
  <el-row :gutter="24" class="page-content">
    <el-col class="grid-content" style="padding: 0px">
      <div class="home-page__section">
        <MyBookings :myBookings="myBookingsPrepared"
                    @on-cancel-booking="onCancel"
                    @on-share-booking="onShareBooking"/>
      </div>
      <div class="home-page__section" v-if="hasWaitingListRegistrations">
        <MyWaitingLists :waitingListRegistrations="waitingListRegistrationsPrepared" @on-cancel-waiting-list="onCancel" />
      </div>
      <div class="home-page__section">
        <MyProducts :products="myProductsPrepared"/>
      </div>
      <div class="home-page__section" v-if="hasVideos">
        <DeciTitle :isLined="false" :text="$t('myVideoLibrary.title')" />
        <div class="home-page__video-library-access">
          <AriButton :content="$t('account.seeMyVideoLibrary')" filled rounded @click="redirectToMyVideoLibraryPage" />
        </div>
      </div>
    </el-col>
    <AriModal v-if="showConfirmationModal" :modalTitle="$t('account.cancelResa')" :showClose="false">
      <template v-slot:body>
        <p>{{ cancelBodyMessage }}</p>
        <p class="sanction-text">{{ sanctionTextMessage }}</p>
      </template>
      <template v-slot:footer>
        <div class="flex justify-between">
          <AriButton text :content="$t('noGoBack')" @click="closeConfirmationModal" />
          <AriButton filled :content="$t('yesCancel')" @click="cancel" />
        </div>
      </template>
    </AriModal>
    <modal-share />
  </el-row>
</template>

<script>
import MyProducts from './components/MyProducts.vue';
import MyBookings from './components/MyBookings.vue';
import MyWaitingLists from './components/MyWaitingLists.vue';
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import CalendarControl from '@/modules/calendar/mixins/calendarControl.js';
import { mapState, mapActions } from 'vuex';
import EventBus from '@/services/eventBus/eventBus';
import ModalShare from '@/core/components/modalShare.vue';
import DeciTitle from "@/modules/common/generic/DeciTitle.vue";

export default {
  mixins: [ProductsControl, CalendarControl],
  components: {
    DeciTitle,
    ModalShare,
    MyProducts,
    MyBookings,
    MyWaitingLists
  },
  mounted () {
    if (!this.rearrangeUrlAfterTransaction()) {
      this.reactToTransactionResponse();
      this.loadAllActivities({ allZone: true });
      this.loadUpcomingUserBookings();
      this.loadUserProducts();
      this.checkIfUserHasVideos();
      window.scroll(0, 0);
    };
  },
  data () {
    return {
      timeslotToCancel: null,
      showConfirmationModal: false,
      hasVideos: false,
      infoOpen: true
    };
  },
  computed: {
    ...mapState('user', ['bookings', 'waitingListRegistrations', 'myProducts']),
    ...mapState('domain', ['zones']),
    hasWaitingListRegistrations () {
      return this.waitingListRegistrations.length > 0;
    },
    myActiveProducts () {
      return this.myProducts.filter(product => {
        if (
          product.product.type === this.$CONSTANTS.PRODUCT_TYPES.PREPAID_CARD &&
            Number.parseFloat(product.remainingCredit) <= 0
        ) {
          return false;
        }
        return this.$datetime.isTodayOrLater(this.$datetime.fromISODate(product.dateEnd));
      });
    },
    myProductsPrepared () {
      const preparedProducts = [...this.myActiveProducts];
      preparedProducts.map(product => {
        product.expiration = this.prepareDate(product.dateEnd);
        product.associatedActivities = this.prepareAssociatedActivities(product.product.associatedActivities);
        product.remaining = this.prepareRemainingCredits(product.remainingCredit, product.product.type);
      });
      return preparedProducts;
    },
    myBookingsPrepared () {
      const preparedBookings = [...this.bookings];
      preparedBookings.map(booking => {
        booking.startDate = this.prepareDate(booking.booking.startDate);
        booking.startTime = this.prepareTime(booking.booking.startDate);
        booking.startDateAndTime = this.prepareBookingDateAndTime(booking.booking.startDate);
        booking.zoneName = this.prepareZoneName(booking.booking.resource.idz);
      });
      return preparedBookings;
    },
    waitingListRegistrationsPrepared () {
      const preparedWaitingListRegistrations = [...this.waitingListRegistrations];
      preparedWaitingListRegistrations.map(registration => {
        registration.startDate = this.prepareDate(registration.booking.startDate);
        registration.startTime = this.prepareTime(registration.booking.startDate);
        registration.startDateAndTime = this.prepareBookingDateAndTime(registration.booking.startDate);
        registration.zoneName = this.prepareZoneName(registration.booking.resource.idz);
      });
      return preparedWaitingListRegistrations;
    },
    cancelBodyMessage () {
      if (this.timeslotToCancel.bookingType === this.$CONSTANTS.BOOKING_TYPES.BOOKING) {
        return this.$t('account.cancelBookingBody', { date: this.timeslotToCancel.bookingDateAndTime });
      } else {
        return this.$t('account.cancelWaitingListBody', { date: this.timeslotToCancel.bookingDateAndTime });
      }
    },
    sanctionTextMessage () {
      let applySanction = false;
      if (this.timeslotToCancel.sanctionText && this.timeslotToCancel.cancelDateSanction) {
        applySanction = this.$datetime.isOlderThanCurrentTime(
          this.$datetime.fromISODate(this.timeslotToCancel.cancelDateSanction)
        );
      }
      return applySanction ? this.timeslotToCancel.sanctionText : '';
    }
  },
  methods: {
    ...mapActions('zone', ['loadAllActivities']),
    ...mapActions('user', ['loadUpcomingUserBookings', 'loadUserProducts']),
    prepareDate (rawDateTime) {
      return this.$datetime.getLocalMediumDate(
        this.$datetime.fromISODate(rawDateTime)
      );
    },
    prepareTime (rawDateTime) {
      return this.$datetime.getLocalTime(
        this.$datetime.fromISODate(rawDateTime)
      );
    },
    prepareAssociatedActivities (associatedActivities) {
      const activityNames = [];
      associatedActivities.map(activity => activityNames.push(activity.name));
      if (activityNames.length) {
        return activityNames.join(', ');
      }
      return this.$t('products.validForAllActivities');
    },
    prepareRemainingCredits (remainingCredit, productType) {
      if (productType === this.$CONSTANTS.PRODUCT_TYPES.SUBSCRIPTION) {
        return null;
      }
      return Number.parseFloat(remainingCredit).toFixed(0);
    },
    prepareZoneName (idz) {
      for (const zone of this.zones) {
        if (zone.id === idz) {
          return zone.clubName;
        }
      }
    },
    onCancel ({ cancelId, bookingDateAndTime, bookingType, sanctionText, cancelDateSanction }) {
      this.timeslotToCancel = { cancelId, bookingDateAndTime, bookingType, sanctionText, cancelDateSanction };
      this.showConfirmationModal = true;
    },
    async cancel () {
      await this.cancelBooking(this.timeslotToCancel);
      this.loadUserProducts();
      this.closeConfirmationModal();
    },
    async onShareBooking ({ bookingId }) {
      try {
        const { data } = await this.$services.HTTP.session.getShareLink(bookingId);
        if (data.link) {
          EventBus.$emit('share', { url: data.link });
        }
      } catch (error) {
        this.$information.capture({ error });
        this.$information.alert({
          showClose: true,
          message: this.$t('booking.share.error'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
        });
      }
    },
    closeConfirmationModal () {
      this.showConfirmationModal = false;
    },
    redirectToMyVideoLibraryPage () {
      this.$router.push({ name: 'myVideoLibrary' });
    },
    async checkIfUserHasVideos () {
      try {
        const { data } = await this.$services.HTTP.videos.getMyVideoLibrary();
        this.hasVideos = data.count > 0;
      } catch (error) {
        this.$information.capture({ error });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.my-account__cancel-modal{
  text-align: center;
  .sanction-text {
    @apply mt-default font-bold;
  }
}
.home-page__section{
  min-height: 200px;
  .home-page__video-library-access{
    @apply flex justify-center mt-lg;
    .deci-button{
      margin: auto;
    }
  }
}
.alert-info {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  background: #d9edf7;
  border-radius: 5px;
}
.align-justify {
  text-align: justify;
}
.align-center {
  text-align: center;
}
.p-info {
  padding-bottom: 5px;
}
.ul-info {
  margin-left: 30px;
}
.fa-qrcode {
  font-size: 5em;
  opacity: .4;
}
summary {
  padding-bottom: 5px;
}
</style>
