<template>
  <div class="video-likes">
    <div class="like-icon" @click="$emit('on-toggle-like')" >
      <i class="fas fa-heart" v-if="liked"></i>
      <i class="far fa-heart" v-if="!liked"></i>
    </div>
    <span>
      {{ likesAmount }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'VideoLikes',

  props: {
    liked: {
      type: Boolean,
      required: true
    },
    likesAmount: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="less">
@import "../../../assets/styles/vars.less";
@import "../../../assets/styles/mixins.less";

.video-likes{
  @apply flex gap-xs;
  .fa-heart{
    font-size: @bigger-text-size;
  }
  .fas{
    color: red;
  }
}
</style>
