import { findDomain } from '@/utils/url';
import store from '@/store';
import i18n from '@/services/translation/i18n';
import { alert } from '@/utils/information';
const { t } = i18n.global;

// By default, a cart is stored during 24 hours in local storage
const __CART_PREFIX = 'deciplus__cart-';
const __CART_DATE = new Date().toLocaleDateString();
const __CART_UID = __CART_PREFIX + __CART_DATE;

function getUserDomainInfo () {
  return store.state.user.userDomainInfo;
}

function generateUID () {
  const userInfos = getUserDomainInfo();
  if (userInfos) {
    const domain = findDomain();
    return `${domain}-${userInfos.id}-${__CART_UID}`;
  }
}

export default {
  save (products) {
    const uid = generateUID();
    if (!uid) {
      alert({
        message: t('calendar.errors.GENERAL_ERROR')
      });
      throw new Error('uid is mandatory to save cart');
    }
    localStorage.setItem(uid, JSON.stringify(products));
    return { ...products };
  },
  restore () {
    const uid = generateUID();
    return JSON.parse(localStorage.getItem(uid));
  },
  clear () {
    const uid = generateUID();
    return localStorage.removeItem(uid);
  },
  clearOldCarts () {
    const cartItemKeys = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes(__CART_PREFIX)) {
        cartItemKeys.push(localStorage.key(i));
      }
    }

    for (let i = 0; i < cartItemKeys.length; i++) {
      const key = cartItemKeys[i];
      const splittedKey = key.split('-');
      const date = splittedKey[splittedKey.length - 1];
      if (date !== __CART_DATE) {
        localStorage.removeItem(cartItemKeys[i]);
      }
    }
  }
};
