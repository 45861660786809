import { createRouter, createWebHistory } from 'vue-router';
import CalendarPage from '../modules/calendar/CalendarPage.vue';
import TimeslotDetailsPage from '../modules/calendar/TimeslotDetailsPage.vue';
import Auth from '../modules/auth/Auth.vue';
import ReinitPasswordPage from '../modules/auth/ReinitPasswordPage.vue';
import Landing from '../modules/landing/Landing.vue';
import AppContainer from '../modules/common/AppContainer.vue';
import AppError from '../modules/common/AppError.vue';
import HomePage from '../modules/home/HomePage.vue';
import MyVideoLibraryPage from '../modules/home/MyVideoLibraryPage.vue';
import MyProfilePage from '../modules/myProfile/MyProfilePage.vue';
import ScheduledPaymentsPage from '../modules/myProfile/ScheduledPaymentsPage.vue';
import InvoicesPage from '../modules/myProfile/InvoicesPage.vue';
import ManageBalancePage from '../modules/myProfile/ManageBalancePage.vue';
import RedeemGiftCardPage from '../modules/myProfile/RedeemGiftCardPage.vue';
import MyPersonalDetailsAdvancedPage from '../modules/myProfile/MyPersonalDetailsAdvancedPage.vue';
import Tunnel from '../modules/tunnel/Tunnel.vue';
import VideoGalleryPage from '../modules/video/VideoGalleryPage.vue';
import VideosByActivityPage from '../modules/video/VideosByActivityPage.vue';
import VideosByCoachPage from '../modules/video/VideosByCoachPage.vue';
import VideoDetailsPage from '../modules/video/VideoDetailsPage.vue';
import ProductDetailsPage from '../modules/products/ProductDetailsPage.vue';
import MobileSearchPage from '../modules/calendar/MobileSearchPage.vue';
import Shop from '@/modules/shop/page.shop.vue';
import Cart from '@/modules/shop/page.cart.vue';

import { mustLogInGuard, mustHaveReinitTokenGuard } from './guards/user';
import { publicPageGuard, discoveryPageGuard } from './guards/scope';
import multiGuard from './guards/multiGuard';
import Session from '@/modules/calendar/Session.vue';
import PassedBookingsPage from '@/modules/myProfile/PassedBookingsPage.vue';
import store from "@/store";

const ROUTES = [
  {
    path: '/errorPage',
    name: 'errorPage',
    component: AppError
  },
  {
    path: '/:domain',
    component: AppContainer,
    children: [
      {
        path: '',
        name: 'landing',
        component: Landing,
        beforeEnter: multiGuard([publicPageGuard, discoveryPageGuard])
      },
      {
        path: 'discovery',
        name: 'discovery',
        component: Landing,
        beforeEnter: multiGuard([publicPageGuard, discoveryPageGuard])
      },
      {
        path: 'calendar',
        name: 'calendar',
        component: CalendarPage,
        beforeEnter: publicPageGuard
      },
      {
        path: 'calendar/timeslot',
        name: 'timeslotDetails',
        component: TimeslotDetailsPage,
        beforeEnter: publicPageGuard
      },
      {
        path: 'calendar/search',
        name: 'mobileSearch',
        component: MobileSearchPage,
        beforeEnter: publicPageGuard
      },
      {
        path: 'sessions/:id/:code?',
        name: 'session',
        component: Session
        // beforeEnter: publicPageGuard
      },
      {
        path: 'signIn',
        name: 'signIn',
        component: Auth,
        props: { componentmode: 'signIn' }
      },
      {
        path: 'signUp',
        name: 'signUp',
        component: Auth,
        props: { componentmode: 'signUp' }
      },
      {
        path: 'reinit-password',
        name: 'reinitPassword',
        component: ReinitPasswordPage,
        beforeEnter: mustHaveReinitTokenGuard
      },
      {
        path: 'me',
        name: 'profile',
        component: MyProfilePage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'me/balance',
        name: 'manageBalance',
        component: ManageBalancePage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'me/balance/redeem',
        name: 'redeemGiftCard',
        component: RedeemGiftCardPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'me/payments',
        name: 'scheduledPayments',
        component: ScheduledPaymentsPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'me/passedBookings',
        name: 'passedBookings',
        component: PassedBookingsPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'me/invoices',
        name: 'invoices',
        component: InvoicesPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'me/modify',
        name: 'personalDetailsAdvanced',
        component: MyPersonalDetailsAdvancedPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'product/:id',
        name: 'productDetails',
        component: ProductDetailsPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'home',
        name: 'home',
        component: HomePage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'home/videos',
        name: 'myVideoLibrary',
        component: MyVideoLibraryPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'tunnel',
        name: 'tunnel',
        component: Tunnel
      },
      {
        path: 'videos',
        name: 'videoGallery',
        component: VideoGalleryPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'video/activity/:id',
        name: 'videosByActivity',
        component: VideosByActivityPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'video/coach/:id',
        name: 'videosByCoach',
        component: VideosByCoachPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'video/:id',
        name: 'videoDetails',
        component: VideoDetailsPage,
        beforeEnter: mustLogInGuard
      },
      {
        path: 'products',
        name: 'products',
        redirect: 'shop'
      },
      {
        path: 'shop',
        name: 'shop',
        component: Shop,
        beforeEnter: multiGuard([publicPageGuard])
      },
      {
        path: 'shop/cart',
        name: 'cart',
        component: Cart
      }
    ]
  }
];

const router = createRouter({
  routes: ROUTES,
  history: createWebHistory(import.meta.env.VITE_BASE_URL)
});

router.beforeEach((to, _from, next) => {
  if(!store.state.config.memberAppConfigs?.urlManager && to.name !== 'errorPage') {
    next({ name: 'errorPage' });
  } else if (to.hash.startsWith('#/')) {
    const path = to.fullPath.substring(2);
    return next(path);
  } else next();
});
export default router;
