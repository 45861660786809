<template>
  <el-row :gutter="24" v-loading="isLoading"
          class="flex items-center justify-center flex-col h-full">
    <el-col :lg="12">
      <DetailedTimeSlot v-if="timeSlot"
                        @booking-success="onSuccess"
                        @booking-cancel="onCancel"
                        :disable-reload="true"
                        :force-is-reservation-slot="true"
                        class="flex flex-grow scroll padding-content-mobile"
                        :timeslot="timeSlot" />
      <div v-else-if="timeSlotUnavailable">
        <p class="text-center">{{$t('session.openlink.error')}}</p>
      </div>
    </el-col>
  <modal-share></modal-share>
  </el-row>
</template>

<script>
import DetailedTimeSlot from '@/modules/calendar/components/Timeslot/DetailedTimeslot/DetailedTimeSlot.vue';
import {
  getSessionStatus,
  prepareBookingDateAndTime,
  prepareDate,
  prepareEndTime,
  prepareTime,
  prepareZoneName
} from '../../utils/bookingSessions.js';
import { mapActions, mapGetters, mapState } from 'vuex';
import CalendarControl from './mixins/calendarControl';
import ModalShare from '../../core/components/modalShare.vue';
export default {
  name: 'Session',
  components: { ModalShare, DetailedTimeSlot },
  data () {
    return {
      timeSlot: null,
      isLoading: false,
      timeSlotUnavailable: false
    };
  },
  computed: {
    ...mapState('domain', ['zones']),
    ...mapGetters('user', ['loggedInUser']),
    getSession () {
      if (this.loggedInUser) {
        return this.$services.HTTP.session.getSessionsFromMembersApiById;
      } else {
        return this.$services.HTTP.session.getSessionsFromPublicApiById;
      }
    }
  },
  methods: {
    ...mapActions('user', ['loadUpcomingUserBookings']),
    ...mapActions('zone', [
      'loadAllResources',
      'loadAllActivities',
      'loadAllCoaches'
    ]),
    onSuccess () {
      this.$router.push({ name: 'home' });
    },
    onCancel () {
      this.$router.push({ name: 'home' });
    },
    async loadSession () {
      this.isLoading = true;
      try {
        const { data } = await this.getSession(
          this.$route.params.id,
          this.$route.params.code
        );
        const timeSlot = data.booking;

        this.loadAllResources({ zoneId: timeSlot.zoneId });
        this.loadAllActivities({ zoneId: timeSlot.zoneId });
        this.loadAllCoaches({ zoneId: timeSlot.zoneId });

        const startDate = timeSlot.startDate;
        timeSlot.startDate = prepareDate(startDate);
        timeSlot.startTime = prepareTime(startDate);
        timeSlot.startDateAndTime = prepareBookingDateAndTime(startDate);
        timeSlot.endTime = prepareEndTime(startDate, timeSlot.duration);
        timeSlot.zoneName = prepareZoneName(timeSlot.zoneId, this.zones);
        timeSlot.date = this.$datetime.fromISODate(startDate);

        timeSlot.status = getSessionStatus(timeSlot);
        this.updateBookingStatus(timeSlot);

        this.timeSlot = timeSlot;
      } catch (error) {
        this.$information.capture({ error });
        this.timeSlotUnavailable = true;
      } finally {
        this.isLoading = false;
      }
    }
  },
  mixins: [CalendarControl],
  async mounted () {
    this.loadUpcomingUserBookings();
    this.loadSession();
  }
};
</script>

<style scoped>

</style>
