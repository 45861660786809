import HTTP from '@/services/HTTP';
import { generateAvailabilityTimeslots, groupAvailabilitiesByDay } from '@/utils/resourceAvailabilities';
import { removeUnsuitableSessions, generateSessionTimeslots, groupSessionsByDay } from '@/utils/bookingSessions';
import CONSTANTS from '@/utils/constants';
import * as datetime from '@/utils/datetime';
import { capture } from '@/utils/information';
import { calendarTypeMap, filterResourcesByType } from '@/utils/resources';

export default {
  namespaced: true,
  state: {
    activities: [],
    resources: [],
    resourceAvailabilities: [],
    bookingSessions: [],
    coaches: [],
    products: [
      {
        id: CONSTANTS.PRODUCT_TYPES.SUBSCRIPTION,
        items: []
      },
      {
        id: CONSTANTS.PRODUCT_TYPES.PREPAID_CARD,
        items: []
      },
      {
        id: CONSTANTS.PRODUCT_TYPES.GIFT_CARD,
        items: []
      }
    ],
    loadAllEventsQuery: {},
    isCalendarEmpty: { value: false },
    videos: []
  },
  mutations: {
    SET_ACTIVITIES (state, activities) {
      state.activities = activities;
    },
    SET_RESOURCES (state, resources) {
      state.resources = resources;
    },
    SET_RESOURCE_AVAILABILITIES (state, resourceAvailabilities) {
      state.resourceAvailabilities = resourceAvailabilities;
    },
    SET_SESSIONS (state, bookingSessions) {
      state.bookingSessions = bookingSessions;
    },
    SET_COACHES (state, coaches) {
      state.coaches = coaches;
    },
    SET_PRODUCTS (state, products) {
      state.products = products;
    },
    SET_ALL_EVENTS_QUERY (state, query) {
      state.loadAllEventsQuery = query;
    },
    SET_IS_CALENDAR_EMPTY (state, isEmpty) {
      state.isCalendarEmpty = { value: isEmpty };
    },
    SET_VIDEOS_FOR_MEMBER (state, videos) {
      state.videos = videos;
    }
  },
  actions: {
    async loadAllEvents ({ dispatch }, { loadingMessage }) {
      dispatch('_resetLoadedEvents', loadingMessage);
      dispatch('_buildLoadAllEventsQuery');
      dispatch('_loadEventsAccordingToCalendarMode');
    },
    _resetLoadedEvents ({ commit, dispatch }, loadingMessage) {
      dispatch(
        'domain/setAppState',
        { newState: CONSTANTS.APP_STATE.LOADING, loadingMessage },
        { root: true }
      );
      commit('SET_SESSIONS', []);
      commit('SET_RESOURCE_AVAILABILITIES', []);
    },
    _buildLoadAllEventsQuery ({ commit, state, rootState, rootGetters }) {
      const tmpSelectedDate = rootGetters['config/selectedDate'] || new Date();
      const query = {
        zoneId: rootGetters['config/selectedZoneId'],
        from: datetime.toISODate(tmpSelectedDate)
      };
      if (!rootGetters['config/selectedActivities'].includes(0)) {
        query.activityIds = rootGetters['config/selectedActivities'];
      }
      if (!rootGetters['config/selectedResources'].includes(0)) {
        query.resources = rootGetters['config/selectedResources'];
      }
      if (!rootGetters['config/selectedCoaches'].includes(0)) {
        query.coachIds = rootGetters['config/selectedCoaches'];
      }
      if (rootGetters['config/selectedCalendarMode'] === CONSTANTS.CALENDAR_MODES.SESSION) {
        query.to = datetime.toISODate(datetime.getNDaysLater(tmpSelectedDate, 6));
      } else {
        query.to = datetime.toISODate(datetime.getNDaysLater(tmpSelectedDate, 7));
      }
      commit('SET_ALL_EVENTS_QUERY', query);
    },
    async _loadEventsAccordingToCalendarMode ({ commit, state, dispatch, rootState, rootGetters }) {
      let bookingSessions, availabilities;
      const getSessionsForWeb = (
        rootGetters['config/selectedCalendarMode'] === CONSTANTS.CALENDAR_MODES.SESSION &&
        rootState.user.deviceFormat === CONSTANTS.DEVICE_FORMATS.WEB
      );
      const getSessionsForMobile = (
        rootGetters['config/selectedCalendarMode'] === CONSTANTS.CALENDAR_MODES.SESSION &&
        rootState.user.deviceFormat === CONSTANTS.DEVICE_FORMATS.MOBILE
      );
      const getAvailabilities = rootGetters['config/selectedCalendarMode'] === CONSTANTS.CALENDAR_MODES.AVAILABILITY;
      const getSessionsFromMembersApi = (
        getSessionsForWeb &&
        rootGetters['user/loggedInUser']
      );
      const getSessionsFromPublicApi = (
        getSessionsForWeb &&
        !rootGetters['user/loggedInUser']
      );
      const getSessionsFromMembersApiForMobile = (
        getSessionsForMobile &&
        rootGetters['user/loggedInUser']
      );
      const getSessionsFromPublicApiForMobile = (
        getSessionsForMobile &&
        !rootGetters['user/loggedInUser']
      );
      const getAvailabilitiesForMembersWeb = (
        getAvailabilities &&
        rootGetters['user/loggedInUser']
      );
      const getAvailabilitiesFromPublicApi = (
        getAvailabilities &&
        !rootGetters['user/loggedInUser']
      );

      if (getSessionsFromMembersApi) {
        bookingSessions = await HTTP.session.getSessionsFromMembersApi(state.loadAllEventsQuery);
      } else if (getSessionsFromPublicApi) {
        bookingSessions = await HTTP.session.getSessionsFromPublicApi(state.loadAllEventsQuery);
      } else if (getSessionsFromMembersApiForMobile) {
        bookingSessions = await HTTP.session.getSessionsFromMembersApi(state.loadAllEventsQuery);
      } else if (getSessionsFromPublicApiForMobile) {
        bookingSessions = await HTTP.session.getSessionsFromPublicApi(state.loadAllEventsQuery);
      } else if (getAvailabilitiesForMembersWeb) {
        availabilities = await HTTP.zone.getAvailabilitiesForMembersWeb(state.loadAllEventsQuery);
      } else if (getAvailabilitiesFromPublicApi) {
        availabilities = await HTTP.zone.getAvailabilitiesFromPublicApi(state.loadAllEventsQuery);
      }

      if (getAvailabilities) {
        commit('SET_RESOURCE_AVAILABILITIES', availabilities.data);
        commit('SET_IS_CALENDAR_EMPTY', !availabilities.data.timeslots?.length);
      } else {
        commit('SET_SESSIONS', bookingSessions.data);
        commit('SET_IS_CALENDAR_EMPTY', !bookingSessions.data.length);
      }

      dispatch(
        'domain/setAppState',
        { newState: CONSTANTS.APP_STATE.LOADED },
        { root: true }
      );
    },
    async loadAllActivities ({ commit, rootGetters },
      options = { allZone: false, zoneId: null }) {
      const usedZoneId = options.zoneId || rootGetters['config/selectedZoneId'];
      let activities;
      if (rootGetters['user/loggedInUser']) {
        const response = await HTTP.activitie.getActivitiesFromMembersApi(!options.allZone ? usedZoneId : null);
        activities = response.data;
      } else {
        const response = await HTTP.activitie.getActivitiesFromPublicApi(usedZoneId);
        activities = response.data;
      }
      commit('SET_ACTIVITIES', activities.filter(activity => activity.isWeb !== CONSTANTS.BOOKING_MODES.NO_BOOKING));
    },
    async loadAllResources ({ commit, state, rootGetters }, options = { zoneId: null }) {
      const usedZoneId = options.zoneId || rootGetters['config/selectedZoneId'];
      let data;
      if (rootGetters['user/loggedInUser']) {
        const response = await HTTP.resource.getResourcesFromMembersApi(usedZoneId);
        data = response.data;
      } else {
        const response = await HTTP.resource.getResourcesFromPublicApi(usedZoneId);
        data = response.data;
      }
      commit('SET_RESOURCES', data);
    },
    async loadAllCoaches ({ commit, rootGetters }, options = { zoneId: null }) {
      const usedZoneId = options.zoneId || rootGetters['config/selectedZoneId'];
      let data;
      if (rootGetters['user/loggedInUser']) {
        const response = await HTTP.coach.getCoachesFromMembersApi(usedZoneId);
        data = response.data;
      } else {
        const response = await HTTP.coach.getCoachesFromPublicApi(usedZoneId);
        data = response.data;
      }
      commit('SET_COACHES', data);
    },
    async loadAllProducts ({ commit }) {
      const { data } = await HTTP.product.getProducts();
      commit('SET_PRODUCTS', data);
    },
    async loadAllPublicProducts ({ commit }, { zoneId } = {}) {
      const { data } = await HTTP.product.getPublicProducts({ zoneId });
      commit('SET_PRODUCTS', data);
    },
    async loadVideos ({ commit }) {
      const { data } = await HTTP.videos.getVideosForMember();
      commit('SET_VIDEOS_FOR_MEMBER', data.rows);
    }
  },
  getters: {
    getResourceAvailabilities: state => {
      let availabilities = {};
      if (state.resourceAvailabilities) {
        try {
          availabilities = generateAvailabilityTimeslots(state.resourceAvailabilities.timeslots);
        } catch (error) {
          capture({
            error,
            inform: true,
            component: 'zone store',
            method: 'getResourceAvailabilities',
            tags: { possibleReason: 'backend API change' }
          });
        }
      }
      return availabilities;
    },
    getResourceAvailabilitiesByDay: state => {
      let availabilities = {};
      try {
        availabilities = groupAvailabilitiesByDay(state.resourceAvailabilities.timeslots);
      } catch (error) {
        capture({
          error,
          inform: true,
          component: 'zone store',
          method: 'getResourceAvailabilitiesByDay',
          tags: { possibleReason: 'backend API change' }
        });
      }
      return availabilities;
    },
    getSessions: state => {
      let bookingSessions = {};
      if (state.bookingSessions) {
        try {
          bookingSessions = removeUnsuitableSessions(state.bookingSessions || []);
          bookingSessions = generateSessionTimeslots(bookingSessions);
        } catch (error) {
          capture({
            error,
            inform: true,
            component: 'zone store',
            method: 'getSessions',
            tags: { possibleReason: 'backend API change' }
          });
        }
      }
      return bookingSessions;
    },
    getSessionsByDay: state => {
      let bookingSessions = {};
      if (state.bookingSessions) {
        try {
          bookingSessions = removeUnsuitableSessions(state.bookingSessions || []);
          bookingSessions = groupSessionsByDay(bookingSessions);
        } catch (error) {
          capture({
            error,
            inform: true,
            component: 'zone store',
            method: 'getSessionsByDay',
            tags: { possibleReason: 'backend API change' }
          });
        }
      }
      return bookingSessions;
    },
    getActivityById: (state) => (id) => {
      return state.activities.find(activity => activity.id === id);
    },
    getResources: (state) => (type) => {
      let res = null;
      if (!type) {
        res =  state.resources;
      } else {
        const mappedType = calendarTypeMap[type];
        res =  state.resources.filter(filterResourcesByType(mappedType));
      }
      return res.filter(r => r.isOnline);
    },
    getProduct: (state) => (productId) => {
      let product = null;
      for (const productGroup of state.products) {
        product = productGroup.items.find(product => product.id === +productId);
        if (product) break;
      }
      return product;
    },
    getVideoByProductId: (state) => (productId) => {
      return state.videos.find(video => video.productId === productId);
    },
    getProductCategories: (state) => {
      const currentCategories = JSON.parse(JSON.stringify(state.products));
      const giftableCategories = currentCategories.filter(category => ['abo', 'seances'].includes(category.id));
      let giftableProducts = [];

      for (const productGroup of giftableCategories) {
        giftableProducts = [
          ...giftableProducts,
          ...productGroup.items
            .filter(product => product.isGiftable === true)
            .map(product => ({ ...product, showAddToCart: false }))
        ];
      }

      const giftCardCategory = currentCategories.find(category => category.id === 'gift-card');

      if (giftableProducts.length) {
        // Si le club n'a pas la catégorie cartes cadeaux mais des produits giftables alors on met en place la categorie
        if (!giftCardCategory) {
          currentCategories.push({
            description: 'Cartes cadeaux',
            id: 'gift-card',
            items: giftableProducts,
            title: 'Cartes cadeaux'
          });
          // on surcharge la categorie deja existante
        } else if (giftCardCategory?.items?.length) {
          const giftcarditems = [...giftCardCategory.items, ...giftableProducts];
          // giftCardCategory.item = [];
          giftCardCategory.items = giftcarditems;
        }
      }

      return currentCategories;
    }
  }
};
