<template>
  <AriModal
    :modalTitle="isReadOnly ? $t('account.yourPlace') : $t('calendar.selectAPlace')"
    :showClose="true"
    :appendToBody="true"
    @close="$emit('on-cancel')"
    @on-cancel="$emit('on-cancel')"
  >
    <template #body>
      <div class="place-map">
        <div class="place-map__cell-container">
          <div v-for="heightIndex in mapHeight" :key="heightIndex" class="flex gap-xxs">
            <div class="place-map__cell" v-for="widthIndex in mapWidth" :key="widthIndex" @click="onClickCell(`${widthIndex - 1}-${heightIndex - 1}`)">
              <span v-html="getCellContent(widthIndex, heightIndex)"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="place-map__actions-container">
        <AriButton outlined class="w-full" :content="$t('close')" @click="$emit('on-cancel')"/>
      </div>
    </template>
  </AriModal>
</template>

<script>
import { createPlaceCoordinates, getCellContentHtml } from '@/utils/placeMap.js';

export default {
  props: {
    placeMap: {
      type: Object,
      required: true
    },
    isReadOnly: {
      type: Boolean
    },
    bookedPlace: {
      type: Number
    }
  },
  created () {
    this.mapWidth = this.placeMap.x;
    this.mapHeight = this.placeMap.y;
  },
  mounted () {
    this.placeCoordinates = createPlaceCoordinates(this.placeMap);
  },
  data () {
    return {
      mapWidth: 0,
      mapHeight: 0,
      placeCoordinates: null
    };
  },
  methods: {
    getCellContent (widthIndex, heightIndex) {
      const coordinate = `${widthIndex - 1}-${heightIndex - 1}`;
      if (this.placeCoordinates && this.placeCoordinates[coordinate]) {
        return getCellContentHtml({
          cell: this.placeCoordinates[coordinate],
          bookedCellId: this.bookedPlace,
          isReadOnly: this.isReadOnly
        });
      }
    },
    onClickCell (coordinate) {
      if (
        !this.isReadOnly &&
        this.placeCoordinates[coordinate] &&
        this.placeCoordinates[coordinate].isAvailable === 'O' &&
        this.placeCoordinates[coordinate].isCoach === 'N' &&
        !this.placeCoordinates[coordinate].isBooked
      ) {
        this.$emit('place-selection', this.placeCoordinates[coordinate].id);
      }
    }
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.place-map{
  @apply p-md;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .place-map__cell-container {
    .place-map__cell {
      min-height: 20px;
      min-width: 20px;
      width: 40px;
      height: 40px;
      margin: 1px;
      border-radius: 10px;
      text-align: center;
      .place-map__cell-content{
        width: 99%;
        padding: 1px;
        border-radius: 10px;
        color: white;
        height: 100%;
        font-weight: 900;
      }
      .place-map__cell-content--available{
        background: rgb(21, 136, 21);
      }
      .place-map__cell-content--unavailable{
        background: rgb(109, 112, 109);
      }
      .place-map__cell-content--coach{
        background: rgb(17, 17, 17);
      }
      .place-map__cell-content--booked{
        background: rgb(197, 32, 32);
      }
      .place-map__cell-content--no-longer-available{
        background: rgb(25, 155, 206);
      }
      .place-map__cell-content--clickable{
        cursor: pointer;
      }
    }
  }
  .place-map__actions-container{
    text-align: center;
  }
  .place-map__cell-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>>
