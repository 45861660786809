<template>
  <el-row
    class="landing-page"
  >
    <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12"
      class="landing-page__stepper-container"
    >
      <Stepper
        :currentStep="step"
        :steps="steps"
      >
        <template v-slot:stepperContent>
          <div v-if="step === activityStepId">
            <ChooseActivity
              :alreadySelectedActivities="selectedActivitiesFromStore"
              :canGoToPreviousStep="canGoToZoneSelection && activityStepId > 0"
              @next="onActivitySelected"
              @back="goToZoneSelection"
            />
          </div>
          <div v-if="step === zoneStepId">
            <ChooseZone
              :alreadySelectedZone="selectedZone"
              :canGoToNextStep="selectedZone !== null"
              :purpose="$t('landing.chooseZoneTitle')"
              :onlyActiveZone="true"
              @zoneClick="onZoneClicked"
              @next="onZoneSelected" />
          </div>
        </template>
      </Stepper>
    </el-col>
    <el-col :span="12">
      <mq-responsive target="md+">
        <LandingVisual />
      </mq-responsive>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import Stepper from '@/modules/common/generic/Stepper.vue';
import ChooseZone from './components/ChooseZone.vue';
import ChooseActivity from './components/ChooseActivity.vue';
import LandingVisual from './components/LandingVisual.vue';

export default {
  name: 'Landing',
  components: {
    Stepper,
    ChooseZone,
    ChooseActivity,
    LandingVisual
  },

  data () {
    return {
      step: 0,
      searchTerm: '',
      steps: []
    };
  },

  created () {
    this.buildSteps();
    if (this.zoneFilterByTunnel) {
      this.SET_SELECTED_ZONE(this.getActiveZone(this.zoneFilterByTunnel));
    }
  },

  mounted () {
    window.scroll(0, 0);
  },

  computed: {
    ...mapGetters('domain', ['domain', 'isMultiSite', 'getZonesVisibleOnline', 'getActiveZone']),
    ...mapState('zone', ['activities']),
    ...mapState('domain', ['zones']),
    ...mapGetters('config', ['selectedZone', 'selectedActivities', 'forcedZoneByTunnel', 'zoneFilterByTunnel']),
    zoneStepId () {
      return this.steps.find(step => step.name === 'zone')?.stepId;
    },
    activityStepId () {
      return this.steps.find(step => step.name === 'activity')?.stepId;
    },
    canGoToActivitySelection () {
      return this.selectedZone;
    },
    canGoToZoneSelection () {
      return this.zoneStepId !== undefined && this.steps[0].name === 'zone';
    },
    selectedActivitiesFromStore () {
      const onlyAllActivitiesSelected = this.selectedActivities.length === 1 && this.selectedActivities[0] === 0;
      if (onlyAllActivitiesSelected) {
        return [];
      } else {
        return this.selectedActivities;
      }
    }
  },

  methods: {
    ...mapMutations('config', ['SET_SELECTED_ZONE', 'SET_SELECTED_ACTIVITIES']),
    buildSteps () {
      let step = 0;
      step = this.addZoneStep(step);
      step = this.addActivityStep(step);
      return step;
    },
    addZoneStep (step) {
      if (this.forcedZoneByTunnel) {
        const zone = this.getActiveZone(this.forcedZoneByTunnel);
        this.SET_SELECTED_ZONE(zone);
      } else if (this.isMultiSite) {
        if (this.getZonesVisibleOnline?.length === 1) {
          this.SET_SELECTED_ZONE(this.getZonesVisibleOnline[0]);
        } else {
          const stepZone = {
            name: 'zone',
            stepId: step++,
            openStep: this.goToZoneSelection
          };
          this.steps.push(stepZone);
        }
      } else {
        this.SET_SELECTED_ZONE(this.zones[0]); // On utilise PAS getZonesVisibleOnline au cas où le manager cache la seule de l'instance
      }
      return step;
    },
    addActivityStep (step) {
      const stepActivity = {
        name: 'activity',
        stepId: step++,
        openStep: this.goToActivitySelection
      };
      this.steps.push(stepActivity);
      return step;
    },
    onZoneClicked (selectedZone) {
      this.SET_SELECTED_ZONE(selectedZone);
    },
    onZoneSelected (selectedZone) {
      this.SET_SELECTED_ZONE(selectedZone);
      this.step = this.zoneStepId + 1;
    },
    onActivitySelected (selectedActivities) {
      this.SET_SELECTED_ACTIVITIES(selectedActivities);
      const query = selectedActivities.length ? '?fromDiscovery=true' : '';
      this.$router.push(`/${this.domain}/calendar${query}`);
    },
    goToZoneSelection () {
      this.step = this.zoneStepId;
    },
    goToActivitySelection () {
      if (this.canGoToActivitySelection) {
        this.step = this.activityStepId;
      }
    }
  }
};
</script>

<style lang="less">
.choose-x{
  .content-title{
    font-size: 26px;
  }
}
.landing-page__stepper-container{
  padding-top: 2rem;
}
</style>
