<template>
  <AriModal @close="showModal = false" :modalTitle="$t('shareTimeslot.title')" v-if="showModal">
    <template #body>
      <div class="container-share-timeslot" v-if="url">
        <div class="social-container" >
          <ShareNetwork
            v-for="(social, index) in allowedSocials"
            :key="index"
            :network="social.name"
            :url="url"
            class="social-item"
          >
            <div :class="['social-icon-container', social.color]">
              <i :class="[social.icon, 'social-icon']"></i>
            </div>
            <p class="social-title"> {{ social.name }} </p>
          </ShareNetwork>
        </div>
        <div class="mt-xs">
          <div @click="copyLink" class="copy-link pt-xxs pb-xxs">
            <p class="mr-3">{{url}}</p>
            <p class="ml-sm">{{ $t('share.copy') }}</p>
          </div>
        </div>
        <input style="display: none"
               type="text"
               readonly
               :value="url"
               ref="input">
      </div>
    </template>
    <template>

    </template>
  </AriModal>
</template>

<script>
import EventBus from '@/services/eventBus/eventBus';

export default {
  name: 'modalShare',
  data () {
    return {
      showModal: false,
      url: '',
      allowedSocials: [
        { name: 'Email', icon: 'fas fa-envelope', color: 'email' },
        // { name: 'Messenger', icon: 'fab fa-facebook-messenger', color: 'messenger' },
        { name: 'Skype', icon: 'fab fa-skype', color: 'skype' },
        { name: 'WhatsApp', icon: 'fab fa-whatsapp', color: 'whatsapp' }
      ]
    };
  },
  methods: {
    closeModal () {
      this.url = '';
      this.showModal = false;
    },
    async copyLink () {
      try {
        await navigator.clipboard.writeText(this.url);
        this.$information.alert({
          showClose: true,
          inform: true,
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS,
          message: this.$t('share.copy.success'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
        });
      } catch {
        this.$information.alert({
          showClose: true,
          message: this.$t('share.copy.error'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
        });
      }
    }
  },
  mounted () {
    EventBus.$on('share', ({ url }) => {
      this.showModal = true;
      this.url = url;
    });
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}
.social-container {
  @apply flex flex-wrap gap-xs;
}
.container-share-timeslot {
  @apply flex flex-col items-center justify-center gap-default;
}
.social-item {
  @apply flex flex-col justify-center items-center px-md;
}
.social-icon-container {
  @apply flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 20px 5px;
  height: 60px;
  width: 60px;
  background-color: #1FE132;
}
.social-icon {
  font-size: 2rem;
  color: white;
  text-decoration: none;
}
.social-title {
  padding-top: 5px;
}
.email {
  background-color: #bb001b;
}
.messenger {
  background-color: #00B2FF;
}
.whatsapp {
  background-color: #25D366;
}
.skype {
  background-color: #00aff0;
}

.copy-link {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
}
</style>
