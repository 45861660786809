 <template>
  <div
    class="my-profile"
  >
    <NarrowContainer>
      <DeciTitle
        showDivider
        :text="$t('profile.personalDetails.myProfile')"
      />
      <div
        class="my-profile__section-body"
      >
        <div class="flex avatarContainer">
          <img v-if="loggedInUser.photo" :src="loggedInUser.photo" class="el-avatar el-avatar--circle" alt="Avatar"/>
          <i v-else class="fa fa-user el-avatar el-avatar--circle"></i>

          <p class="pt-sm ubuntuMedium ariane-text-title-5 ">{{loggedInUser.name}} {{ loggedInUser.surname}}</p>
        </div>
        <MyPersonalDetailsShort />
        <div
          class="my-personal-details__see-more-container"
        >
          <AriButton
            class="seeAll"
            text
            :content="$t('profile.personalDetails.seeAll')"
            buttonType="text"
            @click="goToAdvancedDetailsPage"
          />
        </div>
      </div>
      <DeciTitle
        :text="$t('profile.paymentMethods.title')"
        showDivider
        size="medium"
      />
      <div class="deci-title ubuntuMedium deci-title--small-text pb-default">{{$t('profile.paymentMethods.myCards')}}</div>
      <div class="my-profile__section-body">
        <MyPaymentMethods />
      </div>

      <div class="my-profile__section-body">
        <DeciTitle
          :text="$t('profile.passedBookings.title')"
          size="medium"
        />
        <AriButton filled
          neutralGrey
          :content="$t('profile.passedBookings.action')"
          buttonType="strongFilled"
          @click="goToPassedBookingsPage"
        />
      </div>
      <DeciTitle
        showDivider
        :text="$t('profile.personalDetails.title')"
        size="medium"
      />
      <div
        class="my-profile__section-body"
      >
        <MyPersonalRights />
      </div>
    </NarrowContainer>
  </div>
</template>

<script>
import NarrowContainer from '@/layouts/NarrowContainer.vue';
import MyPaymentMethods from './components/MyPaymentMethods.vue';
import MyPersonalDetailsShort from './components/MyPersonalDetailsShort.vue';
import MyPersonalRights from './components/MyPersonalRights.vue';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'MyProfilePage',

  components: {
    NarrowContainer,
    MyPaymentMethods,
    MyPersonalDetailsShort,
    MyPersonalRights,
    DeciTitle
  },

  data () {
    return {
    };
  },

  mounted () {
    window.scroll(0, 0);
  },
  computed: {
    ...mapGetters('user', ['loggedInUser'])
  },
  methods: {
    goToAdvancedDetailsPage () {
      this.$router.push({
        name: 'personalDetailsAdvanced'
      });
    },
    goToPassedBookingsPage () {
      this.$router.push({
        name: 'passedBookings'
      });
    }
  }
};
</script>

<style lang="less" scoped>
.seeAll {
  margin-left: auto;
}
.avatarContainer {
  margin-bottom: 10px;
}
.el-avatar {
  object-fit: cover;
  width: 64px;
  height: 64px;
  margin-right: 15px;
}
.my-profile{
  .my-profile__section-body{
    padding-bottom: 1.5rem;
  }
  .my-personal-details__see-more-container{
    padding-top: 10px;
    .deci-button{
      margin-left: auto;
    }
  }
}
</style>
