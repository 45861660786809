import store from '@/store';
import router from '@/router';
import {
  isUserLoggedIn,
  attemptAutoSignIn,
  displayProtectedPageWarning,
  isMulti,
  zoneForProducts, selectedZoneForcedByTunnel
} from './guardHelper';

export async function mustLogInGuard (to, from, next) {
  await attemptAutoSignIn();
  if (!isUserLoggedIn()) {
    displayProtectedPageWarning();
    const query = { to: to.name, ...to.query };
    if (to.name === 'videoDetails') {
      query.toVideo = to.params.id;
    }
    if (to.name === 'productDetails') {
      query.toProduct = to.params.id;
    }
    router.push({ path: `/${to.params.domain}/signIn`, query });
  } else {
    if (to.name === 'productDetails' && to.path === '/') {
      router.push({ path: `/${to.params.domain}/product/${from.query.toProduct}` });
    } else if (to.name === 'videoDetails' && to.path === '/') {
      router.push({ path: `/${to.params.domain}/video/${from.query.toVideo}` });
    } else {
      next();
    }
  }
}

export async function mustHaveReinitTokenGuard (to, from, next) {
  const reinitToken = to.query.reinit_token;
  if (!reinitToken) {
    router.push({ path: `/${to.params.domain}/signIn` });
  } else {
    store.commit('user/STORE_REINIT_TOKEN', reinitToken);
    next();
  }
}

export async function mustHaveSelectZoneProductsPage (to, from, next) {
  if (!isMulti()) {
    next();
  } else {
    if (!isUserLoggedIn()) {
      const selectedZoneForProducts = zoneForProducts();
      const selectedZoneByTunnel = selectedZoneForcedByTunnel();
      if (!selectedZoneForProducts && !selectedZoneByTunnel) {
        router.push({ path: `/${to.params.domain}/signUp`, query: { redirectToShop: true } });
      } else {
        next();
      }
    } else {
      next();
    }
  }
}
