<template>
  <div
    @click="onInteraction()"
    @keypress="onInteraction()"
    tabindex="0"
    class="interactive-div"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'InteractiveDiv',

  methods: {
    onInteraction () {
      this.$emit('on-interaction');
    }
  }
};
</script>

<style lang="less">
.interactive-div{
  cursor: pointer;
  &:focus{
    outline: 1px dashed rgba(0, 0, 0, 0.5);
  }
}
</style>
