<template>
  <el-row :gutter="24" class="page-content product-details-page">
    <el-col class="grid-content">
      <div
        class="product-details__go-back"
      >
        <GoBack />
      </div>
      <div>
        <ProductDetails
          :currentProductId="productId"
          @on-loaded="onLoadedProductDetails"
          @on-cancel="onCloseProductDetails"
        />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import GoBack from '@/modules/common/generic/GoBack.vue';
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import CommonControl from '@/modules/common/mixins/commonControl';
import ProductDetails from './components/ProductDetails.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ProductDetailsPage',

  mixins: [ProductsControl, CommonControl],

  components: {
    GoBack,
    ProductDetails
  },

  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  },

  data () {
    return {
      productId: parseInt(this.$route.params.id) || parseInt(this.productForDetailsPage)
    };
  },

  computed: {
    ...mapState('user', ['productForDetailsPage'])
  },

  methods: {
    ...mapActions('user', ['saveProductForDetailsPage']),
    onLoadedProductDetails () {
      window.scroll(0, 0);
    },
    onCloseProductDetails () {
      this.saveProductForDetailsPage(undefined);
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less">
.product-details-page{
  .content-minititle{
    text-align: center;
    color:var(--ariane-primary-400);
    padding-bottom: 1rem;
  }
}
</style>
