<template>
  <AriModal :modalTitle="$t('resiliation.title')" @close="closeTerminationModal()">
    <template #header>
      <div class="resiliation-modal-title">
        <span>{{ $t(`resiliation.subtitle.step${modalResiliationStep > 1 ? 2 : 1}`) }}</span>
        <span v-show="modalResiliationStep > 1">{{ terminationObj.contractId }}</span></div>
      <div class="divider-h my-default"></div>
    </template>
    <template #body>
      <div class="body-termination">
        <span v-if="modalResiliationStep === 1" class="text-primary font-medium text-base">{{ $t('resiliation.pleaseChooseContract') }}</span>
        <div class="flex flex-col gap-md mt-default pb-default" v-show="modalResiliationStep === 1">
          <div v-for="contract in allContracts" :key="contract.id" class="item-contract" @click="selectContract(contract.id)"
                :class="{ 'border-primary-500': terminationObj.contractId === contract.id, 'item-contract-resiliated': requestPendingContracts[contract.id] }">
            <div class="flex items-center justify-between">
              <div class="flex flex-col">
                <span class="text-primary text-base font-medium">{{product.title}}</span>
                <span class="text-secondary">N° {{contract.ref}}</span>
              </div>
              <input type="radio" :checked="terminationObj.contractId === contract.id" :id="contract.id" name="contractToTerminate"
                    :value="contract.id" @change="terminationObj.contractId = contract.id" v-if="!requestPendingContracts[contract.id]">
              <span v-else class="text-pending-500">{{$t('resiliation.alreadyResiliated')}}</span>
            </div>
            <div class="divider-h my-default"></div>
            <div class="flex justify-between">
              <div class="flex flex-col">
                <span class="text-secondary">{{$t('resiliation.contractCreationDate')}}</span>
                <span class="contract-primary-info">{{ $datetime.dateOnlyFormat(contract.createdAt) }}</span>
              </div>
              <div class="flex flex-col">
                <span class="text-secondary">{{$t('resiliation.contractEndDate')}}</span>
                <span class="contract-primary-info">{{ $datetime.dateOnlyFormat(contract.endDate)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-show="(modalResiliationStep === 2) || (modalResiliationStep === 6)">
          <span class="text-primary font-medium text-base">{{ $t('resiliation.youAskForThisContract') }}</span>
          <div class="gap-default flex flex-col py-default pl-sm">
            <div class="flex flex-col">
              <span class="title-contract-option">{{ $t('resiliation.prestation') }}</span>
              <span class="value-contract-option">{{product.title}}</span>
            </div>
            <div class="flex flex-col">
              <span class="title-contract-option">{{ $t('resiliation.contractNumber') }}</span>
              <span class="value-contract-option">{{selectedContract?.ref}}</span>
            </div>
            <div class="flex flex-col">
              <span class="title-contract-option">{{ $t('resiliation.subscribedBy') }}</span>
              <span class="value-contract-option">{{ `${userDomainInfo.surname} ${userDomainInfo.name}` }}</span>
            </div>
            <div class="flex flex-col">
              <span class="title-contract-option"> {{ $t('resiliation.withMail') }}</span>
              <span class="value-contract-option">{{ userDomainInfo.email }}</span>
            </div>
            <div class="flex flex-col" v-if="modalResiliationStep === 6">
              <span class="title-contract-option">{{$t('resiliation.resiliationDate')}}</span>
              <span class="value-contract-option">{{ $datetime.dateOnlyFormat(terminationObj.terminationDate) }}</span>
            </div>
            <div class="flex flex-col" v-if="modalResiliationStep === 6">
              <span class="title-contract-option">{{$t('resiliation.resiliationReason')}}</span>
              <span class="value-contract-option">{{terminationObj.reason === 'OTHER' ? terminationObj.reasonTxt :  $t(`resiliation.reason.${terminationObj.reason}`)}}</span>
            </div>
            <div class="flex flex-col" v-if="modalResiliationStep === 6">
              <span class="title-contract-option"> {{$t('resiliation.contactMail')}} </span>
              <span class="value-contract-option">{{ emailConfirmation }}</span>
            </div>
          </div>

          <div class="container-condition-termination" @click="showTerminationsConditions()">
            <div v-if="modalResiliationStep === 2" class="inline-block gap-xxs">
              <span>{{$t('resiliation.condition1Step2')}}</span>
              <span class="text-secondary-500 underline cursor-pointer">{{ $t('resiliation.condition3') }}</span>
              <span>{{ $t('resiliation.condition2step2') }}</span>
            </div>
            <template v-else>
              <span>{{ $t('resiliation.condition1') }}</span>
              <div class="inline-block ">
                <span>{{ $t('resiliation.condition2') }}</span>
                <span class="text-secondary-500 underline cursor-pointer">{{ $t('resiliation.condition3') }}</span>
                <span>{{ $t('resiliation.condition4') }}</span>
              </div>
            </template>
          </div>
        </div>
        <div v-show="modalResiliationStep === 3">
          <AriForm ref="terminationContractForm">
            <template v-slot="form">
              <span class="text-primary font-medium text-base">{{ $t('resiliation.isCorrectMail') }}</span>
              <div class="flex flex-col py-default pl-sm">
                <span class="text-primary font-medium">{{$t('resiliation.email')}}</span>
                <span class="text-secondary pb-xs">{{$t('resiliation.confirmationMailExplanation')}}</span>
                <ariInput :form="form" v-model="emailConfirmation" :label="$t('resiliation.inputEmail')" :formatter="[required, mail]" />
              </div>
            </template>
          </AriForm>
        </div>
        <div v-show="modalResiliationStep === 4">
          <span class="text-primary font-medium text-base">{{ $t('resiliation.confirmResiliation') }}</span>
          <div class="flex flex-col py-default pl-sm bg-default">
            <span class="font-medium text-secondary">{{ $t('resiliation.looseAdvantages') }}</span>
            <ul class="list-disc list-activities">
              <template v-if="product.associatedActivities.length">
                <li v-for="(activity, index) in product.associatedActivities" :key="index">{{activity.name}}</li>
              </template>
              <li v-else>{{$t('products.validForAllActivities')}}</li>
            </ul>
          </div>
        </div>
        <div v-show="modalResiliationStep === 5">
          <span class="text-primary font-medium text-base">{{ $t('resiliation.sadToSeeYouLeave') }}</span>
          <div class="flex flex-col py-default pl-sm">
            <span class="font-medium text-primary">{{$t('resiliation.selectReason')}}</span>
            <ul class="list-disc list-activities">
                <li class="flex gap-default" v-for="reason in arrTerminationReasons" :key="reason">
                  <input type="radio" :id="reason" name="reason" :value="reason" @change="terminationObj.reason = reason">
                  <label :for="reason">{{$t(`resiliation.reason.${reason}`)}}</label>
                </li>
                <li class="flex flex-col">
                  <textarea class="ariane-textarea" v-model="terminationObj.reasonTxt" v-if="terminationObj.reason === 'OTHER'"/>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full justify-between flex flex-col sm:flex-row pt-default gap-default">
        <AriButton :content="$t('app.return')" @click="closeTerminationModal()" text/>
        <AriButton v-if="modalResiliationStep < 6" :content="$t('continue')" @click="onClickContinue()"
                  :disabled="!terminationObj.contractId || (modalResiliationStep > 4 && !terminationObj.reason)" :filled="modalResiliationStep < 4" />
        <AriButton v-if="modalResiliationStep === 4" :content="$t('resiliation.dontWantAnymore')" @click="closeTerminationModal()" filled />
        <AriButton v-if="modalResiliationStep === 6" error :content="$t('resiliation.confirm')" @click="confirmTermination()" filled />
      </div>
    </template>
  </AriModal>
</template>
<script>
import { mail, required } from '@/utils/inputFormatters';
import { mapState } from 'vuex';
export default {
  name: 'ModalTerminationContract',
  components: {
  },
  props: {
    product: Object
  },
  data () {
    return {
      showTerminationModal: false,
      arrTerminationReasons: [],
      terminationConditions: null,
      requestPendingContracts: [],
      modalResiliationStep: 1,
      emailConfirmation: '',
      terminationObj: {
        contractId: null,
        reason: null,
        reasonTxt: null,
        terminationDate: new Date()
      },
      allContracts: []
    };
  },
  mounted () {
    this.emailConfirmation = this.userDomainInfo.email;
    this.$services.HTTP.contracts.getContract(this.product.id).then(res => {
      this.allContracts = res.data.contracts;
      this.arrTerminationReasons = res.data.terminationReasons;
      this.terminationConditions = res.data.terminationConditions;
      this.requestPendingContracts = res.data.requestPendingContracts;
      if (this.allContracts.length === 1) {
        if (!this.requestPendingContracts[this.allContracts[0].id]) {
          this.terminationObj.contractId = this.allContracts[0].id;
          this.modalResiliationStep = 2;
        }
      }
    });
  },
  computed: {
    ...mapState('user', ['userDomainInfo']),
    selectedContract () {
      if (this.terminationObj.contractId) return this.allContracts.find(c => c.id === this.terminationObj.contractId);
      else return {};
    }
  },
  methods: {
    mail,
    required,
    selectContract (contractId) {
      if (!this.requestPendingContracts[contractId]) this.terminationObj.contractId = contractId ;
    },
    confirmTermination () {
      const body = {
        reason: this.terminationObj.reason,
        annotation: this.terminationObj.reasonTxt,
        email: this.emailConfirmation
      };
      this.$services.HTTP.contracts.askTermination(this.terminationObj.contractId, body).then(res => {
        this.$information.alert({
          type: 'success',
          title: this.$t('resiliation.success'),
          message: this.$t('resiliation.successMessage')
        });
        this.$emit('close');
      }).catch(err => {
        this.$information.alert({
          type: 'error',
          title: this.$t('resiliation.error'),
          message: this.$t('resiliation.errorMessage')
        });
      });
    },
    showTerminationsConditions () {
      this.$ariAlert(this.$t('resiliation.conditions.title'), this.terminationConditions[this.terminationObj.contractId] || this.$t('resiliations.conditions.none'),
      { confirmButtonText: this.$t('app.close')});
    },
    closeTerminationModal () {
      this.terminationObj.contractId = null;
      this.showTerminationModal = false;
      this.modalResiliationStep = 1;
      this.$emit('close');
    },
    onClickContinue () {
      if (this.modalResiliationStep !== 3) this.modalResiliationStep++;
      else if (this.modalResiliationStep === 3) {
        const isMailOk = this.$refs.terminationContractForm.validateForm();
        if (isMailOk) this.modalResiliationStep++;
      };
    }
  }
};
</script>

<style lang="less">
.container-condition-termination {
  @apply flex flex-col w-full items-center pt-lg text-secondary;
}
.text-next-schedule {
  @apply text-secondary font-normal text-sm;
}
.ariane-textarea {
  border: 1px solid;
  height: 100px;
  @apply rounded-sm bg-default border-default resize-none outline-none;
}
.item-contract {
  border: 1px solid;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  @apply border-default p-default rounded cursor-pointer;
}
.item-contract.item-contract-resiliated {
  @apply border-pending cursor-default;
}
.footer-modal-edit-guest {
  @apply flex w-full justify-end items-center;
}
.resiliation-modal-title {
  @apply text-secondary;
}
.title-contract-option {
  @apply text-secondary text-xs;
}
.value-contract-option {
   @apply text-primary font-semibold;
}
.block-resiliation {
  margin: 10px -20px -20px -20px;
  @apply bg-neutral-50 text-secondary p-default items-center justify-between flex gap-default cursor-pointer;
}
.block-resiliation i {
  @apply text-secondary;
}
.divider-h {
  border: 1px solid;
  @apply h-0 w-full border-default;
}
.contract-primary-info {
  @apply text-primary font-medium;
}
.list-activities {
  @apply pl-md;
}

</style>
