<template>
  <AriModal v-if="localStorageUnavailable" :modalTitle="$t('modalLocalStorageUnavailable.title')" :showCLose="false">
    <template v-slot:body>
      <div class="flex body-modale">
        <span>{{ $t('modalLocalStorageUnavailable.descr') }}</span>
        <span> {{ $t('modalLocalStorageUnavailable.process') }}</span>
        <ul>
          <li class="pb-xs"><a href="https://support.google.com/accounts/answer/61416" target="_blank">Google Chrome</a></li>
          <li class="pb-xs"><a href="https://support.mozilla.org/fr/kb/sites-disent-cookies-bloques-les-debloquer" target="_blank">Mozilla Firefox</a></li>
          <li class="pb-xs"><a href="https://support.microsoft.com/fr-fr/microsoft-edge/autoriser-temporairement-les-cookies-et-les-donn%C3%A9es-de-site-dans-microsoft-edge-597f04f2-c0ce-f08c-7c2b-541086362bd2" target="_blank">Microsoft Edge</a></li>
          <li class="pb-xs"><a href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac" target="_blank">Safari</a></li>
          <li class="pb-xs"><a href="https://help.opera.com/en/latest/web-preferences/" target="_blank">Opera</a></li>
        </ul>
      </div>
    </template>
  </AriModal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'modalLocalStorageUnavailable',
  data () {
    return {
    };
  },
  methods: {

  },
  computed: {
    ...mapState('config', ['localStorageUnavailable'])
  }
};
</script>

<style scoped>
  .body-modale {
    overflow: auto;
    gap: 30px;
    flex-direction: column;
  }

</style>
