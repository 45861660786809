import {
  memberAPI
} from '../request';
export default {
  getGiftCardContents: (code) => {
    return memberAPI.post('vouchers', {
      code
    });
  },
  activateGiftCard: (code) => {
    return memberAPI.post('vouchers/activate', {
      code
    });
  }
};
