<template>
  <div class="table-container my-bookings-container grid-content">
    <DeciTitle :isLined="false" :text="$t('account.bookings.title', { count: myBookings.length })" />
    <div class="wrap-container">
      <BookingCard v-for="(booking, index) in orderedBookingsByDate()"
                   :key="index" :booking="booking"
                   :bookingIndex="index"
                   @on-cancel-booking="onCancelBooking"
                   @on-share-booking="onShareBooking"
                   @on-show-booked-place="onShowBookedPlace"
      />
    </div>
    <div class="no-bookings-message" v-if='hasNoBookings'>
      {{ $t('account.booking.notfound') }}
    </div>
    <ModalPlaceMap
      v-if="showPlaceSelectionModal"
      :placeMap="selectedBookingPlaceMap"
      :bookedPlace="myBookedPlace"
      :isReadOnly="true"
      @on-cancel="showPlaceSelectionModal = false"
    />
  </div>
</template>

<script>
import BookingCard from './BookingCard.vue';
import ModalPlaceMap from '@/modules/calendar/components/ModalPlaceMap.vue';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';
import CalendarControl from '@/modules/calendar/mixins/calendarControl.js';

export default {
  name: 'MyBookings',
  components: {
    BookingCard,
    ModalPlaceMap,
    DeciTitle
  },
  emits: ['on-cancel-booking'],
  mixins: [CalendarControl],
  props: {
    myBookings: Array
  },
  data () {
    return {
      showPlaceSelectionModal: false,
      selectedBookingPlaceMap: null,
      myBookedPlace: undefined
    };
  },
  computed: {
    hasNoBookings () {
      return this.myBookings.length === 0;
    }
  },
  methods: {
    orderedBookingsByDate () {
      return this.myBookings.slice(0).sort((a, b) => {
        return new Date(a.booking.startDate).getTime() - new Date(b.booking.startDate).getTime();
      });
    },
    onCancelBooking ({ cancelId, bookingDateAndTime, bookingType, sanctionText, cancelDateSanction }) {
      this.$emit('on-cancel-booking', { cancelId, bookingDateAndTime, bookingType, sanctionText, cancelDateSanction });
    },
    onShareBooking ({ bookingId }) {
      this.$emit('on-share-booking', { bookingId });
    },
    async onShowBookedPlace (booking) {
      this.myBookedPlace = booking.placeId;
      const { map } = await this.getSessionDetails(booking.booking.id);
      this.selectedBookingPlaceMap = map;
      this.showPlaceSelectionModal = true;
    }
  }
};
</script>

<style lang="less" scoped>
.no-bookings-message{
  text-align: center;
}
</style>
