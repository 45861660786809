<template>
  <div class="flex gap-default justify-end pr-default items-center">
    <mq-responsive target="md+">
      <div class="relative">
      <span class="position-label-datepicker" id="start-date-selector">{{$t('calendar.selectADate')}}</span>
      <DatePicker
        :defaultDate="calendarForm.startDate"
        :disabled-date="mq.current === 'md' || mq.current === 'sm' ? isOlderThanToday : isOlderThanCurrentWeekStart"
        :selectionType="mq.current === 'md' || mq.current === 'sm' ? 'date' : 'week'"
        :isClearable="false"
        @on-date-change="onDateSelected" />
    </div>
    </mq-responsive>
    <el-divider direction="vertical" v-if="!isCustomMenuInHeader" />
    <AriButton class="w-full" :text="!isCustomMenuInHeader" trivial :content="$t('calendar.filters')" preIcon="fa fa-sliders"  @click="showMoreFilters = true"/>
  </div>
  <AriModal v-if="showMoreFilters" :modalTitle="$t('calendar.filters')" @close="showMoreFilters = false" width="50%">
    <template v-slot:body>
      <div class="py-default flex flex-col gap-xs">
        <div class="flex gap-default flex-col sm:flex-row flex-wrap">
          <div class="w-full" v-if="isMultiSite">
            <AriDropdown :datas="zoneChoices" :filterable="true" :clearable="false" labelKey="clubName" id="zone-selector" class="w-full"
            v-model="calendarForm.zoneId" valueKey="id" :label="$t('calendar.selectZone')" @update:modelValue="resetFilters(false)"/>
          </div>
          <div class="w-full" v-if="coachesFilter?.length && calendarForm.calendarMode === 'SESSION'">
            <AriDropdown showAll multiple id="coach-selector" :emptyLabel="$t('calendar.allCoaches')" :clearable="false" class="w-full"
                      :datas="coachesFilter" :filterable="true" :showAllLabel="$t('calendar.allCoaches')" labelKey="name"
                      v-model="calendarForm.coachIds" @update:modelValue="onCoachSelected()" valueKey="id"  :label="$t('calendar.selectCoach')"/>
          </div>
          <mq-responsive target="sm-">
            <div class="relative">
              <span class="position-label-datepicker" id="start-date-selector">{{$t('calendar.selectADate')}}</span>
              <DatePicker
                :defaultDate="calendarForm.startDate"
                :disabled-date="mq.current === 'md' || mq.current === 'sm' ? isOlderThanToday : isOlderThanCurrentWeekStart"
                :selectionType="mq.current === 'md' || mq.current === 'sm' ? 'date' : 'week'"
                :isClearable="false"
                @on-date-change="onDateSelected" />
            </div>
          </mq-responsive>
        </div>
        <span class="text-primary">{{$t('calendar.selectTimeslotType')}}</span>
        <div class="flex flex-wrap">
          <AriChoice :choices="calendarModes" v-model="calendarForm.calendarMode" v-if="calendarModes"/>
        </div>
        <span class="text-primary">{{ $t('calendar.timeslot.activity')}}</span>
        <div class="flex flex-wrap gap-y-xxs">
          <div v-for="activity in activitiesFilter" :key="activity.id" class="w-2/4 sm:w-1/4">
            <AriCheckbox :modelValue="calendarForm.activityIds.indexOf(activity.id) > -1" @update:modelValue="checkActivity(activity.id)"
                         :label="activity.name" class="w-fit"/>
          </div>
        </div>
        <template v-if="resourcesFilter && resourcesFilter.length">
          <span class="text-primary">{{resourceLabel}}:</span>
          <div class="flex flex-wrap">
            <div class="w-2/4 sm:w-1/4" v-for="resource in resourcesFilter" :key="resource.id" >
              <AriCheckbox :label="resource.name" class="w-fit"
                          :modelValue="calendarForm.resources.indexOf(resource.id) > -1" @update:modelValue="checkResource(resource.id)"/>
            </div>
          </div>
        </template>

      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-between">
        <AriButton removePaddingLeft text error :content="$t('calendar.filterReinit')" @click="resetFilters"/>
        <AriButton filled :content="$t('calendar.filter')" @click="reloadCalendar"/>
      </div>
    </template>
  </AriModal>
</template>

<script>
import CalendarControl from '../mixins/calendarControl.js';
import DatePicker from '@/modules/common/generic/DatePicker.vue';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import * as datetime from '@/utils/datetime';
import EventBus from '@/services/eventBus/eventBus';
import { getCalendarModeFromIsWebReduce, reduceActivitiesIsWeb } from '@/utils/calendar';

export default {
  components: {
    DatePicker
  },
  inject: ['mq'],
  mixins: [CalendarControl],
  async mounted () {
    await this.prepareStoreForCalendarConfigurations();
    EventBus.$on('saveCalendarStateForLoggedIn', (timeslotId) => {
      this.SAVE_CALENDAR_STATE({ timeslotId, calendarForm: this.calendarForm });
    });
    if (this.saveCalendarStateForLogin && this.saveCalendarStateForLogin.calendarForm) {
      this.calendarForm = this.saveCalendarStateForLogin.calendarForm;
      this.SAVE_CALENDAR_STATE({ timeslotId: this.saveCalendarStateForLogin.timeslotId });
      this.reloadCalendar();
    }
  },
  beforeUnmount () {
    EventBus.$off();
  },
  data () {
    return {
      showMoreFilters: false,
      calendarForm: {
        activityIds: [],
        resources: [],
        coachIds: [],
        zoneId: '',
        coach: '',
        resource: '',
        startDate: new Date(),
        calendarMode: null
      }
    };
  },
  watch: {
    selectedDate () {
      this.calendarForm.startDate = this.selectedDate;
    },
    selectedCalendarMode () {
      this.calendarForm.calendarMode = this.selectedCalendarMode;
    },
    selectedActivities () {
      this.calendarForm.activityIds = this.selectedActivities;
    },
    selectedResources () {
      this.calendarForm.resources = this.selectedResources;
    },
    selectedCoaches () {
      this.calendarForm.coachIds = this.selectedCoaches;
    },
    selectedZoneId () {
      this.calendarForm.zoneId = this.selectedZoneId;
    }
  },
  computed: {
    ...mapState('user', ['userDomainInfo', 'timeslotForDetailsPage', 'saveCalendarStateForLogin']),
    ...mapState('domain', ['domain', 'zones']),
    ...mapState('config', ['memberAppConfigs', 'tunnelConfigurations']),
    ...mapState('zone', {
      activitiesInStore: 'activities',
      coachesInStore: 'coaches'
    }),
    ...mapGetters('user', ['loggedInUser']),
    ...mapGetters('config', [
      'selectedDate',
      'selectedCalendarMode',
      'hasBothCalendarModes',
      'showCoaches',
      'forcedZoneByTunnel',
      'forcedActivityByTunnel',
      'forcedResourceByTunnel',
      'zoneFilterByTunnel',
      'coachFilterByTunnel',
      'resourceLabel',
      'isCustomMenuInHeader',
      'selectedActivities',
      'selectedResources',
      'selectedCoaches',
      'selectedZoneId'
    ]),
    ...mapGetters('domain', ['isMultiSite', 'getZonesVisibleOnline', 'getActiveZone']),
    ...mapGetters('zone', ['getResources']),
    calendarModes () {
      if (this.hasBothCalendarModes) {
        return [
        {
          id: this.$CONSTANTS.CALENDAR_MODES.SESSION,
          label: this.memberAppConfigs.webConfig_bookingCategoriesConfig?.lessonsTitle || this.$t('calendar.sessionsMode')
        },
        {
          id: this.$CONSTANTS.CALENDAR_MODES.AVAILABILITY,
          label: this.memberAppConfigs.webConfig_bookingCategoriesConfig?.resourcesTitle || this.$t('calendar.availabilityMode')
        }
      ];
      } else return false;

    },
    activitiesFilter () {
      return this.activitiesInStore?.length > 1 ? this.activitiesInStore : null;
    },
    resourceLabel () {
      return this.memberAppConfigs?.webConfig_resource_label || this.$t('configurations.defaultResourceLabel');
    },
    resourcesInStore () {
      return this.getResources(this.calendarForm.calendarMode);
    },
    resourcesFilter () {
      return this.resourcesInStore?.length > 1 ? this.resourcesInStore : null;
    },
    coachesFilter () {
      return this.coachesInStore?.length > 0 ? this.coachesInStore : null;
    },
    isOlderThanToday () {
      return datetime.isOlderThanToday;
    },
    isOlderThanCurrentWeekStart () {
      return date => date < datetime.getCurrentWeekStart();
    },
    withCoachFilter () {
      return this.showCoaches && this.calendarForm.calendarMode === this.$CONSTANTS.CALENDAR_MODES.SESSION && this.coachesFilter;
    },
    zoneChoices () {
      let zones = [];
      if (this.forcedZoneByTunnel) {
        zones = this._zonesWithZoneForcing();
      } else {
        zones = this.getZonesVisibleOnline;
      }
      return zones;
    }
  },
  methods: {
    ...mapActions('user', ['saveTimeslotForDetailsPage']),
    ...mapMutations('user', ['SAVE_CALENDAR_STATE']),
    ...mapMutations('config', ['SET_SELECTED_ZONE', 'SET_SELECTED_COACHES', 'SET_SELECTED_CALENDAR_MODE', 'SET_SELECTED_RESOURCES', 'SET_SELECTED_ACTIVITIES']),
    checkActivity (id) {
      const idIndex = this.calendarForm.activityIds.indexOf(id);
      if (idIndex > -1 ) this.calendarForm.activityIds.splice(idIndex, 1);
      else this.calendarForm.activityIds.push(id);
    },
    resetFilters (closeModal = true) {
      this.calendarForm.activityIds = [];
      this.calendarForm.resources = [];
      this.calendarForm.coachIds  = [];
      this.reloadCalendar(closeModal);
    },
    checkResource (id) {
      const idResource = this.calendarForm.resources.indexOf(id);
      if (idResource > -1 ) this.calendarForm.resources.splice(idResource, 1);
      else this.calendarForm.resources.push(id);
    },
    async prepareStoreForCalendarConfigurations () {
      const tunnelCMname = this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.CUSTOM_MENU];
      if (tunnelCMname) return;
      if (this.isComingFromBookingAttempt) {
        this.SET_SELECTED_CALENDAR_MODE(this.timeslotForDetailsPage.type);
        this.calendarForm.startDate = this.$datetime.getWeekStart(this.timeslotForDetailsPage.date);
        this.$router.replace({ 'query': null });
      }
      if (this.zoneFilterByTunnel) {
        this.SET_SELECTED_ZONE(this.getActiveZone(this.zoneFilterByTunnel));
      }
      await this.loadZoneDetailsFromStore();
      if (this.forcedActivityByTunnel) {
        if (Array.isArray(this.forcedActivityByTunnel)) this.SET_SELECTED_ACTIVITIES(this.forcedActivityByTunnel);
        else this.SET_SELECTED_ACTIVITIES([this.forcedActivityByTunnel]);
      }
      if (this.forcedResourceByTunnel) {
        if (Array.isArray(this.forcedResourceByTunnel)) this.SET_SELECTED_RESOURCES(this.forcedResourceByTunnel);
        else this.SET_SELECTED_RESOURCES([this.forcedResourceByTunnel]);
      }
      if (this.coachFilterByTunnel) {
        this.SET_SELECTED_COACHES(this.coachFilterByTunnel);
      }
      this.prepareCalendar();
    },
    redirectToLanding () {
      this.$router.replace('/' + this.domain);
    },
    async prepareCalendar () {
      this.loadSearchParameters();
      await this.getAllTimeslotsFromStore();
    },
    loadSearchParameters () {
      // updating form with store details or (if empty) with default values
      this.setCalendarFiltersInComponent();
      // syncronizing with store in case store was empty
      this.setCalendarFiltersInStore();
    },
    async reloadCalendar (closeModal) {
      try {
        this.saveTimeslotForDetailsPage({});
        this.setCalendarFiltersInStore();
        await this.loadZoneDetailsFromStore();
        await this.getAllTimeslotsFromStore();
        if (this.calendarForm.calendarMode !== this.selectedCalendarMode) this.onReservationModeToggle();
        if (closeModal) this.showMoreFilters = false;
      } catch (error) {
        this.$information.capture({ error });
      }
    },
    onReservationModeToggle () {
      const newCalendarMode = (this.selectedCalendarMode === this.$CONSTANTS.CALENDAR_MODES.SESSION)
        ? this.$CONSTANTS.CALENDAR_MODES.AVAILABILITY : this.$CONSTANTS.CALENDAR_MODES.SESSION;
      this.calendarForm.resources = [];
      this.calendarForm.calendarMode = newCalendarMode;
      this.SET_SELECTED_CALENDAR_MODE(newCalendarMode);
      this.reloadCalendar(false);
    },
    onActivitySelected () {
      if (this.calendarForm.activityIds === null) this.calendarForm.activityIds = [];
      this.setCalendarFiltersInStore();
      this.updateCalendarModeOnActivityChange();
      this.getAllTimeslotsFromStore();
      this.saveTimeslotForDetailsPage({});
    },
    updateCalendarModeOnActivityChange () {
      if (this.calendarForm.activityIds?.length) {
        const activities = this.activitiesInStore;
        const newSelectedActivities = this.calendarForm.activityIds.map(
          activityId => activities.find(activity => activity.id === activityId)
        );
        const result = newSelectedActivities.reduce(reduceActivitiesIsWeb, 0);
        const newCalendarMode = getCalendarModeFromIsWebReduce(result);
        this.SET_SELECTED_CALENDAR_MODE(newCalendarMode);
      }
    },
    onResourceSelected () {
      if (this.calendarForm.resources === null) this.calendarForm.resources = [];
      this.setCalendarFiltersInStore();
      this.getAllTimeslotsFromStore();
      this.saveTimeslotForDetailsPage({});
    },
    onCoachSelected () {
      if (this.calendarForm.coachIds === null) this.calendarForm.coachIds = [];
      this.setCalendarFiltersInStore();
      this.getAllTimeslotsFromStore();
      this.saveTimeslotForDetailsPage({});
    },
    onDateSelected (selection) {
      this.calendarForm.startDate = selection;
      this.setCalendarFiltersInStore();
      this.getAllTimeslotsFromStore();
      this.saveTimeslotForDetailsPage({});
    },
    _zonesWithZoneForcing () {
      let zones = this.getZonesVisibleOnline.filter(zone => zone.id === this.forcedZoneByTunnel);
      if (this.loggedInUser && this.forcedZoneByTunnel !== this.userDomainInfo.zone) {
        const usersZone = this.getActiveZone(this.userDomainInfo.zone);
        zones.push(usersZone);
      }
      return zones;
    }
  }
};
</script>

<style lang="less">
.position-label-resa {
  position: absolute;
  top: -11px;
  left: 12px;
  background-color: #FFF;
  z-index: 2;
  font-size: 14px;
  color: var(--floux-grey);
}
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.wrapper-filter {
  padding: 20px;
  display:flex;
  justify-content: space-around;
  align-items: center
}
#app{
  .calendar__search{
    .box-shadow();
    background:#fff;
    border-radius: 10px;
    border:1px dotted @border-color;
    padding-left:12px;
    padding-right:12px;
    margin: 10px 0 10px 0;
    .el-form{
      input, .el-tag{
        color:var(--ariane-primary-400) !important;
        font-weight: @heavy-font-weight;
        font-size: @standard-text-size;
      }
      .el-form-item{
        margin-top:5px;
        margin-right:5px;
        .el-form-item__content{
          margin-left:0 !important;
          .el-select{
            width:100%;
          }
          .el-date-editor{
            &.el-range-editor{
              &.el-input__inner{
                margin-top:0;
                .el-range-input{
                  padding-left: 5%;
                  &:nth-of-type(2){
                    margin-left:12px;
                    width: 40%;
                  }
                  font-family: 'Poppins', 'Arial', sans-serif;
                  margin-top: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width:1279px){
  .wrapper-filter {
    flex-direction: column;
    gap: 15px;
  }
  #app{
    .el-container{
      .calendar__search{
        .el-form-item{
          &.el-form-item__search{
            width:100%;
            .el-button--primary{
              width:100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width:992px){
  #app{
    .calendar__search{
      &.calendar__search-mobile{
        border:none !important;
        margin:0 !important;
        padding-left:0 !important;
        padding-right:0 !important;
        .buttons-icon{
          margin-bottom:0px !important;
          .el-button{
            i{
              font-size: 20px;
            }
          }
          &.buttons-icon-calendar{
            .el-button{
              &:hover{
                background:@secondary-color;
              }
              &:focus{
                background:darken(@secondary-color,2%);
              }
            }
          }
        }
        .el-form-item{
          margin-right:0 !important;
        }
      }
    }
  }
}
</style>
