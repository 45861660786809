import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import * as datetime from '@/utils/datetime';
import { getBookingErrorTypes } from '@/utils/errors';

export default {
  computed: {
    ...mapState('zone', ['isCalendarEmpty']),
    ...mapGetters('user', ['loggedInUser']),
    ...mapState('user', ['userDomainInfo', 'bookings', 'waitingListRegistrations']),
    ...mapState('domain', ['getZonesVisibleOnline', 'getActiveZone']),
    ...mapGetters('config', {
      facebookTrackingId: 'facebookTrackingId',
      selectedZoneInStore: 'selectedZone',
      selectedActivitiesInStore: 'selectedActivities',
      selectedResourcesInStore: 'selectedResources',
      selectedDateInStore: 'selectedDate',
      selectedCoachesInStore: 'selectedCoaches',
      selectedCalendarModeInStore: 'selectedCalendarMode',
      resourceLabel: 'resourceLabel'
    })
  },
  data () {
    return {
      calendarForm: {}
    };
  },
  inject: ['mq'],
  methods: {
    ...mapActions('user', ['loadUpcomingUserBookings']),
    ...mapActions('domain', ['setAppState']),
    ...mapActions('zone', [
      'loadAllEvents',
      'loadAllResources',
      'loadAllActivities',
      'loadAllCoaches'
    ]),
    ...mapActions('config', [
      'updateTunnelConfigurationShopAndCalendarByZone'
    ]),
    ...mapMutations('config', ['SET_SELECTED_ZONE', 'SET_SELECTED_ACTIVITIES', 'SET_SELECTED_RESOURCES', 'SET_SELECTED_DATE', 'SET_SELECTED_COACHES']),
    isAvailabilitySlot (timeslot) {
      return timeslot.type === this.$CONSTANTS.CALENDAR_MODES.AVAILABILITY;
    },
    setCalendarFiltersInComponent () {
      if (this.loggedInUser && this.userDomainInfo) {
        if (!this.selectedZoneInStore || !this.selectedZoneInStore.isVisibleOnline) {
          this.calendarForm.zoneId = null;
        } else {
          this.calendarForm.zoneId = this.selectedZoneInStore ? this.selectedZoneInStore.id : this.userDomainInfo.zone;
        }
      } else {
        this.calendarForm.zoneId = this.selectedZoneInStore ? this.selectedZoneInStore.id : this.getZonesVisibleOnline[0]?.id;
      }
      this.calendarForm.activityIds = this.selectedActivitiesInStore.length > 0 ? this.selectedActivitiesInStore : [];
      this.calendarForm.resources = this.selectedResourcesInStore.length > 0 ? this.selectedResourcesInStore : [];
      this.calendarForm.coachIds = this.selectedCoachesInStore.length > 0 ? this.selectedCoachesInStore : [];
      this.calendarForm.startDate = this.selectedDateInStore || datetime.getCurrentWeekStart();
      this.calendarForm.calendarMode = this.selectedCalendarModeInStore;
    },
    setCalendarFiltersInStore () {
      const selectedZone = this.getActiveZone(this.calendarForm.zoneId);
      this.SET_SELECTED_ZONE(selectedZone);
      if (!this.loggedInUser) {
        this.updateTunnelConfigurationShopAndCalendarByZone(selectedZone.id);
      }
      this.SET_SELECTED_ACTIVITIES(this.calendarForm.activityIds);
      this.SET_SELECTED_RESOURCES(this.calendarForm.resources);
      this.SET_SELECTED_DATE(this.calendarForm.startDate);
      this.SET_SELECTED_COACHES(this.calendarForm.coachIds);
    },
    async loadZoneDetailsFromStore () {
      try {
        await this.loadAllActivities();
        await this.loadAllResources();
        await this.loadAllCoaches();
      } catch (err) {
        console.log(err);
      }
    },
    async getAllTimeslotsFromStore () {
      try {
        await this.loadAllEvents({
          calendarMode: this.calendarForm.calendarMode,
          loadingMessage: this.$t('calendar.loading')
        });
      } catch (error) {
        this.$information.capture({ error });
      }
    },
    async getSessionDetails (bookingSessionId) {
      try {
        if (this.loggedInUser) {
          const { data } = await this.$services.HTTP.zone.getSessionInfoFromMembersApi(bookingSessionId);
          return data.booking;
        } else {
          const { data } = await this.$services.HTTP.zone.getSessionInfoFromPublicApi(bookingSessionId);
          return data;
        }
      } catch (error) {
        this.$information.capture({ error, inform: true });
        this.$information.alert({
          showClose: true,
          message: this.$t('calendar.errors.GENERAL_ERROR')
        });
      }
    },
    updateBookingStatus (currentSlot) {
      if (this.isBookedByMember(currentSlot)) {
        currentSlot.status = this.$CONSTANTS.STATUSES.BOOKED;
      }
      if (this.isQueuedByMember(currentSlot)) {
        currentSlot.status = this.$CONSTANTS.STATUSES.QUEUED;
      }
      return currentSlot;
    },
    isBookedByMember (currentSlot) {
      if (this.bookings) {
        for (const booking of this.bookings) {
          if (booking.booking.id === currentSlot.id) {
            return true;
          }
        }
      }
      return false;
    },
    isQueuedByMember (currentSlot) {
      if (this.waitingListRegistrations) {
        for (const registration of this.waitingListRegistrations) {
          if (registration.booking.id === currentSlot.id) {
            return true;
          }
        }
      }
      return false;
    },
    handleBookingResult (bookingResponse, fullError) {
      if (fullError && bookingResponse?.response?.data) return bookingResponse;
      else if (bookingResponse === this.$CONSTANTS.CALENDAR.SUCCESSFUL_BOOKING) {
        return bookingResponse;
      } else if (bookingResponse === this.$CONSTANTS.ERRORS.API_ERROR_MESSAGES.MEMBER_DONT_HAVE_VALID_PRODUCT) {
        return this.$CONSTANTS.ERRORS.API_ERROR_MESSAGES.MEMBER_DONT_HAVE_VALID_PRODUCT;
      } else {
        this.handleBookingError(bookingResponse);
        return this.$CONSTANTS.ERRORS.BOOKING_NOT_SUCCESSFUL;
      }
    },
    async cancelBooking ({ cancelId, bookingDateAndTime, calendarMode, bookingType }) {
      try {
        const { data } = await this.$services.HTTP.booking.cancelBooking(cancelId);
        if (data.messages[0]) {
          this.showCancellationMessage(bookingDateAndTime, bookingType);
        }
        this.calendarForm.calendarMode = calendarMode || this.$CONSTANTS.CALENDAR_MODES.SESSION;
        await this.loadUpcomingUserBookings();
        // désolé mais bon
        return true;
      } catch (err) {
        this.handleBookingError(err.response?.data?.message);
        await this.loadUpcomingUserBookings();
        return false;
      }
    },
    handleBookingError (bookingResponse) {
      const errorTypes = getBookingErrorTypes(bookingResponse);
      const errorMessages = errorTypes.map(errorType => this.$t(`calendar.errors.${errorType}`, { resource: this.resourceLabel }));
      if (errorMessages.length) {
        const joinedErrorMessage = errorMessages.join(' / ');
        this.recordError(joinedErrorMessage);
      } else {
        this.$information.capture({
          error: bookingResponse,
          inform: true,
          component: 'calendarControl',
          method: 'handleBookingError',
          tags: { possibleReason: 'unexpected error message from api' }
        });
      }
    },
    showCancellationMessage (bookingDateAndTime, bookingType) {
      const message = bookingType === this.$CONSTANTS.BOOKING_TYPES.WAITING_LIST
        ? `${this.$t('account.waitinglist.cancelled', { date: bookingDateAndTime })}`
        : `${this.$t('account.booking.cancelled', { date: bookingDateAndTime })}`;
      this.$information.alert({
        showClose: true,
        message,
        type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS,
        duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.LONG
      });
    },
    redirectToSignIn () {
      let route;
      if (this.$route.name === 'session') {
        route = this.$route.fullPath;
      }
      const goTo = this.mq.lgPlus ? 'calendar' : 'timeslotDetails';
      this.$router.replace({
        name: 'signIn',
        query: {
          to: goTo,
          route
        }
      });
    },
    redirectToHome () {
      this.$router.replace({ name: 'home', query: {} });
    },
    prepareBookingDateAndTime (isoDate) {
      return this.$datetime.getLocalMediumDateAndTime(
        this.$datetime.fromISODate(isoDate)
      );
    },
    canShowLiveStreamLink (startDate) {
      const startDateJs = this.$datetime.fromISODate(startDate);
      return this.$datetime.isNotLaterThanXMinutesFromNow(startDateJs, this.$CONSTANTS.CALENDAR.COUNTDOWN_MINUTES_TO_SHOW_LIVESTREAM);
    },
    trackBooking () {
      if (this.facebookTrackingId) {
        this.$information.track.bookingMadeWithFacebook();
      }
      this.$information.track.bookingMadeWithGoogle();
    },
    recordError (error) {
      this.$information.capture({ error });
      this.$information.alert({
        message: error,
        duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
      });
    }
  }
};
