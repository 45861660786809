import {
  memberAPI,
} from '../request';
const BASEURL = 'contracts';
export default {
  askTermination: (contractId, params) => {
    return memberAPI.post(`${BASEURL}/${contractId}/request-termination`, params);
  },
  getContract: (contractId) => {
    return memberAPI.get(`${BASEURL}/products/${contractId}`);
  }
};
