import {
  memberAPI,
  publicAPI
} from '../request';
import { findDomain, adaptUrl } from '@/utils/url';
export default {
  getCoachesFromPublicApi: (zoneId) => {
    const url = adaptUrl(':domain/coaches', {
      params: {
        domain: findDomain()
      },
      queries: {
        zoneId
      }
    });
    return publicAPI.get(url);
  },
  getCoachesFromMembersApi: (zoneId) => {
    const url = adaptUrl('coaches', {
      queries: {
        zoneId
      }
    });
    return memberAPI.get(url);
  }
};
