import {
  memberAPI
} from '../request';
export default {
  loadUserScheduledPayments: () => memberAPI.get('scheduled-payments'),
  loadUserUnpaidSchedules: () => memberAPI.get('scheduled-payments/unpaid'),
  payUnpaidSchedules: ({
    paymentScheduleIds,
    paymentCardId,
    useCredit
  }) => memberAPI.post('scheduled-payments/unpaid/immediatePayment', {
    paymentScheduleIds,
    paymentCardId,
    useCredit
  })
};
