<template>
  <el-dropdown
    @command="onChangeLanguage"
    trigger="click">
    <div class="el-button el-button--text">
      <span class="el-dropdown-link  is-uppercase is-bold">
        {{ currentLanguage }}
      </span>
    </div>
    <template v-slot:dropdown>
      <el-dropdown-menu >
        <el-dropdown-item command="en">English</el-dropdown-item>
        <el-dropdown-item command="es">Español</el-dropdown-item>
        <el-dropdown-item command="fr">Français</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters('user', ['language']),
    currentLanguage () {
      const language = this.language || 'fr';
      return language.toUpperCase();
    }
  },
  methods: {
    ...mapActions('user', ['changeLanguage']),
    onChangeLanguage (language) {
      this.changeLanguage(language);
      this.$router.go(0);
    }
  }
};
</script>
