<template>
  <div class="video-gallery__activity_grouping" >
    <DeciTitle :text="$t('videoGallery.byActivity')" size="medium" class="pb-xs" />
    <div class="flex gap-lg wrap container-activities">
      <interactive-div v-for="(grouping, index) in groupings" :key="index" class="activity-grouping__grouping-image"
        @on-interaction="onOpenActivityGrouping(grouping)">
        <img v-if="grouping.backgroundImage" class="grouping-image__background" :src="grouping.backgroundImage"/>
        <div v-else class="grouping-image__background--not-found">
          <i class="fas fa-running" />
        </div>
        <span class="grouping-image__title truncate pt-xxs">{{ grouping.name }}</span>
      </interactive-div>
    </div>
  </div>
</template>

<script>
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';
export default {
  name: 'VideoActivityGrouping',

  props: {
    groupings: Array
  },

  components: {
    DeciTitle
  },

  methods: {
    videoActivityGroupingOpened ({ redirectToVideosByActivityPage, selectedActivity }) {
      redirectToVideosByActivityPage(selectedActivity);
    },
    onOpenActivityGrouping (activityGrouping) {
      this.videoActivityGroupingOpened({
        redirectToVideosByActivityPage: this.redirectToVideosByActivityPage,
        selectedActivity: activityGrouping.id
      });
    },
    redirectToVideosByActivityPage (selectedActivity) {
      this.$router.push({ name: 'videosByActivity', params: { id: selectedActivity } });
    }
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.video-gallery__activity_grouping{
  text-align: center;

  .activity-grouping__grouping-image{
    display: flex;
    max-width: 338px;
    max-height: 99px;
    flex-direction: column;
    .grouping-image__title{
      font-weight: @heavy-font-weight;
      font-size:0.8rem;
      text-transform: capitalize;
    }

    .grouping-image__background{
      width: 338px;
      height: 80.50px;
      object-fit: cover;
      &:hover{
        .box-shadow();
      }
    }
    .grouping-image__background--not-found{
      height: 80.5px;
      width: 338px;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 4rem;
      padding-top: 0.5rem;
      .fa-running{
        vertical-align: super;
      }
      &:hover{
        .box-shadow();
      }
    }

  }

}

.container-activities {
  max-width: 776px;
}

@media screen and (max-width: 500px) {
  .container-activities {
    justify-content: center;
  }
}
@media (min-width:500px){
  .video-gallery__activity_grouping{
    .activity-grouping__grouping-image{
      .grouping-image__title{
        background-color: initial;
      }
    }
  }
}

@media (min-width:992px){
  .video-gallery__activity_grouping{
    text-align: left;
    .activity-grouping__grouping-image{
      .grouping-image__title{
        background-color: initial;
      }
    }
  }
}
</style>
