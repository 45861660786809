<template>
  <div class="flex gap-xs w-full items-center" :class="cashPayment === false && product.regularTotalPrice ? 'flex-col' : null">
    <productPriceLine beforePromotion :quantity="quantity" :cashPayment="cashPayment" :scheduler="product.regularScheduler" :period="displayPrice.period"
                      :currency="displayPrice.currency" :totalPrice="product.regularTotalPrice" :schedulePeriodType="product.schedulePeriodType" :schedulePeriodUnit="product.schedulePeriodUnit"
                      v-if="showRegularPrice"/>
    <productPriceLine :quantity="quantity"
                      :cashPayment="cashPayment"
                      :scheduler="product.scheduler"
                      :period="displayPrice.period"
                      :credits="product.remainingCredit"
                      :currency="displayPrice.currency"
                      :totalPrice="product.totalPrice"
                      :schedulePeriodType="product.schedulePeriodType"
                      :schedulePeriodUnit="product.schedulePeriodUnit"
                      :configSaleMode="configSaleMode"
                      :class="{ 'flex-grow': product.remainingCredit > 0 }"/>
    <i v-if="isRenewable" class="fs-12 text-valid flex fa fa-arrow-rotate-right" :title="$t('cart.autoRenewal')"></i>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import productPriceLine from './lineProductPrice.vue';
export default {
  name: 'product-price',
  mixins: [ProductsControl],
  components: { productPriceLine },
  props: {
    bigPrice: Boolean,
    isRenewable: Boolean,
    quantity: [Number, String],
    cashPayment: Boolean,
    configSaleMode: String,
    product: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('config', ['currency']),
    displayPrice () {
      return this.productPricing(this.product, this.cashPayment === false);
    },
    showRegularPrice () {
      if (this.product.promotion?.code) {
        return this.product.scheduler ? this.cashPayment !== null : this.cashPayment === null;
      }
      return false;
    }
  }
};
</script>

<style lang="less" scoped>
.no-wrap {
  white-space: nowrap
}
.container-price {
  gap: 2px;
}
</style>
