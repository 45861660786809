<template>
  <form v-if="wallets.length > 0">
    <div class="text-left mb-sm">{{$t('cart.choosePaymentMode')}}</div>
    <Ariradio
      class="mb-sm"
      v-for="wallet in wallets" :key="wallet.id"
      name="wallet"
      :value="wallet.id"
      v-model="selectedWallet"
      @change="$emit('update:modelValue', wallet.id)"
    >
      <div class="flex items-center gap-xxs">
        <i :class="'fa-brands fa-lg fa-cc-' + wallet.cardType.toLowerCase()"></i>
        <span>{{wallet.cardNumber}} - {{ $t('profile.paymentMethods.expirationDate') }} {{ DateTime.fromJSDate(new Date(wallet.cardExpiration)).toFormat('LL/yyyy')}}</span>
      </div>
    </Ariradio>
  </form>
</template>

<script>
import {DateTime} from "luxon";
import {mapState} from "vuex";

export default {
  name: 'ChoosePaymentMode',
  data () {
    return {
      selectedWallet: this.modelValue || null
    };
  },
  props: {
    modelValue: {}
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    DateTime() {
      return DateTime;
    },
    ...mapState('user', ['userBalance', 'wallets']),
  },
  mounted () {
    this.handleAutoSelectWallet();
  },
  methods: {
    handleAutoSelectWallet () {
      const favoriteWallet = this.wallets.find(wallet => wallet.isFavorite);
      if(this.wallets.length === 1 || favoriteWallet) {
        this.selectedWallet = this.wallets.length === 1 ? this.wallets[0].id : favoriteWallet.id;
        this.$emit('update:modelValue', this.selectedWallet);
      }
    }
  },
  watch: {
    wallets () {
      this.handleAutoSelectWallet();
    }
  },
};
</script>

<style lang="less" scoped>

</style>
