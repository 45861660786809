import axios from 'axios';
import store from '@/store';
import browserStorage from '@/services/browserStorage';

// we are using local mock server for testing endpoint integration
const BASE_URL = import.meta.env.VITE_VUE_APP_URL_API;

export const COMMON_URLS = {
  PUBLIC_URL: 'public/v1/',
  MEMBERS_URL: 'members/v1/',
  DECIPLUS_MEMBERS_URL: 'deciplus-members/v1/',
  BASE_URL
};
export const publicAPI = axios.create({
  baseURL: BASE_URL + COMMON_URLS.PUBLIC_URL
});

export const memberAPI = axios.create({
  baseURL: BASE_URL + COMMON_URLS.MEMBERS_URL,
  headers: { 'Deciplus-Client-Type': 'web_app' }
});

export const deciplusMemberAPI = axios.create({
  baseURL: BASE_URL + COMMON_URLS.DECIPLUS_MEMBERS_URL,
  headers: { 'Deciplus-Client-Type': 'web_app' }
});

memberAPI.interceptors.response.use(
  (response) => {
    if (response?.headers['x-access-token']) {
      browserStorage.setDomainToken(response?.headers['x-access-token']);
    }
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);
memberAPI.interceptors.request.use(
  (config) => {
    if (store.state.user.domainToken) {
      config.headers['x-access-token'] = store.state.user.domainToken;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

deciplusMemberAPI.interceptors.request.use(
  (config) => {
    if (store.state.user.deciplusToken) {
      config.headers['x-access-token'] = store.state.user.deciplusToken;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
