let currentDomain = '';

function findDomain () {
  if (currentDomain) {
    return currentDomain;
  }
  const maybeDomain = new URL(window.location.href).pathname.split('/')[1];
  const domain = maybeDomain || new URL(window.location.href).hash.split('/')[1]; // because element 0 is ''
  if (domain !== 'errorPage') {
    currentDomain = domain;
  }
  return currentDomain;
}

function getOnlineShopLink () {
  return `${window.location.origin}/${findDomain()}/shop`;
}
function getHomeLink () {
  return `${window.location.origin}/${findDomain()}/home`;
}
function getVideoGalleryLink () {
  return `${window.location.origin}/${findDomain()}/videos`;
}

function getVideoDetailsLink (videoId) {
  return `${window.location.origin}/${findDomain()}/video/${videoId}`;
}

function getHomePageLink () {
  return `${window.location.origin}/${findDomain()}/home`;
}

function adaptUrl (url, { params, queries } = {}) {
  url = _replaceParams(url, params);
  url = _replaceQueries(url, queries);
  return url;
}

function _replaceParams (url, params) {
  for (const param in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(param)) {
      url = url.replace(`:${param}`, params[param]);
    }
  }
  return url;
}

function _replaceQueries (url, queries) {
  if (!queries) {
    return url;
  }
  url = `${url}?`;
  for (const query in queries) {
    // eslint-disable-next-line no-prototype-builtins
    if (queries.hasOwnProperty(query) && queries[query]) {
      url = _addQuery(url, query, queries[query]);
    }
  }
  return url.slice(0, -1);
}

function _addQuery (url, queryLabel, queryValue) {
  const isArrayObject = typeof queryValue === 'object';
  if (isArrayObject) {
    queryValue.map(queryItem => {
      url = `${url}${queryLabel}[]=${queryItem}&`;
    });
  } else {
    url = `${url}${queryLabel}=${queryValue}&`;
  }
  return url;
}

function buildDeciLogoUrl (logoPath) {
  return logoPath ? `https://${findDomain()}.deciplus.pro/${logoPath}` : null;
}

function findCenterCode (reservationLink) {
  if (reservationLink) {
    const resaDomain = reservationLink.replace('.deciplus.pro', '');
    return resaDomain.replace('resa-', '');
  }
}

export {
  findDomain,
  adaptUrl,
  getOnlineShopLink,
  getVideoGalleryLink,
  getVideoDetailsLink,
  getHomePageLink,
  buildDeciLogoUrl,
  findCenterCode,
  getHomeLink
};
