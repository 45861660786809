import {
  memberAPI
} from '../request';
export default {
  loadUserInvoices: () => memberAPI.get('invoices'),
  openInvoice: (invoiceId) => memberAPI.get(
    'invoices/:invoiceId'.replace(':invoiceId', invoiceId),
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    }
  )
};
