<template>
  <ariModal v-if="show" fitContent :showHeader="false" :showClose="false">
    <template v-slot:body>
      <h2 class="text-lg font-semibold ariane-mb-xs">{{ $t('warning') }}</h2>
      <div class="ariane-mb-xs"> {{ $t('cart.alert.message1') }}</div>
      <div class="alert"> {{ $t('cart.alert.message2') }}</div>
    </template>
    <template #footer>
      <div class="flex justify-end gap-xs">
        <AriButton :content="$t('yes')" outlined @click="close(true)" />
        <AriButton :content="$t('no')" filled @click="close()" />
      </div>
    </template>
  </ariModal>
</template>

<script>

export default {
  name: 'quantityAlertModal',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close (response = false) {
      this.$emit('on-close', response);
    }
  }
};
</script>

<style lang="less" scoped>
.alert {
  color: var(--ariane-accent-text-error);
  font-weight: 500;
}
</style>
