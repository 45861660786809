<template>
  <div class="w-6/12">
    <div class="or-container">
      <div class="text">
        <span>{{$t('authenticate.signInIfHasAccount')}}</span>
      </div>
    </div>
    <AriButton class="w-full self-center" filled :content=" $t('authenticate.signIn')" @click="redirectToSignIn"/>
  </div>
</template>

<script>
import AuthControl from '../mixins/authControl.js';

export default {
  name: 'OrSignIn',
  mixins: [AuthControl],
  methods: {
    redirectToSignIn () {
      if (this.goToRouteReminder) {
        this.$router.push({
          name: 'signIn',
          query: this.$router.currentRoute.value.query
        });
      } else {
        this.$router.push('signIn');
      }
    }
  }
};
</script>
