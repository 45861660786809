<template>
  <div class="grid-content grid-content-right">
    <div v-if="logoUrl" class="landing-visual">
      <img
        :src="logoUrl"
        class="el-image__inner landing-img"
        :title="$t('goToHome')"
        :alt="$t('goToHome')"
      />
    </div>
  </div>
</template>

<script>
import { buildDeciLogoUrl } from '@/utils/url';
import { mapGetters } from 'vuex';

export default {

  computed: {
    ...mapGetters('domain', ['getZoneLogoPath']),
    logoUrl () {
      return buildDeciLogoUrl(this.getZoneLogoPath);
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../../assets/styles/vars.less';
@import '../../../assets/styles/mixins.less';
.landing-visual{
  position: relative;
  min-height:~"calc(100vh - 150px)";
  text-align: right;
  display: flex;
  align-items: center;
  img{
    height: auto;
    .rectangle-rounded();
    max-height: @main-section-height;
    min-width: 80%;
  }
}
.landing-img{
  object-fit: contain;
}
</style>
