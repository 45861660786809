 <template>
  <div>
    <NarrowContainer
      :canGoBack="true"
    >
      <ManageBalance />
    </NarrowContainer>
  </div>
</template>

<script>
import ManageBalance from '@/modules/products/components/ManageBalance.vue';
import NarrowContainer from '@/layouts/NarrowContainer.vue';

export default {
  name: 'ManageBalancePage',

  components: {
    ManageBalance,
    NarrowContainer
  },

  mounted () {
    setTimeout(() => window.scroll(0, 0), 100);
  }
};
</script>

<style>

</style>
