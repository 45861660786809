<template>
  <div class="flex justify-between gap-sm w-full md:items-center" :class="{ 'flex-col md:flex-row': colMobile }">
    <div class="flex flex-col">
      <span class="title-item-timeslot" >
        <span v-if="label">{{label}}</span>
        <slot name="label" />
      </span>
      <span class="content-item-timeslot">{{content}}</span>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ItemTimeslot',
  data () {
    return {
    };
  },
  props: {
    label: String,
    content: String,
    colMobile: Boolean
  }
};
</script>

<style scoped>
.title-item-timeslot {
  @apply text-primary text-sm font-medium;
}
.content-item-timeslot {
  @apply text-secondary text-sm;
}
</style>
