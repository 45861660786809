<template>
  <div>
    <iframe
      :src="videoPlayerLink"
      name="deciplusvideoplayer"
      width="100%"
      frameborder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      class="integrated-player-iframe"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    videoPlayerLink: String
  }
};
</script>

<style lang="less">
.integrated-player-iframe{
  min-height: 200px;
}
@media (min-width: 500px){
  .integrated-player-iframe{
    min-height: 300px;
  }
}
@media (min-width:992px){
  .integrated-player-iframe{
    min-height: 400px;
  }
}
</style>
