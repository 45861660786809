<template>
    <div class="card-item-shop-presta card-item-presta" :class="[stateCardItem]" @click="onCardSelection(item.id, $event)">
      <div class="flex flex-col">
        <div class="flex justify-between items-center content-title-shop">
          <span class="title-item-shop ubuntuMedium gap-xs items-center">
            <span v-if="item.recommendedConfig && item.recommendedConfig.quantity !== 1" class="flex text-secondary text-xs font-medium whitespace-nowrap">
              x{{quantityDisplayed}}
            </span>
            <span class="card-title">
              <i v-if="isInCart(item.id)" class="fas fa-shopping-cart shop-icon"></i>
              {{item.name}}
            </span>
          </span>
          <AriButton id="shop-detail-btn" fontSize="12" text removePaddingRight :content="$t('details')" @click="showDetailModal = true"></AriButton>
          <!-- <span  class="text-xs" @click="showDetailModal = true">{{ $t('details') }}</span> -->
        </div>
        <span class="ubuntuRegular duration-club-item-shop">
          <span  v-if="item.remainingCredit">{{quantityDisplayed * item.remainingCredit}} {{$tc('credits', quantityDisplayed * item.remainingCredit)}} - </span>
          <span  v-if="item.validityLength">{{item.validityLength}} {{$tc(`products.durations.${item.validityUnit}`, item.validityLength)}} </span>
          <span  v-if="isMultiSite"> {{item.validityLength ? ' - ' : ''}}{{validityZone(item) || selectedZone}}</span>
        </span>
      </div>
      
      <div class="flex gap-xs items-center">
        <ProductPrice :isRenewable="isRenewable(item)"
                      bigPrice
                      :quantity="quantityDisplayed"
                      :cashPayment="cashPayment"
                      :configSaleMode="configSaleMode"
                      :product="item"/>
      </div>
      <span class="item-description" :title="item.description">
        <div class="whitespace-pre-line" v-html="item.description"></div>
      </span>
      <div class="container-buttons" v-if="isSelectedCard">
        <AriButton v-if="showGiftButton" preIcon="fa fa-gift text-main" :class="isQuantityLocked(item.id)"
                  :title="getBtnTitle(item.id)" :disabled="isQuantityLocked(item.id)" :content="$t('cart.gift')"
                  outlined @click="openGiftModal()" />
        <AriButton v-if="showBuyButton" :title="getBtnTitle(item.id)" :disabled="isQuantityLocked(item.id)"
                :content="$t('cart.addToCart')" filled @click="addProductToCart(item.id)" class="w-full"/>
      </div>
    </div>
    <PrestaDetailModal v-if="showDetailModal" :show="showDetailModal" @on-close="showDetailModal = false" :prestation="item" />
    <GiftModal :selectedZone="selectedZone" v-if="showGiftModal" :show="showGiftModal" @on-close="onGiftModalClose($event)" :prestation="item" />
</template>

<script>
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import PrestaDetailModal from './prestaDetailModal.vue';
import ProductPrice from '../../components/productPrice.vue';
import GiftModal from './giftModal.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'card-presta',
  mixins: [ProductsControl],
  components: {
    PrestaDetailModal,
    GiftModal,
    ProductPrice
  },
  emits: ['card-selected'],
  data () {
    return {
      showDetailModal: false,
      showGiftModal: false
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    selectedCard: String,
    selectedCategory: String,
    groupTitle: String,
    selectedZoneId: Number
  },
  computed: {
    ...mapGetters('domain', ['getZonesWithWebPayment', 'isMultiSite']),

    ...mapGetters('user', ['loggedInUser']),
    ...mapGetters('config', ['currency', 'subscriptionSaleMode', 'prepaidCardSaleMode']),
    ...mapGetters('cart', ['isQuantityLocked', 'isInCart', 'isRenewable']),
    selectedZone () {
      if (this.selectedZoneId) return this.getZonesWithWebPayment.find(z => z.id === this.selectedZoneId)?.clubName;
      else return null;
    },
    stateCardItem () {
      if (this.selectedCard === null) return '';
      else if (this.isSelectedCard) return 'card-selected';
      else return 'card-not-selected';
    },
    quantityDisplayed () {
      if (this.item.recommendedConfig && this.item.recommendedConfig.quantity !== 1) return this.item.recommendedConfig.quantity;
      else return 1;
    },
    configSaleMode () {
      return this.item.type === 'subscription' ? this.subscriptionSaleMode : this.prepaidCardSaleMode;
    },
    cashPayment () {
      return this.configSaleMode === this.$CONSTANTS.PRODUCT_PRICING_FORMATS.TOTAL || this.configSaleMode === this.$CONSTANTS.PRODUCT_PRICING_FORMATS.TOTAL_AND_UNIT_PRICE || this.selectedCategory === 'gift-card';
    },
    isSelectedCard () {
      return this.selectedCard === this.calculatedId;
    },
    showBuyButton () {
      return this.item.type !== this.$CONSTANTS.PRODUCT_TYPES.GIFT_CARD && this.item.showAddToCart !== false;
    },
    showGiftButton () {
      return this.item.isGiftable || this.item.type === this.$CONSTANTS.PRODUCT_TYPES.GIFT_CARD;
    },
    hasRecommendedConfig () {
      return this.item.recommendedConfig && this.item.recommendedConfig.quantity && this.item.recommendedConfig.quantity > 1;
    },
    calculatedId () {
      return `${this.item.id}-${this.groupTitle}`;
    }
  },
  methods: {
    ...mapActions('cart', ['addProduct']),
    openGiftModal () {
      if (!this.loggedInUser) {
        this.$information.alert({
          message: this.$t('cart.gift.alert'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.YELLOW_WARNING,
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.LONG
        });
        return this.redirectToSignIn();
      }
      this.showGiftModal = true;
    },
    onGiftModalClose (gift) {
      this.showGiftModal = false;
      if (gift) {
        this.addProductToCart(gift.item.id, gift.giftConfig);
      }
    },
    async addProductToCart (productId, giftConfig = null) {
      if (!this.loggedInUser) return this.redirectToSignIn(productId);
      const added = await this.addProduct({ id: productId, giftConfig });
      if (added) {
        this.$information.alert({
          message: this.$t('cart.service.added'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
      }
    },
    onCardSelection (id, event) {
      if (event.target.id !== 'shop-detail-btn') {
        this.$emit('card-selected', { id: this.calculatedId, event });
      }
    },
    getBtnTitle (id) {
      return this.isQuantityLocked(id) ? this.$t('cart.quatity.lock.message') : '';
    }
  }
};
</script>

<style lang="less">
.card-title {
  @apply text-primary font-medium items-center gap-xxs text-ellipsis overflow-hidden whitespace-nowrap;
}
.shop-icon {
  display: inline-block !important;
  @apply text-secondary-500;
}
.container-buttons {
  @apply flex gap-xxs justify-center items-center;
}
.card-item-presta {
  @apply py-sm px-default;
}
// On scope pas pour ne pas dupliquer le CSS avec cardProduct
.item-description {
  @apply line-clamp-3 text-secondary text-xs;
  height: 35px;
}
@media (max-width: 640px) {
  .item-description {
    display: none;
  }
}
.card-item-shop-presta {
  border: 1px solid;
  @apply cursor-pointer flex flex-col bg-content rounded border-default gap-sm;
}
.title-item-shop {
  @apply line-clamp-2 flex gap-xs overflow-hidden text-sm text-primary flex-row;
  -webkit-box-orient: horizontal;
  line-height: var(--ariane-font-size-3);
}
.duration-club-item-shop {
  @apply text-secondary text-xs break-words;
}
.item-price-currency {
  @apply text-primary text-xl whitespace-nowrap;
}
.item-font-light {
  @apply text-secondary;
  font-size: var(--ariane-font-size-2);
}
.item-credit {
  @apply text-primary;
  font-size: var(--ariane-font-size-3);
}
.card-selected {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.1);
}
.card-not-selected {
  opacity: 0.5;
  height: fit-content;
}
.initial-price {
  @apply line-through ml-xxs;
  span {
    @apply text-sm;
  }
}
@media (max-width: 1420px) {
  .container-buttons {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    > button {
      width: 100%;
    }
  }
}
</style>
