<template>
  <ariModal v-if="visibility" :modalTitle="$t('tunnel.freeProductTitle')" showClose @close="$emit('on-cancel')">
    <template v-slot:body>
      <div class="register-now-message">
        {{ $t('tunnel.registerNowForFreeProduct') }}
      </div>
    </template>
    <template #footer>
      <div class="register-now-actions">
        <AriButton :content="$t('cancel')" text @click="closeModal()" />
        <AriButton :content="$t('authenticate.signUp')" outlined @click="redirectToSignUp()"/>
      </div>
    </template>
  </ariModal>
</template>
<script>

export default {
  props: {
    visibility: Boolean
  },
  methods: {
    redirectToSignUp () {
      this.closeModal();
      this.$router.replace({
        name: 'signUp'
      });
    },
    closeModal () {
      this.$emit('on-cancel');
    }
  }
};
</script>
<style lang="less" scoped>
.register-now-message{
  margin-bottom: 20px;
}
.register-now-actions{
  display: flex;
  justify-content: space-between;
}
</style>
