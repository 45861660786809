<template>
  <InfiniteLoading :forceUseInfiniteWrapper="forceUseInfiniteWrapper" @infinite="infiniteHandler">
    <template v-slot:no-more> {{ noMoreMessage }} </template>
    <template v-slot:no-results>{{ noResultsMessage }}</template>
  </InfiniteLoading>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    InfiniteLoading
  },

  props: {
    forceUseInfiniteWrapper: String,
    noMoreMessage: String,
    noResultsMessage: String,
    infiniteHandler: {
      type: Function,
      required: true
    }
  }
};
</script>

<style>

</style>
