<template>
  <div class="grid-content videos-per-category">
    <div class="flex flex-col gap-xs">
      <div class="videos-per-category__go-back" v-if="showGoBackButton">
        <GoBack />
      </div>
      <h3 class="content-minititle">  {{ title }} </h3>
    </div>
    <div v-if="showVideos" class="videos-per-category__videos-container" >
      <div v-for="video in videos" :key="video.id" class="videos-per-category__video" >
        <VideoThumbnail :video="video" />
      </div>
      <InfiniteScroller
        forceUseInfiniteWrapper=".videos-per-category__videos-container"
        :infiniteHandler="infiniteHandler"
        noMoreMessage=""
        :noResultsMessage="$t('infiniteScroller.noResult')"
      />
    </div>
  </div>
</template>

<script>
import GoBack from '@/modules/common/generic/GoBack.vue';
import VideoThumbnail from './VideoThumbnail.vue';
import InfiniteScroller from '../../common/generic/InfiniteScroller.vue';
export default {
  name: 'ScrollingVideoList',

  components: {
    GoBack,
    VideoThumbnail,
    InfiniteScroller
  },

  props: {
    title: String,
    showGoBackButton: Boolean,
    showVideos: Boolean,
    videos: Array
  },

  methods: {
    infiniteHandler ($state) {
      this.$emit('on-infinite-scroll-trigger', $state);
    }
  }
};
</script>

<style lang="less">
.videos-per-category__videos-container{
  overflow-y: auto;
  text-align: center;
  .videos-per-category__video {
    padding: 1rem;
    display: inline-block;
    width: 100%;
  }
}
@media (min-width:500px){
  .videos-per-category__videos-container{
    .videos-per-category__video {
      width: 100%;
      max-width: 400px;
    }
  }
}
@media (min-width:992px){
  .videos-per-category__videos-container{
    text-align: left;
    .videos-per-category__video {
      width: 33%;
      min-width: 350px;
    }
  }
}
@media (min-width:1280px){
  .videos-per-category__videos-container{
    .videos-per-category__video {
      width: 20%;
      min-width: 350px;
    }
  }
}
</style>
