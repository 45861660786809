<template>
  <el-row class="page-content">
    <el-col :span="24">
      <div class="grid-content videos-per-category">
        <el-row class="flex">
          <div
            class="video-details__go-back"
            v-if="showGoBackButton"
          >
            <GoBack />
          </div>
          <div class="ml-auto">
            <AriButton v-if="showCart"
              class="video-details__cart-btn text-sm"
              preIcon="filled fas fa-shopping-cart"
              :content="cartSize.toString()"
              filled
              @click="goToCart()"
            />
          </div>
        </el-row>
        <div
          class="video-details__body"
        >
          <div
            class="video-details__half-area"
          >
            <div
              class="video-details__video"
            >
              <VideoPlayerIntegrated
                v-if="isIntegratedPlayerShown"
                :videoPlayerLink="videoPlayerLink"
              />
              <VideoPlayerExternal
                v-if="isExternalPlayerShown"
                :video="video"
              />
              <VideoPlayerBlocked
                v-if="isBlockedPlayerShown"
                :video="video"
                :showDirectVideoPurchase="isDirectVideoPurchaseShown"
                @direct-purchase="redirectToVideoProductPurchasePage(videoProductId)"
              />
            </div>
            <div
              class="video-details__details"
            >
              <div
                class="video-details__summary-details"
              >
                <div
                  class="video-details__summary-details-left"
                >
                  <div
                    class="video-details__title video-details__section"
                  >
                    {{ video.title }}
                  </div>
                  <div
                    class="video-details__coach video-details__section"
                    v-if="isCoachShown"
                  >
                    <CoachAvatar
                      :coachId="video.coach.id"
                      smallAvatar
                    />
                  </div>
                </div>
                <div
                  class="video-details__summary-details-right"
                >
                  <div
                    class="video-details__likes video-details__section"
                    v-if="isVideoLikesShown"
                  >
                    <VideoLikes
                      :liked="video.liked === 1"
                      :likesAmount="video.likes"
                      @on-toggle-like="onToggleLike"
                    />
                  </div>
                </div>
              </div>
              <div
                class="video-details__description video-details__section"
                v-if="isVideoDescriptionShown"
              >
                {{ video.description }}
              </div>
            </div>
          </div>
          <div
            class="video-details__half-area"
          >
            <div
              class="video-details__product-options"
            >
              <div
                class="video-details__section"
                v-if="isProductsForVideoShown"
              >
                <b>{{ $t('videoDetails.upgradeProducts') }}</b>
                <div
                  v-for="(product, index) in productOptions"
                  :key="index"
                >
                  <div
                    class="video-details__product-option"
                  >
                    <ProductCard
                      :product="product"
                      @click="onClickProduct(product)"
                    />
                  </div>
                </div>
              </div>
              <div
                class="video-details__other-videos video-details__section"
                v-if="isOtherVideosFromCoachShown"
              >
                <StaticVideoList
                  :title="$t('videoDetails.otherVideosFromCoach', { coach: video.coach.name })"
                  :videos="otherVideosFromCoach"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import GoBack from '@/modules/common/generic/GoBack.vue';
import VideoPlayerExternal from './components/VideoPlayerExternal.vue';
import VideoPlayerIntegrated from './components/VideoPlayerIntegrated.vue';
import VideoPlayerBlocked from './components/VideoPlayerBlocked.vue';
import { mapState, mapGetters } from 'vuex';
import CoachAvatar from '@/modules/common/CoachAvatar.vue';
import ProductCard from '@/modules/products/components/ProductCard.vue';
import VideoLikes from './components/VideoLikes.vue';
import StaticVideoList from './components/StaticVideoList.vue';
import ProductsControl from '@/modules/products/mixins/productsControl.js';

export default {
  name: 'VideoDetails',

  components: {
    GoBack,
    VideoPlayerExternal,
    VideoPlayerIntegrated,
    VideoPlayerBlocked,
    CoachAvatar,
    VideoLikes,
    ProductCard,
    StaticVideoList
  },

  mixins: [ProductsControl],

  mounted () {
    this.rearrangeUrlAfterTransaction();
    this.reactToTransactionResponse();
    this.onVideoPageLoaded();
    window.scroll(0, 0);
  },

  watch: {
    '$route.params.id': function () {
      this.resetVideoPageDetails();
      this.onVideoPageLoaded();
    }
  },

  data () {
    return {
      isGoBackButtonShown: false,
      videoId: parseInt(this.$router.currentRoute.value?.params?.id),
      video: {},
      selectedVideoProduct: {},
      productOptions: [],
      otherVideosFromCoach: [],
      videoProductId: 0,
      videoPlayerLink: '',
      isIntegratedPlayerShown: false,
      isExternalPlayerShown: false,
      isBlockedPlayerShown: false,
      isDirectVideoPurchaseShown: false,
      isVideoDescriptionShown: false,
      isCoachShown: false,
      isProductsForVideoShown: false,
      selectedProduct: null,
      isProductDetailsWindowShown: false,
      isVideoPurchaseWindowShown: false,
      isVideoLikesShown: false,
      isOtherVideosFromCoachShown: false
    };
  },

  computed: {
    ...mapGetters('cart', ['cartSize']),
    ...mapGetters('domain', ['getZonesWithWebPayment']),
    ...mapGetters('user', ['usersZoneDetails']),
    ...mapState('user', ['productToPurchaseLater']),
    upgradeProducts () {
      return this.video?.upgrade_price?.list || [];
    },
    showCart () {
      return this.cartSize > 0 && this.getZonesWithWebPayment.some(z => this.usersZoneDetails && z.id === this.usersZoneDetails.id);
    }
  },

  methods: {
    videoPageLoaded ({
      showGoBackButton, loadVideoDetails, videoIdentifier, memberHasAccessToVideo, videoFromIntegratedProvider, showIntegratedVideoPlayer, showExternalVideoLink, showBlockedVideoPlayer,
      videoHasProductOptions, showProductOptionsPurchase, videoIsOnUnitSale, loadVideoProductDetails, loadProductOptionsDetails, showDirectVideoPurchase, videoHasDescription, showVideoDescription,
      showVideoCoach, showLikes, loadOtherVideosFromCoach, coachHasOtherVideos, showOtherVideosFromCoach, handleVideoLoadingError
    }) {
      showGoBackButton();
      loadVideoDetails(videoIdentifier).then(() => {
        if (videoHasDescription()) {
          showVideoDescription();
        }
        showVideoCoach();
        if (memberHasAccessToVideo()) {
          showLikes();
          if (videoFromIntegratedProvider()) {
            showIntegratedVideoPlayer();
          } else {
            showExternalVideoLink();
          }
        } else {
          showBlockedVideoPlayer();
          if (videoHasProductOptions()) {
            loadProductOptionsDetails().then(() => {
              showProductOptionsPurchase();
            });
          }
          if (videoIsOnUnitSale()) {
            loadVideoProductDetails().then(() => {
              showDirectVideoPurchase();
            });
          }
        }
        window.scroll(0, 0);
        loadOtherVideosFromCoach().then(() => {
          if (coachHasOtherVideos()) {
            showOtherVideosFromCoach();
          }
        });
      })
        .catch(error => {
          handleVideoLoadingError(error);
        });
    },
    onVideoPageLoaded () {
      this.videoPageLoaded({
        showGoBackButton: this.showGoBackButton,
        loadVideoDetails: this.loadVideoDetails,
        videoIdentifier: this.videoId,
        memberHasAccessToVideo: this.memberHasAccessToVideo,
        videoFromIntegratedProvider: this.videoFromIntegratedProvider,
        showIntegratedVideoPlayer: this.showIntegratedVideoPlayer,
        showExternalVideoLink: this.showExternalVideoLink,
        showBlockedVideoPlayer: this.showBlockedVideoPlayer,
        videoHasProductOptions: this.videoHasProductOptions,
        showProductOptionsPurchase: this.showProductOptionsPurchase,
        loadProductOptionsDetails: this.loadProductOptionsDetails,
        loadVideoProductDetails: this.loadVideoProductDetails,
        videoIsOnUnitSale: this.videoIsOnUnitSale,
        showDirectVideoPurchase: this.showDirectVideoPurchase,
        videoHasDescription: this.videoHasDescription,
        showVideoDescription: this.showVideoDescription,
        showVideoCoach: this.showVideoCoach,
        showLikes: this.showLikes,
        loadOtherVideosFromCoach: this.loadOtherVideosFromCoach,
        coachHasOtherVideos: this.coachHasOtherVideos,
        showOtherVideosFromCoach: this.showOtherVideosFromCoach,
        handleVideoLoadingError: this.handleVideoLoadingError
      });
    },
    resetVideoPageDetails () {
      this.isGoBackButtonShown = false;
      this.videoId = parseInt(this.$router.currentRoute.value.params?.id);
      this.video = {};
      this.selectedVideoProduct = {};
      this.productOptions = [];
      this.otherVideosFromCoach = [];
      this.videoProductId = 0;
      this.videoPlayerLink = '';
      this.isIntegratedPlayerShown = false;
      this.isExternalPlayerShown = false;
      this.isBlockedPlayerShown = false;
      this.isDirectVideoPurchaseShown = false;
      this.isVideoDescriptionShown = false;
      this.isCoachShown = false;
      this.isProductsForVideoShown = false;
      this.selectedProduct = null;
      this.isProductDetailsWindowShown = false;
      this.isVideoPurchaseWindowShown = false;
      this.isVideoLikesShown = false;
      this.isOtherVideosFromCoachShown = false;
    },
    showGoBackButton () {
      this.isGoBackButtonShown = true;
    },
    async loadVideoDetails (videoId) {
      const response = await this.$services.HTTP.videos.getVideoDetails(videoId);
      this.video = response.data;
      this.videoProductId = this.video.productId;
    },
    memberHasAccessToVideo () {
      return this.video?.url;
    },
    videoFromIntegratedProvider () {
      return this.video?.provider === this.$CONSTANTS.VIDEO_PROVIDERS.VIMEO ||
        this.video?.provider === this.$CONSTANTS.VIDEO_PROVIDERS.YOUTUBE;
    },
    showIntegratedVideoPlayer () {
      this.setVideoPlayerLink();
      this.isIntegratedPlayerShown = true;
    },
    setVideoPlayerLink () {
      let providerLink;
      let providerVideoId;
      if (this.video.provider === this.$CONSTANTS.VIDEO_PROVIDERS.VIMEO) {
        providerLink = this.$CONSTANTS.VIDEO_PROVIDERS.VIMEO_LINK;
        providerVideoId = this.video.providerVideoId;
      } else if (this.video.provider === this.$CONSTANTS.VIDEO_PROVIDERS.YOUTUBE) {
        providerLink = this.$CONSTANTS.VIDEO_PROVIDERS.YOUTUBE_LINK;
        providerVideoId = this.video.providerVideoId;
      }
      this.videoPlayerLink = `${providerLink}${providerVideoId}`;
    },
    showExternalVideoLink () {
      this.isExternalPlayerShown = true;
    },
    showBlockedVideoPlayer () {
      this.isBlockedPlayerShown = true;
    },
    videoHasProductOptions () {
      return this.video?.upgrade_price?.list?.length;
    },
    showProductOptionsPurchase () {
      this.isProductsForVideoShown = true;
    },
    videoIsOnUnitSale () {
      return !this.video?.membershipIsRequired;
    },
    showDirectVideoPurchase () {
      this.isDirectVideoPurchaseShown = true;
    },
    videoHasDescription () {
      return this.video?.description;
    },
    showVideoDescription () {
      this.isVideoDescriptionShown = true;
    },
    showVideoCoach () {
      this.isCoachShown = true;
    },
    showLikes () {
      this.isVideoLikesShown = true;
    },
    async loadVideoProductDetails () {
      const { data } = await this.$services.HTTP.product.getProduct(this.videoProductId);
      this.selectedVideoProduct = data;
    },
    async loadProductOptionsDetails () {
      for (const product of this.video.upgrade_price?.list) {
        const { data } = await this.$services.HTTP.product.getProduct(product.id);
        this.productOptions.push(data);
      }
    },
    videoLikeToggled ({ videoIdentifier, toggleVideoLike, reloadVideoDetails }) {
      toggleVideoLike(videoIdentifier).then(() => {
        reloadVideoDetails(videoIdentifier);
      });
    },
    onToggleLike () {
      this.videoLikeToggled({
        videoIdentifier: this.video.id,
        toggleVideoLike: this.toggleVideoLike,
        reloadVideoDetails: this.loadVideoDetails
      });
    },
    toggleVideoLike (videoId) {
      return this.$services.HTTP.videos.likeVideo(videoId);
    },
    handleVideoLoadingError (error) {
      this.$information.capture({ error });
      this.$information.alert({
        message: this.$t('calendar.errors.GENERAL_ERROR')
      });
    },
    redirectToVideoProductPurchasePage (productId) {
      this.$router.push({ name: 'productDetails', params: { id: productId }, query: { to: 'video', id: this.videoId } });
    },
    async loadOtherVideosFromCoach () {
      const { data } = await this.$services.HTTP.videos.getVideosByCoach(this.video.coach.id);
      this.otherVideosFromCoach = data.rows.filter(video => video.id !== this.video.id && video.coach.id === this.video.coach.id);
    },
    coachHasOtherVideos () {
      return this.otherVideosFromCoach.length;
    },
    showOtherVideosFromCoach () {
      this.isOtherVideosFromCoachShown = true;
    },
    onClickProduct (product) {
      this.onOpenBuyWindow(product);
      this.redirectToVideoProductPurchasePage(product.id);
    },
    goToCart () {
      this.$router.push({ name: 'cart' });
    }
  }
};
</script>

<style lang="less">
@import "../../assets/styles/vars.less";
@import "../../assets/styles/mixins.less";

.video-details__cart-btn {
  margin: 0;
  margin-left: auto;
  padding: 2px 14px !important;
  font-size: 12px;
}
.video-details__section{
  padding-top: 1rem;
}
.video-details__title{
  font-size: @big-text-size;
  font-weight: @heavy-font-weight;
}
.video-details__price{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.video-details__coach{
  display: flex;
  vertical-align: middle;
}
.video-details__product-option{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  .el-card{
    width: 100%;
    display: inline-block;
    margin: 1%;
  }
}
.video-details__other-videos{
  text-align: initial;
  .content-minititle{
    text-align: left;
  }
}

@media (min-width: 500px){
  .video-details__body{
    .video-details__details{
      .video-details__summary-details{
        .video-details__summary-details-left{
          width: 60%;
          display: inline-block;
          .video-details__title{
            font-size: @bigger-text-size;
          }
        }
        .video-details__summary-details-right{
          width: 35%;
          text-align: right;
          display: inline-block;
        }
      }
    }
  }
}
@media (min-width: 992px){
  .video-details__body{
    display: flex;
    gap: 2rem;
    justify-content: space-around;
    .video-details__half-area{
      width: 50%;
      .video-gallery__static-video-list{
        text-align: center;
      }
    }
  }
}
</style>
