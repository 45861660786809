<template>
  <AriModal width="80%" v-if="(!!loggedInUser && mandatoryFields.length)" :showClose="false" :modalTitle="$t('modal.mandatoryFields.title')">
    <template #body>
      <div class="flex flex-col flex-grow body-modale-inner gap-default m-xs">
        <span class="mt-xs pr-default text-left text-base">
          {{ $t("modal.mandatoryFields.subtitle", { domain }) }}
        </span>
        <div class="bloc-mandatory">
          <AriForm ref="formMandatoryFiels" class="py-default">
            <template v-slot="form">
              <div v-for="inputItem in mandatoryFieldsInputFiltred" :key="inputItem.prop" class="form-item">
                <span>{{ inputItem.label }}</span>
                <ariInput class="w-full" :form="form" v-model="mandatoryFieldsForm[inputItem.prop]" :formatter="inputItem.formatter" />
              </div>
              <div class="form-item" v-show="mandatoryFields.includes('newsletter')">
                <span>{{ $t('newsletter') }}</span>
                <ariToggle v-model="mandatoryFieldsForm.newsletter" :formatter="required" />
              </div>
              <div class="form-item" v-show="mandatoryFields.includes('notification')">
                <span>{{ $t('notification') }}</span>
                <ariToggle v-model="mandatoryFieldsForm.notification" :formatter="required" />
              </div>
              <div class="form-item" v-if="mandatoryFields.includes('adr1')">
                <span>{{ $t('adr1') }}</span>
                <ariInput :form="form" class="w-full" v-model="mandatoryFieldsForm['adr1']" :formatter="required" />
              </div>
              <div class="form-item" v-if="mandatoryFields.includes('adr1')">
                <span>{{ $t('adr2') }}</span>
                <ariInput v-model="mandatoryFieldsForm['adr2']" class="w-full" />
              </div>
              <div class="form-item" v-if="mandatoryFields.includes('adr1')">
                <span>{{ $t('adr3') }}</span>
                <ariInput v-model="mandatoryFieldsForm['adr3']" class="w-full" />
              </div>
              <div class="bloc-city w-full">
                <ariInput :label="$t('postalCode')" :form="form" v-model="mandatoryFieldsForm.postalCode" :formatter="required" v-if="mandatoryFields.includes('postalCode')"/>
                <ariInput :label="$t('city')" :form="form" v-model="mandatoryFieldsForm.city" :formatter="required" v-if="mandatoryFields.includes('city')"/>
                <ariInput :label="$t('country')" :form="form" v-model="mandatoryFieldsForm.country" :formatter="required" v-if="mandatoryFields.includes('country')"/>
              </div>
              <div class="form-item" v-if="mandatoryFields.includes('birthdate')">
                <el-date-picker
                  v-model="mandatoryFieldsForm.birthdate"
                  :format="$t('configurations.dateFormat')"
                >
                </el-date-picker>
              </div>
              <div v-if="mandatoryFields.includes('certificateDate')" class="form-item">
                <span>{{ $t('certificateDate') }}:</span>
                <ariInput style="max-width: 140px; min-width: 140px;" :form="form" type="date" name="certificateDate" v-model="mandatoryFieldsForm.certificateDate" :formatter="required" />
              </div>
            </template>
          </AriForm>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-between flex-col sm:flex-row gap-lg">
        <AriButton text :content="$t('refuseAndLogout')" @click="refuseAndLogout" />
        <AriButton filled :content="$t('confirm')" @click="sendMandatoryDatas" />
      </div>

    </template>
  </AriModal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mail, required, phone } from '@/utils/inputFormatters';

const EMPTY_FORM = {
  name: '',
  surname: '',
  birthdate: null,
  email: '',
  cellphoneNumber: '',
  phoneNumber: '',
  newsletter: '',
  notification: '',
  adr1: '',
  adr2: '',
  adr3: '',
  postalCode: '',
  city: '',
  country: '',
  certificateDate: '',
  company: ''
};

export default {
  name: 'modalMandatoryFields',
  data () {
    return {
      required,
      mandatoryFieldsForm: { ...EMPTY_FORM },
      mandatoryFieldsInput: [
        { label: this.$t('name'), prop: 'name', formatter: [required]},
        { label: this.$t('surname'), prop: 'surname', formatter: [required]},
        { label: this.$t('email'), prop: 'email', formatter: [required, mail] },
        { label: this.$t('phoneNumber'), prop: 'phoneNumber', formatter: [required, phone]},
        { label: this.$t('cellphoneNumber'), prop: 'cellphoneNumber', formatter: [required, phone]},
        { label: this.$t('company'), prop: 'company'}
      ]
    };
  },
  mounted () {
    if (this.memberAppConfigs.LDC_nommageEntreprise) this.mandatoryFieldsInput[5].label = this.memberAppConfigs.LDC_nommageEntreprise;
  },
  methods: {
    refuseAndLogout  () {
      this.$store.dispatch('user/logout');
      this.$router.replace({ name: 'signIn' });
    },
    sendMandatoryDatas () {
      const isFormValid = this.$refs.formMandatoryFiels.validateForm();
      if (this.allMandatoryFieldsOK && isFormValid) {
        this.$services.HTTP.user.updateDomainUserInfo(this.allFields).then(() => {
          this.mandatoryFields = [];
          this.mandatoryFieldsForm = { ...EMPTY_FORM };
        });
      } else {
        this.$information.alert({
          message: this.$t('mandatoryFields.errors')
        });
      }
    }
  },
  // LDC_nommageEntreprise
  computed: {
    mandatoryFieldsInputFiltred () {
      return this.mandatoryFieldsInput.filter(field => this.mandatoryFields.includes(field.prop));
    },
    allFields () {
      const obj = {};
      this.mandatoryFields.forEach(field => {
        if (this.mandatoryFieldsForm[field]) obj[field] = this.mandatoryFieldsForm[field];
        if (field === 'adr1') {
          if (this.mandatoryFieldsForm['adr2']) obj['adr2'] = this.mandatoryFieldsForm['adr2'];
          if (this.mandatoryFieldsForm['adr3']) obj['adr3'] = this.mandatoryFieldsForm['adr3'];
        }
      });
      return obj;
    },
    allMandatoryFieldsOK () {
      let disabled = false;
      this.mandatoryFields.forEach(field => {
        if (!this.mandatoryFieldsForm[field]) disabled = true;
      });
      return !disabled;
    },
    ...mapGetters('user', ['loggedInUser']),
    ...mapState('user', ['userDomainInfo']),
    ...mapState('domain', ['domain']),
    ...mapState('config', ['memberAppConfigs']),
    mandatoryFields: {
      get () {
        const mandatoryForUsingExist = this.userDomainInfo?.missingFields?.mandatoryForUsing;
        if (mandatoryForUsingExist) return mandatoryForUsingExist;
        else return [];
      },
      set () {
        this.userDomainInfo.missingFields = [];
      }
    }
  }
};
</script>

<style>
  #mandatory-field-dialog .el-form-item__label {
    text-align: left;
  }
</style>

<style scoped>
.form-item span {
  white-space: nowrap;
}
.form-item {
  @apply flex gap-xs items-baseline py-xs flex-col sm:flex-row max-w-full w-full;
}
@media screen and (max-width: 640px) {
  .form-item {
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
.bloc-city {
  flex-direction: column;
  gap: 0px;
}
}
.fg-2 {
  flex-grow: 2;
}
.f-shrink-0 {
flex-shrink: 0;
flex-basis: 0;
}
.title {
  text-align: center;
  font-size: 28px;
  color: #333333;
}
.bloc-mandatory {
  @apply flex flex-col gap-default;
}
.decibutton {
  width: fit-content !important;
  display: flex;
}
.justify-end {
  justify-content: flex-end;
  align-items: flex-end;
}
.text-left {
  text-align: left;
}
.bloc-city {
  display: flex;
  gap: 5%;
}
</style>
