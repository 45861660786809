<template>
  <div
    class="coach-avatar"
    :class="{
      'coach-avatar--mini': miniAvatar,
      'coach-avatar--big': bigAvatar,
      'coach-avatar--small': smallAvatar,
      'coach-avatar--giganticOmega': giganticAvatarOmega
    }"
  >
    <div class="el-avatar el-avatar--circle">
      <img v-if="image" :src="image" class="coach-container__coach-image"/>
      <i v-if="!image" class="fas fa-user-alt" />
    </div>
    <div v-if="showName">
      <p class="coach-container__coach-name">
        {{ name }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CoachAvatar',

  props: {
    coachId: Number,
    bigAvatar: {
      type: Boolean,
      default: false
    },
    showName: {
      type: Boolean,
      default: true
    },
    miniAvatar: {
      type: Boolean
    },
    giganticAvatarOmega: {
      type: Boolean,
      default: false
    },
    smallAvatar: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('zone', ['coaches']),
    coach () {
      return this.coaches.find(coach => coach.id === this.coachId);
    },
    image () {
      return this.coach?.profilePictureUrl;
    },
    name () {
      return this.coach?.name;
    }
  }
};
</script>

<style lang="less">
@import "../../assets/styles/vars.less";
@import "../../assets/styles/mixins.less";
.el-avatar {
  align-items: center;
  justify-content: center;
  display: flex;
}
.coach-avatar{
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  .el-avatar{
    height: 50px;
    width: 50px;
    line-height: initial;
  }
}
.coach-avatar--big{
  text-align: center;
  .el-avatar{
    height: 90px;
    width: 90px;
    &:hover{
      .box-shadow();
    }
    .fa-user-alt{
      font-size: @biggest-text-size;
    }
  }
}
.coach-avatar--giganticOmega {
    .coach-container__coach-name{
    color: @main-color-light;
    font-size: 0.8rem;
    text-transform: capitalize;
  }
    .el-avatar{
    height: 128px;
    width: 128px;
    &:hover{
      .box-shadow();
    }
    .fa-user-alt{
      font-size: 3rem;
    }
  }
}
.coach-container__coach-name {
  text-align: left;
  text-transform: capitalize;
}
.coach-avatar--mini{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .el-avatar{
    height: 20px;
    width: 20px;
    .fa-user-alt{
      font-size: 8px;
    }
  }
  .coach-container__coach-name{
    color: @main-color-light;
    font-size: @standard-text-size;
    text-transform: capitalize;
  }
}
.coach-avatar--small{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .el-avatar{
    height: 35px;
    width: 35px;
  }
  .coach-container__coach-name{
    color: @main-color-light;
    font-size: @standard-text-size;
  }
}
</style>
