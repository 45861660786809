import * as datetime from '@/utils/datetime';

export function getDaySectionsTemplate () {
  return {
    morning: {
      timeslots: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      },
      isExpandable: false,
      isEmpty: true
    },
    day: {
      timeslots: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      },
      isExpandable: false,
      isEmpty: true
    },
    evening: {
      timeslots: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      },
      isExpandable: false,
      isEmpty: true
    }
  };
}

export function setDaySectionProperties (res) {
  for (const daySection in res) {
    for (const dayOfTheWeek in res[daySection].timeslots) {
      const timeslotCount = Object.keys(res[daySection].timeslots[dayOfTheWeek]).length;
      if (timeslotCount > 0) {
        res[daySection].isEmpty = false;
      }
      if (timeslotCount > 3) {
        res[daySection].isExpandable = true;
      }
    }
  }
  return res;
}

export function addTimeSlotToDaySection (res, newTimeslot) {
  const daySection = datetime.detectDaySection(newTimeslot.date);
  const dayOfTheWeek = datetime.getNumericDayOfWeek(newTimeslot.date) - 1;
  if (!res[daySection].timeslots[dayOfTheWeek]) {
    res[daySection].timeslots[dayOfTheWeek] = [];
  }
  res[daySection].timeslots[dayOfTheWeek].push(newTimeslot);
  return res;
}

export function sortEachDaySectionChronologically (res) {
  for (const daySection in res) {
    for (const day in res[daySection].timeslots) {
      res[daySection].timeslots[day].sort((a, b) => a.date - b.date);
    }
  }
  return res;
}
