<template>
  <div>
    <NarrowContainer :canGoBack="true">
      <vouchersHandler />
    </NarrowContainer>
  </div>
</template>

<script>
import NarrowContainer from '@/layouts/NarrowContainer.vue';
import vouchersHandler from '@/core/components/vouchersHandler.vue';

export default {
  name: 'RedeemGiftCardPage',
  components: {
    NarrowContainer,
    vouchersHandler
  },
  data () {
    return {};
  },
  methods: {
    goBackToPreviousPage () {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less">
</style>
