import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import router from '../router';

export const sentryInit = function (app) {
  Sentry.init({
    app,
    dsn: 'https://e2550ea493f54d819e5a20cfaeed6538@sentry.deciplus.pro/18',
    release: import.meta.env.VUE_APP_VERSION ? 'member-web@' + import.meta.env.VITE_VUE_APP_VERSION : undefined,
    environment: import.meta.env.VITE_NODE_ENV,
    integrations: [new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      // tracingOrigins: ["localhost", "my-site-url.com", /^\//],
    })],
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications'],
    beforeSend (event, hint) {
      const error = hint.originalException;

      // On n'envoie pas les erreurs d'authentification et de droits à Sentry
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        return null;
      }

      return event;
    }
  });
};

/**
 @param exception : Exception - l'exeption levée
 @param tags : Object - Tags clef - valeurs
 @param component : String - Le nom du composant ou du fichier en cours
 @param method : String - Le nom de la méthode où l'exception est levée
 */
export const captureExceptionWithTags = function (exception, tags, component, method) {
  Sentry.withScope(scope => {
    Object.keys(tags).forEach(key => {
      scope.setTag(key, tags[key]);
    });
    if (component) scope.setTag('component', component);
    if (method) scope.setTag('method', method);
    Sentry.captureException(exception);
  });
};
