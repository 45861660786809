<template>
  <div class="flex flex-col padding-5 gap-xxs" v-if="showAlertZone">
    <h2 class="error-title">
      {{ $t('alert.zoneNotVisibleOnline.title') }}
    </h2>
    <div class="text-align-center text ubuntuRegular">
      <div>
        {{ $t('alert.zoneNotVisibleOnline', { zoneName: usersZoneDetails.clubName }) }}
      </div>
      <div class="mt-default" v-if="note">{{ note }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CenterNotVisibleOnline',
  data () {
    return {
      showAlertZone: false
    };
  },
  props: {
    note: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.showAlertZone = this.loggedInUser && !this.usersZoneDetails.isVisibleOnline;
  },
  computed: {
    ...mapGetters('user', ['usersZoneDetails', 'loggedInUser'])
  }
};
</script>

<style lang='less' scoped>
  .error-title {
    text-align: center;
    color: #FF8D8D;
    font-family: 'Ubuntu';
    font-weight: 500;
    padding: 1rem;
  }
  .text {
    text-align: center;
    color: #6A6D7D;
    font-size: 1rem;
    font-weight: 400;
  }
  .padding-5 {
    padding: 5rem;
  }
</style>
