import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('user', ['loggedInUser'])
  },
  methods: {
    async getZoneDetails (zoneId) {
      let response;
      if (this.loggedInUser) {
        response = await this.$services.HTTP.zone.getZoneInfoFromMembersApi(zoneId);
      } else {
        response = await this.$services.HTTP.zone.getZoneInfoFromPublicApi(zoneId);
      }
      return response?.data;
    },
    formatPhoneNumber (rawNumber) {
      let formattedNumber = rawNumber;
      const isLikeFrenchNumber = formattedNumber?.length === 10;
      if (isLikeFrenchNumber) {
        const numberParts = [];
        let len;
        let i;
        for (i = 0, len = formattedNumber.length; i < len; i += 2) {
          numberParts.push(formattedNumber.substr(i, 2));
        }
        formattedNumber = numberParts.join(' ');
      }
      return formattedNumber;
    }
  }
};
