<template>
  <div :class="{ small, long, disabled }" class="input-number-container flex justify-center items-center">
    <div class="button-control" @click="changeValue(-1)" :class=" { disabled: (value <= min || disabled) }">
      <i class="fas fa-minus" />
    </div>
    <span class="flex padding-display">
      {{ value }}
    </span>
    <div class="button-control" :class=" { disabled: (value >= max || disabled) }" @click="changeValue(1)">
      <i class="fas fa-plus" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'inputNumber',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    small: Boolean,
    long: Boolean,
    value: Number,
    max: {
      type: [Number, String],
      default: 20
    },
    min: [Number, String],
    disabled: Boolean
  },
  watch: {
    min (newValue) {
      if (newValue > this.value) this.$emit('change', newValue);
    },
    max (newValue) {
      if (newValue < this.value) this.$emit('change', newValue);
    }
  },
  methods: {
    changeValue (step) {
      if (!this.disabled && this.value + step >= this.min && this.value + step <= this.max) {
        this.$emit('change', this.value + step);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.small .invite-others__counter-control {
  font-size: 0.5rem !important;
}

.input-number-container {
  height: 22px;
  @apply flex gap-sm;
}
.small .button-control {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.small .button-control .fas {
  font-size: 0.5rem;
}
.long {
  width: 100px;
  height: 32px;
}
.disabled {
  cursor: default !important;
  opacity: 0.5;
}
.button-control {
  @apply flex items-center justify-center cursor-pointer bg-default text-secondary p-xs;
  border: 1px solid #EDEDEE;
  color: #747474;
  border-radius: 50%;
}
.padding-display {
  padding: 0 0.6rem;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
</style>
