<template>
  <el-row :gutter="24" class="page-content availability-details-page">
    <el-col class="grid-content">
      <div class="availability-details__go-back">
        <GoBack />
      </div>
      <div>
        <FilterBarCalendar />
      </div>
      <div
        class="mobile-search-page__confirmation"
      >
        <AriButton
          class="w-full"
          filled
          :content="$t('confirm')"
          buttonType="strongFilled"
          @click="() => $router.push({name: 'calendar'})"
        />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import FilterBarCalendar from './components/FilterBarCalendar.vue';
import GoBack from '@/modules/common/generic/GoBack.vue';

export default {
  name: 'MobileSearchPage',

  components: {
    FilterBarCalendar,
    GoBack
  },

  mounted () {
    window.scroll(0, 0);
  }
};
</script>

<style lang="less">
.mobile-search-page__confirmation{
  padding-top: 2rem;
  text-align: center;
}
</style>
