<template>
  <el-row class="page-content">
    <el-col :span="24">
      <ScrollingVideoList
        :title="coachName ? $t('videosByCoach.title', { coach: coachName }) : ''"
        :showGoBackButton="goBackButtonIsShown"
        :showVideos="showVideos"
        :videos="videos"
        @on-infinite-scroll-trigger="infiniteHandler"
      />
    </el-col>
  </el-row>
</template>

<script>
import { infiniteVideoListScrolled } from '@/useCases/videoGallery.usecase.js';
import ScrollingVideoList from './components/ScrollingVideoList.vue';
import { mapState } from 'vuex';

export default {
  name: 'VideosByCoach',

  components: {
    ScrollingVideoList
  },

  mounted () {
    this.videosByCoachPageOpened({
      showGoBackButton: this.showGoBackButton,
      showVideosByCoachAsInfiniteScroll: this.showVideosByCoach
    });
    window.scroll(0, 0);
  },

  data () {
    return {
      selectedCoach: parseInt(this.$router.currentRoute?.value.params?.id),
      goBackButtonIsShown: false,
      showVideos: false,
      videos: [],
      page: 1
    };
  },

  computed: {
    ...mapState('zone', ['coaches']),
    coachName () {
      const firstVideo = this.videos[0];
      return firstVideo?.coach?.name || '';
    }
  },

  methods: {
    videosByCoachPageOpened ({ showGoBackButton, showVideosByCoachAsInfiniteScroll }) {
      showGoBackButton();
      showVideosByCoachAsInfiniteScroll();
    },
    showGoBackButton () {
      this.goBackButtonIsShown = true;
    },
    async showVideosByCoach () {
      this.showVideos = true;
    },
    infiniteHandler ($state) {
      infiniteVideoListScrolled({
        getMoreVideos: this.$services.HTTP.videos.getVideosByCoach,
        extractData: this.extractData,
        filters: { coachFilter: [this.selectedCoach], pageFilter: this.page },
        continueInfiniteScroll: this.continueInfiniteScroll($state),
        stopInfiniteScroll: this.stopInfiniteScroll($state),
        handleError: this.handleVideosByCoachLoadingError
      });
    },
    continueInfiniteScroll ($state) {
      return newData => {
        this.page += 1;
        this.videos.push(...newData);
        $state.loaded();
      };
    },
    stopInfiniteScroll ($state) {
      return () => {
        $state.complete();
      };
    },
    extractData ({ data }) {
      return data.rows;
    },
    handleVideosByCoachLoadingError (error) {
      this.$information.capture({ error });
      this.$information.alert({
        message: this.$t('calendar.errors.GENERAL_ERROR')
      });
    }
  }
};
</script>

<style lang="less">
</style>
