<template>
  <el-date-picker
    v-model="startDate"
    :type="selectionType"
    :placeholder="$t('configurations.enterDateInFormat')"
    :disabled-date="disableDates"
    :clearable="isClearable"
    name="DeciDatePicker"
    :format="$t('configurations.dateFormat')"
  >
  </el-date-picker>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    defaultDate: {
      /**
       * default date
       */
      type: Date,
      required: false
    },
    selectionType: {
      /**
       * date selection mode (date, week, etc.)
       */
      type: String,
      default: 'date',
      required: false
    },
    disableDates: {
      /**
       * optional function to prevent some dates to be selected
       */
      type: Function,
      required: false,
      default: () => { return false; }
    },
    isClearable: {
      /**
       * optional boolean to make date input clearable.
       */
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    startDate: {
      get () {
        return this.defaultDate || null;
      },
      set (selection) {
        this.$emit('on-date-change', selection);
      }
    }
  },
  data () {
    return {
    };
  },
  methods: {
  }
};
</script>

<style lang="less" scoped>
</style>
